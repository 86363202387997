import { uploadPopulationFile } from '@cck/backend/dist/evaluation/EvaluationFileUploader'
import { removeFileInEvaluation } from '@cck/backend/dist/evaluation/EvaluationRemover'
import {
  updateProgressState,
  updateEvaluationData
} from '@cck/backend/dist/evaluation/EvaluationUpdater'
import * as EvaluationParser from '@cck/backend/dist/evaluation/ExcelParser'
import { loadPopulationWithTemplate } from '@cck/backend/dist/evaluation/TemplateLoader'
import { NotificationType, sendMailAboutEvaluations } from '@cck/backend/dist/mail/MailManager'
import { getAll as LoadStaffs } from '@cck/backend/dist/rcm/StaffManager'
import { getOrCreateUser } from '@cck/backend/dist/utils/Admin'
import { BaseFile, FileState, FileType } from '@cck/common/dist/data/BaseFile'
import * as Evaluation from '@cck/common/dist/data/Evaluation'
import { getOwnerName, getPerformerName, Level, Staff } from '@cck/common/dist/data/Staff'
import { getKoName } from '@cck/common/dist/data/Translation'
import { getProjectId } from '@cck/common/dist/utils/Config'
import { parsePopulationFile } from '@cck/js/src/PopulationFileReader'
import { createNewPopulationFileFromExcelData } from '@cck/js/src/PopulationFileWriter'
import { useWorker } from '@koale/useworker'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Button from 'antd/lib/button'
import Checkbox from 'antd/lib/checkbox'
import Form from 'antd/lib/form'
import TextArea from 'antd/lib/input/TextArea'
import Modal from 'antd/lib/modal'
import Space from 'antd/lib/space'
import Typography from 'antd/lib/typography'
import _ from 'lodash'
import React from 'react'
import { useCookies } from 'react-cookie'

import AlertMessage, { AlertMessageHandler } from '../../common/AlertMessage'
import CircleBackdrop from '../../common/CircleBackdrop'
import { initTable } from '../../common/TableUtils'
import {
  EvaluationSelectAttribute,
  loadEvaluationDetails,
  loadEvaluationNames
} from '../common/EvaluationUtils'
import EvaluationSelectAccordion from '../view/EvaluationSelectAccordion'
import SamplingView from './SamplingView'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    overflowY: 'auto',
    minHeight: 0
  },
  content: {
    display: 'flex',
    flexDirection: 'column'
  },
  subcontent: {
    padding: 0
  },
  form: {
    marginBottom: 0
  }
}))

async function isChangedPopulation(
  evaluation: Evaluation.EvaluationItem,
  population: Evaluation.TableContent | undefined,
  populationFiles: BaseFile[] | undefined
): Promise<boolean> {
  if (!population && populationFiles && !_.isEmpty(populationFiles)) {
    // NOTE: 3MB 이상의 모집단 파일이 추가되어 수정없이 바로 업로드.
    return _.head(populationFiles)?.state !== FileState.SAVED_FILE
  }

  if (
    evaluation.content.files.population &&
    !_.isEmpty(evaluation.content.files.population.name) &&
    population &&
    population.rowCountExceptHeader > 0
  ) {
    const originalPopulation = await EvaluationParser.parsePopulationFromStorage(
      evaluation.base.name,
      evaluation.controlId,
      evaluation.content.files.population.name,
      parsePopulationFile
    )
    return (
      originalPopulation.state !== FileState.OK ||
      !_.isEqual(originalPopulation.excelData, population)
    )
  }
  return (population && population.rowCountExceptHeader > 0) || false
}

function isChangedSampling(
  evaluation: Evaluation.EvaluationItem,
  sampling: Evaluation.TableContent | undefined
): boolean {
  if (_.some(sampling?.headerRow, (header) => _.isEmpty(header))) {
    return false
  }
  if (
    sampling &&
    sampling.rowCountExceptHeader > 0 &&
    Evaluation.isOperationEvaluation(evaluation.content.data)
  ) {
    return !_.isEqual(evaluation.content.data.samplingResult, sampling)
  }
  return false
}

async function savePopulationAndSampling(
  evaluation: Evaluation.EvaluationItem,
  populationFileName: string,
  population: Evaluation.TableContent | undefined,
  populationFiles: Evaluation.EvaluationFile[] | undefined,
  sampling: Evaluation.TableContent | undefined,
  hasComment: boolean,
  comment: string,
  writeFileWorker: (templateFile: File, excelData: Evaluation.TableContent) => Promise<File>
): Promise<unknown> {
  const changedPopulation = await isChangedPopulation(evaluation, population, populationFiles)
  const changedSampling = isChangedSampling(evaluation, sampling)
  if (!changedPopulation && !changedSampling) {
    throw new Error('Not changed')
  }
  const updatedPromise: Promise<unknown>[] = []
  if (changedPopulation) {
    let fileName
    if (!_.isEmpty(populationFileName)) {
      fileName = populationFileName
    } else if (
      evaluation.content.files.population &&
      !_.isEmpty(evaluation.content.files.population?.name)
    ) {
      fileName = evaluation.content.files.population.name
    } else {
      fileName = `(${evaluation.base.name})(${evaluation.controlId})(모집단)모집단.xlsx`
    }

    const newFile: Evaluation.EvaluationFile = {
      type: FileType.Population,
      evaluationType: Evaluation.Type.Operation,
      name: fileName,
      evaluationName: evaluation.base.name,
      controlId: evaluation.controlId,
      state: FileState.OK,
      updateTime: '',
      updater: '',
      file: undefined,
      excelData: undefined,
      checked: false
    }
    if (populationFiles && !_.isEmpty(populationFiles)) {
      // NOTE: 3MB 이상의 모집단 파일이 추가되어 수정없이 바로 업로드.
      newFile.file = populationFiles[0].file
    } else if (population) {
      newFile.file = await loadPopulationWithTemplate(fileName, population, writeFileWorker)
    } else {
      throw new Error('Failed to upload population')
    }

    updatedPromise.push(uploadPopulationFile(newFile))
  }
  if (changedSampling) {
    const operationEval = evaluation.content.data as Evaluation.OperationEvaluationData
    const populationCount = population?.rowCountExceptHeader || operationEval.populationCount
    const samplingCount = sampling?.rowCountExceptHeader || 0

    let testContents: Evaluation.TableContent = {
      headerRow: [],
      columnCountIncludeNumbering: 0,
      rowCountExceptHeader: 0,
      rowObjectList: []
    }
    if (!_.isEmpty(operationEval.testContents)) {
      testContents = operationEval.testContents
    }
    initTable(testContents, [
      'No',
      'Attribute_1/결과',
      'Attribute_1/상세',
      '테스트 결과',
      '증빙자료 Ref'
    ])

    testContents.rowCountExceptHeader = samplingCount
    for (let i = 0; i < samplingCount; ++i) {
      if (!_.nth(testContents.rowObjectList, i)) {
        const row: Record<string, string> = {}
        _.forEach(testContents.headerRow, (header) => {
          row[header] = ''
        })
        row.No = (i + 1).toString()
        testContents.rowObjectList.push(row)
      }
    }

    operationEval.controlId = evaluation.controlId
    operationEval.populationCount = _.toString(populationCount)
    operationEval.samplingCount = samplingCount
    operationEval.testContents = testContents
    if (sampling) {
      operationEval.samplingResult = sampling
    }
    updatedPromise.push(
      updateEvaluationData(Evaluation.Type.Operation, evaluation.base.name, operationEval)
    )
  }
  if (hasComment) {
    let title
    if (changedSampling && changedPopulation) {
      title = '모집단 및 샘플링 작성 완료'
    } else if (changedPopulation) {
      title = '모집단 작성 완료'
    } else {
      title = '모집단 샘플링 작성 완료'
    }
    updatedPromise.push(
      sendMailAboutEvaluations(
        NotificationType.Custom,
        Evaluation.Type.Operation,
        evaluation.base.name,
        [evaluation.controlId],
        comment || '',
        getProjectId(),
        title
      )
    )
  }
  updatedPromise.push(
    updateProgressState(
      Evaluation.Type.Operation,
      evaluation.base.name,
      [evaluation.controlId],
      Evaluation.ProgressState.Performing
    )
  )

  return Promise.all(updatedPromise)
}

async function resetPopulationSampling(evaluation: Evaluation.EvaluationItem) {
  const allPromise = []
  if (
    evaluation.content.files.population &&
    _.includes([FileState.OK, FileState.SAVED_FILE], evaluation.content.files.population.state)
  ) {
    const populationFile = _.cloneDeep(evaluation.content.files.population)
    allPromise.push(removeFileInEvaluation(populationFile))
  }

  const operationEval = evaluation.content.data as Evaluation.OperationEvaluationData
  const emptyTable: Evaluation.TableContent = {
    headerRow: [],
    columnCountIncludeNumbering: 0,
    rowCountExceptHeader: 0,
    rowObjectList: []
  }

  operationEval.controlId = evaluation.controlId
  operationEval.populationCount = '0'
  operationEval.samplingCount = 0
  operationEval.testContents = emptyTable
  operationEval.samplingResult = emptyTable
  allPromise.push(
    updateEvaluationData(Evaluation.Type.Operation, evaluation.base.name, operationEval)
  )

  await Promise.all(allPromise)
  await updateProgressState(
    Evaluation.Type.Operation,
    evaluation.base.name,
    [evaluation.controlId],
    Evaluation.ProgressState.Unperformed
  )
}

const PopulationSampling: React.FC = () => {
  const classes = useStyles()
  const type: Evaluation.Type = React.useMemo(() => 'operation', [])
  const [select, setSelect] = React.useState<EvaluationSelectAttribute>({
    loading: false,
    selectedName: Evaluation.BASE_ITEM,
    names: [],
    bases: [],
    items: []
  })
  const [states, setStates] = React.useState<{
    selectedDetailId: string
    saveLoading: boolean
    open: boolean
    populationFileName: string
    population: Evaluation.TableContent | undefined
    populationFiles: Evaluation.EvaluationFile[] | undefined
    sampling: Evaluation.TableContent | undefined
    informMessage: string
    hasComment: boolean
  }>({
    selectedDetailId: '',
    saveLoading: false,
    open: false,
    populationFileName: '',
    population: undefined,
    populationFiles: [],
    sampling: undefined,
    informMessage: '',
    hasComment: false
  })
  const [staffs, setStaffs] = React.useState<Staff[]>([])
  const [userLevel, setUserLevel] = React.useState<Level>(Level.NORMAL)
  const alertRef = React.useRef<AlertMessageHandler>(null)
  const [cookies, setCookie] = useCookies([`${type}EvaluationName`])
  const [form] = Form.useForm()
  const [writeFileWorker] = useWorker(
    createNewPopulationFileFromExcelData as (
      templateFile: File,
      excelData: Evaluation.TableContent
    ) => File,
    {
      remoteDependencies: ['https://unpkg.com/exceljs@4.3.0/dist/exceljs.min.js']
    }
  )

  React.useEffect(() => {
    getOrCreateUser().then((user) => {
      setUserLevel(_.get(user, 'level'))
    })
    LoadStaffs().then((newStaffs) => {
      setStaffs(newStaffs)
    })
  }, [])

  React.useEffect(() => {
    loadEvaluationNames(type, cookies[`${type}EvaluationName`], select, setSelect)
    setStates({ ...states, selectedDetailId: '' })
  }, [type])

  const selectedEvaluation = React.useMemo(
    () => _.find(select.items, { controlId: states.selectedDetailId }),
    [states.selectedDetailId, select.items]
  )
  const copiedSelectedEvaluation = React.useMemo(() => {
    return _.cloneDeep(selectedEvaluation)
  }, [selectedEvaluation])

  const onEvaluationSelect = React.useCallback(
    (evaluationName: string): void => {
      loadEvaluationDetails(type, evaluationName, select, setSelect)
      setStates({ ...states, selectedDetailId: '' })
      setCookie(`${type}EvaluationName`, evaluationName)
    },
    [states, select]
  )

  const onClickSaveButton = React.useCallback(
    (
      evaluation: Evaluation.EvaluationItem,
      populationFileName: string,
      population: Evaluation.TableContent | undefined,
      populationFiles: Evaluation.EvaluationFile[] | undefined,
      sampling: Evaluation.TableContent | undefined
    ) => {
      setStates({
        ...states,
        saveLoading: true
      })

      isChangedPopulation(evaluation, population, populationFiles)
        .then((changedPopulation) => {
          // TODO(sangmuk): 통제담당자일 경우에는 population만 체크
          const newStates = {
            ...states,
            saveLoading: false
          }

          const hasUniqueHeaders =
            _.size(_.uniq(population?.headerRow)) === _.size(population?.headerRow)
          const changedSampling = isChangedSampling(evaluation, sampling)
          if (!hasUniqueHeaders) {
            alertRef.current?.showAlert('error', '중복되지 않은 헤더 값을 사용해주세요.')
          } else if (userLevel === 'UPLOADER' && !changedPopulation) {
            alertRef.current?.showAlert('error', '모집단을 작성해주세요.')
          } else if (
            (userLevel === 'NORMAL' || userLevel === 'ADMIN') &&
            !changedSampling &&
            !changedPopulation
          ) {
            alertRef.current?.showAlert('error', '모집단 혹은 모집단 샘플링을 작성해주세요.')
          } else {
            let informMessage = ''
            if (changedSampling && changedPopulation) {
              informMessage =
                '모집단과 모집단 샘플링이 변경되었습니다. 해당 내용을 저장하시겠습니까? (모집단 내용은 엑셀에 기록됩니다.)'
            } else if (changedSampling) {
              informMessage = '모집단 샘플링이 변경되었습니다. 해당 내용을 저장하시겠습니까?'
            } else {
              informMessage =
                '모집단이 변경되었습니다. 해당 내용을 저장하시겠습니까? (모집단 내용은 엑셀에 기록됩니다.)'
            }
            _.assign(newStates, {
              open: true,
              populationFileName,
              population,
              populationFiles,
              sampling,
              informMessage,
              hasComment: false
            })
          }
          setStates(newStates)
        })
        .catch((e) => {
          // eslint-disable-next-line no-console
          console.log('Failed to check population', e)
          setStates({
            ...states,
            saveLoading: false
          })
        })
    },
    [states, select]
  )

  const onSavePopulationAndSampling = React.useCallback(
    (
      evaluation: Evaluation.EvaluationItem,
      populationFileName: string,
      population: Evaluation.TableContent | undefined,
      populationFiles: Evaluation.EvaluationFile[] | undefined,
      sampling: Evaluation.TableContent | undefined,
      hasComment: boolean,
      comment: string
    ) => {
      setStates({
        ...states,
        saveLoading: true
      })
      savePopulationAndSampling(
        evaluation,
        populationFileName,
        population,
        populationFiles,
        sampling,
        hasComment,
        comment,
        writeFileWorker
      )
        .then((result) => {
          loadEvaluationNames(type, select.selectedName, select, setSelect)
          alertRef.current?.showAlert('success', '저장 완료')
        })
        .catch((e) => {
          // eslint-disable-next-line no-console
          console.log('Failed to save file ', e)
          if (e.message === 'Not changed') {
            alertRef.current?.showAlert('error', '모집단 혹은 모집단 샘플링을 작성해주세요.')
          } else {
            alertRef.current?.showAlert('error', '저장 실패')
          }
        })
        .finally(() => {
          form.resetFields()
          setStates({
            ...states,
            open: false,
            saveLoading: false
          })
        })
    },
    [states, select, alertRef]
  )

  return (
    <div className={classes.root}>
      <EvaluationSelectAccordion
        type={type}
        staffs={staffs}
        select={select}
        selectedDetailId={states.selectedDetailId}
        onEvaluationSelect={onEvaluationSelect}
        onEvaluationDetailClick={(evaluationDetail: Evaluation.EvaluationItem) => {
          setStates({
            ...states,
            selectedDetailId: evaluationDetail.controlId
          })
        }}
      />
      <div>
        {copiedSelectedEvaluation && (
          <SamplingView
            staffs={staffs}
            evaluation={copiedSelectedEvaluation}
            onSavePopulationAndSampling={(populationFileName, population, sampling) =>
              onClickSaveButton(
                copiedSelectedEvaluation,
                populationFileName,
                population,
                undefined,
                sampling
              )
            }
            onSavePopulationFileAndSampling={(populationFileName, populationFiles, sampling) => {
              onClickSaveButton(
                copiedSelectedEvaluation,
                populationFileName,
                undefined,
                populationFiles,
                sampling
              )
            }}
            onResetPopulationSampling={() => {
              Modal.confirm({
                title: '모집단과 샘플링을 초기화하시겠습니까?',
                content: '초기화시 보고서의 샘플링 내역과 테스트 수행 내역도 초기화됩니다.',
                okText: '초기화',
                okType: 'danger',
                cancelText: '취소',
                onOk() {
                  setStates({
                    ...states,
                    saveLoading: true
                  })
                  resetPopulationSampling(copiedSelectedEvaluation)
                    .then(() => {
                      loadEvaluationNames(type, select.selectedName, select, setSelect)
                      alertRef.current?.showAlert('success', '초기화 완료')
                    })
                    .catch((e) => {
                      // eslint-disable-next-line no-console
                      console.log('Failed to initialize population sampling', e)
                      alertRef.current?.showAlert('error', '초기화 실패')
                    })
                    .finally(() => {
                      setStates({
                        ...states,
                        saveLoading: false
                      })
                    })
                }
              })
            }}
          />
        )}
      </div>
      <Modal
        centered
        footer={[
          <Button key="back" onClick={() => setStates({ ...states, open: false })}>
            취소
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={states.saveLoading}
            onClick={() => {
              if (selectedEvaluation) {
                onSavePopulationAndSampling(
                  selectedEvaluation,
                  states.populationFileName,
                  states.population,
                  states.populationFiles,
                  states.sampling,
                  states.hasComment,
                  form.getFieldValue('comment')
                )
              }
            }}
          >
            저장
          </Button>
        ]}
        title={
          userLevel === Level.ADMIN || userLevel === Level.NORMAL
            ? '모집단 및 모집단 샘플링 저장'
            : '모집단 저장'
        }
        visible={states.open}
        onCancel={() => setStates({ ...states, open: false })}
      >
        <Space direction="vertical">
          <Typography.Text>{states.informMessage}</Typography.Text>
          <br />
          <Form
            form={form}
            layout="vertical"
            onValuesChange={(value) => {
              if (_.has(value, 'hasComment')) {
                setStates({ ...states, hasComment: value.hasComment })
              }
            }}
          >
            <Form.Item className={classes.form} name="hasComment" valuePropName="checked">
              <Checkbox>
                {getKoName('control', 'owner')}({getOwnerName(staffs, selectedEvaluation)})와{' '}
                {getKoName('control', 'performer')}({getPerformerName(staffs, selectedEvaluation)}
                )에게 알림을 보내시겠습니까?
              </Checkbox>
            </Form.Item>
            <Form.Item className={classes.form} label="코멘트" name="comment">
              <TextArea autoSize={{ minRows: 2, maxRows: 6 }} disabled={!states.hasComment} />
            </Form.Item>
          </Form>
        </Space>
      </Modal>
      <AlertMessage ref={alertRef} />
      <CircleBackdrop open={states.saveLoading || select.loading} />
    </div>
  )
}

export default PopulationSampling
