import { Type as EvaluationType } from '@cck/common/dist/data/Evaluation'
import Typography from 'antd/lib/typography'
import React from 'react'

import NoticeTextView from '../../common/NoticeTextView'

interface Props {
  evaluationType: EvaluationType
  evaluationName?: string
  controlId?: string
}

const FileNameNoticeView: React.FC<Props> = ({ evaluationType, evaluationName, controlId }) => {
  return (
    <NoticeTextView>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <Typography.Text>파일명은 다음의 형태로 업로드해야합니다.</Typography.Text>
        {evaluationType === EvaluationType.Operation && (
          <>
            <div
              style={{ display: 'flex', flexDirection: 'column', marginLeft: 8, marginRight: 8 }}
            >
              <Typography.Text type="danger" strong>
                ({evaluationName || '운영평가 이름'})({controlId || '통제 번호'}
                )(보고서)파일이름.xlsm
              </Typography.Text>
              <Typography.Text type="danger" strong>
                ({evaluationName || '운영평가 이름'})({controlId || '통제 번호'}
                )(모집단)파일이름.xlsx
              </Typography.Text>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Typography.Text type="danger" strong>
                ({evaluationName || '운영평가 이름'})({controlId || '통제 번호'}
                )(증빙)파일이름.확장자
              </Typography.Text>
              <Typography.Text type="danger" strong>
                ({evaluationName || '운영평가 이름'})({controlId || '통제 번호'}
                )(기타)파일이름.확장자
              </Typography.Text>
            </div>
          </>
        )}
        {evaluationType === EvaluationType.Design && (
          <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 8, marginRight: 8 }}>
            <Typography.Text type="danger" strong>
              ({evaluationName || '설계평가 이름'})({controlId || '통제 번호'}
              )(기타)파일이름.확장자
            </Typography.Text>
          </div>
        )}
      </div>
    </NoticeTextView>
  )
}

export default FileNameNoticeView
