import * as ControlManager from '@cck/backend/dist/rcm/ControlManager'
import * as CycleManager from '@cck/backend/dist/rcm/CycleManager'
import * as ProcessManager from '@cck/backend/dist/rcm/ProcessManager'
import * as RiskManager from '@cck/backend/dist/rcm/RiskManager'
import { PRCType, Process } from '@cck/common/dist/data/PRC'
import { Control } from '@cck/common/dist/data/PRCUtils'
import { Staff } from '@cck/common/dist/data/Staff'
import Risk from '@cck/common/dist/data/risk/AbstractRisk'
import { getControlClass, hasThreeCategories } from '@cck/common/dist/utils/Config'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Button from 'antd/lib/button'
import Form from 'antd/lib/form'
import Modal from 'antd/lib/modal'
import _ from 'lodash'
import React from 'react'

import { grey } from '../../../base/color'
import ControlEditView from './creationForm/ControlEditView'
import CycleSelectFormItem from './creationForm/CycleSelectFormItem'
import IdInputFormItem from './creationForm/IdInputFormItem'
import ProcessEditView from './creationForm/ProcessEditView'
import ProcessSelectFormItem from './creationForm/ProcessSelectFormItem'
import RiskEditView from './creationForm/RiskEditView'
import RiskSelectFormItem from './creationForm/RiskSelectFormItem'
import { FormTypes } from './creationForm/Types'

const useStyle = makeStyles((theme: Theme) => ({
  form: {
    border: grey.border,
    height: 'inherit',
    overflowY: 'auto'
  }
}))

async function createOrUpdateControl(
  itemId: string,
  control: Control,
  update: boolean
): Promise<string> {
  if (!update) {
    const controlId = await ControlManager.create(itemId, control)
    await ControlManager.updateMapWithStaff(controlId, control.owner)
  } else {
    await ControlManager.updateMapWithStaff(control.id, control.owner)
    await ControlManager.update(control)
  }

  return itemId
}

interface Props<T extends Process | Risk | Control> {
  type: 'process' | 'risk' | 'control'
  koType: string
  cycles: CycleManager.SimpleNode[]
  dataSource: T[]
  visible: boolean
  sizes: { width: number | null; height: number | null }
  editingControl?: Control
  staff?: Staff[]
  onCancel: (update: boolean) => void
  getReferenceIds?: (
    cycle: string,
    category: string,
    subCategory: string | undefined
  ) => Record<string, string>
  getReferenceNames?: (
    cycle: string,
    category: string,
    subCategory: string | undefined
  ) => Record<string, string>
  getSubReferenceIds?: (
    cycle: string,
    category: string,
    subCategory: string | undefined,
    processId: string
  ) => Record<string, string>
  getSubReferenceNames?: (
    cycle: string,
    category: string,
    subCategory: string | undefined
  ) => Record<string, string>
}

function PRCCreationForm<T extends Process | Risk | Control>({
  type,
  koType,
  cycles,
  dataSource,
  visible,
  sizes,
  editingControl,
  staff,
  onCancel,
  getReferenceIds,
  getReferenceNames,
  getSubReferenceIds,
  getSubReferenceNames
}: Props<T>): React.ReactElement<Props<T>> {
  const classes = useStyle()
  const [form] = Form.useForm()
  const [states, setStates] = React.useState({
    loading: false,
    cycles: {} as Record<string, string>,
    categories: {} as Record<string, Record<string, string>>,
    subCategories: {} as Record<string, Record<string, string>>
  })
  const [formValues, setFormValues] = React.useState<FormTypes>({
    cycle: '',
    category: '',
    subCategory: '',
    itemId: '',
    processId: '',
    processName: '',
    riskName: '',
    selectedStaff: undefined
  })

  const setNewItemId = (newItemId: string) => {
    form.setFieldsValue({ id: newItemId })
  }

  React.useEffect(() => {
    const newCycles: Record<string, string> = {}
    const newCategories: Record<string, Record<string, string>> = {}
    const newSubCategories: Record<string, Record<string, string>> = {}
    _.forEach(cycles, (cycle) => {
      newCycles[cycle.id] = cycle.name
      newCategories[cycle.id] = {}
      _.forEach(cycle.children, (category) => {
        newCategories[cycle.id][category.id] = category.name
        const subCategoryPath = `${cycle.id}-${category.id}`
        newSubCategories[subCategoryPath] = {}
        _.forEach(category.children, (subCategory) => {
          newSubCategories[subCategoryPath][subCategory.id] = subCategory.name
        })
      })
    })

    setStates({
      ...states,
      cycles: newCycles,
      categories: newCategories,
      subCategories: newSubCategories
    })
  }, [cycles])

  React.useEffect(() => {
    if (editingControl) {
      const control = _.cloneDeep(editingControl)
      const selectedStaff = _.find(staff, { id: control.owner })
      let processName = ''
      if (getReferenceNames) {
        processName = _.get(
          getReferenceNames(control.cycleNumber, control.categoryNumber, control.subCategoryNumber),
          control.processCode,
          ''
        )
      }

      const prepareValues = getControlClass().prepareEditingControls(control)
      const newFormValues = {
        cycle: control.cycleNumber,
        category: control.categoryNumber,
        subCategory: control.subCategoryNumber,
        processId: _.first(control.processIds) || '',
        riskId: _.first(control.riskIds) || '',
        itemId: control.id
      }
      form.setFieldsValue({
        ...newFormValues,
        ...control,
        ...prepareValues
      })

      setFormValues({
        ...formValues,
        ...newFormValues,
        selectedStaff,
        processName,
        riskName: control.riskName
      })
    }
  }, [editingControl])

  const initForm = React.useCallback((): void => {
    setFormValues({
      ...formValues,
      cycle: '',
      category: '',
      subCategory: '',
      processId: '',
      processName: '',
      riskName: '',
      itemId: '',
      selectedStaff: undefined
    })
    setNewItemId('')
  }, [formValues])

  const handleCancel = React.useCallback((): void => {
    onCancel(false)
    form.resetFields()
    initForm()
  }, [form, setFormValues])

  const handleOk = React.useCallback((): void => {
    setStates({ ...states, loading: true })
    form
      .validateFields()
      .then(() => {
        const newData = form.getFieldsValue()
        const newItem = {
          id: newData.id,
          name: newData?.name || '',
          cycleNumber: newData.cycle,
          cycleName: states.cycles[newData.cycle],
          categoryNumber: newData.category,
          categoryName: states.categories[newData.cycle][newData.category],
          subCategoryNumber: '01',
          subCategoryName: '01'
        }
        if (hasThreeCategories()) {
          newItem.subCategoryNumber = newData.subCategory
          newItem.subCategoryName =
            states.subCategories[`${newData.cycle}-${newData.category}`][newData.subCategory]
        }

        const cycleId = [
          newItem.cycleNumber,
          newItem.categoryNumber,
          newItem.subCategoryNumber
        ].join('-')
        if (type === 'process') {
          if (_.isEmpty(newItem.name)) {
            newItem.name = newData.narrative
          }

          return ProcessManager.create(newData.id, {
            ...newItem,
            type: PRCType.process,
            narrative: newData.narrative,
            cycleIds: [cycleId],
            riskIds: [],
            controlIds: []
          })
        } else if (type === 'risk') {
          return RiskManager.create(newData.id, {
            ...newItem,
            type: PRCType.risk,
            RoMM: newData?.RoMM || '',
            inherentRiskLevel: newData.inherentRiskLevel,
            cycleIds: [cycleId],
            processIds: [newData.processId],
            controlIds: []
          })
        } else {
          const { cycle, ...rest } = newData
          return createOrUpdateControl(
            newData.id,
            getControlClass().convertFormToControl(
              {
                type: PRCType.control,
                riskName: formValues.riskName,
                ...newItem,
                ...rest,
                cycleIds: [cycleId],
                processCode: newData.processId,
                riskNumber: newData.riskId
              },
              formValues.selectedStaff
            ),
            !_.isEmpty(editingControl)
          )
        }
      })
      .then(() => {
        onCancel(true)
        form.resetFields()
        initForm()
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.log('Failed to create', e)
      })
      .finally(() => setStates({ ...states, loading: false }))
  }, [states, formValues, form, editingControl])

  const names = React.useMemo(() => _.map(dataSource, (item) => item.name.trim()), [dataSource])

  const onFormChange = React.useCallback(
    (formName, info) => {
      let name = info.changedFields?.[0]?.name
      if (_.isArray(name) && !_.isEmpty(name)) {
        name = name[0] as string
      } else {
        name = name as string
      }
      const value = form.getFieldValue(name)
      if (name === 'cycle' && formValues.cycle !== value) {
        form.resetFields(['category', 'subCategory', 'processId', 'riskId'])
        setFormValues({
          ...formValues,
          cycle: value,
          category: '',
          subCategory: '',
          processId: '',
          processName: '',
          riskName: ''
        })
        setNewItemId('')
      } else if (name === 'category' && formValues.category !== value) {
        form.resetFields(['subCategory', 'processId', 'riskId'])
        setFormValues({
          ...formValues,
          category: value,
          subCategory: '',
          processId: '',
          processName: '',
          riskName: ''
        })
        if (type === 'process' && !hasThreeCategories()) {
          ProcessManager.findNumberToCreate(formValues.cycle, value, '01').then((number) => {
            setNewItemId(`${formValues.cycle}-${value}-01-${number}`)
          })
        } else {
          setNewItemId('')
        }
      } else if (name === 'subCategory' && formValues.subCategory !== value) {
        form.resetFields(['processId', 'riskId'])
        setFormValues({
          ...formValues,
          subCategory: value,
          processId: '',
          processName: '',
          riskName: ''
        })
        if (type === 'process') {
          ProcessManager.findNumberToCreate(formValues.cycle, formValues.category, value).then(
            (number) => {
              setNewItemId(`${formValues.cycle}-${formValues.category}-${value}-${number}`)
            }
          )
        } else {
          setNewItemId('')
        }
      } else if (name === 'processId') {
        // for risk
        form.resetFields(['riskId'])

        const processId = form.getFieldValue('processId')
        if (type === 'risk') {
          RiskManager.findNumberToCreate(processId).then((number) => {
            setNewItemId(`${processId}-${number}`)
          })
        } else {
          setNewItemId('')
        }

        let processName = ''
        if (getReferenceNames) {
          const refNames = getReferenceNames(
            formValues.cycle,
            formValues.category,
            hasThreeCategories() ? formValues.subCategory : undefined
          )
          processName = _.get(refNames, processId, '')
        }
        setFormValues({
          ...formValues,
          processId,
          processName,
          riskName: ''
        })
      } else if (name === 'riskId') {
        // for control
        const riskId = form.getFieldValue('riskId')
        let riskName = ''
        if (getSubReferenceNames) {
          const refNames = getSubReferenceNames(
            formValues.cycle,
            formValues.category,
            hasThreeCategories() ? formValues.subCategory : undefined
          )
          riskName = _.get(refNames, riskId, '')
        }

        ControlManager.findNumberToCreate(riskId).then((number) =>
          setNewItemId(`${riskId}-${number}`)
        )
        setFormValues({ ...formValues, riskName })
      } else if (name === 'owner') {
        const staffId = form.getFieldValue('owner')
        const foundStaff = _.find(staff, { id: staffId })
        if (foundStaff) {
          setFormValues({ ...formValues, ...foundStaff })
        }
      }
    },
    [form, formValues]
  )

  return (
    <Modal
      centered
      bodyStyle={{
        height: type === 'control' && sizes.height ? sizes.height * 0.7 : undefined,
        padding: 0,
        margin: 12
      }}
      footer={[
        <Button key="back" onClick={handleCancel}>
          취소
        </Button>,
        <Button key="submit" loading={states.loading} type="primary" onClick={handleOk}>
          저장
        </Button>
      ]}
      title={editingControl ? `${koType} 수정` : `${koType} 추가`}
      visible={visible}
      width={type === 'control' && sizes.width ? sizes.width * 0.8 : undefined}
      onCancel={handleCancel}
      onOk={handleOk}
    >
      <Form.Provider onFormChange={onFormChange}>
        <Form className={classes.form} form={form} layout="horizontal" name="PRCCreation">
          <CycleSelectFormItem
            formValues={formValues}
            isNew={editingControl === undefined}
            cycles={states.cycles}
            categories={states.categories}
            subCategories={states.subCategories}
          />
          <ProcessSelectFormItem
            isNew={editingControl === undefined}
            type={type}
            formValues={formValues}
            getReferenceIds={getReferenceIds}
          />
          <RiskSelectFormItem
            isNew={editingControl === undefined}
            type={type}
            formValues={formValues}
            getReferenceIds={getSubReferenceIds}
          />
          <IdInputFormItem
            type={type}
            isNew={editingControl === undefined}
            formValues={formValues}
          />
          <ProcessEditView type={type} names={names} />
          <RiskEditView type={type} names={names} />
          {type === 'control' && (
            <ControlEditView selectedStaff={formValues.selectedStaff} staffs={staff} />
          )}
        </Form>
      </Form.Provider>
    </Modal>
  )
}

export default PRCCreationForm
