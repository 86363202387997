import _ from 'lodash'
import React from 'react'

import { grey, lightBlue, lightGreen } from '../../../base/color'

interface DesignEvaluationColumnDef {
  span: number
  style?: React.CSSProperties
  valueFormatter?: (params: string) => string
  children?: (DesignEvaluationColumnKeyDef | DesignEvaluationColumnValueDef)[][]
}

export interface DesignEvaluationColumnKeyDef extends DesignEvaluationColumnDef {
  key: string
  type?: 'Text' | 'TextArea' | 'Select' | 'DatePicker'
  editable?: boolean
  options?: string[]
  placeholder?: string
}

export interface DesignEvaluationColumnValueDef extends DesignEvaluationColumnDef {
  value: string
}

export type DesignColumnDef = DesignEvaluationColumnKeyDef | DesignEvaluationColumnValueDef

export const greyTitleStyle = { backgroundColor: grey.background, justifyContent: 'center' }
export const greyStyle = { backgroundColor: grey.background }
export const blueStyle = { backgroundColor: lightBlue.background }
export const greenStyle = { backgroundColor: lightGreen.background }

export function isDesignEvaluationKeyDef(
  value: DesignEvaluationColumnDef
): value is DesignEvaluationColumnKeyDef {
  return _.has(value, 'key')
}

export function isDesignEvaluationValueDef(
  value: DesignEvaluationColumnDef
): value is DesignEvaluationColumnValueDef {
  return _.has(value, 'value')
}
