import * as CycleManager from '@cck/backend/dist/rcm/CycleManager'
import { getKoName } from '@cck/common/dist/data/Translation'
import { hasThreeCategories } from '@cck/common/dist/utils/Config'
import AutoComplete from 'antd/lib/auto-complete'
import Button from 'antd/lib/button'
import Form from 'antd/lib/form'
import Modal from 'antd/lib/modal'
import _ from 'lodash'
import React from 'react'

import AlphaInput from '../../common/AlphaInput'

function getNames(
  cycles: CycleManager.SimpleNode[],
  cycleName?: string,
  categoryName?: string
): string[] {
  if (!cycleName) {
    return _.map(cycles, (cycle) => cycle.name)
  }
  const matchedCycle = _.find(cycles, { name: cycleName })
  if (matchedCycle) {
    if (!categoryName) {
      return _.map(matchedCycle?.children, (child) => child.name)
    }
    const matchedCategory = _.find(matchedCycle?.children, { name: categoryName })
    if (matchedCategory) {
      return _.map(matchedCategory?.children, (child) => child.name)
    }
  }
  return []
}

const convertToOption = (names: string[]): { value: string }[] => {
  return _.map(names, (name) => ({ value: name }))
}

const getNextId = (cycles: CycleManager.SimpleNode[]): string => {
  const maxId = _.max(_.map(cycles, (cycle) => parseInt(cycle.id)))
  return ((maxId || 0) + 1).toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false
  })
}

const createCycle = async (
  cycles: CycleManager.SimpleNode[],
  cycleId: string,
  cycle: string,
  category: string,
  subCategory: string
): Promise<void> => {
  const cycleObject: {
    isNew: boolean
    cycleNumber: string
    cycleName: string
    categoryNumber: string
    categoryName: string
    subCategoryNumber: string
    subCategoryName: string
  } = {
    isNew: true,
    cycleNumber: cycleId,
    cycleName: cycle,
    categoryNumber: '01',
    categoryName: category,
    subCategoryNumber: '01',
    subCategoryName: subCategory
  }

  const matchedCycle = _.find(cycles, { name: cycle })
  if (matchedCycle) {
    const matchedCategory = _.find(matchedCycle.children, { name: category })
    if (!matchedCategory) {
      cycleObject.categoryNumber = getNextId(matchedCycle.children)
    } else {
      cycleObject.categoryNumber = matchedCategory.id
      cycleObject.subCategoryNumber = getNextId(matchedCategory.children)
    }
  }

  return CycleManager.update(cycleObject)
}

interface Message {
  message: string
  status: '' | 'success' | 'warning' | 'error' | 'validating'
}

function getHelpMessage(
  type: 'cycleName' | 'categoryName' | 'subCategoryName',
  names: string[],
  value: string
): Message {
  value = value.trim()
  const typeStr: string = getKoName('common', type)

  if (_.isEmpty(value)) {
    return { message: `${typeStr}을 입력해주세요.`, status: 'error' }
  }
  if (_.includes(names, value)) {
    return { message: `기존의 ${typeStr}`, status: 'success' }
  }
  return { message: `신규 ${typeStr}`, status: 'success' }
}

function getCycleIdHelpMessage(
  cycles: CycleManager.SimpleNode[],
  cycleName: string,
  cycleId: string
): Message {
  const message: Message = {
    message: `기존의 ${getKoName('common', 'cycleNumber')}`,
    status: 'success'
  }

  const cycleNames = getNames(cycles)
  if (!_.includes(cycleNames, cycleName) && !_.isEmpty(cycleName)) {
    if (_.isEmpty(cycleId)) {
      return {
        message: `${getKoName('common', 'cycleNumber')}를 입력해주세요.`,
        status: 'error'
      }
    } else if (_.find(cycles, (row) => _.upperCase(row.id) === _.upperCase(cycleId))) {
      return {
        message: '이미 존재하는 값입니다.',
        status: 'error'
      }
    } else {
      return {
        message: `신규 ${getKoName('common', 'cycleNumber')}`,
        status: 'success'
      }
    }
  }
  return message
}

interface Props {
  cycles: CycleManager.SimpleNode[]
  visible: boolean
  onCancel: (update: boolean) => void
}

const CycleCreationForm: React.FC<Props> = ({ cycles, visible, onCancel }) => {
  const [form] = Form.useForm()
  const [names, setNames] = React.useState({
    cycleId: '',
    cycle: '',
    category: '',
    subCategory: ''
  })
  const [states, setStates] = React.useState({
    cycleNames: getNames(cycles),
    categoryNames: [] as string[],
    subCategoryNames: [] as string[],
    cycleHelpMessage: {} as Message,
    cycleIdHelpMessage: {} as Message,
    categoryHelpMessage: {} as Message,
    subCategoryHelpMessage: {} as Message,
    loading: false
  })

  React.useEffect(() => {
    const cycleNames = getNames(cycles)
    const categoryNames = getNames(cycles, names.cycle)
    const subCategoryNames = getNames(cycles, names.cycle, names.category)
    const cycleHelpMessage = getHelpMessage('cycleName', cycleNames, names.cycle)
    const cycleIdHelpMessage = getCycleIdHelpMessage(cycles, names.cycle, names.cycleId)
    const categoryHelpMessage = getHelpMessage('categoryName', categoryNames, names.category)
    const subCategoryHelpMessage = getHelpMessage(
      'subCategoryName',
      subCategoryNames,
      names.subCategory
    )
    const messages = [cycleHelpMessage, categoryHelpMessage]
    if (hasThreeCategories()) {
      messages.push(subCategoryHelpMessage)
    }
    const isNotNew = _.every(messages, (message) => {
      return _.startsWith(message.message, '기존의')
    })
    if (isNotNew) {
      _.map(messages, (message) => {
        message.status = 'error'
      })
    }
    setStates({
      ...states,
      cycleNames,
      categoryNames,
      subCategoryNames,
      cycleHelpMessage,
      cycleIdHelpMessage,
      categoryHelpMessage,
      subCategoryHelpMessage
    })
  }, [names, cycles])

  const initHelpMessage = (): void => {
    setStates({
      ...states,
      cycleHelpMessage: {} as Message,
      cycleIdHelpMessage: {} as Message,
      categoryHelpMessage: {} as Message,
      subCategoryHelpMessage: {} as Message,
      loading: false
    })
    setNames({
      ...names,
      cycleId: '',
      cycle: '',
      category: '',
      subCategory: ''
    })
  }

  const handleCancel = (): void => {
    onCancel(false)
    form.resetFields()
    initHelpMessage()
  }
  const handleOk = (): void => {
    const messages = [states.cycleHelpMessage, states.categoryHelpMessage]
    if (hasThreeCategories()) {
      messages.push(states.subCategoryHelpMessage)
    }

    if (_.every(messages, (message) => message.status === 'success')) {
      setStates({ ...states, loading: true })
      createCycle(
        cycles,
        _.upperCase(names.cycleId),
        names.cycle,
        names.category,
        hasThreeCategories() ? names.subCategory : '01'
      )
        .then(() => {
          onCancel(true)
          form.resetFields()
          initHelpMessage()
        })
        .finally(() => {
          setStates({ ...states, loading: false })
        })
    }
  }

  return (
    <Modal
      centered
      width={600}
      footer={[
        <Button key="back" onClick={handleCancel}>
          취소
        </Button>,
        <Button key="submit" loading={states.loading} type="primary" onClick={handleOk}>
          저장
        </Button>
      ]}
      title="분류 추가"
      visible={visible}
      onCancel={handleCancel}
      onOk={handleOk}
    >
      <Form.Provider
        onFormChange={(formName, info) => {
          const name = info.changedFields?.[0]?.name
          const value = form.getFieldValue(name)
          if (name.toString() === 'cycle') {
            const cycle = _.find(cycles, { name: value })
            if (cycle) {
              form.setFieldsValue({ cycleId: cycle.id })
              setNames({
                ...names,
                [name as string]: value,
                cycleId: cycle.id
              })
            } else {
              form.resetFields(['cycleId'])
              setNames({
                ...names,
                [name as string]: value,
                cycleId: ''
              })
            }
          } else {
            setNames({
              ...names,
              [name as string]: value
            })
          }
        }}
      >
        <Form
          form={form}
          labelCol={{ span: 4 }}
          layout="horizontal"
          name="cycleCreation"
          wrapperCol={{ span: 16 }}
        >
          <Form.Item
            help={states.cycleHelpMessage.message}
            label="대분류"
            name="cycle"
            validateStatus={states.cycleHelpMessage.status}
          >
            <AutoComplete options={convertToOption(states.cycleNames)} />
          </Form.Item>
          <Form.Item
            label={getKoName('common', 'cycleNumber')}
            name="cycleId"
            help={states.cycleIdHelpMessage.message}
            validateStatus={states.cycleIdHelpMessage.status}
          >
            <AlphaInput
              disabled={_.includes(states.cycleNames, names.cycle) || _.isEmpty(names.cycle)}
              value={(_.includes(states.cycleNames, names.cycle) && names.cycleId) || ''}
            />
          </Form.Item>
          <Form.Item
            help={states.categoryHelpMessage.message}
            label={getKoName('common', 'categoryName')}
            name="category"
            validateStatus={states.categoryHelpMessage.status}
          >
            <AutoComplete
              disabled={_.isEmpty(names.cycle)}
              options={convertToOption(states.categoryNames)}
            />
          </Form.Item>
          {hasThreeCategories() && (
            <Form.Item
              help={states.subCategoryHelpMessage.message}
              label={getKoName('common', 'subCategoryName')}
              name="subCategory"
              validateStatus={states.subCategoryHelpMessage.status}
            >
              <AutoComplete
                disabled={_.isEmpty(names.cycle) || _.isEmpty(names.category)}
                options={convertToOption(states.subCategoryNames)}
              />
            </Form.Item>
          )}
        </Form>
      </Form.Provider>
    </Modal>
  )
}

export default CycleCreationForm
