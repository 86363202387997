import { ICellRendererParams } from '@ag-grid-community/core'
import { getStaffNameAndEmail, Staff } from '@cck/common/dist/data/Staff'
import { getKoName } from '@cck/common/dist/data/Translation'
import Typography from 'antd/lib/typography'
import _ from 'lodash'
import React from 'react'

function renderStaffText(value?: string): React.ReactElement {
  if (!_.isEmpty(value)) {
    return <Typography.Text>{value}</Typography.Text>
  }
  return (
    <Typography.Text type="danger">{getKoName('control', 'owner')}를 선택해주세요</Typography.Text>
  )
}

export function renderStaffDepartment(staff?: Staff): React.ReactElement {
  return renderStaffText(staff?.departmentName)
}

export function renderStaff(staff?: Staff): React.ReactElement {
  return renderStaffText(getStaffNameAndEmail(staff))
}

export function StaffCellRenderer(params: ICellRendererParams): React.ReactElement {
  return (
    <Typography.Text ellipsis={{ tooltip: params.valueFormatted }} style={{ width: '100%' }}>
      {params.valueFormatted}
    </Typography.Text>
  )
}
