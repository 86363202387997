/* eslint-disable @typescript-eslint/no-explicit-any */
import { AgGridReact } from '@ag-grid-community/react/lib/agGridReact'
import {
  AgGridEvent,
  AllModules,
  ColDef,
  ColumnApi,
  ColumnResizedEvent,
  GridApi
} from '@ag-grid-enterprise/all-modules'
import * as DepartmentManager from '@cck/backend/dist/rcm/DepartmentManager'
import * as StaffManager from '@cck/backend/dist/rcm/StaffManager'
import { Staff } from '@cck/common/dist/data/Staff'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Button from 'antd/lib/button'
import Form from 'antd/lib/form'
import Input from 'antd/lib/input'
import Modal from 'antd/lib/modal'
import _ from 'lodash'
import React from 'react'

import { grey } from '../../base/color'
import AlertMessage, { AlertMessageHandler } from '../common/AlertMessage'
import CircleBackdrop from '../common/CircleBackdrop'
import LoadingButton from '../common/LoadingButton'
import NoticeTextView from '../common/NoticeTextView'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column'
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row'
  },
  notice: {
    backgroundColor: grey.background,
    border: grey.border,
    padding: theme.spacing(1, 2),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  buttonContainer: {
    display: 'flex',
    flex: 1,
    margin: theme.spacing(1),
    justifyContent: 'flex-end',
    '& button': {
      marginLeft: theme.spacing(1)
    }
  },
  modal: {
    display: 'flex',
    flexDirection: 'column'
  }
}))

interface GridProps {
  gridApi?: GridApi
  columnApi?: ColumnApi
}

interface Department {
  name: string
  staffCount: string
  staffs: Staff[]
}

function deleteCellRender(params: any): React.ReactElement {
  return (
    <LoadingButton
      type="link"
      disabled={!_.isEmpty(params.data.staffs)}
      onClick={() => {
        const department: Department = params.data
        return DepartmentManager.remove(params.data.name)
          .then(() => {
            params.alertRef.current?.showAlert('success', '부서 삭제 완료')
            params.loadDepartments()
          })
          .catch((e) => {
            // eslint-disable-next-line no-console
            console.log('Failed to delete department', e)
            params.alertRef.current?.showAlert('error', '부서 삭제 실패')
          })
      }}
    >
      삭제
    </LoadingButton>
  )
}

const DepartmentManagement: React.FC<{ active: boolean }> = ({ active }) => {
  const classes = useStyles()
  const [departments, setDepartments] = React.useState<Department[]>([])
  const [gridApi, setGridApi] = React.useState<GridProps>()
  const [states, setStates] = React.useState({
    visible: false,
    loading: false
  })
  const [form] = Form.useForm()
  const alertRef = React.useRef<AlertMessageHandler>(null)

  const loadDepartments = React.useCallback(() => {
    setStates({
      ...states,
      visible: false,
      loading: true
    })
    Promise.all([DepartmentManager.getAll(), StaffManager.getAll(true)])
      .then(([rawDepartments, allStaffs]) => {
        const newDepartments: Department[] = _.map(rawDepartments, (row) => {
          const staffs = _.compact(
            _.map(row.staffIds, (staffId) => {
              return _.find(allStaffs as Staff[], { id: staffId })
            })
          )

          return {
            name: row.name as string,
            staffCount: `${staffs.length}명`,
            staffs
          }
        })
        setDepartments(newDepartments)
      })
      .finally(() => {
        setStates({
          ...states,
          visible: false,
          loading: false
        })
      })
  }, [states])

  const columns: ColDef[] = React.useMemo(
    () => [
      {
        headerName: '부서',
        field: 'name',
        cellRenderer: 'agGroupCellRenderer',
        flex: 1
      },
      {
        headerName: '직원수',
        field: 'staffCount'
      },
      {
        headerName: '삭제',
        cellRenderer: 'deleteCellRender',
        cellRendererParams: { alertRef, loadDepartments }
      }
    ],
    [alertRef, loadDepartments]
  )

  const detailColumns: ColDef[] = React.useMemo(
    () => [
      { headerName: 'ID', field: 'email' },
      { headerName: '이름', field: 'name' },
      { headerName: '권한', field: 'level' }
    ],
    []
  )

  React.useEffect(() => {
    if (active) {
      loadDepartments()
    }
  }, [active])

  const createDepartment = React.useCallback(() => {
    const name = _.trim(form.getFieldValue('name'))
    if (_.isEmpty(name)) {
      return Promise.reject(new Error('부서 이름을 입려해주세요.'))
    }
    if (_.find(departments, { name })) {
      return Promise.reject(new Error('이미 존재하는 부서입니다.'))
    }
    return DepartmentManager.create(name)
      .then(() => {
        form.resetFields()
        loadDepartments()
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.log('Failed to create department', e)
        return Promise.reject(new Error('부서 생성에 실패했습니다.'))
      })
  }, [form, departments])

  return (
    <div className={classes.root}>
      <div className={classes.headerContainer}>
        <NoticeTextView>해당 부서내의 직원이 없을 경우에만 삭제 가능합니다.</NoticeTextView>
        <div className={classes.buttonContainer}>
          <Button type="primary" onClick={() => setStates({ ...states, visible: true })}>
            부서 생성
          </Button>
        </div>
      </div>
      <div className="ag-theme-cck" style={{ height: '100%', position: 'relative' }}>
        <AgGridReact
          applyColumnDefOrder
          detailRowAutoHeight
          enableCellChangeFlash
          columnDefs={columns}
          defaultColDef={{
            wrapText: true,
            autoHeight: true,
            resizable: true,
            lockPosition: true,
            filter: 'agSetColumnFilter',
            suppressSizeToFit: true,
            filterParams: {
              excelMode: 'windows' // can be 'windows' or 'mac'
            }
          }}
          detailCellRendererParams={{
            refreshStrategy: 'rows',
            detailGridOptions: {
              defaultColDef: {
                flex: 1,
                resizable: true,
                lockPosition: true
              },
              enableCellChangeFlash: true,
              columnDefs: detailColumns
            },
            getDetailRowData: (params: any) => params.successCallback(params.data.staffs)
          }}
          isRowMaster={(dataItem: any) => !_.isEmpty(dataItem.staffs)}
          masterDetail
          modules={AllModules}
          rowData={departments}
          onColumnResized={(params: ColumnResizedEvent) => params.api.resetRowHeights()}
          onGridReady={(event: AgGridEvent): void => {
            setGridApi({
              gridApi: event.api,
              columnApi: event.columnApi
            })
          }}
          frameworkComponents={{ deleteCellRender }}
        />
      </div>
      <Modal
        centered
        footer={[
          <Button
            key="back"
            onClick={() => {
              form.resetFields()
              setStates({ ...states, visible: false })
            }}
          >
            취소
          </Button>,
          <LoadingButton key="submit" type="primary" onClick={createDepartment}>
            생성
          </LoadingButton>
        ]}
        onCancel={() => setStates({ ...states, visible: false })}
        title={'부서 생성'}
        visible={states.visible}
      >
        <div className={classes.modal}>
          <Form form={form}>
            <Form.Item name="name" label="부서 이름">
              <Input />
            </Form.Item>
          </Form>
        </div>
      </Modal>
      <CircleBackdrop open={states.loading} />
      <AlertMessage ref={alertRef} />
    </div>
  )
}

export default DepartmentManagement
