/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ColDef, ColGroupDef } from '@ag-grid-community/core'
import { blue } from '@ant-design/colors'
import * as EvaluationParser from '@cck/backend/dist/evaluation/ExcelParser'
import {
  BaseFile,
  FileState,
  ERROR_STATES,
  stateToString,
  stateToColor,
  FileType
} from '@cck/common/dist/data/BaseFile'
import * as Evaluation from '@cck/common/dist/data/Evaluation'
import { makeStyles, Theme } from '@material-ui/core'
import clsx from 'clsx'
import _ from 'lodash'
import React from 'react'
import { useDropzone } from 'react-dropzone'

import TableViewer, { TableViewerHandler } from '../../common/TableViewer'
import UploadIconAndText from './UploadIconAndText'

const useStyles = makeStyles((theme: Theme) => ({
  dropzone: {
    display: 'flex',
    position: 'relative',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1,
    background: 'WhiteSmoke',
    border: '1px dashed lightgray'
  },
  dragActive: {
    borderColor: blue.primary
  },
  fileTable: {
    display: 'flex',
    height: '100%',
    width: '100%',
    flexDirection: 'column'
  }
}))

export function stateCellRenderer(params: any): HTMLElement {
  const span = document.createElement('span')
  span.innerHTML = params.valueFormatted
  span.style.color = stateToColor(params.data.state)

  if (params.data.state === FileState.PARSING_ERROR && params.data?.excelException) {
    span.innerText += ' (' + params.data.excelException.join(', ') + ')'
  }
  return span
}

function previewCellRenderer(params: any): HTMLElement {
  const basefile = params.data as BaseFile
  if (
    !_.includes(ERROR_STATES, basefile.state) &&
    basefile.type === FileType.Record &&
    Evaluation.isEvaluationFile(basefile) &&
    Evaluation.isEvaluationData(basefile.excelData)
  ) {
    const aElement = document.createElement('a')
    aElement.innerHTML = '미리보기'
    aElement.onclick = () => {
      params.setReport(basefile.evaluationName, basefile.controlId, basefile.excelData)
    }
    return aElement
  }
  const span = document.createElement('span')
  span.innerHTML = '-'
  return span
}

export function deleteCellRenderer(params: any): HTMLElement {
  const files: any[] = []
  params.api.forEachNode((node: any) => {
    files.push(node.data)
  })
  const aElement = document.createElement('a')
  aElement.innerHTML = '삭제'
  aElement.onclick = () => params.deleteFile(files, params.data)
  return aElement
}

function updateFileKeys(files: (BaseFile | Evaluation.EvaluationFile)[]): void {
  // TODO(sangmuk): 왜 필요한지 확인 할 것
  let maxLevel = _.max(_.map(files, (file) => _.get(file, 'key'))) || 0
  _.forEach(files, (file) => {
    if (!_.get(file, 'key')) {
      _.set(file, 'key', maxLevel + 1)
      maxLevel += 1
    }
  })
}

interface Props {
  type: Evaluation.Type
  files: (BaseFile | Evaluation.EvaluationFile)[]
  setFiles: (files: (BaseFile | Evaluation.EvaluationFile)[]) => void
  setReport: (
    evaluationName: string,
    controlId: string,
    evaluationData: Evaluation.OperationEvaluationData | Evaluation.DesignEvaluationData
  ) => void
}

const DropBox: React.FC<Props> = ({ type, files, setFiles, setReport }) => {
  const classes = useStyles()
  const [tmpFiles, setTmpFiles] = React.useState<(BaseFile | Evaluation.EvaluationFile)[]>()

  const columns = React.useMemo(
    (): (ColDef | ColGroupDef)[] => [
      { headerName: '파일 이름', field: 'name', flex: 2 },
      {
        headerName: `${Evaluation.getKoEvaluation(type)} 이름`,
        field: 'evaluationName'
      },
      { headerName: '통제번호', field: 'controlId', flex: 1 },
      { headerName: '파일 종류', field: 'type', width: 120 },
      {
        headerName: '파일 상태',
        field: 'state',
        cellRenderer: stateCellRenderer,
        valueFormatter: (params: any): any => stateToString(type, params.value),
        flex: 3
      },
      {
        headerName: '미리보기',
        field: 'preview',
        width: 120,
        cellRenderer: previewCellRenderer,
        cellRendererParams: { setReport }
      },
      {
        headerName: '삭제',
        field: 'delete',
        width: 120,
        cellRenderer: deleteCellRenderer,
        cellRendererParams: {
          deleteFile: (
            allFiles: (BaseFile | Evaluation.EvaluationFile)[],
            file: BaseFile | Evaluation.EvaluationFile
          ): void => {
            const newFiles = allFiles.filter((item) => item.name !== file.name)
            updateFileKeys(newFiles)
            setTmpFiles(newFiles)
          }
        }
      }
    ],
    []
  )
  const viewerRef = React.useRef<TableViewerHandler>(null)

  React.useEffect(() => {
    setFiles(tmpFiles || [])
  }, [tmpFiles])

  const onDrop = React.useCallback(
    (originalFiles) => {
      const acceptedFiles: File[] = []
      _.forEach(originalFiles, (file) => {
        acceptedFiles.push(new File([file], file.name.normalize('NFC'), { type: file.type }))
      })
      EvaluationParser.parseFiles(type, acceptedFiles).then(
        (parsedFiles: (BaseFile | Evaluation.EvaluationFile)[]) => {
          const allFiles = files.slice()
          _.forEach(parsedFiles, (file) => {
            const matched = _.findIndex(allFiles, { name: file.name })
            if (
              matched >= 0 &&
              (file.state === FileState.OK || allFiles[matched]?.state !== FileState.OK)
            ) {
              _.pullAt(allFiles, [matched])
              allFiles.push(file)
            } else if (matched < 0) {
              allFiles.push(file)
            }
          })

          updateFileKeys(allFiles)
          setFiles(allFiles)
        }
      )
    },
    [files]
  )
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    noClick: !_.isEmpty(files)
  })

  return (
    <div
      className={clsx(classes.dropzone, { [classes.dragActive]: isDragActive })}
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      {_.isEmpty(files) && <UploadIconAndText isDragActive={isDragActive} />}
      {!_.isEmpty(files) && (
        <div className={classes.fileTable}>
          <TableViewer noBorder columnDefs={columns} ref={viewerRef} rowData={files} />
        </div>
      )}
    </div>
  )
}

export default DropBox
