/* eslint-disable @typescript-eslint/no-explicit-any */
import { makeStyles, Theme } from '@material-ui/core/styles'
import { Table, Form, Select } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import Typography from 'antd/lib/typography'
import clsx from 'clsx'
import _ from 'lodash'
import React from 'react'
import useResizeAware from 'react-resize-aware'

import { lightBlue, lightGrey } from '../../base/color'

const useStyle = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexGrow: 1,
    flexBasis: 0,
    minWidth: 0,
    width: 200,
    flexDirection: 'column'
  },
  title: {
    fontWeight: 'bold',
    padding: theme.spacing(1)
  },
  tableContainer: {
    display: 'flex',
    position: 'relative',
    flexGrow: 1,
    flexBasis: 0,
    minHeight: 0,
    height: 400,
    flexDirection: 'row'
  },
  table: {
    flexGrow: 1,
    '& .ant-spin-nested-loading, .ant-spin-container, .ant-table, .ant-table-container': {
      height: '100%'
    },
    '& .ant-table-body': {
      height: '100%',
      borderBottom: lightGrey.border
    },
    '& .ant-table-tbody > tr > td': {
      border: 0,
      borderTop: lightGrey.border,
      padding: theme.spacing(0, 2),
      wordBreak: 'keep-all'
    },
    '& .ant-table-tbody > tr:last-child > td': {
      borderBottom: lightGrey.border
    },
    // eslint-disable-next-line max-len
    '& .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > td:last-child':
      {
        borderRight: 0
      }
  },
  tableHover: {
    '& .ant-table-tbody > tr:hover > td': {
      color: lightBlue.color,
      borderTop: lightBlue.border,
      background: lightBlue.background
    },
    '& .ant-table-tbody > tr:last-child:hover > td': {
      borderBottom: lightBlue.border
    },
    '& .ant-table-tbody > tr:hover+tr > td': {
      borderTop: lightBlue.border
    },
    '& .ant-table-tbody > tr:hover > td:first-child': {
      borderLeft: lightBlue.border
    },
    // eslint-disable-next-line max-len
    '& .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr:hover > td:last-child':
      {
        borderRight: lightBlue.border
      },
    '& .ant-table-tbody > tr:active > td': {
      backgroundColor: lightBlue.background
    }
  },
  emptyTable: {
    '& .ant-table-tbody > tr > td': {
      borderBottom: 0
    }
  },
  editableCell: {
    margin: theme.spacing(1, 0)
  },
  viewCell: {
    padding: theme.spacing(2, 0)
  },
  blue: {
    background: lightBlue.background,
    '& .ant-table-tbody > tr:hover > td': {
      background: lightBlue.background
    }
  }
}))

interface DataType {
  key: React.Key
  id: string
  name: string
  selected: boolean
}

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean
  dataIndex: string
  title: string
  record: DataType
  index: number
  options: string[]
  children: React.ReactNode
}

const EditableCell: React.FC<EditableCellProps> = ({
  editing,
  dataIndex,
  title,
  record,
  index,
  children,
  options,
  ...restProps
}) => {
  const classes = useStyle()
  return (
    <td {...restProps} className={clsx({ [classes.blue]: record?.selected })}>
      {editing ? (
        <Form.Item
          className={classes.editableCell}
          name={dataIndex}
          rules={[
            {
              required: true,
              message: `${title}을 입력해주세요.`
            }
          ]}
        >
          {!options || _.isEmpty(options) ? (
            <TextArea autoSize={{ minRows: 1, maxRows: 5 }} />
          ) : (
            <Select size="small">
              {_.map(options, (option) => {
                return (
                  <Select.Option key={option} value={option}>
                    {option}
                  </Select.Option>
                )
              })}
            </Select>
          )}
        </Form.Item>
      ) : (
        <div className={classes.viewCell}>{children}</div>
      )}
    </td>
  )
}

function getUniqueKeys(columns: any): string[] {
  const keys: string[] = []
  _.forEach(columns, (column) => {
    if (column.unique) {
      keys.push(column.dataIndex)
    }
    if (column.children) {
      keys.push(...getUniqueKeys(column.children))
    }
  })
  return keys
}

type EditableTableProps = Parameters<typeof Table>[0]
type ColumnTypes = Exclude<EditableTableProps['columns'], undefined>

interface Props extends EditableTableProps {
  name: string
  data: { id: string; name: string }[]
  selectingKey?: string
  editingKey: string
  setEditingKey: (key: string) => void
  noClickable?: boolean
  columns: ColumnTypes
  onItemEdit?: (item?: { id: string; name: string }, error?: string) => void
  onItemDelete?: (item: any) => void
  onItemClick?: (item: any) => void
  onCustomItemEdit?: (item: any) => void
}

const EditableTable: React.FC<Props> = ({
  name,
  data,
  selectingKey,
  editingKey,
  setEditingKey,
  noClickable,
  loading,
  columns,
  onItemEdit,
  onItemDelete,
  onItemClick,
  onCustomItemEdit
}) => {
  const classes = useStyle()
  const [form] = Form.useForm()
  const [dataSource, setDataSource] = React.useState([] as DataType[])
  const [resizeListener, sizes] = useResizeAware()

  const isEditing = (record: DataType): boolean => record.id + record.name === editingKey
  const select = (record: DataType): void => {
    if (editingKey) {
      return
    }
    const newDataSource: DataType[] = []
    _.forEach(dataSource, (value) => {
      newDataSource.push({
        ...value,
        selected: value.key === record.key && !noClickable
      })
    })
    setDataSource(newDataSource)
    if (onItemClick) {
      onItemClick(record)
    }
  }
  const edit = (record: DataType & { key: React.Key }): void => {
    form.setFieldsValue({ ...record })
    setEditingKey(record.id + record.name)
  }

  React.useEffect(() => {
    const newDataSource: DataType[] = []
    _.forEach(data, (value) => {
      newDataSource.push({
        ..._.omit(value, 'children'),
        key: value.id,
        selected: value.id === selectingKey
      })
    })
    setDataSource(newDataSource)
  }, [data])

  const save = async (record: any): Promise<void> => {
    if (!onItemEdit) return
    try {
      const row = (await form.validateFields()) as any
      const uniqueKeys = getUniqueKeys(columns)
      if (!_.isMatch(record, row)) {
        for (const key of uniqueKeys) {
          if (record[key] !== row[key] && _.find(data, { [key]: _.trim(row[key]) })) {
            // eslint-disable-next-line no-console
            console.log(_.trim(row[key]), _.find(data, { [key]: _.trim(row[key]) }))
            onItemEdit(undefined, '이미 존재하는 값입니다.')
            return
          }
        }
        onItemEdit({ ...record, ...row }) // 수정된 경우
      }
    } catch (errInfo) {
      // eslint-disable-next-line no-console
      console.log('Editable Table Validate Failed:', errInfo)
    }
    setEditingKey('')
  }

  const renderCell = (value: any, record: any): any => {
    const editable = isEditing(record)
    return editable ? (
      <span>
        <Typography.Link style={{ marginRight: 8 }} onClick={() => save(record)}>
          저장
        </Typography.Link>
        <Typography.Link onClick={() => setEditingKey('')}>취소</Typography.Link>
      </span>
    ) : (
      <span>
        <Typography.Link
          disabled={editingKey !== ''}
          onClick={() => {
            if (onCustomItemEdit) onCustomItemEdit(record)
            else edit(record)
          }}
        >
          수정
        </Typography.Link>
        {onItemDelete && (
          <Typography.Link
            disabled={editingKey !== ''}
            style={{ marginLeft: 8 }}
            onClick={() => onItemDelete(record)}
          >
            삭제
          </Typography.Link>
        )}
      </span>
    )
  }

  const newColumns = columns.slice()
  newColumns.push({
    title: onItemDelete ? '수정/삭제' : '수정',
    dataIndex: 'operation',
    render: renderCell,
    width: 100
  })

  let hierarchy = 1
  _.forEach(newColumns, (col: any) => {
    if (col.children) {
      hierarchy = 2
      _.forEach(col.children, (childCol: any) => {
        childCol.onCell = (record: DataType) => ({
          record,
          editing: childCol.editable && isEditing(record),
          dataIndex: childCol.dataIndex,
          title: childCol.title,
          options: childCol.options
        })
      })
    } else {
      col.onCell = (record: DataType) => ({
        record,
        editing: col.editable && isEditing(record),
        dataIndex: col.dataIndex,
        title: col.title,
        options: col.options
      })
    }
  })

  return (
    <div className={classes.root}>
      <Typography className={classes.title}>{name}</Typography>
      <div className={classes.tableContainer}>
        {resizeListener}
        <Form component={false} form={form}>
          <Table
            bordered
            className={clsx(classes.table, {
              [classes.tableHover]: !_.isEmpty(dataSource) && !noClickable,
              [classes.emptyTable]: _.isEmpty(dataSource)
            })}
            columns={newColumns}
            components={{ body: { cell: EditableCell } }}
            dataSource={dataSource}
            loading={loading}
            pagination={false}
            scroll={sizes.height ? { y: sizes.height - 56 * hierarchy } : {}}
            onRow={(item: any) => {
              return {
                onClick: () => select(item)
              }
            }}
          />
        </Form>
      </div>
    </div>
  )
}

export default EditableTable
