import { ColDef } from '@ag-grid-community/core'
import { getFileNameWithoutPrefix } from '@cck/backend/dist/evaluation/EvaluationUtils'
import { BaseFile, FileState } from '@cck/common/dist/data/BaseFile'
import * as Evaluation from '@cck/common/dist/data/Evaluation'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Typography from 'antd/lib/typography'
import _ from 'lodash'
import React from 'react'

import { getEditableColor } from '../../../base/color'
import CommonAgGrid, { CommonAgGridHandler } from '../../common/CommonAgGrid'
import agMultiSelectCellEditor from '../../common/agMultiSelectCellEditor'

const useStyles = makeStyles((theme: Theme) => ({
  evidenceContainer: {
    margin: theme.spacing(2, 0),
    height: theme.spacing(60),
    display: 'flex'
  }
}))

export interface EvidenceMappingHandler {
  getEvidenceMappings: () => string[]
}

interface Props {
  evaluation: Evaluation.EvaluationItem
  evidences: string[]
  evidenceFiles: BaseFile[]
}

function compareFilename(a: string, b: string): number {
  const reg = /^\d+/
  const aNum = a.trim().match(reg)
  const bNum = b.trim().match(reg)

  if (aNum && bNum) {
    const aNumber = _.parseInt(aNum[0])
    const bNumber = _.parseInt(bNum[0])
    return aNumber - bNumber
  }

  if (a < b) {
    return -1
  }
  if (a > b) {
    return 1
  }

  // 이름이 같을 경우
  return 0
}

const EvidenceMappingView = React.forwardRef<EvidenceMappingHandler, Props>(
  ({ evaluation, evidences, evidenceFiles }, ref) => {
    const classes = useStyles()
    const evidenceRef = React.useRef<CommonAgGridHandler>(null)
    const [data, setData] = React.useState<Record<string, string>[]>([])
    const [columns, setColumns] = React.useState<ColDef[]>([])

    React.useImperativeHandle(ref, () => ({
      getEvidenceMappings() {
        const results: string[] = []
        evidenceRef.current?.getGridApi()?.gridApi.forEachNode((rowNode) => {
          results.push(_.get(rowNode.data, Evaluation.EVIDENCE_KEY, ''))
        })
        return results
      }
    }))

    React.useEffect(() => {
      const evalData = evaluation.content.data as Evaluation.OperationEvaluationData
      const headers = evalData.samplingResult.headerRow
      const newData: Record<string, string>[] = []
      _.forEach(_.cloneDeep(evalData.samplingResult.rowObjectList), (rowObject, idx) => {
        rowObject[Evaluation.EVIDENCE_KEY] = _.nth(evidences, idx) || ''
        newData.push(rowObject)
      })

      const newColumns: ColDef[] = _.map(headers, (header) => ({
        field: header,
        headerName: header,
        sortable: true
      }))
      newColumns.push({
        width: 500,
        field: Evaluation.EVIDENCE_KEY,
        headerName: Evaluation.EVIDENCE_KEY,
        wrapText: true,
        editable: true,
        pinned: 'right',
        suppressPaste: true,
        cellEditor: 'agMultiSelectCellEditor',
        cellEditorParams: {
          values: _(evidenceFiles)
            .map((evidence) => {
              if (evidence.state === FileState.OK || evidence.state === FileState.SAVED_FILE) {
                return getFileNameWithoutPrefix(
                  evaluation.base.name,
                  evaluation.controlId,
                  evidence.name
                )
              }
              return undefined
            })
            .compact()
            .value()
            .sort(compareFilename)
        },
        cellStyle: { backgroundColor: getEditableColor() }
      })
      setColumns(newColumns)
      setData(newData)
    }, [evaluation, evidences, evidenceFiles])

    return (
      <div>
        <Typography.Title level={5}>증빙 연결</Typography.Title>
        <div className={classes.evidenceContainer}>
          <CommonAgGrid
            wrapText
            autoSize="sizeColumnsToFit"
            suppressFieldDotNotation
            ref={evidenceRef}
            columnDefs={columns}
            rowData={data}
            frameworkComponents={{ agMultiSelectCellEditor }}
            onCellValueChanged={(event) => {
              event.api.resetRowHeights()
            }}
          />
        </div>
      </div>
    )
  }
)

export default EvidenceMappingView
