import { makeStyles, Theme } from '@material-ui/core/styles'
import Form, { Rule } from 'antd/lib/form'
import Select, { RefSelectProps } from 'antd/lib/select'
import _ from 'lodash'
import React from 'react'

import { grey } from '../../../../base/color'
import TableItem from '../../../common/TableItem'

const useStyle = makeStyles((theme: Theme) => ({
  select: {
    maxWidth: 330
  },
  formSelect: {
    maxWidth: 400,
    flexGrow: 1,
    marginBottom: 0
  }
}))

function renderOptions(data: Record<string, string>): JSX.Element[] {
  const dataList: { id: string; number: string }[] = []
  _.forEach(data, (id, number) => {
    dataList.push({ id, number })
  })
  if (_.every(dataList, (element) => !_.isNaN(_.parseInt(element.id)))) {
    dataList.sort((a, b) => _.parseInt(a.id) - _.parseInt(b.id))
  }

  return _.map(dataList, ({ id, number }) => {
    return (
      <Select.Option key={id} value={number}>
        {id}
      </Select.Option>
    )
  })
}
export const selectValidator = (
  key: string
): ((rule: unknown, value: unknown) => Promise<void>) => {
  return (rule: unknown, value: unknown): Promise<void> => {
    if (_.isEmpty(value)) {
      return Promise.reject(new Error(`${key} 선택해주세요.`))
    }
    return Promise.resolve()
  }
}

interface Props {
  disabled?: boolean
  name: string
  dataKey: string
  required?: boolean
  options: Record<string, string>
  rules?: Rule[]
  noDivider?: boolean
  ref?: React.RefObject<RefSelectProps>
}

const SelectItem: React.FC<Props> = ({
  disabled,
  name,
  dataKey,
  required,
  options,
  rules,
  noDivider
}) => {
  const classes = useStyle()
  return (
    <TableItem name={name} required={required} noDivider={noDivider}>
      <Form.Item className={classes.formSelect} name={dataKey} rules={rules}>
        <Select className={classes.select} disabled={disabled} size="small">
          {renderOptions(options)}
        </Select>
      </Form.Item>
    </TableItem>
  )
}

export default SelectItem
