import { ColDef } from '@ag-grid-enterprise/all-modules'
import { QuestionCircleOutlined } from '@ant-design/icons'
import * as Evaluation from '@cck/common/dist/data/Evaluation'
import { Control } from '@cck/common/dist/data/PRCUtils'
import { Period } from '@cck/common/dist/data/control/AbstractControl'
import { RiskLevel } from '@cck/common/dist/data/risk/AbstractRisk'
import { parsePopulationFileForWorker } from '@cck/js/src/PopulationFileReader'
import { useWorker } from '@koale/useworker'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Button from 'antd/lib/button'
import Form from 'antd/lib/form'
import InputNumber from 'antd/lib/input-number'
import Modal from 'antd/lib/modal'
import Typography from 'antd/lib/typography'
import _ from 'lodash'
import React from 'react'

import { grey, lightBlue } from '../../../base/color'
import AlertMessage, { AlertMessageHandler } from '../../common/AlertMessage'
import CircleBackdrop from '../../common/CircleBackdrop'
import CommonAgGrid, { CommonAgGridHandler } from '../../common/CommonAgGrid'
import MultipleTableItem from '../../common/MultipleTableItem'
import { insertNoColumn } from '../../common/TableUtils'

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  formItem: {
    margin: theme.spacing(1)
  },
  samplingContainer: {
    margin: theme.spacing(2, 0),
    height: theme.spacing(40),
    display: 'flex'
  },
  table: {
    marginRight: 38,
    border: grey.border,
    borderBottom: 0
  }
}))

function getRecommendSamplingNumber(control: Control, numberOfPopulation: number): number {
  if (control.period === Period.byCase || control.period === Period.always) {
    if (numberOfPopulation <= 1) {
      return 1
    } else if (numberOfPopulation <= 4) {
      return 2
    } else if (numberOfPopulation <= 24) {
      if (control.controlRiskLevel !== RiskLevel.low) return 3
      return 2
    } else if (numberOfPopulation <= 100) {
      if (control.controlRiskLevel === RiskLevel.high) return 8
      else if (control.controlRiskLevel === RiskLevel.moderate) return 7
      else return 5
    } else {
      if (control.controlRiskLevel === RiskLevel.high) return 25
      else if (control.controlRiskLevel === RiskLevel.moderate) return 20
      else return 15
    }
  } else if (control.period === Period.dailyAnyTime) {
    if (control.controlRiskLevel === RiskLevel.high) return 60
    else if (control.controlRiskLevel === RiskLevel.moderate) return 45
    else return 25
  } else if (control.period === Period.daily) {
    if (control.controlRiskLevel === RiskLevel.high) return 40
    else if (control.controlRiskLevel === RiskLevel.moderate) return 25
    else return 15
  } else if (control.period === Period.weekly) {
    if (control.controlRiskLevel === RiskLevel.high) return 10
    else if (control.controlRiskLevel === RiskLevel.moderate) return 8
    else return 5
  } else if (control.period === Period.monthly) {
    if (control.controlRiskLevel === RiskLevel.high) return 4
    else if (control.controlRiskLevel === RiskLevel.moderate) return 3
    else return 2
  } else if (control.period === Period.quarterly) {
    return 2
  } else {
    // Period.halfBiAnnual, Period.annual
    return 1
  }
}
export interface SamplingHandler {
  getSampling: () => Evaluation.TableContent | undefined
}

interface Props {
  control: Control
  numberOfPopulation: number
  samplingResult?: Evaluation.TableContent
  getPopulation: () => Evaluation.TableContent | undefined
  getPopulationFiles: () => Evaluation.EvaluationFile[] | undefined
}

const SamplingDetailView = React.forwardRef<SamplingHandler, Props>(
  ({ control, numberOfPopulation, samplingResult, getPopulation, getPopulationFiles }, viewRef) => {
    const classes = useStyles()
    const [states, setStates] = React.useState<{
      samplingCount: number
      randomIndices: number[]
      population: Evaluation.TableContent | undefined
      open: boolean
    }>({
      samplingCount: 1,
      randomIndices: [],
      population: undefined,
      open: false
    })
    const [sampling, setSampling] = React.useState<Evaluation.TableContent>()
    const alertRef = React.useRef<AlertMessageHandler>(null)
    const gridRef = React.useRef<CommonAgGridHandler>(null)
    const recommendSamplingNumber = React.useMemo(
      () => getRecommendSamplingNumber(control, numberOfPopulation),
      [control, numberOfPopulation]
    )
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [readFileWorker] = useWorker(parsePopulationFileForWorker as any, {
      remoteDependencies: ['https://unpkg.com/xlsx@0.17.3/dist/xlsx.full.min.js']
    })

    React.useImperativeHandle(viewRef, () => ({
      getSampling() {
        return sampling
      }
    }))

    React.useEffect(() => {
      setSampling(samplingResult)
      setStates({
        ...states,
        samplingCount: samplingResult?.rowCountExceptHeader || 1
      })
    }, [samplingResult])

    const columns: ColDef[] = React.useMemo(() => {
      const newColumns: ColDef[] = _.map(sampling?.headerRow, (header) => ({
        editable: true,
        headerName: header,
        field: header
      }))

      if (!_.isEmpty(newColumns)) {
        newColumns[0].editable = false
        newColumns[0].cellStyle = { backgroundColor: '#f8f8f8' }
      }

      return newColumns
    }, [sampling])

    const samplingPopulation = React.useCallback(
      (population) => {
        try {
          if (states.samplingCount > population.rowCountExceptHeader) {
            alertRef.current?.showAlert('error', '모집단의 수가 샘플링 항목수 보다 적습니다.')
            return
          }

          const randomIndices: number[] = _(_.range(population.rowCountExceptHeader))
            .sampleSize(states.samplingCount)
            .sort()
            .value()

          const newPopulation = _.cloneDeep(population)
          // NOTE: 보고서 파싱시에 No가 있으므로 No column 추가.
          insertNoColumn(newPopulation)

          newPopulation.rowCountExceptHeader = states.samplingCount
          newPopulation.rowObjectList = _.map(randomIndices, (selectedIndex, idx) => {
            const row = newPopulation.rowObjectList[selectedIndex]
            if (_.has(row, 'No')) {
              _.set(row, 'No', (idx + 1).toString())
            }
            return row
          })

          setSampling(newPopulation)
          setStates({
            ...states,
            randomIndices,
            population
          })
        } catch (error) {
          alertRef.current?.showAlert('error', _.get(error, 'message'))
        }
      },
      [getPopulation, states, alertRef]
    )

    const trySamplingPopulation = () => {
      const population = getPopulation()
      if (population && population.rowCountExceptHeader > 0) {
        samplingPopulation(population)
        return
      }

      const populationFiles = getPopulationFiles()
      if (!populationFiles || _.isEmpty(populationFiles)) {
        return
      }
      setStates({ ...states, open: true })
      readFileWorker(populationFiles[0])
        .then((parsedFile) => {
          samplingPopulation(parsedFile.excelData)
        })
        .catch((e) => {
          // eslint-disable-next-line no-console
          console.log('Failed to sampling ', e)
          alertRef.current?.showAlert('error', '샘플링에 실패했습니다.')
        })
        .finally(() => {
          setStates({ ...states, open: false })
        })
    }

    const onRecommendDescriptionClick = () => {
      const blueBackgroundColor = {
        backgroundColor: lightBlue.background,
        justifyContent: 'center'
      }
      const greyBackgroundColor = { backgroundColor: grey.background, justifyContent: 'center' }
      const greyBackgroundRight = { backgroundColor: '#fafafa', justifyContent: 'right' }

      const values = {
        '4건 이하': { H: 2, M: 2, L: 2, style: greyBackgroundRight },
        '24건 이하': { H: 3, M: 3, L: 2, style: greyBackgroundRight },
        '100건 이하': { H: 8, M: 7, L: 5, style: greyBackgroundRight },
        '365건 이하': { H: 25, M: 20, L: 15, style: greyBackgroundRight },
        일별수시: { H: 60, M: 45, L: 25, style: greyBackgroundColor },
        일별: { H: 40, M: 25, L: 15, style: greyBackgroundColor },
        주별: { H: 10, M: 8, L: 5, style: greyBackgroundColor },
        월별: { H: 4, M: 3, L: 2, style: greyBackgroundColor },
        분기별: { H: 2, M: 2, L: 2, style: greyBackgroundColor },
        반기별: { H: 1, M: 1, L: 1, style: greyBackgroundColor },
        연별: { H: 1, M: 1, L: 1, style: greyBackgroundColor }
      }

      Modal.info({
        title: '권장 샘플링 수',
        width: 800,
        content: (
          <div className={classes.table}>
            <MultipleTableItem
              key={0}
              values={[
                { key: '0', value: '', span: 6, style: blueBackgroundColor },
                { key: '1', value: '통제위험', span: 18, style: blueBackgroundColor }
              ]}
            />
            <MultipleTableItem
              key={1}
              values={[
                { key: '0', value: '구분', span: 6, style: blueBackgroundColor },
                { key: '1', value: 'H', span: 6, style: blueBackgroundColor },
                { key: '2', value: 'M', span: 6, style: blueBackgroundColor },
                { key: '3', value: 'L', span: 6, style: blueBackgroundColor }
              ]}
            />
            <MultipleTableItem
              key={1}
              values={[
                { key: '0', value: '건별', span: 6, style: greyBackgroundColor },
                {
                  key: '1',
                  value: '확정된 모집단의 수에 따라 샘플 수 결정',
                  span: 18,
                  style: greyBackgroundColor
                }
              ]}
            />
            {_.map(values, (value, key) => (
              <MultipleTableItem
                key={1}
                values={[
                  { key: '0', value: key, span: 6, style: value.style },
                  {
                    key: '1',
                    value: value.H.toString(),
                    span: 6,
                    style: { justifyContent: 'center' }
                  },
                  {
                    key: '2',
                    value: value.M.toString(),
                    span: 6,
                    style: { justifyContent: 'center' }
                  },
                  {
                    key: '3',
                    value: value.L.toString(),
                    span: 6,
                    style: { justifyContent: 'center' }
                  }
                ]}
              />
            ))}
          </div>
        ),
        onOk() {
          return undefined
        },
        okText: '확인'
      })
    }

    return (
      <div>
        <Typography.Title level={5}>모집단 샘플링</Typography.Title>
        <div className={classes.header}>
          <Form.Item className={classes.formItem} label="샘플링 항목수">
            <InputNumber
              onChange={(value) => {
                setStates({
                  ...states,
                  samplingCount: value as number
                })
              }}
              value={states.samplingCount}
              min={1}
            />
          </Form.Item>
          <Button
            type="primary"
            disabled={!(_.isNumber(states.samplingCount) && states.samplingCount > 0)}
            onClick={() => trySamplingPopulation()}
          >
            모집단 샘플링 하기
          </Button>
          <Button
            className={classes.formItem}
            danger
            disabled={!(_.isNumber(states.samplingCount) && states.samplingCount > 0)}
            onClick={() => {
              if (sampling) {
                const newSampling = _.cloneDeep(sampling)
                _.forEach(newSampling.rowObjectList, (row) => {
                  _.forEach(newSampling.headerRow, (header) => {
                    if (header !== 'No') {
                      row[header] = ''
                    }
                  })
                })

                setSampling(newSampling)
                setStates({
                  ...states,
                  randomIndices: []
                })
              }
            }}
          >
            샘플링 초기화
          </Button>
        </div>
        <div className={classes.header}>
          <Typography.Text type="secondary">
            권장 샘플링 수: {recommendSamplingNumber} (구분: {control.period}. 통제위험:{' '}
            {control.controlRiskLevel})
          </Typography.Text>
          <Button
            icon={<QuestionCircleOutlined />}
            type="text"
            style={{ margin: '8px' }}
            onClick={onRecommendDescriptionClick}
          />
        </div>
        <div className={classes.samplingContainer}>
          <CommonAgGrid
            defaultColDef={{
              sortable: true
            }}
            suppressFieldDotNotation
            enableRangeSelection
            ref={gridRef}
            columnDefs={columns}
            rowData={sampling?.rowObjectList}
          />
        </div>
        <AlertMessage ref={alertRef} />
        <CircleBackdrop open={states.open} />
      </div>
    )
  }
)
export default SamplingDetailView
