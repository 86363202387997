import { getKoName } from '@cck/common/dist/data/Translation'
import { makeStyles, Theme } from '@material-ui/core'
import { Input } from 'antd'
import Form, { Rule } from 'antd/lib/form'
import _ from 'lodash'
import React from 'react'

import TableItem from '../../../common/TableItem'

const useStyle = makeStyles((theme: Theme) => ({
  formText: {
    marginBottom: 0,
    width: '100%',
    '& .ant-form-item-control-input': {
      minHeight: 0
    }
  }
}))

export function getRequiredMessage(type: string, nameStr?: string): string {
  let typeStr = type
  if (type === 'name' && nameStr) typeStr = nameStr
  if (type === 'narrative') typeStr = getKoName('process', 'type')
  if (type === 'RoMM') typeStr = getKoName('risk', type)

  if (_.includes(['cycle', 'category', 'subCategory'], type)) {
    return `${typeStr}를 선택해주세요.`
  }
  return `${typeStr}을 입력해주세요.`
}

interface TextItemProps {
  name: string
  dataKey: string
  rules?: Rule[]
  noDivider?: boolean
  required?: boolean
  multiline?: boolean
}

const TextItem: React.FC<TextItemProps> = ({
  name,
  dataKey,
  rules,
  noDivider,
  required,
  multiline
}) => {
  const classes = useStyle()
  return (
    <TableItem name={name} noDivider={noDivider} required={required}>
      <Form.Item className={classes.formText} name={dataKey} rules={rules}>
        {multiline ? <Input.TextArea autoSize /> : <Input />}
      </Form.Item>
    </TableItem>
  )
}

export default TextItem
