import { isNotUniqueId } from '@cck/backend/dist/rcm/RCMUtils'
import { getKoName } from '@cck/common/dist/data/Translation'
import { hasThreeCategories } from '@cck/common/dist/utils/Config'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { Form, Input } from 'antd'
import _ from 'lodash'
import React from 'react'

import TableItem from '../../../common/TableItem'
import { FormTypes } from './Types'

const useStyle = makeStyles((theme: Theme) => ({
  formText: {
    marginBottom: 0,
    width: '100%',
    '& .ant-form-item-control-input': {
      minHeight: 0
    }
  }
}))

export interface Props {
  type: 'process' | 'risk' | 'control'
  isNew: boolean
  formValues: FormTypes
}

const IdInputFormItem: React.FC<Props> = ({ type, isNew, formValues }) => {
  const classes = useStyle()

  return (
    <>
      <TableItem minHeight={40} required={true} name={getKoName(type, 'id')}>
        <Form.Item
          className={classes.formText}
          dependencies={['cycle', 'category', 'subCategory', 'processId', 'riskId']}
          name="id"
          rules={[
            ({ getFieldValue }) => ({
              async validator(rule: unknown, value: unknown): Promise<void> {
                if (_.isEmpty(getFieldValue('cycle'))) {
                  return Promise.reject(new Error('대분류를 선택해주세요.'))
                } else if (_.isEmpty(getFieldValue('category'))) {
                  return Promise.reject(
                    new Error(`${getKoName('common', 'categoryName')} 선택해주세요.`)
                  )
                } else if (hasThreeCategories() && _.isEmpty(getFieldValue('subCategory'))) {
                  return Promise.reject(new Error('소분류를 선택해주세요.'))
                } else if (
                  (type === 'risk' || type === 'control') &&
                  _.isEmpty(getFieldValue('processId'))
                ) {
                  return Promise.reject(new Error(`${getKoName('risk', 'id')}를 선택해주세요.`))
                } else if (type === 'control' && _.isEmpty(getFieldValue('riskId'))) {
                  return Promise.reject(new Error(`${getKoName('risk', 'id')}를 선택해주세요.`))
                }

                const idStr = _.trim(value as string)
                if (_.isEmpty(idStr)) {
                  return Promise.reject(new Error(`${getKoName(type, 'id')}를 입력해주세요.`))
                }

                if (isNew) {
                  const alreadySet = await isNotUniqueId(value as string)
                  if (alreadySet) {
                    return Promise.reject(
                      new Error(`${getKoName('common', alreadySet)}에서 이미 사용 중인 ID 입니다.`)
                    )
                  }
                }

                return Promise.resolve()
              }
            })
          ]}
        >
          <Input
            value={formValues.itemId}
            // onChange={(event) => {
            //   setItemId(event.target.value)
            // }}
            disabled={
              _.isEmpty(formValues.cycle) ||
              _.isEmpty(formValues.category) ||
              (hasThreeCategories() && _.isEmpty(formValues.subCategory)) ||
              (type !== 'process' && _.isEmpty(formValues.processName)) ||
              (type === 'control' && _.isEmpty(formValues.riskName)) ||
              !isNew
            }
          />
        </Form.Item>
      </TableItem>
    </>
  )
}

export default IdInputFormItem
