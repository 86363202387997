import * as EvaluationUtils from '@cck/backend/dist/evaluation/EvaluationUtils'
import { BASE_ITEM, getKoEvaluation } from '@cck/common/dist/data/Evaluation'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { Button } from 'antd'
import _ from 'lodash'
import React from 'react'
import { useCookies } from 'react-cookie'

import { grey } from '../../base/color'
import AlertMessage, { AlertMessageHandler } from '../common/AlertMessage'
import DividerSelect from '../common/DividerSelect'
import {
  EvaluationSelectAttribute,
  loadEvaluationDetails,
  loadEvaluationNames
} from '../evaluation/common/EvaluationUtils'
import { downloadSummary } from './ResultReportUtils'
import ResultStatusView from './ResultStatusView'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: 'white',
    border: grey.border,
    padding: theme.spacing(1),
    margin: theme.spacing(1),
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  header: {
    padding: theme.spacing(2)
  },
  selectContainer: {
    display: 'flex',
    flexDirection: 'row',
    padding: theme.spacing(0, 1)
  },
  buttonContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    margin: theme.spacing(1)
  }
}))

const ResultReport: React.FC = () => {
  const classes = useStyles()
  const [evaluationType, setEvaluationType] = React.useState<'operation' | 'design'>('operation')
  const [select, setSelect] = React.useState<EvaluationSelectAttribute>({
    loading: false,
    selectedName: BASE_ITEM,
    names: [],
    bases: [],
    items: []
  })
  const [cookies, setCookie, removeCookie] = useCookies([
    'resultReportEvaluationType',
    'resultReportEvaluationName'
  ])
  const alertRef = React.useRef<AlertMessageHandler>(null)

  React.useEffect(() => {
    const typeCookie = cookies.resultReportEvaluationType
    const nameCookie = cookies.resultReportEvaluationName
    if (typeCookie && nameCookie) {
      setEvaluationType(typeCookie)
      loadEvaluationNames(typeCookie, nameCookie, select, setSelect)
    } else {
      loadEvaluationNames(evaluationType, undefined, select, setSelect)
    }
  }, [evaluationType])

  const onEvaluationSelect = React.useCallback(
    (evaluationName: string): void => {
      loadEvaluationDetails(evaluationType, evaluationName, select, setSelect)
      setCookie('resultReportEvaluationType', evaluationType)
      setCookie('resultReportEvaluationName', evaluationName)
    },
    [evaluationType, select]
  )

  return (
    <div className={classes.root}>
      <div className={classes.selectContainer}>
        <DividerSelect
          style={{ marginRight: 16 }}
          defaultName="운영/설계평가 선택"
          defaultValue={BASE_ITEM}
          loading={select.loading}
          value={evaluationType}
          names={['운영평가', '설계평가']}
          values={['operation', 'design']}
          onChange={(value) => {
            if (value === 'operation' || value === 'design') {
              removeCookie('resultReportEvaluationType')
              removeCookie('resultReportEvaluationName')
              setSelect({
                ...select,
                selectedName: BASE_ITEM,
                names: [],
                items: []
              })
              setEvaluationType(value)
            }
          }}
        />
        <DividerSelect
          defaultName={`${getKoEvaluation(evaluationType)} 선택`}
          defaultValue={BASE_ITEM}
          loading={select.loading}
          names={EvaluationUtils.getEvaluationNamesWithDate(_.filter(select.bases, { state: '2' }))}
          value={select.selectedName}
          values={_(select.bases)
            .filter({ state: '2' })
            .map((summary) => summary.name)
            .value()}
          onChange={(value) => onEvaluationSelect(value as string)}
        />
        <div className={classes.buttonContainer}>
          <Button
            type="primary"
            disabled={_.isEmpty(select.items)}
            onClick={() => {
              const summary = _.find(select.bases, { name: select.selectedName })
              if (!select.items || _.isEmpty(select.items) || !summary) {
                alertRef.current?.showAlert('error', '비어있는 평가입니다.')
                return
              }
              downloadSummary(evaluationType, summary, select.items)
            }}
          >
            다운로드
          </Button>
        </div>
      </div>
      <ResultStatusView type={evaluationType} evaluations={select.items || []} />
      <AlertMessage ref={alertRef} />
    </div>
  )
}

export default ResultReport
