import { PRCType } from '@cck/common/dist/data/PRC'
import { getKoName } from '@cck/common/dist/data/Translation'
import { Node as TreeNode } from '@cck/common/dist/tree/Tree'
import { getProjectId, hasThreeCategories } from '@cck/common/dist/utils/Config'
import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { makeStyles, Theme } from '@material-ui/core/styles'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import _ from 'lodash'
import React from 'react'

import CategorySelector from './CategorySelector'
import CategoryTree from './CategoryTree'
import OptionSelector from './OptionSelector'
import TypeSelector from './TypeSelector'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%'
  },
  accordionDetails: {
    display: 'flex',
    flexDirection: 'column',
    padding: 0
  },
  container: {
    display: 'flex',
    flexGrow: 1,
    padding: theme.spacing(2)
  },
  item: {
    minWidth: 200
  },
  smallItem: {
    minWidth: 150
  },
  horizontalDivider: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    height: 1
  },
  divider: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    width: 1
  }
}))

function _getLastChild(tree: TreeNode[]): TreeNode[] {
  if (tree?.[0]) {
    if (tree[0]?.children && tree[0].children.length > 0) {
      return _getLastChild(tree[0].children)
    }
    tree[0].children = []
    return tree[0].children
  }
  return tree
}

function _removeTargetChild(target: string, tree: TreeNode[]): TreeNode[] {
  if (tree?.[0]?.children) {
    tree[0].children = _removeTargetChild(target, tree[0].children)
  }

  if (_.startsWith(tree?.[0]?.key.toString(), target)) {
    if (tree?.[0]?.children?.[0]) {
      tree[0] = tree[0].children[0]
    } else {
      tree.splice(0, tree.length)
    }
  }

  return tree
}

function _getTreeKeys(treeData: TreeNode[]): string[] {
  let keys: string[] = []
  if (treeData?.[0]?.children) {
    keys = _.concat(keys, _getTreeKeys(treeData[0].children))
  }

  if (treeData?.[0]?.key) {
    keys.push(treeData[0].key.toString())
  }
  return keys
}

function _updateSelectedCategories(
  treeData: TreeNode[],
  originalSelectedCategories: string[]
): string[] {
  const selectedCategories = Array<string>(0)
  _.forEach(treeData, (item: TreeNode) => {
    if (_.includes(originalSelectedCategories, item.key)) {
      selectedCategories.push(item.key as string)
    }
    if (item.children) {
      selectedCategories.push(
        ..._updateSelectedCategories(item.children, originalSelectedCategories)
      )
    }
  })
  return selectedCategories
}

function _updateTree(
  category: 'cycle' | 'worker',
  checked: boolean,
  treeData: TreeNode[],
  selectedCategories: string[]
): {
  treeData: TreeNode[]
  treeKeys: string[]
  selectedCategories: string[]
} {
  const categoryToSubcategories = {
    cycle: hasThreeCategories() ? ['cycle', 'category', 'subCategory'] : ['cycle', 'category'],
    // worker: [getKoName('control', 'department'), getKoName('control', 'owner')]
    worker: ['department', 'owner']
  }

  let updatedTree = _removeTargetChild('hidden', treeData.slice())
  if (checked) {
    let lastChild: TreeNode[] = _getLastChild(updatedTree)
    _.forEach(categoryToSubcategories[category], (subcategory) => {
      let koName
      if (category === 'cycle') {
        koName = getKoName('common', `${subcategory}Name`, getProjectId())
      } else if (subcategory === 'owner') {
        koName = getKoName('control', 'owner')
      } else {
        koName = getKoName('control', subcategory)
      }

      const newKey = category + '-' + subcategory
      lastChild.push({
        title: koName,
        key: newKey,
        id: newKey,
        type: 'subCategory',
        children: [] as TreeNode[]
      })
      lastChild = _getLastChild(lastChild)
    })
  } else {
    updatedTree = _removeTargetChild(category, updatedTree)
  }

  // Add hidden child
  const lastChild = _getLastChild(updatedTree)
  lastChild.push({
    title: '',
    key: 'hidden',
    type: 'not found',
    id: 'hidden',
    disabled: true
  })

  return {
    treeData: updatedTree,
    treeKeys: _getTreeKeys(updatedTree),
    selectedCategories: _updateSelectedCategories(treeData, selectedCategories)
  }
}

export const updateCategories = (
  cycle: boolean,
  worker: boolean,
  originalCategories: string[],
  originalTreeData: TreeNode[],
  originalSelectedCategories: string[],
  categoryName: 'cycle' | 'worker',
  isChecked: boolean
):
  | {
      cycle: boolean
      categories: string[]
      treeData: TreeNode[]
      treeKeys: string[]
      selectedCategories: string[]
    }
  | {
      worker: boolean
      categories: string[]
      treeData: TreeNode[]
      treeKeys: string[]
      selectedCategories: string[]
    }
  | Record<string, never> => {
  if (
    (categoryName === 'cycle' && cycle === isChecked) ||
    (categoryName === 'worker' && worker === isChecked)
  ) {
    return {}
  }

  const categories = originalCategories.slice()
  if (isChecked) {
    categories.push(categoryName)
  } else {
    _.remove(categories, function (name: string) {
      return name === categoryName
    })
  }

  const { treeData, treeKeys, selectedCategories } = _updateTree(
    categoryName,
    isChecked,
    originalTreeData,
    originalSelectedCategories
  )

  if (categoryName === 'cycle') {
    return {
      cycle: isChecked,
      categories,
      treeData,
      treeKeys,
      selectedCategories
    }
  }
  return {
    worker: isChecked,
    categories,
    treeData,
    treeKeys,
    selectedCategories
  }
}
interface Props {
  // TypeSelector
  type: PRCType
  typeOnChange: (value: PRCType) => void
  // OptionSelector
  inquireProcessView: boolean
  inquireRiskView: boolean
  inquireControlView: boolean
  controlOption: string
  controlOptionOnChange: (value: string) => void
  inquireViewOnChange: (target: string, checked: boolean) => void
  // CategorySelector
  cycle: boolean
  worker: boolean
  categoryOnChange: (name: 'cycle' | 'worker', checked: boolean) => void
  // CategoryTree
  treeData: TreeNode[]
  treeKeys: string[]
  selectedCategories: string[]
  categoryOnSelect: (selectedKeys: string[]) => void
}

const ExplorerSetting: React.FC<Props> = ({
  type,
  typeOnChange,
  inquireProcessView,
  inquireRiskView,
  inquireControlView,
  controlOption,
  controlOptionOnChange,
  inquireViewOnChange,
  cycle,
  worker,
  categoryOnChange,
  treeData,
  treeKeys,
  selectedCategories,
  categoryOnSelect
}) => {
  const classes = useStyles()
  const typeStr = React.useMemo(() => type[0].toUpperCase() + type.slice(1), [type])
  const optionStr = React.useMemo(() => {
    const newOptions: string[] = []
    if (type !== PRCType.process && inquireProcessView) {
      newOptions.push('Process')
    }
    if (type !== PRCType.risk && inquireRiskView) {
      newOptions.push('Risk')
    }
    if (type !== PRCType.control && inquireControlView) {
      newOptions.push('Control')
    }
    if (type === PRCType.control || inquireControlView) {
      if (controlOption === 'key control') {
        newOptions.push('핵심통제')
      } else if (controlOption === 'except key control') {
        newOptions.push('비핵심통제')
      }
    }
    if (_.isEmpty(newOptions)) {
      newOptions.push('옵션선택')
    }
    return newOptions
  }, [type, inquireProcessView, inquireRiskView, inquireControlView, controlOption])

  const categoryStr = React.useMemo(() => {
    const newCategories: string[] = []
    if (cycle) {
      newCategories.push('분류')
    }
    if (type === PRCType.control && worker) {
      newCategories.push('담당자')
    }
    if (_.isEmpty(newCategories)) {
      newCategories.push('탐색할 항목')
    }
    return newCategories
  }, [cycle, type, worker])

  const categoryTreeStr = React.useMemo(() => {
    const newCategoryTreeStr: string[] = []
    _.forEach(selectedCategories, (category: string) => {
      const splitCategory = _.split(category, '-')
      if (['cycle', 'category', 'subCategory'].includes(splitCategory[1])) {
        newCategoryTreeStr.push(`${splitCategory[1]}Name`)
      } else {
        newCategoryTreeStr.push(splitCategory[1])
      }
    })
    if (!cycle && (type !== PRCType.control || !worker)) {
      newCategoryTreeStr.length = 0
    }
    if (_.isEmpty(newCategoryTreeStr)) {
      newCategoryTreeStr.push('폴더 구조 선택')
    }

    return newCategoryTreeStr
  }, [selectedCategories, type, cycle])

  return (
    <Accordion defaultExpanded className={classes.root}>
      <AccordionSummary
        aria-controls="pannel1d-content"
        expandIcon={<ExpandMoreIcon />}
        id="pannel1d-header"
      >
        <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon fontSize="small" />}>
          <Typography variant="h6">{typeStr}</Typography>
          <Typography variant="h6">{_.join(optionStr, ', ')}</Typography>
          <Typography variant="h6">{_.join(categoryStr, ', ')}</Typography>
          <Typography variant="h6">
            {_.join(
              _.map(categoryTreeStr, (str) => getKoName('control', str)),
              ', '
            )}
          </Typography>
        </Breadcrumbs>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetails}>
        <Divider flexItem className={classes.horizontalDivider} orientation="horizontal" />
        <Grid container className={classes.container}>
          <Grid item className={classes.item} xs={2}>
            <TypeSelector value={type} onChange={typeOnChange} />
          </Grid>
          <Divider flexItem className={classes.divider} orientation="vertical" />
          <Grid item className={classes.item} xs={2}>
            <OptionSelector
              controlOption={controlOption}
              controlOptionOnChange={controlOptionOnChange}
              isControlView={inquireControlView}
              isProcessView={inquireProcessView}
              isRiskView={inquireRiskView}
              optionOnChange={inquireViewOnChange}
              type={type}
            />
          </Grid>
          <Divider flexItem className={classes.divider} orientation="vertical" />
          <Grid item className={classes.smallItem} xs={2}>
            <CategorySelector
              cycle={cycle}
              type={type}
              worker={worker}
              onChange={categoryOnChange}
            />
          </Grid>
          <Divider flexItem className={classes.divider} orientation="vertical" />
          <Grid item className={classes.item} xs={3}>
            <CategoryTree
              expandedKeys={treeKeys}
              selectedCategories={selectedCategories}
              treeData={treeData}
              onSelect={categoryOnSelect}
            />
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  )
}

export default ExplorerSetting
