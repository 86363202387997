import _ from 'lodash'

function recur<T>(values: T[], n: number): T[][] {
  if (--n < 0) {
    return [[]]
  }
  const result: T[][] = []
  values.forEach((value, index, array) => {
    array = array.slice()
    array.splice(index, 1)
    recur(array, n).forEach((permutation: T[]) => {
      permutation.unshift(value)
      result.push(permutation)
    })
  })
  return result
}

export function permutations<T>(collection: T[], n: number): T[][] {
  const values = _.values(collection)
  if (values.length < n) {
    return []
  }
  return recur(values, n)
}
