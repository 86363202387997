import * as EvaluationLoader from '@cck/backend/dist/evaluation/EvaluationLoader'
import * as Evaluation from '@cck/common/dist/data/Evaluation'
import _ from 'lodash'
import React from 'react'

export interface EvaluationSelectAttribute {
  loading: boolean
  selectedName: string
  names: string[]
  bases: Evaluation.Base[]
  items?: Evaluation.EvaluationItem[]
}

export function loadEvaluationDetails(
  type: Evaluation.Type,
  evaluationName: string,
  selectAttributes: EvaluationSelectAttribute,
  setSelectAttributes: React.Dispatch<React.SetStateAction<EvaluationSelectAttribute>>
): void {
  if (evaluationName === Evaluation.BASE_ITEM) {
    setSelectAttributes({
      ...selectAttributes,
      loading: false,
      selectedName: Evaluation.BASE_ITEM,
      items: []
    })
    return
  }

  setSelectAttributes({
    ...selectAttributes,
    loading: true
  })
  EvaluationLoader.getEvaluationItems(type, evaluationName)
    .then((items) => {
      setSelectAttributes({
        ...selectAttributes,
        loading: false,
        selectedName: evaluationName,
        items: Object.values(items)
      })
    })
    .catch((e) => {
      // eslint-disable-next-line no-console
      console.log('Failed to load evaluation details', e)
      setSelectAttributes({
        ...selectAttributes,
        loading: false
      })
    })
}

export function loadEvaluationNames(
  type: Evaluation.Type,
  evaluationName: string | undefined,
  selectAttributes: EvaluationSelectAttribute,
  setSelectAttributes: (d: EvaluationSelectAttribute) => void
): void {
  setSelectAttributes({
    ...selectAttributes,
    loading: true
  })

  EvaluationLoader.getBases(type)
    .then((bases) => {
      const names = _.map(bases, (base) => base.name)
      if (evaluationName && evaluationName !== Evaluation.BASE_ITEM) {
        setSelectAttributes({
          ...selectAttributes,
          loading: true
        })
        EvaluationLoader.getEvaluationItems(type, evaluationName)
          .then((items) => {
            setSelectAttributes({
              ...selectAttributes,
              names,
              bases,
              loading: false,
              selectedName: evaluationName,
              items: Object.values(items)
            })
          })
          .catch((e) => {
            // eslint-disable-next-line no-console
            console.log('Failed to load evaluation item', e)
            setSelectAttributes({
              ...selectAttributes,
              names,
              bases,
              loading: false
            })
          })
      } else {
        setSelectAttributes({
          ...selectAttributes,
          loading: false,
          selectedName: Evaluation.BASE_ITEM,
          names,
          bases,
          items: []
        })
      }
    })
    .catch((e) => {
      // eslint-disable-next-line no-console
      console.log(`Failed to get ${type} evaluation.`, e)
      setSelectAttributes({
        ...selectAttributes,
        loading: false
      })
    })
}

export function modifyOperationEvaluationForSaving(
  evaluation: Evaluation.OperationEvaluationData
): void {
  if (_.isNil(evaluation.populationCount)) {
    evaluation.populationCount = '0'
  }
  _.forEach(
    ['populationTableColumnCount', 'samplingAttributeCount', 'samplingCount'] as const,
    (key) => {
      if (_.isNil(evaluation[key])) {
        evaluation[key] = 0
      }
    }
  )

  const sampling = evaluation.samplingResult as Evaluation.TableContent
  const newHeaders: string[] = ['No']
  _.forEach(_.drop(sampling.headerRow), (header) => {
    newHeaders.push(sampling.rowObjectList[0][header])
  })
  const newRowObject: Record<string, string>[] = []
  _.forEach(_.drop(sampling.rowObjectList), (row: Record<string, string>) => {
    const newRow: Record<string, string> = {}
    _.forEach(_.zip(sampling.headerRow, newHeaders), ([header, newHeader]) => {
      if (header && newHeader) newRow[newHeader] = _.get(row, header)
    })
    newRowObject.push(newRow)
  })
  sampling.headerRow = newHeaders
  sampling.rowCountExceptHeader -= 1
  sampling.rowObjectList = newRowObject

  const cutData = (content: Evaluation.TableContent): void => {
    content.rowObjectList = _.take(content.rowObjectList, content.rowCountExceptHeader)
    content.rowObjectList = _.map(content.rowObjectList, (row) => {
      const newRow: Record<string, string> = {}
      _.forEach(content.headerRow, (header) => {
        newRow[header] = _.get(row, header) || ''
      })
      return newRow
    })
  }

  cutData(evaluation.samplingResult)
  cutData(evaluation.attributeContents)
  cutData(evaluation.testContents)
}
