import { ColDef } from '@ag-grid-enterprise/all-modules'
import * as ControlManager from '@cck/backend/dist/rcm/ControlManager'
import { SimpleNode } from '@cck/backend/dist/rcm/CycleManager'
import * as ProcessManager from '@cck/backend/dist/rcm/ProcessManager'
import * as RiskManager from '@cck/backend/dist/rcm/RiskManager'
import { BasePRC, Process } from '@cck/common/dist/data/PRC'
import { Control, isControl, isProcess, isRisk } from '@cck/common/dist/data/PRCUtils'
import { Staff } from '@cck/common/dist/data/Staff'
import Risk from '@cck/common/dist/data/risk/AbstractRisk'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Button from 'antd/lib/button'
import Modal from 'antd/lib/modal'
import Typography from 'antd/lib/typography'
import _ from 'lodash'
import React from 'react'
import useResizeAware from 'react-resize-aware'

import AlertMessage, { AlertMessageHandler } from '../../common/AlertMessage'
import CircleBackdrop from '../../common/CircleBackdrop'
import CommonAgGrid from '../../common/CommonAgGrid'
import { GetControlColumnDefs } from './ControlEditorUtils'
import PRCCreationForm from './PRCCreationForm'
import { GetProcessColumnDefs } from './ProcessEditorUtils'
import { GetRiskColumnDefs } from './RiskEditorUtils'

const useStyle = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexGrow: 1,
    flexBasis: 0,
    flexDirection: 'column',
    minHeight: 0
  },
  button: {
    padding: theme.spacing(1),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  content: {
    display: 'flex',
    flexGrow: 1,
    flexBasis: 0,
    flexDirection: 'row',
    minWidth: 0,
    height: 200
  }
}))

function KoType(type: 'process' | 'risk' | 'control'): string {
  if (type === 'process') return '프로세스'
  if (type === 'risk') return '리스크'
  return '컨트롤'
}

interface Props<T extends Process | Risk | Control> {
  type: 'process' | 'risk' | 'control'
  cycles: SimpleNode[]
  data: T[]
  warning: string
  loading: boolean
  staff?: Staff[]
  loadData: () => void
  setData: (newData: T[]) => void
  getReferenceIds?: (
    cycle: string,
    category: string,
    subCategory: string | undefined
  ) => Record<string, string>
  getReferenceNames?: (
    cycle: string,
    category: string,
    subCategory: string | undefined
  ) => Record<string, string>
  getSubReferenceIds?: (
    cycle: string,
    category: string,
    subCategory: string | undefined,
    processId: string
  ) => Record<string, string>
  getSubReferenceNames?: (
    cycle: string,
    category: string,
    subCategory: string | undefined
  ) => Record<string, string>
}

function PRCEditor<T extends Process | Risk | Control>({
  type,
  cycles,
  data,
  warning,
  loading,
  staff,
  loadData,
  setData,
  getReferenceIds,
  getReferenceNames,
  getSubReferenceIds,
  getSubReferenceNames
}: Props<T>): React.ReactElement<Props<T>> {
  const classes = useStyle()
  const [editingKey, setEditingKey] = React.useState('')
  const [states, setStates] = React.useState({
    loading: false,
    creationVisible: false
  })
  const [columns, setColumns] = React.useState<ColDef[]>([])
  const [resizeListener, sizes] = useResizeAware()
  const [editingControl, setEditingControl] = React.useState({
    id: '',
    control: {} as Control
  })
  const alertRef = React.useRef<AlertMessageHandler>(null)

  const editItems = React.useCallback(
    (item: T): void => {
      // TODO(sangmuk): 유니크 여부 체크
      const index = data.findIndex((value) => value.id === item.id)
      if (index >= 0) {
        let editPromise
        if (isProcess(item)) {
          // TODO(sangmuk): Process name과 narrative를 분리해서 사용할 경우 수정 필요.
          item.name = item.narrative
          editPromise = ProcessManager.update(item)
        } else if (isRisk(item)) editPromise = RiskManager.update(item)
        else if (isControl(item)) editPromise = ControlManager.update(item)
        else return

        setStates({ ...states, loading: true })
        editPromise
          .then(() => {
            const newData = data.slice()
            newData.splice(index, 1, item)
            setData(newData)
            alertRef.current?.showAlert('success', '수정 완료')
          })
          .catch((e) => {
            // eslint-disable-next-line no-console
            console.log('Failed to edit ', type, e)
            alertRef.current?.showAlert('error', '수정 실패')
          })
          .finally(() => {
            setStates({ ...states, loading: false })
          })
      }
    },
    [states, data]
  )

  const deleteItem = React.useCallback(
    (item: BasePRC): void => {
      setEditingKey(item.id)
      Modal.confirm({
        title: `${KoType(type)} 삭제`,
        content: (
          <div>
            <Typography style={{ marginBottom: 8 }}>{`[${item.id}] ${item.name}`}</Typography>
            <Typography>삭제하시겠습니까?</Typography>
          </div>
        ),
        centered: true,
        okText: '삭제',
        cancelText: '취소',
        onOk: () => {
          let deletePromise
          if (isProcess(item)) deletePromise = ProcessManager.remove(item.id)
          else if (isRisk(item)) deletePromise = RiskManager.remove(item.id)
          else if (isControl(item)) deletePromise = ControlManager.remove(item.id)
          else return

          return deletePromise
            .then(() => {
              const newData = data.slice()
              _.remove(newData, (value) => value.id === item.id)
              setData(newData)
              alertRef.current?.showAlert('success', '삭제 완료')
            })
            .catch((e) => {
              // eslint-disable-next-line no-console
              console.log('Failed to delete ', type, e)
              if (_.startsWith(e.message, '삭제 불가')) {
                alertRef.current?.showAlert('error', e.message)
              } else {
                alertRef.current?.showAlert('error', '삭제 실패')
              }
            })
            .finally(() => setEditingKey(''))
        },
        onCancel: () => setEditingKey('')
      })
    },
    [data]
  )

  const openControlEditor =
    type === 'control'
      ? (item: BasePRC) => {
          setEditingControl({
            id: item.id,
            control: item as Control
          })
          setStates({ ...states, creationVisible: true })
        }
      : undefined

  React.useEffect(() => {
    let newColumns: ColDef[]
    if (type === 'process') {
      newColumns = GetProcessColumnDefs(deleteItem)
    } else if (type === 'risk') {
      newColumns = GetRiskColumnDefs(deleteItem)
    } else {
      newColumns = GetControlColumnDefs(openControlEditor, deleteItem)
    }

    setColumns(newColumns)
  }, [data])

  const onCreationCancel = React.useCallback(
    (update: boolean): void => {
      setStates({ ...states, creationVisible: false })
      setEditingControl({ ...editingControl, id: '' })
      if (update) loadData()
    },
    [states, editingControl]
  )

  return (
    <div className={classes.root}>
      {resizeListener}
      <div className={classes.button}>
        <Button
          disabled={!_.isEmpty(editingKey)}
          loading={states.loading}
          size="large"
          type="primary"
          onClick={() => setStates({ ...states, creationVisible: true })}
        >
          {`${KoType(type)} 추가`}
        </Button>
        <Typography.Text style={{ marginLeft: 16 }} type="danger">
          {warning}
        </Typography.Text>
        <PRCCreationForm
          cycles={cycles}
          dataSource={data}
          editingControl={editingControl.id ? editingControl.control : undefined}
          koType={KoType(type)}
          sizes={sizes}
          staff={staff}
          type={type}
          visible={states.creationVisible}
          onCancel={onCreationCancel}
          getReferenceIds={getReferenceIds}
          getReferenceNames={getReferenceNames}
          getSubReferenceIds={getSubReferenceIds}
          getSubReferenceNames={getSubReferenceNames}
        />
      </div>
      <div className={classes.content}>
        <CommonAgGrid
          autoSize="sizeColumnsToFit"
          wrapText
          enableRangeSelection
          columnDefs={columns}
          rowData={data}
          onCellValueChanged={(event) => {
            const header = event.colDef.field
            if (event.colDef.cellEditorParams?.unique && header) {
              const found = _.find(data, (row) => {
                return row.id !== event.data.id && _.get(row, header) === event.data[header]
              })
              if (found) {
                alertRef.current?.showAlert('error', '이미 존재하는 값입니다.')
                return
              }
            }
            editItems(event.data)
          }}
        />
      </div>
      <AlertMessage ref={alertRef} />
      <CircleBackdrop open={states.loading} />
    </div>
  )
}

export default PRCEditor
