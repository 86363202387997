import { getEvaluationNamesWithDate } from '@cck/backend/dist/evaluation/EvaluationUtils'
import * as Evaluation from '@cck/common/dist/data/Evaluation'
import { Staff } from '@cck/common/dist/data/Staff'
import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import { makeStyles, Theme } from '@material-ui/core/styles'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Typography from 'antd/lib/typography'
import _ from 'lodash'
import React from 'react'

import DividerSelect from '../../common/DividerSelect'
import { EvaluationSelectAttribute } from '../common/EvaluationUtils'
import EvaluationTable from './EvaluationTable'

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: 0,
    margin: theme.spacing(2),
    verticalAlign: 'middle',
    justifyContent: 'center'
  },
  select: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  accordion: {
    boxShadow: 'none',
    border: '1px solid rgba(0, 0, 0, .125)',
    '&:not(:last-child)': {
      borderBottom: 0
    },
    '&:before': {
      display: 'none'
    },
    '&.MuiAccordion-root.Mui-expanded': {
      margin: 0
    },
    '& .MuiCollapse-wrapper > div > div': {
      display: 'flex'
    }
  },
  accordionSummary: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    minHeight: 56,
    '&.MuiAccordionSummary-root.Mui-expanded': {
      minHeight: 56
    },
    '& .MuiAccordionSummary-content.Mui-expanded': {
      margin: 0
    }
  },
  accordionDetail: {
    '&.MuiAccordionDetails-root': {
      padding: 0,
      height: 300,
      flexDirection: 'column',
      flexGrow: 1,
      minWidth: 900,
      width: 400
    }
  }
}))

interface Props {
  type: Evaluation.Type
  staffs: Staff[]
  select: EvaluationSelectAttribute
  selectedDetailId: string
  onEvaluationSelect: (name: string) => void
  onEvaluationDetailClick: (evaluationDetail: Evaluation.EvaluationItem) => void
}

const EvaluationSelectAccordion: React.FC<Props> = ({
  type,
  staffs,
  select,
  selectedDetailId,
  onEvaluationSelect,
  onEvaluationDetailClick
}) => {
  const classes = useStyles()
  return (
    <div className={classes.header}>
      <div className={classes.select}>
        <DividerSelect
          defaultName={`${Evaluation.getKoEvaluation(type)} 선택`}
          defaultValue={Evaluation.BASE_ITEM}
          loading={select.loading}
          names={getEvaluationNamesWithDate(select.bases)}
          value={select.selectedName}
          values={select.names}
          onChange={(value) => onEvaluationSelect(value as string)}
        />
      </div>
      <Accordion defaultExpanded className={classes.accordion}>
        <AccordionSummary
          aria-controls="pannel1d-content"
          className={classes.accordionSummary}
          expandIcon={<ExpandMoreIcon />}
          id="pannel1d-header"
        >
          <Typography.Title level={5}>
            {_.isEmpty(selectedDetailId) ? '통제활동을 선택하세요.' : `${selectedDetailId}`}
          </Typography.Title>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetail}>
          <EvaluationTable
            noBorder
            items={select.items || []}
            evaluationType={type}
            height={300}
            loading={select.loading}
            staffs={staffs}
            type="management"
            onItemClick={onEvaluationDetailClick}
          />
        </AccordionDetails>
      </Accordion>
    </div>
  )
}

export default EvaluationSelectAccordion
