import { makeStyles, Theme } from '@material-ui/core'
import { Row } from 'antd'
import React, { ReactElement } from 'react'

import { lightGrey } from '../../base/color'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    margin: theme.spacing(1),
    border: lightGrey.border,
    minWidth: 0
  },
  content: {
    background: '#ffffff',
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    minWidth: 0,
    minHeight: 0,
    height: 400
  },
  header: {
    background: lightGrey.background,
    padding: theme.spacing(1),
    height: 48
  }
}))

interface Props {
  headerView: ReactElement
  mainView: ReactElement
}

const WhiteBoxView: React.FC<Props> = ({ headerView, mainView }) => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <Row className={classes.header}>{headerView}</Row>
      <div style={{ width: '100%', height: 1, borderBottom: lightGrey.border }} />
      <div className={classes.content}>{mainView}</div>
    </div>
  )
}

export default WhiteBoxView
