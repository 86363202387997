import { getFileListForSaving } from '@cck/backend/dist/evaluation/EvaluationUtils'
import { BaseFile } from '@cck/common/dist/data/BaseFile'
import * as Evaluation from '@cck/common/dist/data/Evaluation'
import { Staff } from '@cck/common/dist/data/Staff'
import {
  getControlClass,
  getNumberOfDesignEvaluationConsiderations
} from '@cck/common/dist/utils/Config'
import Divider from '@material-ui/core/Divider'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Form from 'antd/lib/form'
import Select from 'antd/lib/select'
import Typography from 'antd/lib/typography'
import _ from 'lodash'
import moment from 'moment'
import React from 'react'

import { grey } from '../../../base/color'
import TableItem from '../../common/TableItem'
import { EvaluationReportEditorHandler } from '../report/EvaluationEditor'
import DesignDefectContentView, { DesignDefectHandler } from './DesignDefectContentView'
import DesignEvaluationTable from './DesignEvaluationTable'
import EvaluationFileUploader from './EvaluationFileUploader'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: 0,
    border: grey.border,
    backgroundColor: 'white'
  },
  content: {
    margin: theme.spacing(2),
    border: grey.border,
    borderBottom: 0
  },
  textDescription: {
    margin: theme.spacing(0, 2, 0, 2)
  },
  description: {
    margin: theme.spacing(0, 2, 2, 2)
  },
  formSelect: {
    maxWidth: 400,
    flexGrow: 1,
    marginBottom: 0
  },
  item: {
    flexFlow: 'row'
  },
  valueItem: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1),
    flexGrow: 1
  }
}))

interface Props {
  staffs: Staff[]
  evaluation: Evaluation.EvaluationItem
}

const DesignEvaluationReportEditor = React.forwardRef<EvaluationReportEditorHandler, Props>(
  ({ staffs, evaluation }, editorRef) => {
    const classes = useStyles()
    const [form] = Form.useForm()
    const [files, setFiles] = React.useState<BaseFile[]>([])

    const designColumns = React.useMemo(
      () => getControlClass().getDesignColumns(staffs, true),
      [staffs]
    )
    const defectRef = React.useRef<DesignDefectHandler>(null)

    React.useImperativeHandle(editorRef, () => ({
      getNewEvaluation() {
        const { deletedFiles, newFiles } = getFileListForSaving(evaluation, files)
        const considerations = []
        for (let i = 0; i < getNumberOfDesignEvaluationConsiderations(); ++i) {
          considerations.push({
            content: form.getFieldValue(`considerationContent_${i + 1}`),
            result: form.getFieldValue(`considerationResult_${i + 1}`)
          })
        }
        const newDesignEvaluationData: Evaluation.DesignEvaluationData = {
          type: Evaluation.Type.Design,
          updateTime: '',
          createDate: '',
          controlId: evaluation.controlId,
          ownerAssessment: form.getFieldValue('ownerAssessment') || '',
          judgementLevel: Evaluation.JudgementLevel.low,
          riskAssessmentBasis: '',
          considerations,
          baselineReference: form.getFieldValue('baselineReference')?.format('YYYY-MM-DD'),
          procedureContent: form.getFieldValue('procedureContent') || '',
          resultState:
            form.getFieldValue('result') === 'Effective'
              ? Evaluation.ResultState.Normal
              : Evaluation.ResultState.Defect
        }

        const designDefect = defectRef.current?.getNewDesignDefect()
        return {
          evaluationData: newDesignEvaluationData,
          deletedFiles,
          newFiles,
          weakState: designDefect ? designDefect.weakState : undefined,
          weakStateData: designDefect ? designDefect.weakStateData : undefined
        }
      }
    }))

    const setFormWithEvaluation = React.useCallback(
      (newEvaluation: Evaluation.EvaluationItem) => {
        const evalData = newEvaluation.content.data as Evaluation.DesignEvaluationData
        let baselineRef = moment(evalData.baselineReference)
        if (!baselineRef.isValid()) {
          baselineRef = moment()
        }
        const fieldValues: Record<string, unknown> = {
          ownerAssessment: evalData.ownerAssessment,
          baselineReference: baselineRef,
          procedureContent: evalData.procedureContent,
          result:
            evalData.resultState === Evaluation.ResultState.Normal
              ? Evaluation.Result.effective
              : Evaluation.Result.ineffective
        }

        _.forEach(evalData.considerations, ({ content, result }, i) => {
          fieldValues[`considerationContent_${i + 1}`] = content
          fieldValues[`considerationResult_${i + 1}`] = result
        })
        if (_.isEmpty(evalData.considerations)) {
          for (let i = 0; i < getNumberOfDesignEvaluationConsiderations(); ++i) {
            fieldValues[`considerationContent_${i + 1}`] = ''
            fieldValues[`considerationResult_${i + 1}`] = Evaluation.Result.ineffective
          }
        }

        form.resetFields()
        form.setFieldsValue(fieldValues)
      },
      [form]
    )

    React.useEffect(() => {
      setFormWithEvaluation(evaluation)
    }, [evaluation])

    return (
      <div className={classes.root}>
        <div className={classes.content}>
          <DesignEvaluationTable columns={designColumns.processColumns} evaluation={evaluation} />
        </div>
        <div className={classes.content}>
          <EvaluationFileUploader
            staffs={staffs}
            evaluation={evaluation}
            files={files}
            setFiles={setFiles}
            type="design"
            updateEvaluation={(excelData: Evaluation.EvaluationData) => {
              const newEvaluation = _.cloneDeep(evaluation)
              _.assign(newEvaluation, excelData)
              setFormWithEvaluation(newEvaluation)
            }}
          />
          <div className={classes.description}>
            <Typography.Text type="secondary">
              업로드하려는 파일을 상단의 박스로 끌어 놓기
            </Typography.Text>
          </div>
          <Divider />
        </div>
        <div className={classes.content}>
          <DesignEvaluationTable
            columns={designColumns.riskControlColumn}
            evaluation={evaluation}
          />
        </div>
        <Form.Provider>
          <Form form={form} name="DesignEvaluationReportEditor">
            <div className={classes.content}>
              <DesignEvaluationTable
                columns={designColumns.controlDetailColumns}
                evaluation={evaluation}
              />
            </div>
            <div className={classes.content}>
              <DesignEvaluationTable
                columns={getControlClass().getEditableDesignColumns().considerationColumns}
                evaluation={evaluation}
              />
            </div>
            <div className={classes.content}>
              <DesignEvaluationTable
                columns={getControlClass().getEditableDesignColumns().TOCColumns}
                evaluation={evaluation}
              />
            </div>
            <Typography.Text className={classes.textDescription} type="secondary">
              1. 기존 통제사항 중 변경사항 존재시 반드시 Ineffective 처리(추후 내부회계관리자와
              협의하여 RCM 수정 )
            </Typography.Text>
            <br />
            <Typography.Text className={classes.textDescription} type="secondary">
              2. 상기 평가결론 중 Ineffective 존재시 최종 결과는 Ineffective
            </Typography.Text>
            <div className={classes.content} style={{ marginTop: 0 }}>
              <TableItem name="평가 결론">
                <Form.Item className={classes.formSelect} name="result">
                  <Select>
                    <Select.Option value="Effective">Effective</Select.Option>
                    <Select.Option value="Ineffective">Ineffective</Select.Option>
                  </Select>
                </Form.Item>
              </TableItem>
            </div>
          </Form>
        </Form.Provider>
        <DesignDefectContentView
          editable={true}
          staffs={staffs}
          evaluation={evaluation}
          ref={defectRef}
        />
      </div>
    )
  }
)

export default DesignEvaluationReportEditor
