import { progressStateToString } from '@cck/backend/dist/evaluation/EvaluationUtils'
import { BaseFile } from '@cck/common/dist/data/BaseFile'
import * as Evaluation from '@cck/common/dist/data/Evaluation'
import { Staff } from '@cck/common/dist/data/Staff'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Button from 'antd/lib/button'
import Typography from 'antd/lib/typography'
import _ from 'lodash'
import React from 'react'

import { grey } from '../../../base/color'
import EvidenceFileUploader from './EvidenceFileUploader'
import EvidenceMappingView, { EvidenceMappingHandler } from './EvidenceMappingView'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: 'white',
    border: grey.border,
    display: 'flex',
    flexDirection: 'column',
    margin: theme.spacing(2),
    padding: theme.spacing(1)
  },
  saveButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginRight: theme.spacing(2)
  }
}))

interface Props {
  staffs: Staff[]
  evaluation: Evaluation.EvaluationItem
  evidenceFiles: BaseFile[]
  evidences: string[]
  saveEvidences: (evidenceFiles: BaseFile[], evidences: string[]) => void
}

const EvidenceView: React.FC<Props> = ({
  staffs,
  evaluation,
  evidenceFiles,
  evidences,
  saveEvidences
}) => {
  const classes = useStyles()
  const [localEvidenceFiles, setLocalEvidenceFiles] = React.useState<BaseFile[]>([])
  const mappingViewRef = React.useRef<EvidenceMappingHandler>(null)

  React.useEffect(() => {
    setLocalEvidenceFiles(evidenceFiles)
  }, [evidenceFiles])

  return (
    <div>
      <div className={classes.saveButtonContainer}>
        <Typography.Text
          style={{
            marginRight: 4,
            visibility: _.includes(
              [Evaluation.ProgressState.Performed, Evaluation.ProgressState.Approved],
              evaluation?.state.state
            )
              ? 'visible'
              : 'hidden'
          }}
          type="danger"
        >
          {progressStateToString(evaluation.state.state)} 된 보고서는 변경할 수 없습니다.
        </Typography.Text>
        <Button
          type="primary"
          disabled={_.includes(
            [Evaluation.ProgressState.Performed, Evaluation.ProgressState.Approved],
            evaluation.state.state
          )}
          onClick={() =>
            saveEvidences(localEvidenceFiles, mappingViewRef.current?.getEvidenceMappings() || [])
          }
        >
          저장
        </Button>
      </div>
      <div className={classes.root}>
        <EvidenceFileUploader
          staffs={staffs}
          evaluationName={evaluation.base.name}
          controlId={evaluation.controlId}
          evidenceFiles={localEvidenceFiles}
          setEvidences={(files) => setLocalEvidenceFiles(files)}
        />
      </div>
      <div className={classes.root}>
        <EvidenceMappingView
          evaluation={evaluation}
          evidences={evidences}
          evidenceFiles={localEvidenceFiles}
          ref={mappingViewRef}
        />
      </div>
    </div>
  )
}

export default EvidenceView
