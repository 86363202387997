import { ColDef } from '@ag-grid-enterprise/all-modules'
import { BasePRC } from '@cck/common/dist/data/PRC'
import { getKoName } from '@cck/common/dist/data/Translation'
import { hasThreeCategories, noProcessName } from '@cck/common/dist/utils/Config'
import _ from 'lodash'

import { getEditableColor } from '../../../base/color'
import { deleteCellRenderer } from './PRCEditorUtils'

export function GetProcessColumnDefs(deleteItem: (item: BasePRC) => void): ColDef[] {
  const columns: ColDef[] = [
    { headerName: getKoName('common', 'cycleName'), field: 'cycleName', sortable: true },
    {
      headerName: getKoName('common', 'categoryName'),
      field: 'categoryName',
      sortable: true
    }
  ]

  if (hasThreeCategories()) {
    columns.push({
      headerName: getKoName('common', 'subCategoryName'),
      field: 'subCategoryName',
      sortable: true
    })
  }
  columns.push({ headerName: getKoName('process', 'id'), field: 'id', sortable: true })

  if (!noProcessName()) {
    columns.push({
      headerName: getKoName('process', 'name'),
      field: 'name',
      sortable: true,
      editable: true,
      cellStyle: { backgroundColor: getEditableColor() }
    })
  }

  return [
    ...columns,
    {
      headerName: getKoName('process', 'narrative'),
      field: 'narrative',
      flex: 1,
      editable: true,
      cellStyle: { backgroundColor: getEditableColor() },
      cellEditor: 'agLargeTextCellEditor',
      cellEditorParams: {
        maxLength: '1000',
        column: '800',
        rows: '10'
      }
    },
    {
      headerName: '삭제',
      field: 'action',
      cellRenderer: deleteCellRenderer,
      cellRendererParams: {
        deleteItem
      }
    }
  ]
}
