import { BasePRC, PRCType, Process } from '@cck/common/dist/data/PRC'
import { Control } from '@cck/common/dist/data/PRCUtils'
import { Staff } from '@cck/common/dist/data/Staff'
import { YesNo } from '@cck/common/dist/data/control/AbstractControl'
import Risk from '@cck/common/dist/data/risk/AbstractRisk'
import { makeStyles, Theme } from '@material-ui/core'
import List from '@material-ui/core/List'
import Typography from '@material-ui/core/Typography'
import _ from 'lodash'
import React from 'react'

import WhiteBoxView from '../../../common/WhiteBoxView'
import RCMItemRow from './RCMItemRow'

const useStyles = makeStyles((theme: Theme) => ({
  list: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(2),
    width: '100%',
    height: '100%',
    overflowY: 'auto'
  }
}))

interface RCMListProps {
  data: Array<BasePRC>
  subItems?: Map<string, Array<BasePRC>>
  staffs?: Staff[]
}

const RCMList: React.FC<RCMListProps> = ({ data, subItems, staffs }) => {
  const classes = useStyles()
  return (
    <List className={classes.list} component="div">
      {_.map(data, (element) => {
        const subItem = subItems ? subItems.get(element.id) : undefined
        return <RCMItemRow item={element} key={element.id} staffs={staffs} subItems={subItem} />
      })}
    </List>
  )
}

function getCheckedItems(
  type: PRCType,
  processes: Record<string, BasePRC>,
  risks: Record<string, BasePRC>,
  controls: Record<string, BasePRC>,
  checkedItems: string[]
): BasePRC[] {
  let data: Record<string, BasePRC>
  if (type === PRCType.process) {
    data = processes
  } else if (type === PRCType.risk) {
    data = risks
  } else {
    data = controls
  }

  const checked: BasePRC[] = []
  _.forEach(checkedItems, (id: string) => {
    if (_.has(data, id)) {
      checked.push(_.get(data, id) as BasePRC)
    }
  })
  return checked
}

function PrepareChildren(
  ids: string[],
  data: Record<string, BasePRC>,
  container: Array<BasePRC>
): void {
  _.forEach(ids, (id: string) => {
    const item = _.get(data, id)
    if (item) {
      container.push(item)
    } else {
      // eslint-disable-next-line no-console
      console.log('ERROR: Not found', id)
    }
  })
}

function PrepareAllChildren(
  type: PRCType,
  processes: Record<string, BasePRC>,
  risks: Record<string, BasePRC>,
  controls: Record<string, BasePRC>,
  isProcessView: boolean,
  isRiskView: boolean,
  isControlView: boolean,
  data: BasePRC[]
): Map<string, Array<BasePRC>> {
  const children = new Map<string, Array<BasePRC>>()
  _.forEach(data, (item: BasePRC) => {
    const subItems = Array<BasePRC>(0)
    if (isProcessView && type !== PRCType.process) {
      PrepareChildren(_.get(item, 'processIds'), processes, subItems)
    }
    if (isRiskView && type !== PRCType.risk) {
      PrepareChildren(_.get(item, 'riskIds'), risks, subItems)
    }
    if (isControlView && type !== PRCType.control) {
      PrepareChildren((item as Process).controlIds, controls, subItems)
    }
    children.set(item.id, subItems)
  })
  return children
}

interface Props {
  type: PRCType
  processes: Record<string, Process>
  risks: Record<string, Risk>
  controls: Record<string, Control>
  staffs: Staff[]
  inquireProcessView: boolean
  inquireRiskView: boolean
  inquireControlView: boolean
  controlOption: string
  checkedItems: string[]
}

const RCMDetail: React.FC<Props> = ({
  type,
  processes,
  risks,
  controls,
  staffs,
  inquireProcessView,
  inquireRiskView,
  inquireControlView,
  controlOption,
  checkedItems
}) => {
  const modifiedControls = React.useMemo(() => {
    if (controlOption === 'both') {
      return controls
    }

    const modifications: Record<string, Control> = {}
    _.forEach(controls, (value: Control, key: string) => {
      if (controlOption === 'key control' && value.keyControl === YesNo.yes) {
        modifications[key] = value
      } else if (controlOption !== 'key control' && value.keyControl === YesNo.no) {
        modifications[key] = value
      }
    })
    return modifications
  }, [controlOption, controls])

  const data = React.useMemo(
    () => getCheckedItems(type, processes, risks, controls, checkedItems),
    [type, process, risks, controls, checkedItems]
  )
  const children = React.useMemo(
    () =>
      PrepareAllChildren(
        type,
        processes,
        risks,
        modifiedControls,
        inquireProcessView,
        inquireRiskView,
        inquireControlView,
        data
      ),
    [
      type,
      processes,
      risks,
      modifiedControls,
      inquireControlView,
      inquireProcessView,
      inquireRiskView,
      data
    ]
  )

  const typeName = type[0].toUpperCase() + type.slice(1)
  return (
    <WhiteBoxView
      key={typeName}
      headerView={<Typography variant="subtitle1">{typeName}</Typography>}
      mainView={<RCMList data={data} staffs={staffs} subItems={children} />}
    />
  )
}

export default RCMDetail
