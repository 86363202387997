import { Staff } from '@cck/common/dist/data/Staff'
import { getKoName } from '@cck/common/dist/data/Translation'
import { YesNo } from '@cck/common/dist/data/control/AbstractControl'
import { isNumber, isString, isStringArray } from '@cck/common/dist/utils/TypeUtils'
import { makeStyles, Theme } from '@material-ui/core'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import { Checkbox, Col, Radio, Row } from 'antd'
import _ from 'lodash'
import React, { ReactElement } from 'react'

import { lightGrey } from '../../base/color'
import { renderStaff } from './StaffRenderer'

const useStyles = makeStyles((theme: Theme) => ({
  item: {
    flexFlow: 'row'
  },
  valueItem: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1),
    flexGrow: 1
  },
  nameItem: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1),
    background: lightGrey.background
  },
  attributeText: {
    display: 'inline-block'
  },
  stringText: {
    whiteSpace: 'pre-line'
  }
}))

function RenderString(value: string | number): ReactElement {
  const classes = useStyles()
  return (
    <Col>
      <Typography className={classes.stringText} variant="body2">
        {value}
      </Typography>
    </Col>
  )
}

function RenderRadio(radioTypes: Record<string, string>, value: string): ReactElement {
  const classes = useStyles()
  return (
    <Col>
      <Radio.Group value={value}>
        {_.map(radioTypes, (type) => {
          return (
            <Radio key={type} value={type}>
              <Typography className={classes.attributeText} variant="body2">
                {type}
              </Typography>
            </Radio>
          )
        })}
      </Radio.Group>
    </Col>
  )
}

function RenderCheck(objectValue: Record<string, YesNo>): ReactElement {
  const classes = useStyles()
  const options: string[] = []
  const checked: string[] = []
  _.forEach(objectValue, function (value: YesNo, key: string) {
    const koName = getKoName('control', key)
    options.push(koName)
    if (value === 'Yes') {
      checked.push(koName)
    }
  })

  return (
    <Col>
      {_.map(options, (option: string, index) => {
        return (
          <Checkbox
            key={index}
            checked={_.includes(checked, option)}
            style={{ marginLeft: 0, marginRight: 8 }}
          >
            <Typography className={classes.attributeText} variant="body2">
              {option}
            </Typography>
          </Checkbox>
        )
      })}
    </Col>
  )
}

interface Props {
  name: string
  children: React.ReactNode
  minHeight?: number
  noDivider?: boolean
  required?: boolean
}

const TableItem: React.FC<Props> = ({ name, children, minHeight, noDivider, required }) => {
  const classes = useStyles()
  return (
    <>
      <Row className={classes.item} style={{ minHeight }}>
        <Col className={classes.nameItem} flex="220px">
          <Row>
            {required && (
              <Typography color="error" variant="body2">
                *
              </Typography>
            )}
            <Typography style={{ fontWeight: 'bold' }} variant="body2">
              {name}
            </Typography>
          </Row>
        </Col>
        <div style={{ width: 1, borderLeft: lightGrey.border }} />
        <Col className={classes.valueItem}>{children}</Col>
      </Row>
      {!noDivider && <Divider />}
    </>
  )
}

interface ComplexTableItemProps {
  name: string
  value: string | number | Record<string, YesNo> | undefined
  checkTypes?: boolean
  radioTypes?: Record<string, string>
  noDivider?: boolean
  staff?: Staff
}

export const ComplexTableItem: React.FC<ComplexTableItemProps> = ({
  name,
  value,
  checkTypes,
  radioTypes,
  noDivider,
  staff
}) => {
  let tableValue: ReactElement
  if (radioTypes && isString(value)) {
    tableValue = RenderRadio(radioTypes, value)
  } else if (checkTypes && value && !isString(value) && !isNumber(value)) {
    tableValue = RenderCheck(value)
  } else if (staff) {
    tableValue = renderStaff(staff)
  } else if (isString(value) || isNumber(value)) {
    tableValue = RenderString(value)
  } else {
    tableValue = RenderString('')
  }

  return (
    <TableItem name={name} noDivider={noDivider}>
      {tableValue}
    </TableItem>
  )
}

export default TableItem
