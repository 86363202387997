import { getKoName } from '@cck/common/dist/data/Translation'
import { hasThreeCategories } from '@cck/common/dist/utils/Config'
import _ from 'lodash'
import React from 'react'

import SelectItem from './SelectItem'

function generateCycleOptions(options: Record<string, string>): Record<string, string> {
  return _.reduce(
    options,
    (result, name, number) => {
      _.set(result, number, `${number}. ${name}`)
      return result
    },
    {} as Record<string, string>
  )
}

export interface Props {
  formValues: { cycle: string; category: string; subCategory: string }
  isNew: boolean
  cycles: Record<string, string>
  categories: Record<string, Record<string, string>>
  subCategories: Record<string, Record<string, string>>
}

const CycleSelectFormItem: React.FC<Props> = ({
  formValues,
  isNew,
  cycles,
  categories,
  subCategories
}) => {
  const selectValidator = React.useCallback(
    (key: string): ((rule: unknown, value: unknown) => Promise<void>) => {
      return (rule: unknown, value: unknown): Promise<void> => {
        if (_.isEmpty(value)) {
          return Promise.reject(new Error(`${key} 선택해주세요.`))
        }
        return Promise.resolve()
      }
    },
    []
  )

  return (
    <>
      <SelectItem
        required
        dataKey="cycle"
        disabled={!isNew}
        name="대분류"
        options={generateCycleOptions(cycles)}
        rules={[() => ({ validator: selectValidator('대분류를') })]}
      />
      <SelectItem
        required
        dataKey="category"
        disabled={!isNew || _.isEmpty(formValues.cycle)}
        name={getKoName('common', 'categoryName')}
        options={generateCycleOptions(categories?.[formValues.cycle])}
        rules={[() => ({ validator: selectValidator(`${getKoName('common', 'categoryName')}를`) })]}
      />
      {hasThreeCategories() && (
        <SelectItem
          required
          dataKey="subCategory"
          disabled={!isNew || _.isEmpty(formValues.cycle) || _.isEmpty(formValues.category)}
          name="소분류"
          options={generateCycleOptions(
            subCategories?.[formValues.cycle + '-' + formValues.category]
          )}
          rules={[() => ({ validator: selectValidator('소분류를') })]}
        />
      )}
    </>
  )
}

export default CycleSelectFormItem
