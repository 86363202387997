import { TableContent } from '@cck/common/dist/data/Evaluation'
import { toLetters } from '@cck/common/dist/utils/StringUtils'
import _ from 'lodash'

/**
 * content에 headers를 이용해서 테이블 초기화
 * @param content
 * @param headers
 */
export function initTable(content: TableContent, headers: string[]): void {
  if (_.isEmpty(content.headerRow)) {
    content.headerRow = headers
    content.columnCountIncludeNumbering = headers.length
    content.rowCountExceptHeader = 1
    const row: Record<string, string> = {}
    _.forEach(headers, (header) => {
      row[header] = _.includes(['No', 'Attribute'], header) ? '1' : ''
    })
    content.rowObjectList = [row] as Record<string, string>[]
  }
}

/**
 * 저장용 데이터를 수정용 데이터로 변경한다. 첫번째 Column은 Numbering 첫번째 Row는 A, B, C, ...
 * @param content
 * @returns
 */
export function convertDynamicHeaderTable(content: TableContent): void {
  if (_.isEmpty(content)) return

  const rowObjectList: Record<string, string>[] = [{}]
  for (let i = 0; i < content.headerRow.length; ++i) {
    const header = toLetters(i + 1)
    rowObjectList[0][header] = content.headerRow[i]
    content.headerRow[i] = header
  }
  for (let i = 0; i < content.rowObjectList.length; ++i) {
    rowObjectList.push(
      _.reduce(
        content.headerRow,
        (result, header) => {
          result[header] = content.rowObjectList[i][rowObjectList[0][header]]
          return result
        },
        {} as Record<string, string>
      )
    )
  }
  rowObjectList[0].A = 'No\\Header'
  content.rowCountExceptHeader += 1
  content.rowObjectList = rowObjectList
}

export function changeNumberOfRow(content: TableContent, num: number): void {
  // TODO(sangmuk): num가 기존 보다 감소 했을때 처리가 필요한지 확인
  content.rowCountExceptHeader = num
  let index: number = content.rowObjectList.length
  const aValue = _.last(content.rowObjectList)?.A
  if (aValue) {
    const aValueNum = parseInt(aValue)
    index = aValueNum || index
  }
  const additionalRows = num - content.rowObjectList.length + 1
  for (let i = 0; i < additionalRows; ++i) {
    content.rowObjectList.push(
      _.reduce(
        content.headerRow,
        (result, header) => {
          result[header] = content.headerRow[0] === header ? (index + i + 1).toString() : ''
          return result
        },
        {} as Record<string, string>
      )
    )
  }
}

export function changeHeaders(content: TableContent, headers: (string | undefined)[]): void {
  if (_.some(headers, (header) => _.isEmpty(header))) {
    return
  }

  content.headerRow = headers as string[]
  content.columnCountIncludeNumbering = headers.length
  for (let i = 0; i < content.rowObjectList.length; ++i) {
    _.forEach(content.headerRow, (header) => {
      if (!_.has(content.rowObjectList[i], header)) {
        content.rowObjectList[i][header] = ''
      }
    })
  }
}

/**
 * numberOfHeaders의 값에 따라 Header에 A, B, C, ... 를 설정한다.
 * @param content 수정용 표 데이터셋. 첫번째 Column은 Numbering 첫번째 Row는 A, B, C, ...
 * @param numberOfHeaders
 */
export function adjustAlphabetHeaders(content: TableContent, numberOfHeaders: number): void {
  const headers: string[] = []
  for (let i = 0; i < numberOfHeaders + 1; ++i) {
    headers.push(toLetters(i + 1))
  }
  changeHeaders(content, headers)
}

export function insertNoColumn(content: TableContent): void {
  content.headerRow = ['No', ...content.headerRow]
  content.columnCountIncludeNumbering += 1
  _.forEach(content.rowObjectList, (row, i) => (row.No = (i + 1).toString()))
}
