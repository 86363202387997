import * as EvaluationLoader from '@cck/backend/dist/evaluation/EvaluationLoader'
import * as EvaluationUpdater from '@cck/backend/dist/evaluation/EvaluationUpdater'
import * as EvaluationUtils from '@cck/backend/dist/evaluation/EvaluationUtils'
import { getAll as LoadStaffs } from '@cck/backend/dist/rcm/StaffManager'
import { getOrCreateUser } from '@cck/backend/dist/utils/Admin'
import {
  BaseFile,
  FileType,
  FileState,
  stateToColor,
  stateToString,
  ERROR_STATES
} from '@cck/common/dist/data/BaseFile'
import * as Evaluation from '@cck/common/dist/data/Evaluation'
import { BASE_ITEM } from '@cck/common/dist/data/Evaluation'
import { Staff, Level } from '@cck/common/dist/data/Staff'
import { makeStyles, Theme } from '@material-ui/core'
import { Button, Modal, Typography } from 'antd'
import _ from 'lodash'
import React from 'react'
import { useCookies } from 'react-cookie'
import useResizeAware from 'react-resize-aware'

import AlertMessage, { AlertMessageHandler } from '../../common/AlertMessage'
import CircleBackdrop from '../../common/CircleBackdrop'
import DividerSelect from '../../common/DividerSelect'
import DropBox from '../common/DropBox'
import {
  EvaluationSelectAttribute,
  loadEvaluationDetails,
  loadEvaluationNames
} from '../common/EvaluationUtils'
import DesignEvaluationReportView from '../view/DesignEvaluationReportView'
import EvaluationTable from '../view/EvaluationTable'
import OperationEvaluationReportViewer from '../view/OperationEvaluationReportViewer'
import FileNameNoticeView from './FileNameNoticeView'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    minHeight: 600,
    padding: theme.spacing(1)
  },
  uploadList: {
    flexGrow: 1,
    flexBasis: 0,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    minHeight: 0
  },
  content: {
    flexGrow: 1,
    flexBasis: 0,
    display: 'flex',
    flexDirection: 'column',
    minHeight: 0
  },
  subheader: {
    display: 'flex',
    flexDirection: 'row',
    padding: theme.spacing(1)
  }
}))

function updateFileState(
  userLevel: Level,
  type: Evaluation.Type,
  files: Evaluation.EvaluationFile[],
  evalName: string,
  documents?: Evaluation.EvaluationItem[]
): Evaluation.EvaluationFile[] {
  const newFiles = files.slice()
  _.forEach(newFiles, (file) => {
    if (!_.includes(ERROR_STATES, file.state)) {
      if (file.state !== FileState.OK) {
        return
      }

      if (file.evaluationName === evalName && documents) {
        const matched = _.find(documents, { controlId: file.controlId })
        if (!matched) {
          file.state = FileState.UNRELATED_FILE
        } else {
          if (matched.state.state === Evaluation.ProgressState.Approved) {
            file.state = FileState.NOT_EDITABLE
          } else if (file.type === FileType.Record && matched.content.files?.record?.name) {
            file.state = FileState.ALREADY_EXISTING
          } else if (type === Evaluation.Type.Operation) {
            if (file.type === FileType.Population && matched.content.files?.population?.name) {
              if (userLevel === Level.ADMIN || userLevel === Level.NORMAL) {
                file.state = FileState.ALREADY_EXISTING
              } else {
                file.state = FileState.NOT_ALLOWED
              }
            } else if (
              file.type === FileType.Evidence &&
              _.find(matched.content.files?.evidence, { name: file.name })
            ) {
              file.state = FileState.ALREADY_EXISTING
            } else if (
              file.type === FileType.Extra &&
              _.find(matched.content.files?.extra, { name: file.name })
            ) {
              file.state = FileState.ALREADY_EXISTING
            }
          }

          // NOTE: ADMIN, NORMAL 외의 경우, 조서를 업로드 할 수 없음
          if (
            file.type === FileType.Record &&
            !_.includes([Level.ADMIN, Level.NORMAL], userLevel)
          ) {
            file.state = FileState.NO_PERMISSION
          }
        }
      } else {
        file.state = FileState.UNRELATED_OPERATION_EVAL
      }
    }
  })
  return newFiles
}

function getUploadableFiles(uploadFiles: Evaluation.EvaluationFile[]): Evaluation.EvaluationFile[] {
  return _.filter(uploadFiles, (file) => {
    return file.state === FileState.OK || file.state === FileState.ALREADY_EXISTING
  })
}

function isEnableUpload(selectedDoc: string, uploadFiles: Evaluation.EvaluationFile[]): boolean {
  return (
    !_.isEmpty(selectedDoc) &&
    selectedDoc !== '__select__' &&
    !_.isEmpty(getUploadableFiles(uploadFiles))
  )
}

function showUploadFiles(
  type: Evaluation.Type,
  operationEvaluationName: string,
  files: Evaluation.EvaluationFile[],
  afterUploadFiles: (uploadedFiles: Evaluation.EvaluationFile[]) => void,
  alertRef: React.RefObject<AlertMessageHandler>
): void {
  const uploadableFiles = getUploadableFiles(files)
  const modalProps = {
    centered: true,
    okCancel: true,
    width: 600,
    onOk() {
      return EvaluationUpdater.updateEvaluationWithFile(
        type,
        operationEvaluationName,
        uploadableFiles
      )
        .then(() => {
          const changeStates = _.compact(
            _.map(uploadableFiles, (uploadableFile) => {
              return EvaluationUpdater.updateProgressState(
                type,
                uploadableFile.evaluationName,
                [uploadableFile.controlId],
                Evaluation.ProgressState.Performing
              )
            })
          )
          Promise.all(changeStates).then(() => {
            afterUploadFiles(uploadableFiles)
            alertRef.current?.showAlert('success', '업로드 완료')
          })
        })
        .catch((e) => {
          // eslint-disable-next-line no-console
          console.log('Failed to upload file', e)
          alertRef.current?.showAlert('error', '업로드 실패')
        })
    }
  }

  if (_.every(uploadableFiles, { state: FileState.OK })) {
    Modal.success({
      title: (
        <div>
          <p>업로드 하시겠습니까?</p>
          <p>
            (파일이 업로드된 통제항목은 수행 중으로 전환됩니다. {Evaluation.getKoEvaluation(type)}{' '}
            보고서 작성 화면에서 수행완료 처리 하여야 합니다.)
          </p>
        </div>
      ),
      content: _.map(uploadableFiles, (file) => {
        return (
          <p key={file.name} style={{ margin: 0 }}>
            {file.name}
          </p>
        )
      }),
      ...modalProps
    })
  } else {
    Modal.confirm({
      title: (
        <div>
          <p>이미 업로드된 파일입니다. 업로드 하시겠습니까?</p>
          <p>
            (파일이 업로드된 통제항목은 수행 중으로 전환됩니다. {Evaluation.getKoEvaluation(type)}{' '}
            보고서 작성 화면에서 수행완료 처리 하여야 합니다.)
          </p>
        </div>
      ),
      content: _.map(uploadableFiles, (file) => {
        return (
          <p key={file.name} style={{ margin: 0, color: stateToColor(file.state) }}>
            {file.name} - {stateToString(type, file.state)}
          </p>
        )
      }),
      ...modalProps
    })
  }
}

interface Props {
  active: boolean
  type: Evaluation.Type
}

const FileManagement: React.FC<Props> = ({ active, type }) => {
  const classes = useStyles()
  const [select, setSelect] = React.useState<EvaluationSelectAttribute>({
    loading: false,
    selectedName: BASE_ITEM,
    names: [],
    bases: [],
    items: []
  })
  const [files, setFiles] = React.useState(new Array<Evaluation.EvaluationFile>(0))
  const [report, setReport] = React.useState<{
    visible: boolean
    data: Evaluation.EvaluationItem | undefined
  }>({
    visible: false,
    data: undefined
  })
  const [evaluationMap, setEvaluationMap] = React.useState<
    Record<string, Evaluation.EvaluationItem[]>
  >({})
  const [backdrop, setBackdrop] = React.useState(false)
  const [resizeListener, sizes] = useResizeAware()
  const [staffs, setStaffs] = React.useState<Staff[]>([])
  const [cookies, setCookie, removeCookie] = useCookies([`${type}EvaluationName`])
  const [userLevel, setUserLevel] = React.useState<Level>(Level.UPLOADER)
  const alertRef = React.useRef<AlertMessageHandler>(null)

  React.useEffect(() => {
    LoadStaffs().then((newStaffs) => {
      setStaffs(newStaffs)
    })
    getOrCreateUser().then((user) => {
      const level = _.get(user, 'level')
      setUserLevel(level)
    })
  }, [])

  React.useEffect(() => {
    if (active) {
      loadEvaluationNames(type, cookies[`${type}EvaluationName`], select, setSelect)
      setEvaluationMap({})
    }
  }, [active, type])

  React.useEffect(() => {
    setFiles(updateFileState(userLevel, type, files, select.selectedName, select.items))
  }, [select.items])

  const afterUploadFiles = React.useCallback(
    (uploadedFiles: Evaluation.EvaluationFile[]): void => {
      const notUploadedFiles = _.filter(files, (file) => {
        return !_.includes(uploadedFiles, file)
      })

      setFiles(notUploadedFiles)
      loadEvaluationDetails(type, select.selectedName, select, setSelect)
    },
    [files, select]
  )

  const afterDeleteFile = React.useCallback(
    (newData: Evaluation.EvaluationItem[]): void => {
      setSelect({
        ...select,
        items: newData
      })
      setFiles(updateFileState(userLevel, type, files, select.selectedName, newData))
    },
    [select, files]
  )

  const onEvaluationSelect = React.useCallback(
    (evaluationName: string): void => {
      loadEvaluationDetails(type, evaluationName, select, setSelect)
      setCookie(`${type}EvaluationName`, evaluationName)
    },
    [select]
  )

  const setEvaluationData = React.useCallback(
    (
      controlId: string,
      evaluationData: Evaluation.OperationEvaluationData | Evaluation.DesignEvaluationData,
      evaluations: Evaluation.EvaluationItem[]
    ): void => {
      const evaluation = _.find(evaluations, { controlId })
      if (!evaluation) {
        alertRef.current?.showAlert(
          'error',
          `해당 ${Evaluation.getKoEvaluation(type)}에 존재하지 않는 통제입니다.`
        )
        return
      }
      const newEvaluation = _.cloneDeep(evaluation)
      newEvaluation.content.data = evaluationData
      setReport({
        ...report,
        visible: true,
        data: newEvaluation
      })
    },
    [report]
  )

  const setReportFromDropBox = React.useCallback(
    (
      evaluationName: string,
      controlId: string,
      evaluationData: Evaluation.OperationEvaluationData | Evaluation.DesignEvaluationData
    ) => {
      if (_.has(evaluationMap, evaluationName)) {
        setEvaluationData(controlId, evaluationData, _.get(evaluationMap, evaluationName))
      } else {
        setBackdrop(true)
        EvaluationLoader.getEvaluationItems(type, evaluationName)
          .then((evaluations) => {
            setEvaluationMap({
              ...evaluationMap,
              [evaluationName]: _.values(evaluations)
            })
            setEvaluationData(controlId, evaluationData, _.values(evaluations))
          })
          .catch((e) => {
            // eslint-disable-next-line no-console
            console.log('Failed to get controls', evaluationName)
          })
          .finally(() => {
            setBackdrop(false)
          })
      }
    },
    [setEvaluationData, evaluationMap]
  )

  const setFilesFromDropBox = React.useCallback(
    (newFiles) => {
      setFiles(updateFileState(userLevel, type, newFiles, select.selectedName, select.items))
    },
    [select]
  )

  return (
    <div className={classes.root}>
      {resizeListener}
      <DividerSelect
        defaultName={`${Evaluation.getKoEvaluation(type)} 선택`}
        defaultValue={BASE_ITEM}
        loading={select.loading}
        names={EvaluationUtils.getEvaluationNamesWithDate(select.bases)}
        value={select.selectedName}
        values={select.names}
        onChange={(value) => onEvaluationSelect(value as string)}
      />
      <div className={classes.uploadList}>
        <div className={classes.subheader}>
          <Typography.Title level={5}>현재 업로드 내역</Typography.Title>
        </div>
        <EvaluationTable
          afterDeleteFile={afterDeleteFile}
          items={select.items || []}
          evaluationType={type}
          loading={select.loading}
          staffs={staffs}
          type="upload"
        />
      </div>
      <div style={{ marginTop: 4, marginBottom: 4 }} />
      <div className={classes.content}>
        <div className={classes.subheader}>
          <Typography.Title level={5} style={{ flexGrow: 1 }}>
            업로드 파일
          </Typography.Title>
          <Button
            disabled={!isEnableUpload(select.selectedName, files)}
            onClick={() => {
              showUploadFiles(type, select.selectedName, files, afterUploadFiles, alertRef)
            }}
          >
            업로드
          </Button>
        </div>
        <FileNameNoticeView
          evaluationType={type}
          evaluationName={select.selectedName !== BASE_ITEM ? select.selectedName : undefined}
        />
        <DropBox
          files={files}
          setFiles={setFilesFromDropBox}
          setReport={setReportFromDropBox}
          type={type}
        />
      </div>
      <Modal
        bodyStyle={{ padding: 0 }}
        footer={[
          <Button
            key="back"
            onClick={() => setReport({ ...report, data: undefined, visible: false })}
          >
            닫기
          </Button>
        ]}
        title="보고서 상세"
        visible={report.visible}
        width={sizes.width ? sizes.width * 0.8 : 1000}
        onCancel={() => setReport({ ...report, data: undefined, visible: false })}
        onOk={() => setReport({ ...report, data: undefined, visible: false })}
      >
        {type === 'operation' && report.data && (
          <OperationEvaluationReportViewer
            isPopup
            noFiles
            evaluation={report.data}
            staffs={staffs}
          />
        )}
        {type === 'design' && report.data && (
          <DesignEvaluationReportView noFiles staffs={staffs} evaluation={report.data} />
        )}
      </Modal>
      <AlertMessage ref={alertRef} />
      <CircleBackdrop open={backdrop} />
    </div>
  )
}

export default FileManagement
