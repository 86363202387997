import { getAll as LoadStaffs } from '@cck/backend/dist/rcm/StaffManager'
import { Staff } from '@cck/common/dist/data/Staff'
import { Theme, makeStyles } from '@material-ui/core/styles'
import _ from 'lodash'
import React from 'react'

import { getLogoImage } from '../../base/utils/ConfigUtils'
import EvaluationStatusView from './EvaluationStatusView'
import NoticeView from './NoticeView'
import TimelineView from './TimelineView'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: '#f5f5f5',
    justifyContent: 'center',
    padding: theme.spacing(2, 10, 2, 10),
    flex: 1
  },
  rowOne: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: theme.spacing(80),
    padding: theme.spacing(1)
  }
}))

const Dashboard: React.FC = () => {
  const classes = useStyles()
  const [staffs, setStaffs] = React.useState<Staff[]>([])
  const logoImgContainer = getLogoImage()

  React.useEffect(() => {
    LoadStaffs().then((newStaff) => {
      setStaffs(newStaff)
    })
  }, [])

  return (
    <div className={classes.root}>
      {logoImgContainer}
      <div className={classes.rowOne}>
        <NoticeView staffs={staffs} />
        <TimelineView />
      </div>
      <EvaluationStatusView staffs={staffs} />
    </div>
  )
}

export default Dashboard
