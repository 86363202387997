import * as ControlManager from '@cck/backend/dist/rcm/ControlManager'
import * as CycleManager from '@cck/backend/dist/rcm/CycleManager'
import * as DepartmentManager from '@cck/backend/dist/rcm/DepartmentManager'
import * as StaffManager from '@cck/backend/dist/rcm/StaffManager'
import { PRCType } from '@cck/common/dist/data/PRC'
import { Node as TreeNode, getDataTree } from '@cck/common/dist/tree/Tree'
import {
  collapseTree,
  expandTree,
  findLeafNodes,
  getRCMLeafKeys,
  sortKeysByTreeOrder
} from '@cck/common/dist/tree/TreeUtils'
import { makeStyles, Theme } from '@material-ui/core'
import { Button, Col, Select } from 'antd'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import useResizeAware from 'react-resize-aware'

import TreeTransfer from '../../common/TreeTransfer'
import { getTreeIcon, applyIconAndControlOption } from '../../common/TreeUtils'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    minHeight: 0,
    margin: theme.spacing(1),
    padding: theme.spacing(2),
    backgroundColor: 'white',
    background: '#ffffff',
    border: '1px solid lightgray',
    verticalAlign: 'middle',
    justifyContent: 'center'
  },
  header: {
    paddingBottom: theme.spacing(1),
    paddingRight: theme.spacing(2.5),
    display: 'flex',
    flexDirection: 'row',
    width: '50%'
  },
  select: {
    width: 200
  },
  button: {
    marginLeft: theme.spacing(1)
  },
  content: {
    height: '100%',
    position: 'relative',
    '& .ant-transfer-list-header-selected': {
      visibility: 'hidden'
    }
  }
}))

function findTreeId(treeData: TreeNode[], controlId: string): string | undefined {
  for (const item of treeData) {
    if (item.id && item.id === controlId) {
      return item.key as string
    }
    if (item.children) {
      const id = findTreeId(item.children, controlId)
      if (id) {
        return id
      }
    }
  }
  return undefined
}

function getDataWithNotFound(
  rawData: TreeNode[],
  data: TreeNode[],
  treeKeys: string[]
): TreeNode[] {
  const output = _.filter(data, { key: 'root0' })

  const prefix = 'not found/'
  const notFoundControlIds = _.reduce(
    treeKeys,
    (result: string[], key: string): string[] => {
      if (_.startsWith(key, prefix)) {
        result.push(key.slice(prefix.length))
      }
      return result
    },
    [] as string[]
  )

  const notFoundNodes = findLeafNodes(
    applyIconAndControlOption(rawData, PRCType.control, 'both'),
    notFoundControlIds
  )
  if (!_.isEmpty(notFoundNodes)) {
    output.unshift({
      key: 'not found',
      type: 'not found',
      id: 'not found',
      title: '(해당 없음)',
      icon: ({ expanded }) => getTreeIcon(expanded, ''),
      children: _.map(notFoundNodes, (node) => {
        return {
          ...node,
          key: 'not found/' + node.id
        }
      })
    })
  }

  const deletedControlIds = _.difference(
    notFoundControlIds,
    _.map(notFoundNodes, (node) => node.id)
  )
  if (!_.isEmpty(deletedControlIds)) {
    output.unshift({
      key: 'deleted',
      type: 'deleted',
      id: 'deleted',
      title: '(삭제된 Control)',
      icon: ({ expanded }) => getTreeIcon(expanded, ''),
      children: _.map(deletedControlIds, (controlId) => {
        return {
          id: controlId,
          key: 'not found/' + controlId,
          type: PRCType.control,
          icon: () => getTreeIcon(false, PRCType.control),
          title: '(삭제된 Control) ' + controlId
        }
      })
    })
  }

  return output
}

interface Props {
  targetControls: string[]
  treeKeys: string[]
  onChangeTreeKeys: (keys: string[]) => void
}

const CreationContent: React.FC<Props> = ({ targetControls, treeKeys, onChangeTreeKeys }) => {
  const classes = useStyles()
  const [explorer, setExplorer] = useState([] as TreeNode[])
  const [controls, setControls] = useState({
    option: 'key control',
    data: [] as TreeNode[],
    allLeafKeys: [] as string[],
    expandedKeys: [] as (string | number)[]
  })
  const [transferKeys, setTransferKeys] = useState([] as string[])
  const [resizeListener, sizes] = useResizeAware()

  const keyControls = {
    'key control': '핵심통제',
    'except key control': '비핵심통제',
    both: '모두'
  }

  React.useEffect(() => {
    Promise.all([
      ControlManager.getContainCycle(),
      CycleManager.getAll(),
      DepartmentManager.getAll(),
      StaffManager.getAll()
    ]).then((values) => {
      const [newControls, cycles, departments, staffs] = values
      const items = getDataTree(['cycle-cycle'], newControls, cycles, departments, staffs)
      setExplorer(items)
      const newData = applyIconAndControlOption(items, PRCType.control, controls.option)
      setControls({
        ...controls,
        data: getDataWithNotFound(items, newData, treeKeys),
        allLeafKeys: getRCMLeafKeys(applyIconAndControlOption(items, PRCType.control, 'both'))
      })
    })
  }, [])

  useEffect(() => {
    const newTreeKeys: string[] = []
    _.forEach(targetControls, (controlId) => {
      let treeId = findTreeId(controls.data, controlId)
      if (!treeId) {
        treeId = 'not found/' + controlId
      }
      newTreeKeys.push(treeId)
    })
    const newData = getDataWithNotFound(explorer, controls.data, newTreeKeys)
    const sortedKeys = sortKeysByTreeOrder(newData, newTreeKeys)
    onChangeTreeKeys(sortedKeys)
    setControls({
      ...controls,
      data: newData
    })
    setTransferKeys([])
  }, [targetControls])

  const onTransferChange = React.useCallback(
    (keys: string[]): void => {
      const newKeys = sortKeysByTreeOrder(
        controls.data,
        _.intersection(keys, _.union(controls.allLeafKeys, treeKeys))
      )

      onChangeTreeKeys(newKeys)
      setTransferKeys([])
    },
    [controls, treeKeys, onChangeTreeKeys]
  )

  const onControlOptionChange = React.useCallback(
    (option: string): void => {
      const newData = applyIconAndControlOption(explorer, PRCType.control, option)
      const newTreeKeys: string[] = []
      _.forEach(treeKeys, (treeKey) => {
        const index = treeKey.indexOf('/')
        if (index === -1) {
          return
        }
        const controlId = treeKey.slice(index + 1)
        let treeId = findTreeId(newData, controlId)
        if (!treeId) {
          treeId = 'not found/' + controlId
        }
        newTreeKeys.push(treeId)
      })

      onChangeTreeKeys(newTreeKeys)
      setControls({
        ...controls,
        option,
        data: getDataWithNotFound(explorer, newData, newTreeKeys)
      })
      setTransferKeys([])
    },
    [explorer, controls, onChangeTreeKeys]
  )

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Select
          className={classes.select}
          defaultValue="key control"
          value={controls.option}
          onChange={onControlOptionChange}
        >
          {_.map(keyControls, (value, key) => {
            return (
              <Select.Option key={key} value={key}>
                {value}
              </Select.Option>
            )
          })}
        </Select>
        <Col flex="auto"></Col>
        <Button
          className={classes.button}
          onClick={() => {
            setControls({
              ...controls,
              expandedKeys: expandTree(controls.data, controls.expandedKeys)
            })
          }}
        >
          다음 수준 열기
        </Button>
        <Button
          className={classes.button}
          onClick={() => {
            setControls({
              ...controls,
              expandedKeys: collapseTree(controls.data, controls.expandedKeys)
            })
          }}
        >
          현재 수준 닫기
        </Button>
      </div>
      <div className={classes.content}>
        {resizeListener}
        <TreeTransfer
          allSelectedKeys={transferKeys}
          dataSource={controls.data}
          expandedKeys={[...controls.expandedKeys]}
          height={sizes.height as number}
          targetKeys={treeKeys}
          treeOnExpand={(keys: (string | number)[]) => {
            setControls({
              ...controls,
              expandedKeys: keys.slice()
            })
          }}
          onChange={onTransferChange}
          onSelect={(selectedKeys) => {
            setTransferKeys(selectedKeys)
          }}
        />
      </div>
    </div>
  )
}

export default CreationContent
