/* eslint-disable @typescript-eslint/no-explicit-any */
import { ColDef, ColGroupDef, GridApi } from '@ag-grid-community/core'
import { removeFileInEvaluation } from '@cck/backend/dist/evaluation/EvaluationRemover'
import * as EvaluationUtils from '@cck/backend/dist/evaluation/EvaluationUtils'
import { BaseFile, FileType, initBaseFile } from '@cck/common/dist/data/BaseFile'
import * as Evaluation from '@cck/common/dist/data/Evaluation'
import { Staff, StaffFormatValue } from '@cck/common/dist/data/Staff'
import { getKoName } from '@cck/common/dist/data/Translation'
import _ from 'lodash'
import React from 'react'

import { grey } from '../../../base/color'
import {
  dateCellRenderer,
  FileNameCellRenderer,
  getFileViewColumns
} from '../../../base/utils/FileUtils'
import AlertMessage, { AlertMessageHandler } from '../../common/AlertMessage'
import LoadingButton from '../../common/LoadingButton'
import TableViewer, { TableViewerHandler } from '../../common/TableViewer'
import ToolTipTextRenderer from '../../common/ToolTipTextRenderer'

export type TableType = 'upload' | 'template' | 'management' | 'modification' | 'operation_defect'

function defectCellStyle(params: any): any {
  const evaluation = params.data
  if (
    evaluation.state?.state !== Evaluation.ProgressState.Approved ||
    evaluation.state?.weakState === Evaluation.OperationWeakState.Default
  ) {
    return { backgroundColor: grey.background }
  }
}

function removeFileName(
  evaluation: Evaluation.EvaluationItem,
  fileName: string,
  key: string
): void {
  // eslint-disable-next-line newline-per-chained-call
  const newFileNames = _(_.get(evaluation, key)).split(', ').without(fileName).join(', ')
  _.set(evaluation, key, newFileNames)
}

export function unsetFiles(evaluation: Evaluation.EvaluationItem, file: BaseFile): void {
  if (file.type === FileType.Record) {
    initBaseFile(evaluation.content.files.record)
    _.unset(evaluation, 'extra.record')
  } else if (file.type === FileType.Population) {
    initBaseFile(evaluation.content.files.population)
    _.unset(evaluation, 'extra.population')
  } else if (file.type === FileType.Evidence) {
    removeFileName(evaluation, file.name, 'extra.evidence')
    if (evaluation.content.files.evidence) {
      delete evaluation.content.files.evidence[file.name]
    }
  } else if (file.type === FileType.Extra) {
    removeFileName(evaluation, file.name, 'extra.extra')
    if (evaluation.content.files.extra) {
      delete evaluation.content.files.extra[file.name]
    }
  }
}

const deleteCellRender =
  (type: Evaluation.Type) =>
  (params: any): React.ReactElement => {
    return (
      <LoadingButton
        type="link"
        onClick={() => {
          const file = params.data as BaseFile
          return removeFileInEvaluation(file)
            .then(() => {
              const gridApi: GridApi = params.masterApi
              const evaluationDetails: any[] = []
              gridApi.forEachNode((node) => {
                evaluationDetails.push(node.data)
              })

              const deletedData = _.find(evaluationDetails, { controlId: file.controlId })
              if (!_.isEmpty(deletedData)) {
                unsetFiles(deletedData, file)
                gridApi.applyTransaction({ update: [deletedData] })
                params.alertRef.current?.showAlert('success', '파일 삭제 완료')
              }
            })
            .catch((e) => {
              // eslint-disable-next-line no-console
              console.log('Failed to delete file', e)
              params.alertRef.current?.showAlert('error', '파일 삭제 실패')
            })
        }}
      >
        삭제
      </LoadingButton>
    )
  }

export function getEvaluationColumns(
  type: TableType,
  evaluationType: Evaluation.Type,
  staffs: Staff[]
): ColDef[] {
  const columns: ColDef[] = [
    { headerName: getKoName('control', 'id'), field: 'controlId', width: 240, sortable: true },
    {
      headerName: getKoName('control', 'name'),
      field: 'content.control.name',
      suppressSizeToFit: false,
      flex: 2
    },
    {
      headerName: getKoName('common', 'cycleName'),
      field: 'content.control.cycleName',
      width: 200,
      sortable: true
    },
    {
      headerName: getKoName('control', 'department'),
      field: 'state.department',
      width: 200,
      sortable: true
    },
    {
      headerName: getKoName('control', 'ownerInTable'),
      field: 'extra.state.owner',
      suppressSizeToFit: false,
      flex: 1,
      cellRenderer: 'ToolTipTextRenderer',
      sortable: true
    },
    {
      headerName: getKoName('control', 'performerInTable'),
      field: 'extra.state.performer',
      suppressSizeToFit: false,
      flex: 1,
      cellRenderer: 'ToolTipTextRenderer',
      sortable: true
    },
    {
      headerName: '상태',
      field: 'extra.state.state',
      width: 120,
      sortable: true
    }
  ]

  if (type === 'management' && evaluationType === 'operation') {
    columns.push({
      headerName: '모집단',
      field: 'extra.population',
      menuTabs: [],
      width: 80,
      cellRenderer: 'FileNameCellRenderer'
    })
    columns.push({
      headerName: '모집단 샘플링',
      field: 'extra.populationSampling',
      menuTabs: [],
      width: 140
    })
    columns.push({
      headerName: '증빙/샘플링 수',
      field: 'extra.evidenceSamplingCount',
      menuTabs: [],
      width: 140
    })
  }

  if (type === 'template' && evaluationType === 'operation') {
    columns.push({
      headerName: '템플릿',
      field: 'extra.template',
      width: 80,
      menuTabs: [],
      cellRenderer: 'FileNameCellRenderer'
    })
  }
  if (_.includes(['template', 'upload'], type)) {
    if (evaluationType === 'operation') {
      columns.push(
        ...[
          {
            headerName: '보고서',
            field: 'extra.record',
            menuTabs: [],
            width: 80,
            cellRenderer: 'FileNameCellRenderer'
          },
          {
            headerName: '모집단',
            field: 'extra.population',
            menuTabs: [],
            width: 80,
            cellRenderer: 'FileNameCellRenderer'
          },
          {
            headerName: '증빙',
            field: 'extra.evidence',
            menuTabs: [],
            width: 80,
            cellRenderer: 'FileNameCellRenderer'
          }
        ]
      )
    }
    columns.push({
      headerName: '기타',
      field: 'extra.extra',
      menuTabs: [],
      width: 80,
      cellRenderer: 'FileNameCellRenderer',
      cellRendererParams: {
        fileType: 'extraFile'
      }
    })
  }
  if (type === 'upload') {
    const controlColumn = columns[0]
    controlColumn.width = 260
    controlColumn.cellRenderer = 'agGroupCellRenderer'
  }
  if (type === 'operation_defect') {
    columns.push({
      headerName: '미비사항',
      field: 'extra.state.resultState',
      width: 120,
      sortable: true
    })
    columns.push({
      headerName: '반영 상태',
      field: 'extra.state.weakState',
      width: 220,
      sortable: true
    })
  }
  return columns
}
// TODO(sangmuk): Consider not to use extra.
function updateValue(
  target: Evaluation.EvaluationItem,
  key: string,
  formatter: (d: any) => string
) {
  if (_.get(target, key)) {
    _.set(target, `extra.${key}`, formatter(_.get(target, key)))
  }
}

function formatEvaluations(
  staffs: Staff[],
  evaluations: Evaluation.EvaluationItem[]
): Evaluation.EvaluationItem[] {
  return _.map(evaluations, (evaluation) => {
    const newEvaluation = _.cloneDeep(evaluation)
    updateValue(newEvaluation, 'state.owner', StaffFormatValue(staffs))
    updateValue(newEvaluation, 'state.performer', StaffFormatValue(staffs))
    updateValue(newEvaluation, 'state.state', EvaluationUtils.progressStateToString)

    const samplingResult = _.get(newEvaluation, 'content.data.samplingResult')
    if (samplingResult) {
      const populationSampling =
        !_.isEmpty(samplingResult.headerRow) &&
        _.every(samplingResult.headerRow, (header) => !_.isEmpty(header))
          ? 'O'
          : 'X'
      _.set(newEvaluation, 'extra.populationSampling', populationSampling)
    }

    const sampling = _.get(newEvaluation, 'content.data.samplingCount', 0)
    const evidenceSize = _.size(_.get(newEvaluation, 'content.files.evidence')) || 0
    _.set(newEvaluation, 'extra.evidenceSamplingCount', `${evidenceSize}/${sampling}`)

    updateValue(newEvaluation, 'state.resultState', EvaluationUtils.resultStateToString)
    updateValue(newEvaluation, 'state.weakState', (v) =>
      EvaluationUtils.weakStateToString(evaluation.type, v)
    )

    const files = newEvaluation.content.files
    _.set(newEvaluation, 'extra.record', files.record?.name || '')
    _.set(newEvaluation, 'extra.population', files.population?.name || '')
    _.set(newEvaluation, 'extra.evidence', _.join(_.keys(files.evidence), ', ') || '')
    _.set(newEvaluation, 'extra.extra', _.join(_.keys(files.extra), ', ') || '')
    _.set(newEvaluation, 'extra.template', 'template')
    return newEvaluation
  })
}

interface Props {
  noBorder?: boolean
  loading: boolean
  evaluationType: Evaluation.Type
  type: TableType
  items: Evaluation.EvaluationItem[]
  staffs: Staff[]
  viewerRef?: React.RefObject<TableViewerHandler>
  height?: number
  onItemClick?: (item: Evaluation.EvaluationItem) => void
  afterDeleteFile?: (newData: Evaluation.EvaluationItem[]) => void
}

const EvaluationTable: React.FC<Props> = ({
  noBorder,
  loading,
  evaluationType,
  type,
  items,
  staffs,
  viewerRef,
  height,
  onItemClick,
  afterDeleteFile
}) => {
  const [evaluations, setEvaluations] = React.useState<Evaluation.EvaluationItem[]>()
  const [formattedEvaluations, setFormattedEvaluations] =
    React.useState<Evaluation.EvaluationItem[]>()
  const [columns, setColumns] = React.useState<(ColDef | ColGroupDef)[]>([])
  const alertRef = React.useRef<AlertMessageHandler>(null)

  React.useEffect(() => {
    setColumns(getEvaluationColumns(type, evaluationType, staffs))
  }, [type, evaluationType, staffs])

  React.useEffect(() => {
    if (EvaluationUtils.isEqualEvaluations(evaluations, items)) {
      return
    }
    setEvaluations(items)
    setFormattedEvaluations(formatEvaluations(staffs, items))
  }, [items])

  const getDeleteFileViewColumns = (): (ColDef | ColGroupDef)[] => {
    const fileViewColumns = getFileViewColumns(staffs)
    fileViewColumns.push({
      headerName: '삭제',
      cellRenderer: 'deleteCellRender',
      cellRendererParams: { alertRef }
    })
    return fileViewColumns
  }

  return (
    <>
      <TableViewer
        applyColumnDefOrder
        detailRowAutoHeight
        enableCellChangeFlash
        columnDefs={columns}
        defaultColDef={{
          wrapText: true,
          autoHeight: true,
          resizable: true,
          lockPosition: true,
          filter: 'agSetColumnFilter',
          suppressSizeToFit: true,
          filterParams: {
            excelMode: 'windows' // can be 'windows' or 'mac'
          },
          cellStyle: type === 'operation_defect' ? defectCellStyle : undefined
        }}
        detailCellRendererParams={{
          refreshStrategy: 'rows',
          detailGridOptions: {
            defaultColDef: {
              resizable: true,
              lockPosition: true
            },
            enableCellChangeFlash: true,
            columnDefs: getDeleteFileViewColumns(),
            frameworkComponents: {
              dateCellRenderer,
              deleteCellRender: deleteCellRender(evaluationType)
            }
          },
          getDetailRowData: (params: any) => {
            return params.successCallback(EvaluationUtils.getFileList(params.data))
          }
        }}
        frameworkComponents={{ FileNameCellRenderer, ToolTipTextRenderer }}
        height={height}
        isRowMaster={(dataItem) => !_.isEmpty(EvaluationUtils.getFileList(dataItem))}
        loading={loading}
        masterDetail={type === 'upload'}
        noBorder={noBorder}
        ref={viewerRef}
        rowData={formattedEvaluations}
        onRowClick={onItemClick ? (row) => onItemClick(row) : undefined}
        onRowDataUpdated={() => {
          if (!afterDeleteFile) {
            return
          }
          const newData: Evaluation.EvaluationItem[] = []
          _.forEach(_.zip(evaluations, items), ([row, evaluation]) => {
            if (row && evaluation) {
              const newEvaluation = _.cloneDeep(evaluation)
              newEvaluation.content.files = _.cloneDeep(row.content.files)
              newData.push(newEvaluation)
            }
          })
          afterDeleteFile(newData)
        }}
      />
      <AlertMessage ref={alertRef} />
    </>
  )
}

export default EvaluationTable
