import { ColDef, RowNode } from '@ag-grid-enterprise/all-modules'
import { FileState } from '@cck/common/dist/data/BaseFile'
import * as Evaluation from '@cck/common/dist/data/Evaluation'
import { getFileSizeByMB, MAX_POPULATION_SIZE } from '@cck/common/dist/utils/FileUtils'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Form from 'antd/lib/form'
import InputNumber from 'antd/lib/input-number'
import Typography from 'antd/lib/typography'
import _ from 'lodash'
import React from 'react'

import AlertMessage, { AlertMessageHandler } from '../../common/AlertMessage'
import CommonAgGrid, { CommonAgGridHandler } from '../../common/CommonAgGrid'
import {
  adjustAlphabetHeaders,
  changeNumberOfRow,
  convertDynamicHeaderTable,
  insertNoColumn
} from '../../common/TableUtils'

const useStyles = makeStyles((theme: Theme) => ({
  formItem: {
    margin: theme.spacing(1)
  },
  button: {
    margin: theme.spacing(1)
  },
  populationContainer: {
    margin: theme.spacing(2, 0),
    height: theme.spacing(60),
    display: 'flex'
  }
}))

export interface PopulationHandler {
  getPopulation: () => Evaluation.TableContent
}

interface Props {
  editable: boolean
  canModified: boolean
  setEditable: (editable: boolean) => void
  evaluation: Evaluation.EvaluationItem
  population?: Evaluation.TableContent
  populationFile?: Evaluation.EvaluationFile
  setNumberOfPopulation: (numberOfPopulation: number) => void
}

const PopulationViewer = React.forwardRef<PopulationHandler, Props>(
  (
    {
      editable,
      canModified,
      setEditable,
      evaluation,
      population: loadedPopulation,
      populationFile,
      setNumberOfPopulation
    },
    viewRef
  ) => {
    const classes = useStyles()
    const [form] = Form.useForm()
    const populationRef = React.useRef<CommonAgGridHandler>(null)
    const alertRef = React.useRef<AlertMessageHandler>(null)
    const [population, setPopulation] = React.useState<Evaluation.TableContent>({
      headerRow: ['A'],
      columnCountIncludeNumbering: 1,
      rowCountExceptHeader: 2,
      rowObjectList: [{ A: 'No\\Header' }, { A: '1' }]
    })

    React.useImperativeHandle(viewRef, () => ({
      getPopulation() {
        const newPopulation: Evaluation.TableContent = {
          headerRow: [],
          columnCountIncludeNumbering: 0,
          rowCountExceptHeader: 0,
          rowObjectList: []
        }
        const gridApi = populationRef.current?.getGridApi()
        if (!gridApi) {
          return newPopulation
        }

        // 앞에 Numbering 삭제
        const columns = _.drop(
          _.map(gridApi.columnApi.getAllColumns(), (column) => column.getColId())
        )

        newPopulation.columnCountIncludeNumbering = columns.length

        const headers = gridApi.gridApi.getPinnedTopRow(0)
        newPopulation.headerRow = _.map(columns, (column) => _.trim(_.get(headers.data, column)))

        gridApi.gridApi.forEachNode((rowNode: RowNode, index: number) => {
          const row: Record<string, string> = {}
          _.forEach(columns, (column, i) => {
            const header = _.nth(newPopulation.headerRow, i)
            if (header) {
              // NOTE: header에 점이 있을 경우 path로 인식될 수 있으니 set을 사용하지 말것
              row[header] = _.get(rowNode.data, column)
            }
          })
          newPopulation.rowObjectList.push(row)
        })
        newPopulation.rowCountExceptHeader = newPopulation.rowObjectList.length

        if (_.some(newPopulation.headerRow, (header) => _.isEmpty(header))) {
          throw new Error('empty header')
        }

        return newPopulation
      }
    }))

    const loadExcelData = React.useCallback(
      (excelData) => {
        if (!excelData) {
          form.setFieldsValue({
            numberOfRow: 5,
            numberOfColumn: 5
          })

          const newPopulation = _.cloneDeep({
            headerRow: ['A'],
            columnCountIncludeNumbering: 1,
            rowCountExceptHeader: 2,
            rowObjectList: [{ A: 'No\\Header' }, { A: '1' }]
          })
          adjustAlphabetHeaders(newPopulation, 5)
          changeNumberOfRow(newPopulation, 5)
          setPopulation(newPopulation)
          setNumberOfPopulation(5)
          return
        }

        form.setFieldsValue({
          numberOfRow: excelData.rowCountExceptHeader,
          numberOfColumn: excelData.columnCountIncludeNumbering
        })
        const newPopulation = _.cloneDeep(excelData)
        insertNoColumn(newPopulation)
        convertDynamicHeaderTable(newPopulation)
        setPopulation(newPopulation)
        setNumberOfPopulation(excelData.rowCountExceptHeader)
      },
      [form]
    )

    React.useEffect(() => {
      if (
        !populationFile ||
        !_.includes([FileState.OK, FileState.SAVED_FILE], populationFile.state) ||
        !populationFile.file
      ) {
        loadExcelData(undefined)
        setEditable(true)
        return
      }
      const isEditable = getFileSizeByMB(populationFile.file) < MAX_POPULATION_SIZE
      if (isEditable && populationFile.excelData) {
        loadExcelData(populationFile.excelData)
      } else {
        loadExcelData(undefined)
      }
      setEditable(isEditable)
    }, [populationFile])

    React.useEffect(() => {
      if (loadedPopulation) {
        loadExcelData(loadedPopulation)
      }
    }, [loadedPopulation])

    const columns: ColDef[] = React.useMemo(() => {
      const newColumns: ColDef[] = _.map(population.headerRow, (header) => ({
        editable: editable && canModified,
        headerName: header,
        field: header
      }))
      if (!_.isEmpty(newColumns)) {
        newColumns[0].editable = false
        newColumns[0].cellStyle = { backgroundColor: '#f8f8f8' }
      }
      return newColumns
    }, [population, editable, canModified])

    const onFormChange = React.useCallback(
      (formName, info) => {
        const values: Record<string, string> = {}
        _.forEach(info.changedFields, ({ name, value }) => {
          const filedPath = Array.isArray(name) ? _.join(name, '/') : name
          values[filedPath] = value
        })

        const newPopulation = _.cloneDeep(population)
        // TODO(sangmuk): update ag grid data

        _.forEach(values, (value, name) => {
          if (name === 'numberOfRow') {
            const numberOfRow = parseInt(value)
            if (newPopulation.rowCountExceptHeader >= numberOfRow) {
              newPopulation.rowCountExceptHeader = numberOfRow
              newPopulation.rowObjectList = _.slice(newPopulation.rowObjectList, 0, numberOfRow + 1)
            } else {
              changeNumberOfRow(newPopulation, numberOfRow)
            }
            setNumberOfPopulation(numberOfRow)
          } else if (name === 'numberOfColumn') {
            adjustAlphabetHeaders(newPopulation, parseInt(value))
          }
        })
        setPopulation(newPopulation)
      },
      [form, population]
    )

    return (
      <div>
        <Typography.Title level={5}>모집단</Typography.Title>
        {!editable && (
          <>
            <Typography.Text type="danger">
              파일 크기가 {MAX_POPULATION_SIZE}MB보다 큰 경우
            </Typography.Text>
            <br />
            <Typography.Text type="danger">- 웹 미리보기 및 수정 지원 불가</Typography.Text>
            <br />
            <Typography.Text type="danger">- 모집단 자동 샘플링 지원</Typography.Text>
          </>
        )}
        {editable && (
          <>
            {canModified && (
              <Form.Provider onFormChange={onFormChange}>
                <Form form={form} layout="inline">
                  <Form.Item className={classes.formItem} name="numberOfRow" label="항목수(행)">
                    <InputNumber />
                  </Form.Item>
                  <Form.Item className={classes.formItem} name="numberOfColumn" label="속성수(열)">
                    <InputNumber />
                  </Form.Item>
                </Form>
              </Form.Provider>
            )}
            <div className={classes.populationContainer}>
              <CommonAgGrid
                defaultColDef={{ sortable: true }}
                enableRangeSelection
                ref={populationRef}
                columnDefs={columns}
                pinnedTopRowData={[_.head(population.rowObjectList)]}
                rowData={_.drop(population.rowObjectList)}
              />
            </div>
          </>
        )}
        <AlertMessage ref={alertRef} />
      </div>
    )
  }
)

export default PopulationViewer
