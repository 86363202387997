import { ColDef } from '@ag-grid-community/core'
import * as Evaluation from '@cck/common/dist/data/Evaluation'
import { getStaffNameAndEmailById, Staff } from '@cck/common/dist/data/Staff'
import { getKoName } from '@cck/common/dist/data/Translation'
import { parseDateStr, parseTimeStr } from '@cck/common/dist/utils/TimeUtils'
import Divider from '@material-ui/core/Divider'
import { makeStyles, Theme } from '@material-ui/core/styles'
import _ from 'lodash'
import React from 'react'

import { grey } from '../../../base/color'
import TableContentView, { TableContentProps } from '../../common/TableContentView'
import TableHeader from '../../common/TableHeader'
import { ComplexTableItem } from '../../common/TableItem'
import EvaluationFileView from './EvaluationFileView'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    border: grey.border,
    margin: theme.spacing(2)
  },
  table: {
    padding: theme.spacing(2)
  }
}))

interface TableRowProps {
  value: Evaluation.TableContent
  gridOptions: TableContentProps
  isPopup?: boolean
}

const TableRow: React.FC<TableRowProps> = ({ value, gridOptions, isPopup }) => {
  const classes = useStyles()
  if (_.isEmpty(value)) {
    return null
  }
  return (
    <>
      <div className={classes.table}>
        <TableContentView gridOptions={gridOptions} isPopup={isPopup} value={value} />
      </div>
      <Divider />
    </>
  )
}

interface Props {
  evaluation: Evaluation.EvaluationItem
  noFiles?: boolean
  staffs: Staff[]
  isPopup?: boolean
}

const OperationEvaluationReportViewer: React.FC<Props> = ({
  evaluation,
  noFiles,
  staffs,
  isPopup
}) => {
  const classes = useStyles()

  const firstColumnOptions = {
    resizable: false,
    editable: false,
    cellStyle: { backgroundColor: '#f8f8f8' }
  }
  const evalData = React.useMemo(
    () => evaluation.content.data as Evaluation.OperationEvaluationData,
    [evaluation]
  )

  return (
    <div className={classes.root}>
      <TableHeader value="Test Info" />
      <ComplexTableItem name={getKoName('control', 'number')} value={evaluation.controlId} />
      <ComplexTableItem name={getKoName('control', 'period')} value={evaluation.base.period} />
      <ComplexTableItem
        name={getKoName('control', 'inchargeInRCM')}
        value={getStaffNameAndEmailById(staffs, evaluation.content.control.incharge)}
      />
      <ComplexTableItem
        name={getKoName('control', 'ownerInRCM')}
        value={getStaffNameAndEmailById(staffs, evaluation.content.control.owner)}
      />
      <ComplexTableItem
        name={getKoName('control', 'createDate')}
        value={parseDateStr('YYYY-MM-DD', evalData.createDate)}
      />
      <ComplexTableItem
        name={getKoName('control', 'updateTime')}
        value={parseTimeStr(evalData.updateTime)}
      />
      {!noFiles && <EvaluationFileView document={evaluation} type="operation" />}
      <Divider />
      <TableHeader value="모집단 정보" />
      <ComplexTableItem
        name={getKoName('control', 'populationDescription')}
        value={evalData.populationDescription}
      />
      <ComplexTableItem
        name={getKoName('control', 'populationCount')}
        value={evalData.populationCount}
      />
      <ComplexTableItem
        name={getKoName('control', 'populationCompleteness')}
        value={evalData.populationCompleteness}
      />
      <ComplexTableItem
        name={getKoName('control', 'samplingMethod')}
        value={evalData.samplingMethod}
      />
      <ComplexTableItem
        name={`${getKoName('control', 'samplingCount')}(행)`}
        value={evalData.samplingCount}
      />
      <ComplexTableItem
        name={`${getKoName('control', 'samplingAttributeCount')}(열)`}
        value={evalData.samplingAttributeCount}
      />
      <TableHeader value="샘플링 결과" />
      <TableRow
        value={evalData.samplingResult}
        gridOptions={{
          columnOptions: {
            No: { ...firstColumnOptions, width: 80 }
          }
        }}
        isPopup={isPopup}
      />
      <TableHeader value="테스트 수행 기준" />
      <ComplexTableItem
        name={getKoName('control', 'populationTableColumnCount')}
        value={evalData.populationTableColumnCount}
      />
      <TableRow
        value={evalData.attributeContents}
        gridOptions={{
          columnOptions: {
            Attribute: { ...firstColumnOptions, width: 120 }
          }
        }}
        isPopup={isPopup}
      />
      <TableHeader value="테스트 수행 내역" />
      <TableRow
        value={evalData.testContents}
        gridOptions={{
          wrapText: true,
          columnOptions: {
            No: { ...firstColumnOptions, width: 80 },
            ..._.reduce(
              _.filter(evalData.testContents?.headerRow, (header) => _.endsWith(header, '결과')),
              (result, header) => {
                result[header] = { width: 120, resizable: false }
                return result
              },
              {} as Record<string, ColDef>
            ),
            '테스트 결과': {
              cellEditor: 'agSelectCellEditor',
              cellEditorParams: { values: ['유효함', '미비사항 발견'] }
            }
          }
        }}
        isPopup={isPopup}
      />
      {/* <ItemRow noDivider evaluation={evaluation} name='controlException' /> */}
      <TableHeader value="결과정리" />
      <ComplexTableItem
        name={getKoName('control', 'populationCount')}
        value={evalData.populationCount}
      />
      <ComplexTableItem
        name={getKoName('control', 'samplingCount')}
        value={evalData.samplingCount}
      />
      <ComplexTableItem name={getKoName('control', 'validCount')} value={evalData.validCount} />
      <ComplexTableItem name={getKoName('control', 'invalidCount')} value={evalData.invalidCount} />
      <ComplexTableItem
        name={getKoName('control', 'testResult')}
        value={evalData.testResult}
        noDivider
      />
    </div>
  )
}

export default OperationEvaluationReportViewer
