import { ColDef } from '@ag-grid-enterprise/all-modules'
import { BasePRC } from '@cck/common/dist/data/PRC'
import { getKoName } from '@cck/common/dist/data/Translation'
import { RiskLevel } from '@cck/common/dist/data/risk/AbstractRisk'
import { hasRoMMInRisk, hasThreeCategories } from '@cck/common/dist/utils/Config'
import _ from 'lodash'

import { getEditableColor } from '../../../base/color'
import { deleteCellRenderer } from './PRCEditorUtils'

export function GetRiskColumnDefs(deleteItem: (item: BasePRC) => void): ColDef[] {
  const columns: ColDef[] = [{ headerName: '대분류', field: 'cycleName', sortable: true }]

  if (hasThreeCategories()) {
    columns.push({ headerName: '중분류', field: 'categoryName', sortable: true })
    columns.push({ headerName: '소분류', field: 'subCategoryName', sortable: true })
  } else {
    columns.push({ headerName: '분류', field: 'categoryName', sortable: true })
  }

  columns.push({ headerName: getKoName('risk', 'id'), field: 'id', sortable: true })
  columns.push({
    headerName: getKoName('risk', 'name'),
    field: 'name',
    editable: true,
    cellStyle: { backgroundColor: getEditableColor() },
    cellEditorParams: { unique: true },
    flex: 1,
    sortable: true
  })

  if (hasRoMMInRisk()) {
    columns.push({
      headerName: getKoName('risk', 'RoMM'),
      field: 'RoMM',
      editable: true,
      cellStyle: { backgroundColor: getEditableColor() },
      sortable: true
    })
  }

  return [
    ...columns,
    {
      headerName: getKoName('risk', 'inherentRiskLevel'),
      field: 'inherentRiskLevel',
      editable: true,
      cellStyle: { backgroundColor: getEditableColor() },
      cellEditor: 'agRichSelectCellEditor',
      cellEditorParams: {
        values: [RiskLevel.low, RiskLevel.moderate, RiskLevel.high]
      },
      sortable: true
    },
    {
      headerName: '삭제',
      field: 'action',
      cellRenderer: deleteCellRenderer,
      cellRendererParams: {
        deleteItem
      }
    }
  ]
}
