import * as EvaluationUtils from '@cck/backend/dist/evaluation/EvaluationUtils'
import * as Evaluation from '@cck/common/dist/data/Evaluation'
import { makeStyles, Theme, Typography } from '@material-ui/core'
import { Row, DatePicker, Button } from 'antd'
import _ from 'lodash'
import moment from 'moment'
import React from 'react'

import { BlueInput } from '../../common/BlueInput'
import DividerSelect from '../../common/DividerSelect'
import TableItem from '../../common/TableItem'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: 0,
    margin: theme.spacing(1),
    padding: theme.spacing(2),
    backgroundColor: 'white',
    background: '#ffffff',
    border: '1px solid lightgray',
    verticalAlign: 'middle',
    justifyContent: 'center'
  },
  header: {
    justifyContent: 'space-between'
  },
  content: {
    border: '1px solid lightgray'
  },
  input: {
    marginLeft: theme.spacing(1),
    width: 400
  },
  attributeText: {
    display: 'inline-block'
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: theme.spacing(2)
  },
  button: {
    width: 'fit-content',
    marginLeft: theme.spacing(1)
  }
}))

interface Props {
  type: Evaluation.Type
  evaluationSelect: {
    loading: boolean
    selectedName: string
    names: string[]
    bases: Evaluation.Base[]
    onSelect: (name: string) => void
  }
  createLoading: boolean
  documentName: string
  startDate: string
  endDate: string
  disabledMessage: string
  changeDocumentName: (value: string) => void
  onChangeCalendar: (startDate: string, endDate: string) => void
  onCreateDocument: () => void
}

const CreationHeader: React.FC<Props> = ({
  type,
  evaluationSelect,
  createLoading,
  documentName,
  startDate,
  endDate,
  disabledMessage,
  changeDocumentName,
  onChangeCalendar,
  onCreateDocument
}) => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <Row className={classes.header}>
        <DividerSelect
          defaultName="신규생성"
          defaultValue={Evaluation.BASE_ITEM}
          loading={evaluationSelect.loading}
          names={EvaluationUtils.getEvaluationNamesWithDate(evaluationSelect.bases)}
          value={evaluationSelect.selectedName}
          values={evaluationSelect.names}
          onChange={(value) => evaluationSelect.onSelect(value as string)}
        />
        <div className={classes.buttonContainer}>
          <Typography align="right" color="error" variant="body2">
            {disabledMessage}
          </Typography>
          <Button
            className={classes.button}
            disabled={!_.isEmpty(disabledMessage)}
            loading={createLoading}
            type="primary"
            onClick={() => onCreateDocument()}
          >
            {Evaluation.getKoEvaluation(type)} 생성
          </Button>
        </div>
      </Row>
      <div className={classes.content}>
        <TableItem name={`${Evaluation.getKoEvaluation(type)}명`}>
          <BlueInput
            className={classes.input}
            value={documentName}
            onChange={(event) => {
              changeDocumentName(event.target.value)
            }}
          />
        </TableItem>
        <TableItem noDivider name="대상기간">
          <DatePicker.RangePicker
            bordered={false}
            value={[moment(startDate), moment(endDate)]}
            onCalendarChange={(values, formatString) => {
              onChangeCalendar(formatString[0], formatString[1])
            }}
          />
        </TableItem>
      </div>
    </div>
  )
}

export default CreationHeader
