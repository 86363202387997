import { ColDef, ColGroupDef } from '@ag-grid-community/all-modules'
import { ValueFormatterParams } from '@ag-grid-community/core'
import { blue } from '@ant-design/colors'
import * as EvaluationParser from '@cck/backend/dist/evaluation/ExcelParser'
import { FileState, stateToString } from '@cck/common/dist/data/BaseFile'
import * as Evaluation from '@cck/common/dist/data/Evaluation'
import { Staff } from '@cck/common/dist/data/Staff'
import { getFileSizeByMB, MAX_POPULATION_SIZE } from '@cck/common/dist/utils/FileUtils'
import { parsePopulationFileForWorker } from '@cck/js/src/PopulationFileReader'
import { useWorker } from '@koale/useworker'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Typography from 'antd/lib/typography'
import clsx from 'clsx'
import _ from 'lodash'
import React from 'react'
import { useDropzone } from 'react-dropzone'

import { dateCellRenderer, getFileViewColumns } from '../../../base/utils/FileUtils'
import AlertMessage, { AlertMessageHandler } from '../../common/AlertMessage'
import CircleBackdrop from '../../common/CircleBackdrop'
import CommonAgGrid from '../../common/CommonAgGrid'
import NoticeTextView from '../../common/NoticeTextView'
import { StaffCellRenderer } from '../../common/StaffRenderer'
import { stateCellRenderer } from '../common/DropBox'
import UploadIconAndText from '../common/UploadIconAndText'

const useStyles = makeStyles((theme: Theme) => ({
  dropzone: {
    display: 'flex',
    position: 'relative',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1,
    background: 'WhiteSmoke',
    border: '1px dashed lightgray',
    marginBottom: theme.spacing(1)
  },
  dragActive: {
    borderColor: blue.primary
  }
}))

function getFileUploaderColumns(staffs: Staff[]): (ColDef | ColGroupDef)[] {
  return [
    ...getFileViewColumns(staffs),
    {
      headerName: '파일 상태',
      field: 'state',
      cellRenderer: stateCellRenderer,
      valueFormatter: (params: ValueFormatterParams): string =>
        stateToString(Evaluation.Type.Operation, params.value)
    }
  ]
}

export interface PopulationFileUploaderHandler {
  getPopulationFiles: () => Evaluation.EvaluationFile[]
}

interface Props {
  evaluationName: string
  controlId: string
  staffs: Staff[]
  populationFile?: Evaluation.EvaluationFile
  setPopulation: (
    fileName: string,
    population: Evaluation.TableContent | undefined,
    files: Evaluation.EvaluationFile[]
  ) => void
}

const PopulationFileUploader = React.forwardRef<PopulationFileUploaderHandler, Props>(
  ({ evaluationName, controlId, staffs, populationFile, setPopulation }, viewRef) => {
    const classes = useStyles()
    const [states, setStates] = React.useState({
      saveLoading: false
    })
    const alertRef = React.useRef<AlertMessageHandler>(null)
    const [file, setFile] = React.useState<Evaluation.EvaluationFile>()
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [readFileWorker] = useWorker(parsePopulationFileForWorker as any, {
      remoteDependencies: ['https://unpkg.com/xlsx@0.17.3/dist/xlsx.full.min.js']
    })

    React.useImperativeHandle(viewRef, () => ({
      getPopulationFiles() {
        return file ? [file] : []
      }
    }))

    const onDrop = React.useCallback(
      (originalFiles) => {
        const acceptedFiles: File[] = []
        _.forEach(originalFiles, (originalFile) => {
          acceptedFiles.push(
            new File([originalFile], originalFile.name.normalize('NFC'), {
              type: originalFile.type
            })
          )
        })

        const fileInfos = EvaluationParser.parseFileInfo(
          'operation',
          acceptedFiles,
          evaluationName,
          controlId
        )
        if (fileInfos.length !== 1) {
          alertRef.current?.showAlert('error', '하나의 모집단 파일을 업로드해주세요.')
          return
        }
        if (fileInfos[0].type !== '모집단') {
          alertRef.current?.showAlert('error', '모집단 파일을 업로드해주세요.')
          return
        }

        const newPopulationFile = fileInfos[0] as Evaluation.EvaluationFile
        if (!newPopulationFile.file) {
          return
        }
        if (newPopulationFile.state !== FileState.OK) {
          setFile(newPopulationFile)
          return
        }

        setStates({ saveLoading: true })
        if (getFileSizeByMB(newPopulationFile.file) >= MAX_POPULATION_SIZE) {
          setPopulation(newPopulationFile.file.name, undefined, [newPopulationFile])
          setFile(newPopulationFile)
          setStates({ saveLoading: false })
        } else {
          readFileWorker(newPopulationFile)
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            .then((newPopulation: any) => {
              if (newPopulation.state === FileState.OK) {
                setPopulation(newPopulation.file.name, newPopulation.excelData, [newPopulationFile])
              }
              setFile(newPopulationFile)
            })
            .catch((error: any) => {
              console.log('Failed to read file', error)
              alertRef.current?.showAlert(
                'error',
                '잘못된 형식의 모집단 파일입니다. 모집단 Template파일을 사용하여 다시 업로드하거나 모집단 표에 직접 붙여 넣어 주세요.'
              )
            })
            .finally(() => setStates({ saveLoading: false }))
        }
      },
      [controlId, evaluationName, file, states]
    )

    React.useEffect(() => {
      if (!_.isEmpty(populationFile?.name)) {
        setFile(populationFile)
      } else {
        setFile(undefined)
      }
    }, [controlId, populationFile])

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
      onDrop,
      noClick: file !== undefined
    })

    return (
      <div>
        <Typography.Title level={5}>모집단 파일 업로드</Typography.Title>
        <div
          className={clsx(classes.dropzone, { [classes.dragActive]: isDragActive })}
          {...getRootProps()}
        >
          <input {...getInputProps()} />
          {!file && <UploadIconAndText isDragActive={isDragActive} />}
          {file && (
            <CommonAgGrid
              noBorder
              columnDefs={getFileUploaderColumns(staffs)}
              domLayout="autoHeight"
              frameworkComponents={{ dateCellRenderer, StaffCellRenderer }}
              rowData={[file]}
            />
          )}
        </div>
        <NoticeTextView>
          <Typography.Text>
            모집단 파일명은{' '}
            <Typography.Text type="danger" strong>
              ({evaluationName})({controlId})(모집단)파일이름.xlsx
            </Typography.Text>
            으로 업로드해야합니다.
          </Typography.Text>
          <br />
          <Typography.Text>
            모집단 Template 파일은{' '}
            <Typography.Text type="danger" strong>
              운영평가 파일 관리
            </Typography.Text>
            {' / '}
            <Typography.Text type="danger" strong>
              템플릿 다운로드
            </Typography.Text>
            에서 다운로드 할 수 있습니다.
          </Typography.Text>
        </NoticeTextView>
        <AlertMessage ref={alertRef} />
        <CircleBackdrop open={states.saveLoading} />
      </div>
    )
  }
)

export default PopulationFileUploader
