import Input from 'antd/lib/input'
import _ from 'lodash'
import React from 'react'

// NOTE: resolveOnChange 함수는 antd/lib/input/input.js에서 가져옴
function resolveOnChange(
  target: HTMLInputElement,
  e: React.ChangeEvent<HTMLInputElement>,
  onChange: React.ChangeEventHandler<HTMLInputElement> | undefined
) {
  if (onChange) {
    let event = e

    if (e.type === 'click') {
      // click clear icon
      event = Object.create(e)
      event.target = target
      event.currentTarget = target
      // change target ref value cause e.target.value should be '' when clear input
      const originalInputValue = target.value

      target.value = ''
      onChange(event) // reset target ref value

      target.value = originalInputValue
      return
    }

    onChange(event)
  }
}

function isAlpha(str: string): boolean {
  return /^[a-zA-Z]+$/.test(str)
}

class AlphaInput extends Input {
  handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (
      _.get(e.nativeEvent, 'inputType') === 'insertText' &&
      !isAlpha(_.get(e.nativeEvent, 'data'))
    ) {
      return
    }
    this.setValue(e.target.value)
    resolveOnChange(this.input, e, this.props.onChange)
  }
}

export default AlphaInput
