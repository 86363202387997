/* eslint-disable */
// NOTE: useWorker와 사용하기 때문에 webpack을 사용하지 말 것.
const XLSX = require('xlsx')

export const parsePopulationFile = (inputFile) => {
  /*
  운영평가 모집단 엑셀파일을 파싱한다.
  사전에 정의한 Template 파일이다.

  결과의 형태는 다음과 같을 것이다.
  A | B | C
  1 | 2 | 3
  11| 22| 33
  {
    headerRow: ["A", "B", "C"],
    columnCountIncludeNumbering: 3,
    rowCountExceptHeader: 2,
    rowObjectList: [
      {
        "A": 1,
        "B": 2,
        "C": 3,
      },
      {
        "A": 11,
        "B": 22,
        "C": 33,
      }
    ],
  }
  */
  return new Promise((resolve, reject) => {
    // eslint-disable-next-line no-undef
    const fileReader = new FileReader()
    fileReader.onloadend = (evt) => {
      try {
        const newFile = {
          ...inputFile,
          state: 1, // FileState.OK
          excelData: undefined,
          excelException: []
        }

        if (!evt?.target?.result) {
          reject(new Error('Failed read file'))
          return
        }
        const {
          target: { result: targetExcelFile }
        } = evt

        const excelData = XLSX.read(targetExcelFile, {
          type: 'binary',
          sheetStubs: true,
          cellDates: true,
          dateNF: 'yyyy-mm-dd'
        })

        const workSheetName = '모집단'
        const workSheet = excelData.Sheets[workSheetName]
        if (!workSheet || !workSheet['!ref']) {
          newFile.state = 3 // FileState.PARSING_ERROR
          newFile.excelException = ['형식에 맞지 않은 파일입니다. Template 파일을 사용해주세요.']
          resolve(newFile)
          return
        }

        // 시작 위치는 무조건 정해져있다. -> 9행
        const range = XLSX.utils.decode_range(workSheet['!ref'])
        range.s.r = 7 // 헤더도 읽고 싶어서
        workSheet['!ref'] = XLSX.utils.encode_range(range)
        let jsonExcelData = XLSX.utils.sheet_to_json(workSheet, { defval: '', raw: false})
        if (jsonExcelData.length < 2) {
          // 헤더만 있거나 아무것도 없는 상태
          newFile.state = 3 // FileState.PARSING_ERROR
          newFile.excelException.push('모집단 엑셀파일이 비었습니다.')
          resolve(newFile)
          return
        }
        // 헤더 가져오기
        const headerRow = []
        const rawHeaderData = jsonExcelData[0]
        if (!rawHeaderData) {
          newFile.state = 3 // FileState.PARSING_ERROR
          newFile.excelException.push('모집단 헤더가 비었습니다.')
          resolve(newFile)
          return
        }

        const excelResultException = []
        let emptyHeaderFlag = false
        const duplicateHeaderMap = {}
        Object.keys(rawHeaderData).forEach((k, index) => {
          if (index !== 0) {
            let value = rawHeaderData[k]
            if (value !== '') {
              if (!(value in duplicateHeaderMap)) {
                duplicateHeaderMap[value] = 0
              } else {
                const duplicateValue = duplicateHeaderMap[value] + 1
                duplicateHeaderMap[value] = duplicateValue
                value = value + '_' + duplicateValue
              }
              headerRow.push(value)
              if (emptyHeaderFlag) {
                newFile.state = 3 // FileState.PARSING_ERROR
                excelResultException.push('헤더가 없는 데이터 열이 존재합니다.')
                headerRow.splice(headerRow.length - 1, 0, '')
                emptyHeaderFlag = false
              }
            } else {
              emptyHeaderFlag = true
            }
          }
        })
        const columnCountIncludeNumbering = headerRow.length
        // 헤더는 이제 제외
        const otherExcelData = jsonExcelData.slice(1)
        // 나머지 데이터
        const rowObjectList = []
        otherExcelData.forEach((rowData) => {
          const tempRowData = {}
          Object.values(rowData).forEach((value, index) => {
            try {
              if (index > 0 && (index <= columnCountIncludeNumbering || value !== '')) {
                tempRowData[headerRow[index - 1]] = value
              }
            } catch {
              newFile.state = 3 // FileState.PARSING_ERROR
              excelResultException.push('헤더가 없는 데이터 열이 존재합니다.')
            }
          })
          if (Object.keys(tempRowData).length !== columnCountIncludeNumbering) {
              newFile.state = 3 // FileState.PARSING_ERROR
            excelResultException.push('헤더가 없는 데이터 열이 존재합니다.')
          }
          rowObjectList.push(tempRowData)
        })
        const excelResult = {
          headerRow,
          columnCountIncludeNumbering,
          rowCountExceptHeader: rowObjectList.length,
          rowObjectList
        }
        const exceptionMessages = new Set(excelResultException)
        const uniqMessages = Array.from(exceptionMessages.values())
        if (uniqMessages.length > 0) {
          newFile.state = 3 // FileState.PARSING_ERROR
        }
        newFile.excelData = excelResult
        newFile.excelException = excelResultException

        console.log('parsePopulationFile ', excelResult)
        resolve(newFile)
      } catch (error) {
        newFile.state = 3 // FileState.PARSING_ERROR
        newFile.excelException = ['형식에 맞지 않은 파일입니다. Template 파일을 사용해주세요.']
        resolve(newFile)
      }
    }
    fileReader.onerror = reject
    fileReader.readAsBinaryString(inputFile.file)
  })
}

export const parsePopulationFileForWorker = new Function(
  'inputFile',
  `
  /*
  운영평가 모집단 엑셀파일을 파싱한다.
  사전에 정의한 Template 파일이다.

  결과의 형태는 다음과 같을 것이다.
  A | B | C
  1 | 2 | 3
  11| 22| 33
  {
    headerRow: ["A", "B", "C"],
    columnCountIncludeNumbering: 3,
    rowCountExceptHeader: 2,
    rowObjectList: [
      {
        "A": 1,
        "B": 2,
        "C": 3,
      },
      {
        "A": 11,
        "B": 22,
        "C": 33,
      }
    ],
  }
  */
  return new Promise((resolve, reject) => {
    // eslint-disable-next-line no-undef
    const fileReader = new FileReader()
    fileReader.onloadend = (evt) => {
      try {
        const newFile = {
          ...inputFile,
          state: 1, // FileState.OK
          excelData: undefined,
          excelException: []
        }

        if (!evt?.target?.result) {
          reject(new Error('Failed read file'))
          return
        }
        const {
          target: { result: targetExcelFile }
        } = evt

        const excelData = XLSX.read(targetExcelFile, {
          type: 'binary',
          sheetStubs: true,
          cellDates: true,
          dateNF: 'yyyy-mm-dd'
        })

        const workSheetName = '모집단'
        const workSheet = excelData.Sheets[workSheetName]
        if (!workSheet || !workSheet['!ref']) {
          newFile.state = 3 // FileState.PARSING_ERROR
          newFile.excelException = ['형식에 맞지 않은 파일입니다. Template 파일을 사용해주세요.']
          resolve(newFile)
          return
        }

        // 시작 위치는 무조건 정해져있다. -> 9행
        const range = XLSX.utils.decode_range(workSheet['!ref'])
        range.s.r = 7 // 헤더도 읽고 싶어서
        workSheet['!ref'] = XLSX.utils.encode_range(range)
        let jsonExcelData = XLSX.utils.sheet_to_json(workSheet, { defval: '', raw: false})
        if (jsonExcelData.length < 2) {
          // 헤더만 있거나 아무것도 없는 상태
          newFile.state = 3 // FileState.PARSING_ERROR
          newFile.excelException.push('모집단 엑셀파일이 비었습니다.')
          resolve(newFile)
          return
        }
        // 헤더 가져오기
        const headerRow = []
        const rawHeaderData = jsonExcelData[0]
        if (!rawHeaderData) {
          newFile.state = 3 // FileState.PARSING_ERROR
          newFile.excelException.push('모집단 헤더가 비었습니다.')
          resolve(newFile)
          return
        }

        const excelResultException = []
        let emptyHeaderFlag = false
        const duplicateHeaderMap = {}
        Object.keys(rawHeaderData).forEach((k, index) => {
          if (index !== 0) {
            let value = rawHeaderData[k]
            if (value !== '') {
              if (!(value in duplicateHeaderMap)) {
                duplicateHeaderMap[value] = 0
              } else {
                const duplicateValue = duplicateHeaderMap[value] + 1
                duplicateHeaderMap[value] = duplicateValue
                value = value + '_' + duplicateValue
              }
              headerRow.push(value)
              if (emptyHeaderFlag) {
                newFile.state = 3 // FileState.PARSING_ERROR
                excelResultException.push('헤더가 없는 데이터 열이 존재합니다.')
                headerRow.splice(headerRow.length - 1, 0, '')
                emptyHeaderFlag = false
              }
            } else {
              emptyHeaderFlag = true
            }
          }
        })
        const columnCountIncludeNumbering = headerRow.length
        // 헤더는 이제 제외
        const otherExcelData = jsonExcelData.slice(1)
        // 나머지 데이터
        const rowObjectList = []
        otherExcelData.forEach((rowData) => {
          const tempRowData = {}
          Object.values(rowData).forEach((value, index) => {
            try {
              if (index > 0 && (index <= columnCountIncludeNumbering || value !== '')) {
                tempRowData[headerRow[index - 1]] = value
              }
            } catch {
              newFile.state = 3 // FileState.PARSING_ERROR
              excelResultException.push('헤더가 없는 데이터 열이 존재합니다.')
            }
          })
          if (Object.keys(tempRowData).length !== columnCountIncludeNumbering) {
              newFile.state = 3 // FileState.PARSING_ERROR
            excelResultException.push('헤더가 없는 데이터 열이 존재합니다.')
          }
          rowObjectList.push(tempRowData)
        })
        const excelResult = {
          headerRow,
          columnCountIncludeNumbering,
          rowCountExceptHeader: rowObjectList.length,
          rowObjectList
        }
        const exceptionMessages = new Set(excelResultException)
        const uniqMessages = Array.from(exceptionMessages.values())
        if (uniqMessages.length > 0) {
          newFile.state = 3 // FileState.PARSING_ERROR
        }
        newFile.excelData = excelResult
        newFile.excelException = excelResultException

        console.log('parsePopulationFile ', excelResult)
        resolve(newFile)
      } catch (error) {
        newFile.state = 3 // FileState.PARSING_ERROR
        newFile.excelException = ['형식에 맞지 않은 파일입니다. Template 파일을 사용해주세요.']
        resolve(newFile)
      }
    }
    fileReader.onerror = reject
    fileReader.readAsBinaryString(inputFile.file)
  })
  `
)
