import { getKoName } from '@cck/common/dist/data/Translation'
import _ from 'lodash'
import React from 'react'

import TextItem, { getRequiredMessage } from './TextItem'

interface Props {
  type: 'process' | 'risk' | 'control'
  names: string[]
}

const ProcessEditView: React.FC<Props> = ({ type, names }) => {
  if (type !== 'process') {
    return <></>
  }

  return (
    <>
      <TextItem
        required
        dataKey="name"
        name={getKoName(type, 'name')}
        rules={[
          {
            required: true,
            message: getRequiredMessage('name', getKoName(type, 'name'))
          },
          () => ({
            validator(rule: unknown, value: string): Promise<void> {
              if (!_.isEmpty(value) && names && _.includes(names, value.trim())) {
                return Promise.reject(new Error(`이미 존재하는 ${getKoName(type, 'name')}입니다.`))
              }
              return Promise.resolve()
            }
          })
        ]}
      />
      <TextItem
        multiline
        noDivider
        required
        dataKey="narrative"
        name={getKoName('process', 'narrative')}
        rules={[{ required: true, message: getRequiredMessage('narrative') }]}
      />
    </>
  )
}

export default ProcessEditView
