import { close as closeEvaluation } from '@cck/backend/dist/evaluation/EvaluationArchiver'
import * as EvaluationLoader from '@cck/backend/dist/evaluation/EvaluationLoader'
import { updateWeakStateData } from '@cck/backend/dist/evaluation/EvaluationUpdater'
import { getEvaluationNamesWithDate } from '@cck/backend/dist/evaluation/EvaluationUtils'
import { getAll as LoadStaffs } from '@cck/backend/dist/rcm/StaffManager'
import * as Evaluation from '@cck/common/dist/data/Evaluation'
import {
  BASE_ITEM,
  getKoEvaluation,
  isAbleToClose,
  isAbleToDefect,
  isAlreadyClose
} from '@cck/common/dist/data/Evaluation'
import { Staff } from '@cck/common/dist/data/Staff'
import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import { makeStyles, Theme } from '@material-ui/core/styles'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Button from 'antd/lib/button'
import Form from 'antd/lib/form'
import Input from 'antd/lib/input'
import Modal from 'antd/lib/modal'
import Space from 'antd/lib/space'
import Typography from 'antd/lib/typography'
import clsx from 'clsx'
import _ from 'lodash'
import Animate from 'rc-animate'
import React from 'react'
import { useCookies } from 'react-cookie'

import { grey } from '../../../base/color'
import AlertMessage, { AlertMessageHandler } from '../../common/AlertMessage'
import CircleBackdrop from '../../common/CircleBackdrop'
import DividerSelect from '../../common/DividerSelect'
import { TableViewerHandler } from '../../common/TableViewer'
import {
  EvaluationSelectAttribute,
  loadEvaluationDetails,
  loadEvaluationNames
} from '../common/EvaluationUtils'
import DesignEvaluationReportView from '../view/DesignEvaluationReportView'
import EvaluationTable from '../view/EvaluationTable'
import OperationEvaluationReportViewer from '../view/OperationEvaluationReportViewer'
import EvaluationDefectSummary from './EvaluationDefectSummary'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    overflowY: 'auto',
    minHeight: 0,
    margin: theme.spacing(1)
  },
  select: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  warningText: {
    marginRight: theme.spacing(2)
  },
  invisibleWarningText: {
    visibility: 'hidden'
  },
  closeButton: {
    marginBottom: theme.spacing(2)
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: 0,
    margin: theme.spacing(1),
    verticalAlign: 'middle',
    justifyContent: 'center'
  },
  headerBox: {
    padding: theme.spacing(1),
    border: grey.border
  },
  accordion: {
    boxShadow: 'none',
    border: '1px solid rgba(0, 0, 0, .125)',
    '&:not(:last-child)': {
      borderBottom: 0
    },
    '&:before': {
      display: 'none'
    },
    '&.MuiAccordion-root.Mui-expanded': {
      margin: 0
    },
    '& .MuiCollapse-wrapper > div > div': {
      display: 'flex'
    }
  },
  accordionSummary: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    minHeight: 56,
    '&.MuiAccordionSummary-root.Mui-expanded': {
      minHeight: 56
    },
    '& .MuiAccordionSummary-content.Mui-expanded': {
      margin: 0
    }
  },
  accordionDetail: {
    '&.MuiAccordionDetails-root': {
      padding: 0,
      height: 300,
      flexDirection: 'column',
      flexGrow: 1,
      minWidth: 900,
      width: 400
    }
  },
  content: {
    display: 'flex',
    flexDirection: 'column'
  },
  viewer: {
    padding: theme.spacing(1),
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    minHeight: 0,
    margin: theme.spacing(1),
    background: 'white',
    border: grey.border
  }
}))

interface Props {
  type: Evaluation.Type
}

const EvaluationDefectView: React.FC<Props> = ({ type }) => {
  const classes = useStyles()
  const [select, setSelect] = React.useState<EvaluationSelectAttribute>({
    loading: false,
    selectedName: BASE_ITEM,
    names: [],
    bases: [],
    items: []
  })
  const [states, setStates] = React.useState({
    sortedEvaluations: [] as Evaluation.EvaluationItem[],
    selectedDetailId: '',
    saveLoading: false
  })
  const [closingEvaluation, setClosingEvaluation] = React.useState({
    visible: false,
    loading: false
  })
  const [staffs, setStaffs] = React.useState<Staff[]>([])
  const [evaluationDefects, setEvaluationDefects] = React.useState(
    {} as Record<string, Evaluation.ItemState>
  )
  const [form] = Form.useForm()
  const alertRef = React.useRef<AlertMessageHandler>(null)
  const tableViewerRef = React.useRef<TableViewerHandler>(null)
  const [cookies, setCookie] = useCookies([`${type}EvaluationName`])

  React.useEffect(() => {
    LoadStaffs().then((newStaffs) => {
      setStaffs(newStaffs)
    })
  }, [])

  React.useEffect(() => {
    loadEvaluationNames(type, cookies[`${type}EvaluationName`], select, setSelect)
    setStates({ ...states, selectedDetailId: '' })
  }, [type])

  React.useEffect(() => {
    if (_.isEmpty(select.items)) {
      return
    }

    const sortedEvaluations = _.cloneDeep(select.items) || []
    sortedEvaluations.sort((a, b) => {
      if (isAbleToDefect(a) && isAbleToDefect(b)) {
        return 0
      }

      if (isAbleToDefect(a)) {
        return -1
      } else if (isAbleToDefect(b)) {
        return 1
      }
      return 0
    })

    setStates({
      ...states,
      saveLoading: true
    })

    EvaluationLoader.getEvaluationStates(type, select.selectedName)
      .then((evaluationStates) => {
        const newEvaluationDefect: Record<string, Evaluation.ItemState> = evaluationStates
        setEvaluationDefects(newEvaluationDefect)
      })
      .finally(() => {
        setStates({
          ...states,
          saveLoading: false,
          sortedEvaluations
        })
      })
  }, [select.items])

  const onEvaluationSelect = React.useCallback(
    (evaluationName: string): void => {
      loadEvaluationDetails(type, evaluationName, select, setSelect)
      setCookie(`${type}EvaluationName`, evaluationName)
      setStates({ ...states, selectedDetailId: '' })
    },
    [type, select, states]
  )

  const selectedEvaluation = React.useMemo((): Evaluation.EvaluationItem | undefined => {
    return _.find(select.items, { controlId: states.selectedDetailId })
  }, [states, select])

  const onSaveEvaluationDefect = React.useCallback(
    (
      weakState: Evaluation.OperationWeakState | Evaluation.DesignWeakState,
      weakStateData: Evaluation.OperationDefect | Evaluation.DesignDefect
    ): Promise<void> => {
      if (!selectedEvaluation) {
        return Promise.reject(new Error('not valid evaluation'))
      }

      return updateWeakStateData(
        type,
        selectedEvaluation.base.name,
        selectedEvaluation.controlId,
        weakState,
        weakStateData
      )
        .then(() => {
          loadEvaluationDetails(type, select.selectedName, select, setSelect)
          alertRef.current?.showAlert('success', '저장되었습니다.')
        })
        .catch((e) => {
          alertRef.current?.showAlert('error', '저장 실패했습니다.')
        })
    },
    [type, alertRef, select, selectedEvaluation]
  )

  const getEvaluationSelectorHeader = React.useCallback(() => {
    if (
      !_.isEmpty(select.selectedName) &&
      select.selectedName !== BASE_ITEM &&
      _.isEmpty(states.sortedEvaluations)
    ) {
      return `해당 ${getKoEvaluation(type)}에는 미비사항이 없습니다.`
    } else if (selectedEvaluation) {
      return selectedEvaluation.controlId
    }
    return '통제활동을 선택해주세요.'
  }, [select, states])

  const closingEvaluationCancel = React.useCallback((): void => {
    setClosingEvaluation({
      ...closingEvaluation,
      visible: false
    })
    form.resetFields()
  }, [closingEvaluation])

  const closingEvaluationOk = React.useCallback((): void => {
    if (select.selectedName === BASE_ITEM || !isAbleToClose(states.sortedEvaluations)) {
      alertRef.current?.showAlert('error', '마감할 수 없는 평가입니다.')
      setClosingEvaluation({
        ...closingEvaluation,
        visible: false
      })
      form.resetFields()
      return
    }

    if (
      select.selectedName !== form.getFieldValue('name') ||
      select.selectedName !== form.getFieldValue('reenterName')
    ) {
      alertRef.current?.showAlert('error', '입력한 이름이 다릅니다.')
      return
    }

    setClosingEvaluation({
      ...closingEvaluation,
      loading: true
    })
    closeEvaluation(type, select.selectedName)
      .then(() => {
        alertRef.current?.showAlert('success', `${getKoEvaluation(type)} 마감`)
        loadEvaluationNames(type, select.selectedName, select, setSelect)
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.log('Failed to close evaluation', e)
        alertRef.current?.showAlert('error', '마감 실패')
      })
      .finally(() => {
        setClosingEvaluation({
          ...closingEvaluation,
          loading: false,
          visible: false
        })
      })
  }, [closingEvaluation, select, type, form])

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <div className={classes.select}>
          <DividerSelect
            defaultName={`${getKoEvaluation(type)} 선택`}
            defaultValue={BASE_ITEM}
            loading={select.loading}
            names={getEvaluationNamesWithDate(select.bases)}
            value={select.selectedName}
            values={select.names}
            onChange={(value) => onEvaluationSelect(value as string)}
          />
          <div>
            <Typography.Text
              className={clsx(classes.warningText, {
                [classes.invisibleWarningText]: isAbleToClose(states.sortedEvaluations)
              })}
              strong
              type="danger"
            >
              {isAlreadyClose(states.sortedEvaluations)
                ? `이미 마감된 ${getKoEvaluation(type)}입니다.`
                : `모든 통제의 미비점 평가가 완료된 이후에 ${getKoEvaluation(
                    type
                  )}의 마감이 가능합니다.`}
            </Typography.Text>
            <Button
              type="primary"
              className={classes.closeButton}
              disabled={
                _.isEqual(BASE_ITEM, select.selectedName) ||
                !isAbleToClose(states.sortedEvaluations)
              }
              onClick={() => setClosingEvaluation({ ...closingEvaluation, visible: true })}
            >
              {type === 'operation' && '운영평가 마감'}
              {type === 'design' && '설계평가 마감 및 RCM 반영'}
            </Button>
            <Modal
              centered
              footer={[
                <Button key="back" onClick={closingEvaluationCancel}>
                  취소
                </Button>,
                <Button
                  key="submit"
                  loading={closingEvaluation.loading}
                  type="primary"
                  onClick={closingEvaluationOk}
                >
                  마감
                </Button>
              ]}
              title={`${getKoEvaluation(type)} 마감`}
              visible={closingEvaluation.visible}
              onCancel={closingEvaluationCancel}
              onOk={closingEvaluationOk}
            >
              <Space direction="vertical">
                <Typography.Paragraph>
                  선택된 {getKoEvaluation(type)}를 마감합니다.
                  <Typography.Text type="danger" strong>
                    {' 이후에는 해당 평가를 수정할 수 없습니다. '}
                    {type === 'design' && '수정사항이 RCM에 반영됩니다. '}
                  </Typography.Text>
                  마감을 원하실 경우 아래에 {getKoEvaluation(type)} 이름을 입력해주세요.
                </Typography.Paragraph>
                <br />
                <Form form={form}>
                  <Form.Item name="name">
                    <Input placeholder={`${getKoEvaluation(type)} 이름 입력`} />
                  </Form.Item>
                  <Form.Item name="reenterName">
                    <Input placeholder={`${getKoEvaluation(type)} 이름 재입력`} />
                  </Form.Item>
                </Form>
              </Space>
            </Modal>
          </div>
        </div>
        <Accordion defaultExpanded className={classes.accordion}>
          <AccordionSummary
            aria-controls="pannel1d-content"
            className={classes.accordionSummary}
            expandIcon={<ExpandMoreIcon />}
            id="pannel1d-header"
          >
            <Typography.Title level={5}>{getEvaluationSelectorHeader()}</Typography.Title>
          </AccordionSummary>
          <AccordionDetails className={classes.accordionDetail}>
            <EvaluationTable
              noBorder
              items={states.sortedEvaluations}
              evaluationType={type}
              height={300}
              loading={select.loading}
              staffs={staffs}
              type="operation_defect"
              viewerRef={tableViewerRef}
              onItemClick={(evaluationDetail: Evaluation.EvaluationItem) => {
                setStates({
                  ...states,
                  selectedDetailId: evaluationDetail.controlId
                })
              }}
            />
          </AccordionDetails>
        </Accordion>
      </div>
      <Animate transitionAppear className={classes.content} transitionName="fade">
        <div>
          {selectedEvaluation && (
            <div className={classes.viewer}>
              <EvaluationDefectSummary
                type={type}
                evaluation={selectedEvaluation}
                evaluationDefect={_.get(evaluationDefects, states.selectedDetailId)}
                onSaveEvaluationDefect={onSaveEvaluationDefect}
              />
              {type === 'operation' && (
                <OperationEvaluationReportViewer evaluation={selectedEvaluation} staffs={staffs} />
              )}
              {type === 'design' && (
                <DesignEvaluationReportView staffs={staffs} evaluation={selectedEvaluation} />
              )}
            </div>
          )}
        </div>
      </Animate>
      <CircleBackdrop open={states.saveLoading} />
      <AlertMessage ref={alertRef} />
    </div>
  )
}

export default EvaluationDefectView
