import { downloadFilesInEvaluation } from '@cck/backend/dist/evaluation/EvaluationDownloader'
import * as EvaluationUtils from '@cck/backend/dist/evaluation/EvaluationUtils'
import * as CycleManager from '@cck/backend/dist/rcm/CycleManager'
import * as DepartmentManager from '@cck/backend/dist/rcm/DepartmentManager'
import * as StaffManager from '@cck/backend/dist/rcm/StaffManager'
import { Department } from '@cck/common/dist/data/Department'
import * as Evaluation from '@cck/common/dist/data/Evaluation'
import { BASE_ITEM } from '@cck/common/dist/data/Evaluation'
import { Cycle, PRCType } from '@cck/common/dist/data/PRC'
import { Staff } from '@cck/common/dist/data/Staff'
import { getKoName } from '@cck/common/dist/data/Translation'
import { Node as TreeNode, getDataTree } from '@cck/common/dist/tree/Tree'
import { makeStyles, Theme, Typography } from '@material-ui/core'
import { Button, Popover } from 'antd'
import Select from 'antd/lib/select'
import _ from 'lodash'
import React from 'react'
import { useCookies } from 'react-cookie'

import { lightGrey } from '../../../base/color'
import { permutations } from '../../../base/utils/Permutations'
import DividerSelect from '../../common/DividerSelect'
import TableItem from '../../common/TableItem'
import { applyIconAndControlOption } from '../../common/TreeUtils'
import RCMDirectory from '../../rcm/common/RCMDirectory'
import {
  EvaluationSelectAttribute,
  loadEvaluationDetails,
  loadEvaluationNames
} from '../common/EvaluationUtils'
import EvaluationTable from '../view/EvaluationTable'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    minHeight: 0
  },
  dividerSelect: {
    margin: theme.spacing(1)
  },
  setting: {
    border: lightGrey.border,
    margin: theme.spacing(1)
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
    flexBasis: 0,
    minHeight: 0,
    height: 400
  },
  explorer: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    flexBasis: 0,
    minWidth: 310,
    width: 310
  },
  tableHeader: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    background: '#f8f8f8',
    border: lightGrey.border,
    borderBottom: '1px solid #babfc7'
  },
  tableContent: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    minHeight: 0,
    height: 0,
    border: lightGrey.border,
    borderTop: '0px'
  },
  table: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 3,
    flexBasis: 0,
    minWidth: 1100,
    width: 900,
    margin: theme.spacing(1)
  },
  select: {
    width: 200
  },
  button: {
    margin: theme.spacing(1)
  }
}))

const DOWNLOAD_WARNING = '항목 탐색기와 동일한 폴더 구조로 다운됩니다. 폴더 구조 선택 유의.'

enum Folder {
  cycle = 'cycle',
  owner = 'owner',
  performer = 'performer'
}

const KO_FOLDER: Record<Folder, string> = {
  cycle: getKoName('common', 'cycleName'),
  owner: getKoName('control', 'owner'),
  performer: getKoName('control', 'performer')
}

const FOLDER_STRUCTURES = [
  ...permutations([Folder.cycle, Folder.owner, Folder.performer], 1),
  ...permutations([Folder.cycle, Folder.owner, Folder.performer], 2),
  ...permutations([Folder.cycle, Folder.owner, Folder.performer], 3)
]

type DownloadType = 'template' | 'uploaded' | 'both'

function filterExplorerItems(
  items: TreeNode[],
  evaluations: Evaluation.EvaluationItem[]
): TreeNode[] {
  const newItems: TreeNode[] = []
  _.forEach(items, (item) => {
    if (item.children) {
      const children = filterExplorerItems(item.children, evaluations)
      if (!_.isEmpty(children)) {
        newItems.push({
          ...item,
          children
        })
      }
    } else if (item.type === PRCType.control && _.find(evaluations, { controlId: item.id })) {
      newItems.push(item)
    }
  })
  return newItems
}

function convertFilterDataNode(
  explorerItems: TreeNode[],
  evaluationDetails: Evaluation.EvaluationItem[] | undefined
): TreeNode[] {
  if (!evaluationDetails || _.isEmpty(evaluationDetails)) {
    return []
  }
  return applyIconAndControlOption(
    filterExplorerItems(explorerItems, evaluationDetails),
    'control',
    'both'
  )
}

function hasUploadedData(evaluations: Evaluation.EvaluationItem[]): boolean {
  return (
    !_.isEmpty(evaluations) &&
    _.some(evaluations, (evaluation) => {
      if (evaluation.type === Evaluation.Type.Operation) {
        return (
          !_.isEmpty(evaluation.content.files?.record?.name) ||
          !_.isEmpty(evaluation.content.files?.population?.name) ||
          !_.isEmpty(evaluation.content.files?.evidence) ||
          !_.isEmpty(evaluation.content.files?.extra) ||
          evaluation.state.state === Evaluation.ProgressState.Approved // 승인완료시 보고서 readonly 다운로드
        )
      }
      return (
        !_.isEmpty(evaluation.content.files?.record?.name) ||
        !_.isEmpty(evaluation.content.files?.extra)
      )
    })
  )
}

interface Props {
  type: Evaluation.Type
  active: boolean
}

const TemplateDownload: React.FC<Props> = ({ type, active }) => {
  const classes = useStyles()
  const [select, setSelect] = React.useState<EvaluationSelectAttribute>({
    loading: false,
    selectedName: Evaluation.BASE_ITEM,
    names: [],
    bases: [],
    items: []
  })
  const [states, setStates] = React.useState({
    loading: false,
    folderStructure: [Folder.cycle],
    originalExplorerItems: [] as TreeNode[],
    explorerItems: [] as TreeNode[],
    checkedData: [] as Evaluation.EvaluationItem[]
  })
  const [downloadLoading, setDownloadLoading] = React.useState({
    template: false,
    uploaded: false,
    both: false
  })
  const [data, setData] = React.useState({
    cycles: [] as Cycle[],
    departments: [] as Department[],
    staffs: [] as Staff[]
  })
  const [cookies, setCookie, removeCookie] = useCookies([`${type}EvaluationName`])

  React.useEffect(() => {
    Promise.all([CycleManager.getAll(), DepartmentManager.getAll(), StaffManager.getAll()]).then(
      (values) => {
        const [cycles, departments, staffs] = values
        setData({
          cycles,
          departments,
          staffs
        })
      }
    )
  }, [])

  React.useEffect(() => {
    if (active) {
      loadEvaluationNames(type, cookies[`${type}EvaluationName`], select, setSelect)
      setStates({
        ...states,
        folderStructure: [Folder.cycle],
        originalExplorerItems: [],
        explorerItems: [],
        checkedData: []
      })
    }
  }, [active, type])

  const getExplorerItemWithSelectedCategories = React.useCallback(
    (
      selectedCategories: Folder[]
    ): { originalExplorerItems: TreeNode[]; explorerItems: TreeNode[] } => {
      const items = getDataTree(
        _.map(selectedCategories, (key) => {
          if (key === Folder.cycle) return `cycle-${Folder.cycle}`
          return `worker-${key}`
        }),
        _.map(select.items, (detail) => {
          // NOTE: Control 데이터에 performer 추가
          return {
            ...detail.content.control,
            performer: detail.state.performer
          }
        }),
        data.cycles,
        data.departments,
        data.staffs
      )
      return {
        originalExplorerItems: items,
        explorerItems: convertFilterDataNode(items, select.items)
      }
    },
    [data, select]
  )

  React.useEffect(() => {
    setStates({
      ...states,
      loading: false,
      checkedData: [] as Evaluation.EvaluationItem[],
      ...getExplorerItemWithSelectedCategories(states.folderStructure)
    })
  }, [select.items])

  const onEvaluationSelect = React.useCallback(
    (evaluationName: string): void => {
      setStates({
        ...states,
        explorerItems: [],
        checkedData: []
      })
      loadEvaluationDetails(type, evaluationName, select, setSelect)
      setCookie(`${type}EvaluationName`, evaluationName)
    },
    [states, select]
  )

  const onFolderStructureSelect = React.useCallback(
    (folderStructure: string): void => {
      const folders = _.split(folderStructure, '-') as Folder[]
      setStates({
        ...states,
        folderStructure: folders,
        loading: false,
        checkedData: [] as Evaluation.EvaluationItem[],
        ...getExplorerItemWithSelectedCategories(folders)
      })
    },
    [states]
  )

  const itemOnChecked = React.useCallback(
    (checkedIds: string[]): void => {
      const checkedData: Evaluation.EvaluationItem[] = []
      _.forEach(checkedIds, (controlTreeId) => {
        const controlIds = controlTreeId.split('/')
        const checked = _.find(select.items, { controlId: _.last(controlIds) })
        if (checked) {
          checkedData.push(checked)
        }
      })
      setStates({ ...states, checkedData })
    },
    [states, select]
  )

  const downloadEvaluations = React.useCallback(
    (downloadType: DownloadType): void => {
      setDownloadLoading({
        ...downloadLoading,
        [downloadType]: true
      })
      downloadFilesInEvaluation(
        type,
        select.selectedName,
        states.originalExplorerItems,
        downloadType,
        _.map(states.checkedData, (item) => item.controlId)
      )
        .then(() => {
          setDownloadLoading({
            ...downloadLoading,
            [downloadType]: false
          })
        })
        .catch((e) => {
          // eslint-disable-next-line no-console
          console.log('Failed to download ', e)
          setDownloadLoading({
            ...downloadLoading,
            [downloadType]: false
          })
        })
    },
    [downloadLoading, select, states]
  )

  return (
    <div className={classes.root}>
      <div className={classes.dividerSelect}>
        <DividerSelect
          defaultName={`${Evaluation.getKoEvaluation(type)} 선택`}
          defaultValue={BASE_ITEM}
          loading={select.loading}
          names={EvaluationUtils.getEvaluationNamesWithDate(select.bases)}
          value={select.selectedName}
          values={select.names}
          onChange={(value) => onEvaluationSelect(value as string)}
        />
      </div>
      <div className={classes.setting}>
        <TableItem noDivider minHeight={48} name="폴더구조 선택">
          <Select
            className={classes.select}
            defaultValue={Folder.cycle}
            disabled={select.selectedName === BASE_ITEM}
            style={{ width: 300 }}
            onChange={(value) => onFolderStructureSelect(value)}
          >
            {_.map(FOLDER_STRUCTURES, (folders) => {
              const folderStr = _(folders)
                .map((value) => _.get(KO_FOLDER, value))
                .join('-')
              const key = _.join(folders, '-')
              return (
                <Select.Option key={key} value={key}>
                  {folderStr}
                </Select.Option>
              )
            })}
          </Select>
        </TableItem>
      </div>
      <div className={classes.content}>
        <div className={classes.explorer}>
          <RCMDirectory
            RCMItems={states.explorerItems}
            isReady={select.loading || states.loading}
            itemOnChecked={itemOnChecked}
          />
        </div>
        <div className={classes.table}>
          <div className={classes.tableHeader}>
            <Typography style={{ fontWeight: 'bold', marginLeft: 8 }} variant="subtitle1">
              선택된 항목
            </Typography>
            {type === 'operation' && (
              <Typography style={{ marginLeft: 8 }}>
                (승인완료된 통제의 경우, 작성된 보고서가 포함되어 다운로드 됩니다.)
              </Typography>
            )}
            <div style={{ flexGrow: 1 }} />
            {type === Evaluation.Type.Operation && (
              <Popover content={DOWNLOAD_WARNING} title="주의">
                <Button
                  className={classes.button}
                  disabled={_.isEmpty(states.checkedData) || _.isEmpty(select.selectedName)}
                  loading={downloadLoading.template}
                  onClick={() => downloadEvaluations('template')}
                >
                  템플릿 다운로드
                </Button>
              </Popover>
            )}
            <Button
              className={classes.button}
              disabled={!hasUploadedData(states.checkedData) || _.isEmpty(select.selectedName)}
              loading={downloadLoading.uploaded}
              onClick={() => downloadEvaluations('uploaded')}
            >
              업로드 자료 다운로드
            </Button>
            {type === Evaluation.Type.Operation && (
              <Popover content={DOWNLOAD_WARNING} title="주의">
                <Button
                  className={classes.button}
                  disabled={!hasUploadedData(states.checkedData) || _.isEmpty(select.selectedName)}
                  loading={downloadLoading.both}
                  onClick={() => downloadEvaluations('both')}
                >
                  모두 다운로드
                </Button>
              </Popover>
            )}
          </div>
          <div className={classes.tableContent}>
            <EvaluationTable
              noBorder
              items={states.checkedData}
              evaluationType={type}
              loading={select.loading || states.loading}
              staffs={data.staffs}
              type="template"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default TemplateDownload
