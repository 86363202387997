import { saveEvaluationItem } from '@cck/backend/dist/evaluation/EvaluationUpdater'
import * as EvaluationUtils from '@cck/backend/dist/evaluation/EvaluationUtils'
import { NotificationType, sendMailAboutEvaluations } from '@cck/backend/dist/mail/MailManager'
import { getAll as LoadStaffs } from '@cck/backend/dist/rcm/StaffManager'
import { getOrCreateUser } from '@cck/backend/dist/utils/Admin'
import { BaseFile, FileState } from '@cck/common/dist/data/BaseFile'
import * as Evaluation from '@cck/common/dist/data/Evaluation'
import { getOwnerName, getPerformerName, Level, Staff } from '@cck/common/dist/data/Staff'
import { getKoName } from '@cck/common/dist/data/Translation'
import { getProjectId } from '@cck/common/dist/utils/Config'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Button from 'antd/lib/button'
import Checkbox from 'antd/lib/checkbox'
import Form from 'antd/lib/form'
import TextArea from 'antd/lib/input/TextArea'
import Modal from 'antd/lib/modal'
import Space from 'antd/lib/space'
import Typography from 'antd/lib/typography'
import _ from 'lodash'
import React from 'react'
import { useCookies } from 'react-cookie'

import AlertMessage, { AlertMessageHandler } from '../../common/AlertMessage'
import CircleBackdrop from '../../common/CircleBackdrop'
import {
  EvaluationSelectAttribute,
  loadEvaluationDetails,
  loadEvaluationNames
} from '../common/EvaluationUtils'
import EvaluationSelectAccordion from '../view/EvaluationSelectAccordion'
import EvidenceView from './EvidenceView'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    overflowY: 'auto',
    minHeight: 0
  },
  content: {
    display: 'flex',
    flexDirection: 'column'
  },
  subcontent: {
    padding: 0
  },
  form: {
    marginBottom: 0
  }
}))

const EvidenceUploader: React.FC = () => {
  const classes = useStyles()
  const type: Evaluation.Type = React.useMemo(() => Evaluation.Type.Operation, [])
  const [select, setSelect] = React.useState<EvaluationSelectAttribute>({
    loading: false,
    selectedName: Evaluation.BASE_ITEM,
    names: [],
    bases: [],
    items: []
  })
  const [states, setStates] = React.useState<{
    selectedDetailId: string
    saveLoading: boolean
    evidenceFiles: BaseFile[]
    evidences: string[]
    open: boolean
    hasComment: boolean
  }>({
    selectedDetailId: '',
    saveLoading: false,
    evidenceFiles: [],
    evidences: [],
    open: false,
    hasComment: false
  })
  const [staffs, setStaffs] = React.useState<Staff[]>([])
  const [userLevel, setUserLevel] = React.useState<Level>(Level.NORMAL)
  const alertRef = React.useRef<AlertMessageHandler>(null)
  const [cookies, setCookie] = useCookies([`${type}EvaluationName`])
  const [form] = Form.useForm()

  React.useEffect(() => {
    getOrCreateUser().then((user) => {
      setUserLevel(_.get(user, 'level'))
    })
    LoadStaffs().then((newStaffs) => {
      setStaffs(newStaffs)
    })
  }, [])

  React.useEffect(() => {
    loadEvaluationNames(type, cookies[`${type}EvaluationName`], select, setSelect)
    setStates({ ...states, selectedDetailId: '' })
  }, [type])

  const selectedEvaluation = React.useMemo(
    () => _.cloneDeep(_.find(select.items, { controlId: states.selectedDetailId })),
    [states.selectedDetailId, select.items]
  )

  React.useEffect(() => {
    if (!selectedEvaluation) {
      return
    }

    const evidenceFiles: BaseFile[] = _(selectedEvaluation.content.files.evidence)
      .map((evidenceFile) => {
        evidenceFile.state = FileState.SAVED_FILE
        return evidenceFile
      })
      .sortBy((evidenceFile) => evidenceFile.name)
      .value()

    const evalData = selectedEvaluation.content.data as Evaluation.OperationEvaluationData
    const evidences: string[] = _.map(evalData.testContents.rowObjectList, (row) =>
      _.get(row, '증빙자료 Ref', '')
    )
    setStates({
      ...states,
      evidenceFiles,
      evidences
    })
  }, [selectedEvaluation])

  const onEvaluationSelect = React.useCallback(
    (evaluationName: string): void => {
      loadEvaluationDetails(type, evaluationName, select, setSelect)
      setStates({ ...states, selectedDetailId: '' })
      setCookie(`${type}EvaluationName`, evaluationName)
    },
    [states, select]
  )

  const saveEvidences = React.useCallback(
    (
      evidenceFiles: BaseFile[],
      evidences: string[],
      hasComment: boolean,
      comment: string | undefined
    ) => {
      if (!selectedEvaluation) {
        return
      }

      const { deletedFiles, newFiles } = EvaluationUtils.getFileListForSaving(
        selectedEvaluation,
        evidenceFiles
      )

      const newEvaluationData = _.cloneDeep(
        selectedEvaluation.content.data
      ) as Evaluation.OperationEvaluationData

      _.forEach(newEvaluationData.testContents.rowObjectList, (row, idx) => {
        _.set(row, '증빙자료 Ref', _.nth(evidences, idx) || '')
      })

      setStates({
        ...states,
        saveLoading: true
      })

      const updatedPromise: Promise<unknown>[] = [
        saveEvaluationItem(
          type,
          select.selectedName,
          select.items || [],
          states.selectedDetailId,
          newEvaluationData,
          _(deletedFiles)
            .map((file) => (file.type === '증빙' ? file : undefined))
            .compact()
            .value(),
          newFiles,
          '2'
        )
      ]

      if (hasComment) {
        updatedPromise.push(
          sendMailAboutEvaluations(
            NotificationType.Custom,
            Evaluation.Type.Operation,
            selectedEvaluation.base.name,
            [selectedEvaluation.controlId],
            comment || '',
            getProjectId(),
            '증빙 파일 업로드 및 증빙 연결 저장'
          )
        )
      }

      Promise.all(updatedPromise)
        .then(() => {
          loadEvaluationDetails(type, select.selectedName, select, setSelect)
          alertRef.current?.showAlert('success', '저장 성공')
        })
        .catch((e) => {
          // eslint-disable-next-line no-console
          console.log('Failed to save ', e)
          if (e.message === 'not changed') {
            alertRef.current?.showAlert('warning', '변경 사항이 없음')
          } else {
            alertRef.current?.showAlert('error', '저장 실패')
          }
        })
        .finally(() => {
          setStates({
            ...states,
            open: false,
            saveLoading: false
          })
        })
    },
    [selectedEvaluation, states, select]
  )

  return (
    <div className={classes.root}>
      <EvaluationSelectAccordion
        type={type}
        staffs={staffs}
        select={select}
        selectedDetailId={states.selectedDetailId}
        onEvaluationSelect={onEvaluationSelect}
        onEvaluationDetailClick={(evaluationDetail: Evaluation.EvaluationItem) => {
          setStates({
            ...states,
            selectedDetailId: evaluationDetail.controlId
          })
        }}
      />
      <div>
        {selectedEvaluation && (
          <EvidenceView
            staffs={staffs}
            evaluation={selectedEvaluation}
            evidenceFiles={states.evidenceFiles}
            evidences={states.evidences}
            saveEvidences={(evidenceFiles, evidences) => {
              const { deletedFiles, newFiles } = EvaluationUtils.getFileListForSaving(
                selectedEvaluation,
                evidenceFiles
              )

              const selectedEvalData = selectedEvaluation.content
                .data as Evaluation.OperationEvaluationData
              const isNotModifiedContent = _.every(
                selectedEvalData.testContents.rowObjectList,
                (row, idx) => {
                  return _.get(row, '증빙자료 Ref') === _.nth(evidences, idx)
                }
              )

              if (isNotModifiedContent && _.isEmpty(deletedFiles) && _.isEmpty(newFiles)) {
                alertRef.current?.showAlert('warning', '변경 사항이 없음')
                return
              }

              setStates({
                ...states,
                open: true,
                evidenceFiles,
                evidences
              })
            }}
          />
        )}
      </div>
      <Modal
        centered
        footer={[
          <Button key="back" onClick={() => setStates({ ...states, open: false })}>
            취소
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={states.saveLoading}
            onClick={() => {
              saveEvidences(
                states.evidenceFiles,
                states.evidences,
                states.hasComment,
                form.getFieldValue('comment')
              )
            }}
          >
            저장
          </Button>
        ]}
        title={'증빙 파일 업로드 및 증빙 연결 저장'}
        visible={states.open}
        onCancel={() => setStates({ ...states, open: false })}
      >
        <Space direction="vertical">
          <Typography.Text>증빙 파일 및 연결을 저장하시겠습니까?</Typography.Text>
          <br />
          <Form
            form={form}
            layout="vertical"
            onValuesChange={(value) => {
              if (_.has(value, 'hasComment')) {
                setStates({ ...states, hasComment: value.hasComment })
              }
            }}
          >
            <Form.Item className={classes.form} name="hasComment" valuePropName="checked">
              <Checkbox>
                {getKoName('control', 'owner')}({getOwnerName(staffs, selectedEvaluation)})와{' '}
                {getKoName('control', 'performer')}({getPerformerName(staffs, selectedEvaluation)}
                )에게 알림을 보내시겠습니까?
              </Checkbox>
            </Form.Item>
            <Form.Item className={classes.form} label="코멘트" name="comment">
              <TextArea autoSize={{ minRows: 2, maxRows: 6 }} disabled={!states.hasComment} />
            </Form.Item>
          </Form>
        </Space>
      </Modal>
      <AlertMessage ref={alertRef} />
      <CircleBackdrop open={states.saveLoading} />
    </div>
  )
}

export default EvidenceUploader
