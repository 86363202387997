import { Control } from '@cck/common/dist/data/PRCUtils'
import { Staff } from '@cck/common/dist/data/Staff'
import AbstractControl, {
  isViewTitle,
  isViewItem,
  ViewItem,
  ViewTitle
} from '@cck/common/dist/data/control/AbstractControl'
import { getControlClass } from '@cck/common/dist/utils/Config'
import { makeStyles, Theme } from '@material-ui/core'
import clsx from 'clsx'
import _ from 'lodash'
import React from 'react'

import { grey } from '../../../base/color'
import TableHeader from '../../common/TableHeader'
import { ComplexTableItem } from '../../common/TableItem'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    border: grey.border
  },
  margin: {
    margin: theme.spacing(2)
  }
}))

interface Props {
  control: Control
  noMargin?: boolean
  staffs: Staff[]
}

const ControlView: React.FC<Props> = ({ control: baseControl, noMargin, staffs }) => {
  const classes = useStyles()
  const viewItems = React.useMemo(() => {
    if (baseControl instanceof AbstractControl) {
      return baseControl.getControlViewItems(staffs)
    }
    return getControlClass().from(baseControl).getControlViewItems(staffs)
  }, [baseControl, staffs])

  return (
    <div className={clsx(classes.root, { [classes.margin]: !noMargin })}>
      {_.map(viewItems, (item: ViewItem | ViewTitle) => {
        if (isViewTitle(item)) {
          return <TableHeader value={item.value} />
        } else if (isViewItem(item)) {
          return (
            <ComplexTableItem
              name={item.name}
              value={item.value}
              checkTypes={item.checkTypes}
              radioTypes={item.radioTypes}
              staff={item.staff}
            />
          )
        }
      })}
    </div>
  )
}

export default ControlView
