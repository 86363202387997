/* eslint-disable @typescript-eslint/no-explicit-any */
import { ColDef, ColGroupDef, RowNode, ValueFormatterParams } from '@ag-grid-community/core'
import { blue } from '@ant-design/colors'
import { CommonFile, parseFileInfos } from '@cck/backend/dist/filebox/FileboxManager'
import { formatBytes, FileState, stateToColor } from '@cck/common/dist/data/BaseFile'
import { makeStyles, Theme } from '@material-ui/core'
import clsx from 'clsx'
import _ from 'lodash'
import moment from 'moment'
import React from 'react'
import { useDropzone } from 'react-dropzone'

import CommonAgGrid from '../common/CommonAgGrid'
import UploadIconAndText from '../evaluation/common/UploadIconAndText'

const useStyles = makeStyles((theme: Theme) => ({
  dropzone: {
    display: 'flex',
    position: 'relative',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1,
    background: 'WhiteSmoke',
    border: '1px dashed lightgray'
  },
  dragActive: {
    borderColor: blue.primary
  },
  fileTable: {
    display: 'flex',
    height: '100%',
    width: '100%',
    flexDirection: 'column'
  }
}))

function stateCellRenderer(params: any): HTMLElement {
  const duplication = params.value as boolean
  const span = document.createElement('span')
  span.innerHTML = duplication ? '기존 항목 대체' : '신규'
  span.style.color = stateToColor(duplication ? FileState.ALREADY_EXISTING : FileState.OK)
  return span
}

function deleteCellRenderer(params: any): HTMLElement {
  const files: any[] = []
  params.api.forEachNode((node: RowNode) => {
    files.push(node.data)
  })
  const aElement = document.createElement('a')
  aElement.innerHTML = '삭제'
  aElement.onclick = () => params.deleteFile(files, params.data)
  return aElement
}

interface Props {
  files: CommonFile[]
  setFiles: (files: CommonFile[]) => void
}

const FileUploadBox: React.FC<Props> = ({ files, setFiles }) => {
  const classes = useStyles()
  const [tmpFiles, setTmpFiles] = React.useState<CommonFile[]>()

  const columns = React.useMemo(
    (): (ColDef | ColGroupDef)[] => [
      { headerName: '파일 이름', field: 'name', flex: 2 },
      {
        headerName: '크기',
        field: 'size',
        valueFormatter: (params: ValueFormatterParams) => formatBytes(params.value, 2)
      },
      {
        headerName: '마지막 수정일',
        field: 'data.lastModifiedDate',
        valueFormatter: (params: ValueFormatterParams) => moment(params.value).format('LLL'),
        width: 240
      },
      {
        headerName: '상태',
        field: 'duplication',
        cellRenderer: stateCellRenderer
      },
      {
        headerName: '삭제',
        field: 'delete',
        width: 120,
        cellRenderer: deleteCellRenderer,
        cellRendererParams: {
          deleteFile: (allFiles: CommonFile[], file: CommonFile): void => {
            const newFiles = allFiles.filter((item) => item.name !== file.name)
            setTmpFiles(newFiles)
          }
        }
      }
    ],
    []
  )

  React.useEffect(() => {
    setFiles(tmpFiles || [])
  }, [tmpFiles])

  const onDrop = React.useCallback(
    (originalFiles) => {
      const prevFiles = _.cloneDeep(files)
      const acceptedFiles: File[] = []
      _.forEach(originalFiles, (originalFile) => {
        if (originalFile.size >= 10 * Math.pow(1024, 2)) {
          return
        }

        // NOTE: 업로드 할 리스트에 같은 이름 파일이 있을 경우 삭제.
        _.remove(prevFiles, (prev) => prev.name === originalFile.name)
        acceptedFiles.push(originalFile)
      })

      parseFileInfos(acceptedFiles).then((result) => {
        setFiles([...prevFiles, ...result])
      })
    },
    [files]
  )
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    noClick: !_.isEmpty(files)
  })

  return (
    <div
      className={clsx(classes.dropzone, { [classes.dragActive]: isDragActive })}
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      {_.isEmpty(files) && <UploadIconAndText isDragActive={isDragActive} />}
      {!_.isEmpty(files) && (
        <div className={classes.fileTable}>
          <CommonAgGrid columnDefs={columns} rowData={files} />
        </div>
      )}
    </div>
  )
}

export default FileUploadBox
