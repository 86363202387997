import { ColDef } from '@ag-grid-enterprise/all-modules'
import { BasePRC } from '@cck/common/dist/data/PRC'
import { getKoName } from '@cck/common/dist/data/Translation'
import { hasThreeCategories } from '@cck/common/dist/utils/Config'
import _ from 'lodash'

import { modifyAndDeleteCellRenderer } from './PRCEditorUtils'

export function GetControlColumnDefs(
  openItemEditor: ((item: BasePRC) => void) | undefined,
  deleteItem: (item: BasePRC) => void
): ColDef[] {
  const columns = [{ headerName: '대분류', field: 'cycleName', sortable: true }]

  if (hasThreeCategories()) {
    columns.push({ headerName: '중분류', field: 'categoryName', sortable: true })
    columns.push({ headerName: '소분류', field: 'subCategoryName', sortable: true })
  } else {
    columns.push({ headerName: '분류', field: 'categoryName', sortable: true })
  }

  return [
    ...columns,
    { headerName: getKoName('control', 'id'), field: 'id', sortable: true },
    { headerName: getKoName('control', 'name'), field: 'name', sortable: true },
    {
      headerName: '수정/삭제',
      field: 'action',
      cellRenderer: modifyAndDeleteCellRenderer,
      cellRendererParams: {
        openItemEditor,
        deleteItem
      }
    }
  ]
}
