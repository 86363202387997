import * as Evaluation from '@cck/common/dist/data/Evaluation'
import _ from 'lodash'
import React from 'react'

import MultipleTableItem, { TableValue } from '../../common/MultipleTableItem'
import {
  DesignEvaluationColumnKeyDef,
  DesignEvaluationColumnValueDef,
  isDesignEvaluationKeyDef,
  isDesignEvaluationValueDef
} from './DesignReportColumn'

interface Props {
  columns: (DesignEvaluationColumnKeyDef | DesignEvaluationColumnValueDef)[][]
  evaluation: Evaluation.EvaluationItem
}

function parseDesignEvaluationDef(
  evaluation: Evaluation.EvaluationItem,
  key: string,
  column: DesignEvaluationColumnKeyDef | DesignEvaluationColumnValueDef
): TableValue {
  let value = ''
  if (isDesignEvaluationKeyDef(column)) {
    key = column.key
    value = _.get(evaluation, column.key)
  } else if (isDesignEvaluationValueDef(column)) {
    value = column.value
  }

  if (column.children) {
    const children = _.map(column.children, (childRow, idx1) => {
      return _.map(childRow, (childCol, idx2) => {
        return parseDesignEvaluationDef(evaluation, `${key}_${idx1}_${idx2}`, childCol)
      })
    })
    return {
      key,
      value: '',
      span: column.span,
      type: 'nest',
      editable: _.get(column, 'editable', false),
      children
    }
  }

  if (column.valueFormatter) {
    value = column.valueFormatter(value)
  }

  return {
    key,
    value,
    span: column.span,
    style: column.style,
    type: _.get(column, 'type'),
    editable: _.get(column, 'editable', false),
    options: _.get(column, 'options', []),
    placeholder: _.get(column, 'placeholder')
  }
}

const DesignEvaluationTable: React.FC<Props> = ({ columns, evaluation }) => {
  return (
    <>
      {_.map(columns, (row, idx) => {
        return (
          <MultipleTableItem
            key={idx}
            values={_.map(row, (column, idx2) => {
              const key = `${idx}_${idx2}`
              return parseDesignEvaluationDef(evaluation, key, column)
            })}
          />
        )
      })}
    </>
  )
}

export default DesignEvaluationTable
