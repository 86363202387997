import { ColDef } from '@ag-grid-enterprise/all-modules'
import * as Evaluation from '@cck/common/dist/data/Evaluation'
import { getKoName } from '@cck/common/dist/data/Translation'
import { YesNo } from '@cck/common/dist/data/control/AbstractControl'
import { getProjectId } from '@cck/common/dist/utils/Config'
import _ from 'lodash'

export interface ResultStatus {
  cycleName: string
  categoryName: string
  totalControl: number
  nonKeyControls: number
  keyControls: number
  normal: number
  deficiency?: number // 운영평가. 단순한 미비점
  significantDeficiency?: number // 운영평가. 유의한 미비점
  materialWeakness?: number // 운영평가. 중요한 취약점
  modifications?: number // 설계평가. 수정사항 있음
}

function getResultStatusInner(
  type: Evaluation.Type,
  evaluations: Evaluation.EvaluationItem[]
): ResultStatus {
  const firstControl = evaluations[0].content.control
  const resultStatus: ResultStatus = {
    cycleName: `${firstControl.cycleNumber}. ${firstControl.cycleName}`,
    categoryName: `${firstControl.categoryNumber}. ${firstControl.categoryName}`,
    totalControl: _.size(evaluations),
    nonKeyControls: _(evaluations)
      .filter((value) => value.content.control.keyControl === YesNo.no)
      .size(),
    keyControls: _(evaluations)
      .filter((value) => value.content.control.keyControl === YesNo.yes)
      .size(),
    normal: _(evaluations)
      .filter((value) => value.state.resultState === '2')
      .size()
  }

  if (type === Evaluation.Type.Operation) {
    resultStatus.deficiency = _(evaluations)
      .filter((value) => value.state.weakState === Evaluation.OperationWeakState.Deficiency)
      .size()
    resultStatus.significantDeficiency = _(evaluations)
      .filter(
        (value) => value.state.weakState === Evaluation.OperationWeakState.SignificantDeficiency
      )
      .size()
    resultStatus.materialWeakness = _(evaluations)
      .filter((value) => value.state.weakState === Evaluation.OperationWeakState.MaterialWeakness)
      .size()
  } else {
    resultStatus.modifications = _(evaluations)
      .filter((value) => value.state.weakState === Evaluation.DesignWeakState.Modification)
      .size()
  }
  return resultStatus
}

export function getResultStatus(
  type: Evaluation.Type,
  evaluations: Evaluation.EvaluationItem[]
): ResultStatus[] {
  const resultStatus: ResultStatus[] = _(evaluations)
    .groupBy('content.control.cycleNumber')
    .map((subEvaluations) => {
      return _(subEvaluations)
        .groupBy('content.control.categoryNumber')
        .map((values) => getResultStatusInner(type, values))
        .value()
    })
    .flatten()
    .value()
  return resultStatus
}

export function getResultStatusColumns(type: 'operation' | 'design'): ColDef[] {
  const newColumns = [
    { headerName: getKoName('common', 'cycleName'), field: 'cycleName' },
    { headerName: getKoName('common', 'categoryName', getProjectId()), field: 'categoryName' },
    { headerName: 'Total Control', field: 'totalControl', menuTabs: [] },
    { headerName: 'Non-Key Controls', field: 'nonKeyControls', menuTabs: [] },
    { headerName: 'Key Controls', field: 'keyControls', menuTabs: [] },
    { headerName: '정상', field: 'normal', menuTabs: [] }
  ]
  if (type === 'operation') {
    newColumns.push(
      ...[
        { headerName: '단순한 미비점', field: 'deficiency', menuTabs: [] },
        { headerName: '유의한 미비점', field: 'significantDeficiency', menuTabs: [] },
        { headerName: '중요한 취약점', field: 'materialWeakness', menuTabs: [] }
      ]
    )
  } else {
    newColumns.push({ headerName: '수정사항 존재', field: 'modifications', menuTabs: [] })
  }
  return newColumns
}
