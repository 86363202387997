import { Type as EvaluationType, getKoEvaluation } from '@cck/common/dist/data/Evaluation'
import { Tabs } from 'antd'
import React from 'react'

import CardTabs from '../../common/CardTabs'
import FileUploader from './FileUploader'
import TemplateDownload from './TemplateDownload'

interface Props {
  type: EvaluationType
}

const EvaluationFileManagement: React.FC<Props> = ({ type }) => {
  const [activeKey, setActiveKey] = React.useState('1')
  React.useEffect(() => {
    setActiveKey('1')
  }, [type])

  const downloadTitle = React.useMemo(() => {
    if (type === EvaluationType.Operation) {
      return '운영평가 템플릿 다운로드'
    }
    return '설계평가 다운로드'
  }, [type])

  return (
    <CardTabs defaultActiveKey={activeKey} onChange={(key) => setActiveKey(key)}>
      <Tabs.TabPane key="1" tab={downloadTitle}>
        <TemplateDownload active={activeKey === '1'} type={type} />
      </Tabs.TabPane>
      <Tabs.TabPane key="2" tab={`${getKoEvaluation(type)} 파일 업로드`}>
        <FileUploader active={activeKey === '2'} type={type} />
      </Tabs.TabPane>
    </CardTabs>
  )
}

export default EvaluationFileManagement
