import { getKoName } from '@cck/common/dist/data/Translation'
import { RiskLevel } from '@cck/common/dist/data/risk/AbstractRisk'
import { hasRoMMInRisk } from '@cck/common/dist/utils/Config'
import _ from 'lodash'
import React from 'react'

import SelectItem, { selectValidator } from './SelectItem'
import TextItem, { getRequiredMessage } from './TextItem'

function generateOptions (options: string[]): Record<string, string> {
  return _.reduce(
    options,
    (result, value) => {
      _.set(result, value, value)
      return result
    },
    {} as Record<string, string>
  )
}

interface Props {
  type: 'process' | 'risk' | 'control'
  names: string[]
}

const RiskEditView: React.FC<Props> = ({ type, names }) => {
  if (type !== 'risk') {
    return <></>
  }

  return (
    <>
      <TextItem
        required
        dataKey="name"
        name={getKoName(type, 'name')}
        rules={[
          {
            required: true,
            message: getRequiredMessage('name', getKoName(type, 'name'))
          },
          () => ({
            validator (rule: unknown, value: string): Promise<void> {
              // if (!_.isEmpty(value) && names && _.includes(names, value.trim())) {
              //   return Promise.reject(new Error(`이미 존재하는 ${getKoName(type, 'name')}입니다.`))
              // }
              return Promise.resolve()
            }
          })
        ]}
      />
      {hasRoMMInRisk() && (
        <TextItem
          required
          dataKey="RoMM"
          name={getKoName('risk', 'RoMM')}
          rules={[{ required: true, message: getRequiredMessage('RoMM') }]}
        />
      )}
      <SelectItem
        noDivider
        required
        dataKey="inherentRiskLevel"
        name={getKoName('risk', 'inherentRiskLevel')}
        options={generateOptions([RiskLevel.low, RiskLevel.moderate, RiskLevel.high])}
        rules={[
          () => ({
            validator: selectValidator(`${getKoName('risk', 'inherentRiskLevel')}을`)
          })
        ]}
      />
    </>
  )
}

export default RiskEditView
