import { ColDef } from '@ag-grid-enterprise/all-modules'
import * as Evaluation from '@cck/common/dist/data/Evaluation'
import { makeStyles, Theme } from '@material-ui/core'
import React from 'react'

import CommonAgGrid from '../common/CommonAgGrid'
import { getResultStatus, getResultStatusColumns, ResultStatus } from './ResultStatusUtils'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column'
  }
}))

interface Props {
  type: 'operation' | 'design'
  evaluations: Evaluation.EvaluationItem[]
}

const ResultStatusView: React.FC<Props> = ({ type, evaluations }) => {
  const classes = useStyles()
  const [statusData, setStatusData] = React.useState<ResultStatus[]>([])

  const columns: ColDef[] = React.useMemo(() => getResultStatusColumns(type), [type])

  React.useEffect(() => {
    setStatusData(getResultStatus(type, evaluations))
  }, [evaluations])

  return (
    <div className={classes.root}>
      <CommonAgGrid autoSize="autoSizeColumns" columnDefs={columns} rowData={statusData} />
    </div>
  )
}

export default ResultStatusView
