/* eslint-disable @typescript-eslint/no-explicit-any */
import * as BackupManager from '@cck/backend/dist/backup/BackupManager'
import { parseDateStr } from '@cck/common/dist/utils/TimeUtils'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Button from 'antd/lib/button'
import List from 'antd/lib/list'
import Modal from 'antd/lib/modal'
import Typography from 'antd/lib/typography'
import React from 'react'

import { lightGrey } from '../../../base/color'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    margin: theme.spacing(1),
    padding: theme.spacing(2),
    backgroundColor: 'white',
    border: lightGrey.border
  },
  list: {
    marginTop: theme.spacing(1)
  }
}))

const RCMBackup: React.FC = () => {
  const classes = useStyles()
  const [states, setStates] = React.useState({
    backupDates: [] as any
  })

  React.useEffect(() => {
    BackupManager.get().then((newBackupDates) => {
      // eslint-disable-next-line no-console
      console.log('backup ', newBackupDates)
      setStates({
        ...states,
        backupDates: newBackupDates.reverse()
      })
    })
  }, [])

  const recover = (item: any): void => {
    Modal.confirm({
      title: 'RCM 복구',
      content: (
        <Typography.Text>
          {parseDateStr('YYYYMMDD', item)}의 데이터로 복구하시겠습니까?
        </Typography.Text>
      ),
      okText: '복구',
      cancelText: '취소',
      onOk: () => BackupManager.restore(item)
    })
  }

  return (
    <div className={classes.root}>
      <Typography.Title level={3}>RCM 복구</Typography.Title>
      <Typography.Text>
        선택된 날짜의 데이터로 복구됩니다. (부서, 분류, 프로세스, 리스크, 컨트롤, 알림)
      </Typography.Text>
      <Typography.Text type="danger">
        컨트롤에서 연결된 담당자의 경우, 이전의 데이터로 구성되어 있으니 복구 이후 다시
        설정해야합니다.
      </Typography.Text>
      <Typography.Text type="danger">
        이전 날짜의 복구 이후에는 현재 시점의 데이터로 복구 할 수 없습니다.
      </Typography.Text>
      <List
        bordered
        className={classes.list}
        dataSource={states.backupDates}
        renderItem={(item: any) => (
          <List.Item
            actions={[
              <Button key="recover" onClick={() => recover(item)}>
                복구
              </Button>
            ]}
          >
            {parseDateStr('YYYYMMDD', item)}
          </List.Item>
        )}
      />
    </div>
  )
}

export default RCMBackup
