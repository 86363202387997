import * as Evaluation from '@cck/common/dist/data/Evaluation'
import { Staff } from '@cck/common/dist/data/Staff'
import { getControlClass } from '@cck/common/dist/utils/Config'
import Divider from '@material-ui/core/Divider'
import { makeStyles, Theme } from '@material-ui/core/styles'
import _ from 'lodash'
import React from 'react'

import { grey } from '../../../base/color'
import TableItem from '../../common/TableItem'
import DesignDefectContentView, { DesignDefectHandler } from './DesignDefectContentView'
import DesignEvaluationTable from './DesignEvaluationTable'
import EvaluationFileView from './EvaluationFileView'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: theme.spacing(2),
    border: grey.border,
    borderBottom: 0
  }
}))

interface Props {
  noFiles?: boolean
  evaluation: Evaluation.EvaluationItem
  staffs: Staff[]
}

const DesignEvaluationReportView: React.FC<Props> = ({ noFiles, evaluation, staffs }) => {
  const classes = useStyles()
  const evalData = React.useMemo(
    () => evaluation.content.data as Evaluation.DesignEvaluationData,
    [evaluation]
  )
  const designColumns = React.useMemo(
    () => getControlClass().getDesignColumns(staffs, false),
    [staffs]
  )
  const defectRef = React.useRef<DesignDefectHandler>(null)

  return (
    <div>
      <div className={classes.root}>
        <DesignEvaluationTable columns={designColumns.processColumns} evaluation={evaluation} />
      </div>
      {!noFiles && (
        <div className={classes.root}>
          <EvaluationFileView document={evaluation} type="design" />
          <Divider />
        </div>
      )}
      <div className={classes.root}>
        <DesignEvaluationTable columns={designColumns.riskControlColumn} evaluation={evaluation} />
      </div>
      <div className={classes.root}>
        <DesignEvaluationTable
          columns={designColumns.controlDetailColumns}
          evaluation={evaluation}
        />
      </div>
      <div className={classes.root}>
        <DesignEvaluationTable
          columns={designColumns.considerationColumns}
          evaluation={evaluation}
        />
      </div>
      <div className={classes.root}>
        <DesignEvaluationTable columns={designColumns.TOCColumns} evaluation={evaluation} />
      </div>

      <div className={classes.root}>
        <TableItem name="평가 결론">
          {evalData.resultState === Evaluation.ResultState.Normal ? 'Effective' : 'Ineffective'}
        </TableItem>
      </div>
      <DesignDefectContentView
        editable={false}
        staffs={staffs}
        evaluation={evaluation}
        ref={defectRef}
      />
    </div>
  )
}

export default DesignEvaluationReportView
