/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  AgGridEvent,
  CellValueChangedEvent,
  ColDef,
  ColumnApi,
  GridApi,
  RowNode,
  ValueFormatterParams
} from '@ag-grid-community/core'
import { exportToExcelZipDesign } from '@cck/backend/dist/evaluation/DesignExcelExporter'
import { updateOwnerOrPerformer } from '@cck/backend/dist/evaluation/EvaluationUpdater'
import {
  isEqualEvaluations,
  resultStateToString
} from '@cck/backend/dist/evaluation/EvaluationUtils'
import { exportToExcelZip } from '@cck/backend/dist/evaluation/ExcelExporter'
import { NotificationType, sendMailAboutEvaluations } from '@cck/backend/dist/mail/MailManager'
import {
  EvaluationItem,
  ProgressState,
  isAlreadyClose,
  Type as EvaluationType,
  ResultState,
  getKoEvaluation,
  FrontendProgressState as FState
} from '@cck/common/dist/data/Evaluation'
import {
  Staff,
  StaffFormatValue,
  getStaffNameAndEmailById,
  StaffValueFormatter,
  Level
} from '@cck/common/dist/data/Staff'
import { getKoName } from '@cck/common/dist/data/Translation'
import { getProjectId } from '@cck/common/dist/utils/Config'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Button from 'antd/lib/button'
import Checkbox from 'antd/lib/checkbox'
import Form from 'antd/lib/form'
import TextArea from 'antd/lib/input/TextArea'
import Modal from 'antd/lib/modal'
import Tabs from 'antd/lib/tabs'
import Typography from 'antd/lib/typography'
import _ from 'lodash'
import React from 'react'
import useResizeAware from 'react-resize-aware'

import { getEditableColor } from '../../../base/color'
import { FileCellRenderer } from '../../../base/utils/FileUtils'
import AlertMessage, { AlertMessageHandler } from '../../common/AlertMessage'
import CircleBackdrop from '../../common/CircleBackdrop'
import CommonAgGrid from '../../common/CommonAgGrid'
import { NextButton, PreviousButton } from '../../common/ModalButton'
import { StaffCellRenderer } from '../../common/StaffRenderer'
import DesignEvaluationReportView from './DesignEvaluationReportView'
import OperationEvaluationReportViewer from './OperationEvaluationReportViewer'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexGrow: 1,
    '& .ant-tabs-content-holder': {
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'column',
      minHeight: 0
    },
    '& .ant-tabs-content': {
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'column',
      minHeight: 0,
      height: 400
    },
    '& .ant-tabs-tabpane': {
      display: 'flex',
      flexGrow: 1,
      flexBasis: 0,
      flexDirection: 'column',
      minHeight: 0
    }
  },
  button: {
    marginLeft: theme.spacing(1)
  },
  recordButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: theme.spacing(2)
  },
  rightButton: {
    direction: 'rtl',
    display: 'flex',
    justifyContent: 'flex-end',
    '&.ant-btn > .anticon + span': {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(0)
    },
    '&.ant-btn > .anticon': {
      lineHeight: 1.9
    }
  },
  form: {
    marginBottom: 0
  }
}))

function getKoProgress(state: FState): string {
  return getKoName('control', _.invert(ProgressState)[state])
}

function getNeighborControlIdAndChecked(
  api: GridApi,
  controlId: string
): {
  [x: string]: boolean | string
  previousControlId: string
  nextControlId: string
} {
  const data: any[] = []
  api.forEachNodeAfterFilter((node: any) => {
    data.push(node.data)
  })
  const index = _.findIndex(data, { controlId })
  const selected = _.nth(data, index)
  return {
    previousControlId: index - 1 < 0 ? '' : _.nth(data, index - 1)?.controlId,
    nextControlId: index < 0 || index + 1 >= data.length ? '' : _.nth(data, index + 1)?.controlId,
    [getKoProgress(ProgressState.Rejected)]:
      selected?.view?.[getKoProgress(ProgressState.Rejected)] || false,
    [getKoProgress(ProgressState.Performed)]:
      selected?.view?.[getKoProgress(ProgressState.Performed)] || false,
    [getKoProgress(ProgressState.Request)]:
      selected?.view?.[getKoProgress(ProgressState.Request)] || false,
    [getKoProgress(ProgressState.Approved)]:
      selected?.view?.[getKoProgress(ProgressState.Approved)] || false,
    [getKoProgress(ProgressState.Exported)]:
      selected?.view?.[getKoProgress(ProgressState.Exported)] || false
  }
}

const CheckboxHeader = (props: any): React.ReactElement => {
  const data: any[] = []
  props.api.forEachNodeAfterFilter((node: RowNode) => {
    data.push(node.data)
  })

  const [checked, setChecked] = React.useState(
    !_.isEmpty(data) && _.every(data, { [props.column.colId]: true })
  )

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
      }}
    >
      <Checkbox
        checked={checked}
        onChange={(event) => {
          props.api.forEachNodeAfterFilter((node: RowNode) => {
            node.setDataValue(props.column.colId, event.target.checked)
            setChecked(event.target.checked)
          })
        }}
      >
        {props.displayName}
      </Checkbox>
    </div>
  )
}

const CheckboxCellRenderer = (props: any): React.ReactElement => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <Checkbox
        checked={props.value}
        onClick={(event) => {
          const checked = (event.target as any)?.checked
          const colId = props.column.colId
          props.node.setDataValue(colId, checked)

          const data: any[] = []
          props.api.forEachNodeAfterFilter((node: any) => {
            if (props.node.rowIndex === node.rowIndex) {
              return
            }
            data.push(node.data)
          })
          if (!_.isEmpty(data) && _.every(data, { [colId]: true })) {
            props.api.refreshHeader()
          }
        }}
      />
    </div>
  )
}

function detailCellRenderer(params: any): HTMLElement {
  const aElement = document.createElement('a')
  aElement.innerHTML = '보고서 상세'
  aElement.onclick = () => {
    params.setReport({
      visible: true,
      selectedControlId: params.data.controlId,
      ...getNeighborControlIdAndChecked(params.api, params.data.controlId)
    })
  }
  return aElement
}

function getColumns(
  isClosed: boolean,
  evaluationType: EvaluationType,
  activeTab: ProgressState,
  staffs: Staff[],
  setReport: (d: {
    [x: string]: string | boolean
    visible: boolean
    selectedControlId: string
    previousControlId: string
    nextControlId: string
  }) => void
): ColDef[] {
  const staffsWithoutWatcher = _(staffs)
    .sortBy((staff) => getStaffNameAndEmailById(staffs, staff.id))
    .map((staff) => staff.level !== Level.WATCHER && staff)
    .compact()
    .value()
  const managers = _(staffs)
    .sortBy((staff) => getStaffNameAndEmailById(staffs, staff.id))
    .map((staff) => (staff.level === Level.ADMIN || staff.level === Level.NORMAL) && staff)
    .compact()
    .value()

  const staffComparator = (a: string, b: string) => {
    const aName = getStaffNameAndEmailById(staffs, a)
    const bName = getStaffNameAndEmailById(staffs, b)
    return aName.localeCompare(bName)
  }

  const isStaffEditable =
    !isClosed &&
    _.includes(
      [ProgressState.Unperformed, ProgressState.Performing, ProgressState.Rejected],
      activeTab
    )

  const fileCol = { menuTabs: [], width: 80, cellRenderer: 'FileCellRenderer' }
  const newColumns: ColDef[] = [
    { headerName: getKoName('control', 'id'), field: 'controlId', width: 240, sortable: true },
    {
      headerName: getKoName('control', 'name'),
      field: 'content.control.name',
      suppressSizeToFit: false,
      flex: 2
    },
    {
      headerName: getKoName('common', 'cycleName'),
      field: 'content.control.cycleName',
      width: 200,
      sortable: true
    },
    {
      headerName: getKoName('control', 'department'),
      field: 'state.department',
      width: 200,
      sortable: true
    },
    {
      headerName: getKoName('control', 'ownerInTable'),
      field: 'state.owner',
      suppressSizeToFit: false,
      flex: 1,
      cellRenderer: 'StaffCellRenderer',
      valueFormatter: StaffValueFormatter(staffsWithoutWatcher),
      editable: isStaffEditable,
      cellEditor: 'agRichSelectCellEditor',
      cellEditorParams: {
        values: _.map(staffsWithoutWatcher, (staff) => staff.id),
        formatValue: StaffFormatValue(staffsWithoutWatcher)
      },
      filterParams: {
        comparator: staffComparator,
        valueFormatter: StaffValueFormatter(staffsWithoutWatcher)
      },
      cellStyle: isStaffEditable && {
        backgroundColor: getEditableColor()
      },
      sortable: true
    },
    {
      headerName: getKoName('control', 'performerInTable'),
      field: 'state.performer',
      suppressSizeToFit: false,
      flex: 1,
      cellRenderer: 'StaffCellRenderer',
      valueFormatter: StaffValueFormatter(staffsWithoutWatcher),
      editable: isStaffEditable,
      cellEditor: 'agRichSelectCellEditor',
      cellEditorParams: {
        values: _.map(managers, (manager) => manager.id),
        formatValue: StaffFormatValue(managers)
      },
      filterParams: {
        comparator: staffComparator,
        valueFormatter: StaffValueFormatter(managers)
      },
      cellStyle: isStaffEditable && {
        backgroundColor: getEditableColor()
      },
      sortable: true
    }
  ]

  if (activeTab === ProgressState.Approved) {
    newColumns.push({
      headerName: '미비사항',
      field: 'state.resultState',
      width: 120,
      valueFormatter: (params: ValueFormatterParams) => resultStateToString(params.value),
      filterParams: {
        valueFormatter: (params: ValueFormatterParams) => resultStateToString(params.value)
      },
      sortable: true
    })
  }

  if (evaluationType === EvaluationType.Operation) {
    newColumns.push(
      ...[
        { ...fileCol, headerName: '보고서', field: 'content.files.record' },
        { ...fileCol, headerName: '모집단', field: 'content.files.population' },
        { ...fileCol, headerName: '증빙', field: 'content.files.evidence' }
      ]
    )
  }
  newColumns.push({ ...fileCol, headerName: '기타', field: 'content.files.extra' })

  const checkboxCol = {
    menuTabs: [],
    width: 120,
    cellRenderer: 'CheckboxCellRenderer',
    headerComponent: 'CheckboxHeader',
    cellStyle: { backgroundColor: 'rgb(255, 248, 240, 0.45)' }
  }
  if (!isClosed) {
    if (activeTab === ProgressState.Performed) {
      newColumns.push(
        ...[
          {
            ...checkboxCol,
            headerName: getKoName('control', 'Rejected'),
            field: `view.${getKoProgress(ProgressState.Rejected)}`
          },
          {
            ...checkboxCol,
            headerName: getKoName('control', 'Approved'),
            field: `view.${getKoProgress(ProgressState.Approved)}`
          }
        ]
      )
    } else if (activeTab === ProgressState.Approved) {
      newColumns.push({
        ...checkboxCol,
        headerName: getKoName('control', 'Rejected'),
        field: `view.${getKoProgress(ProgressState.Rejected)}`
      })
    } else {
      newColumns.push(
        ...[
          {
            ...checkboxCol,
            headerName: getKoName('control', 'Request'),
            field: `view.${getKoProgress(ProgressState.Request)}`
          },
          {
            ...checkboxCol,
            headerName: getKoName('control', 'Performed'),
            field: `view.${getKoProgress(ProgressState.Performed)}`
          }
        ]
      )
    }
  }

  if (activeTab === ProgressState.Approved) {
    newColumns.push({
      ...checkboxCol,
      width: 140,
      headerName: getKoName('control', 'Exported'),
      field: `view.${getKoProgress(ProgressState.Exported)}`
    })
  }

  newColumns.push({
    menuTabs: [],
    width: 120,
    headerName: '보고서 상세',
    field: 'action',
    cellRenderer: detailCellRenderer,
    cellRendererParams: { setReport },
    cellStyle: { backgroundColor: 'rgb(255, 248, 240, 0.45)' }
  })

  return newColumns
}

interface GridProps {
  gridApi?: GridApi
  columnApi?: ColumnApi
}

interface Props {
  loading: boolean
  type: EvaluationType
  evaluationName: string
  evaluationDetails: EvaluationItem[]
  staffs: Staff[]
  updateEvaluationState: (stateType: FState, controlIds: string[]) => Promise<void>
}

const EvaluationStateView: React.FC<Props> = ({
  loading,
  type,
  evaluationName,
  evaluationDetails,
  staffs,
  updateEvaluationState
}) => {
  const classes = useStyles()
  const [rowData, setRowData] = React.useState<EvaluationItem[]>([])
  const [evaluations, setEvaluations] = React.useState({
    [ProgressState.Unperformed]: [] as EvaluationItem[],
    [ProgressState.Performing]: [] as EvaluationItem[],
    [ProgressState.Rejected]: [] as EvaluationItem[],
    [ProgressState.Performed]: [] as EvaluationItem[],
    [ProgressState.Approved]: [] as EvaluationItem[]
  })
  // const [staffNames, setStaffNames] = React.useState<string[]>([])
  const [unperformedGridApi, setUnperformedGridApi] = React.useState<GridProps>()
  const [performingGridApi, setPerformingGridApi] = React.useState<GridProps>()
  const [rejectedGridApi, setRejectedGridApi] = React.useState<GridProps>()
  const [performedGridApi, setPerformedGridApi] = React.useState<GridProps>()
  const [approvedGridApi, setApprovedGridApi] = React.useState<GridProps>()

  const [activeTab, setActiveTab] = React.useState<ProgressState>(ProgressState.Unperformed)
  const [form] = Form.useForm()
  const [states, setStates] = React.useState({
    visible: false,
    loading: false,
    title: ProgressState.Performed as FState,
    contents: [] as { id: string; name: string; invalid: boolean }[],
    referenceName: undefined as string | undefined,
    isComment: false
  })
  const [report, setReport] = React.useState({
    visible: false,
    selectedControlId: '',
    previousControlId: '',
    nextControlId: '',
    [getKoProgress(ProgressState.Request)]: false,
    [getKoProgress(ProgressState.Performed)]: false,
    [getKoProgress(ProgressState.Rejected)]: false,
    [getKoProgress(ProgressState.Approved)]: false
  })
  const alertRef = React.useRef<AlertMessageHandler>(null)
  const [loadingPerformer, setLoadingPerformer] = React.useState(false)
  const [columns, setColumns] = React.useState<ColDef[]>([])
  const [resizeListener, sizes] = useResizeAware()

  React.useEffect(() => {
    setColumns(getColumns(isAlreadyClose(evaluationDetails), type, activeTab, staffs, setReport))
  }, [type, activeTab, staffs, evaluationDetails])

  React.useEffect(() => {
    if (isEqualEvaluations(rowData, evaluationDetails)) {
      return
    }
    setRowData(evaluationDetails)

    const newEvaluations = {
      [ProgressState.Unperformed]: [] as EvaluationItem[],
      [ProgressState.Performing]: [] as EvaluationItem[],
      [ProgressState.Rejected]: [] as EvaluationItem[],
      [ProgressState.Performed]: [] as EvaluationItem[],
      [ProgressState.Approved]: [] as EvaluationItem[]
    }
    _.forEach(evaluationDetails, (evaluationDetail) => {
      const state = evaluationDetail.state.state
      const row = _.cloneDeep(evaluationDetail)
      const prev = _.find(evaluations[state], { controlId: evaluationDetail.controlId })
      newEvaluations[state].push(row)
      _.forEach(
        [
          ProgressState.Request,
          ProgressState.Performed,
          ProgressState.Rejected,
          ProgressState.Approved,
          ProgressState.Exported
        ],
        (stateKey) => {
          const key = `view.${getKoProgress(stateKey)}`
          _.set(row, key, _.get(prev, key, false))
        }
      )
    })

    if (isAlreadyClose(evaluationDetails)) {
      setActiveTab(ProgressState.Approved)
    }

    setEvaluations({
      ...evaluations,
      ...newEvaluations
    })
  }, [evaluationDetails])

  const openMessageModal = React.useCallback(
    (stateType: FState, gridApi?: GridApi): void => {
      const data: EvaluationItem[] = []
      if (gridApi) {
        gridApi.forEachNodeAfterFilter((row) => {
          data.push(row.data)
        })
      }

      const contents: { id: string; name: string; invalid: boolean }[] = []
      _.forEach(data, (row) => {
        if (_.get(row, `view.${getKoProgress(stateType)}`)) {
          contents.push({
            id: row.controlId,
            name: '',
            invalid:
              stateType === ProgressState.Performed && row.state.resultState === ResultState.Default
          })
        }
      })

      if (_.isEmpty(contents)) {
        alertRef.current?.showAlert('error', '선택된 항목이 없습니다.')
        return
      }

      form.setFieldsValue({
        isComment: _.includes([ProgressState.Request, ProgressState.Rejected], stateType)
      })
      setStates({
        ...states,
        visible: true,
        title: stateType,
        referenceName: _.first(data)?.base.refEvaluationName,
        contents,
        isComment: _.includes([ProgressState.Request, ProgressState.Rejected], stateType)
      })
    },
    [form, states]
  )

  const getGridApi = React.useCallback(
    (state: ProgressState): GridApi | undefined => {
      if (state === ProgressState.Unperformed) {
        return unperformedGridApi?.gridApi
      } else if (state === ProgressState.Performing) {
        return performingGridApi?.gridApi
      } else if (state === ProgressState.Rejected) {
        return rejectedGridApi?.gridApi
      } else if (state === ProgressState.Performed) {
        return performedGridApi?.gridApi
      } else {
        return approvedGridApi?.gridApi
      }
    },
    [unperformedGridApi, performingGridApi, rejectedGridApi, performedGridApi, approvedGridApi]
  )

  const stateButtons: Record<ProgressState, FState[]> = React.useMemo(() => {
    return {
      [ProgressState.Unperformed]: [ProgressState.Request, ProgressState.Performed],
      [ProgressState.Performing]: [ProgressState.Request, ProgressState.Performed],
      [ProgressState.Rejected]: [ProgressState.Request, ProgressState.Performed],
      [ProgressState.Performed]: [ProgressState.Rejected, ProgressState.Approved],
      [ProgressState.Approved]: [ProgressState.Rejected, ProgressState.Exported]
    }
  }, [])

  const selectedEvaluation = React.useMemo(
    (): EvaluationItem | undefined =>
      _.find(evaluations[activeTab], { controlId: report.selectedControlId }),
    [evaluations, activeTab, report]
  )

  const handleOk = React.useCallback((): void => {
    setStates({ ...states, loading: true })
    const controlIds = _.map(states.contents, (content) => content.id)
    if (states.title === ProgressState.Exported) {
      if (type === EvaluationType.Design) {
        exportToExcelZipDesign(
          staffs,
          _.filter(evaluations[activeTab], (evaluation) =>
            _.includes(controlIds, evaluation.controlId)
          )
        )
          .then(() => {
            alertRef.current?.showAlert('success', '다운로드 시작')
          })
          .catch(() => {
            alertRef.current?.showAlert('error', '다운로드 실패')
          })
          .finally(() => {
            setStates({ ...states, loading: false, visible: false })
          })
        return

        // setStates({ ...states, loading: false, visible: false })
        // return
      }

      exportToExcelZip(
        staffs,
        _.filter(evaluations[activeTab], (evaluation) =>
          _.includes(controlIds, evaluation.controlId)
        )
      )
        .then(() => {
          alertRef.current?.showAlert('success', '다운로드 시작')
        })
        .catch(() => {
          alertRef.current?.showAlert('error', '다운로드 실패')
        })
        .finally(() => {
          setStates({ ...states, loading: false, visible: false })
        })
      return
    }

    updateEvaluationState(states.title, controlIds)
      .then(() => {
        const createNotification = []
        if (states.title === ProgressState.Request) {
          createNotification.push(
            sendMailAboutEvaluations(
              NotificationType.Request,
              type,
              evaluationName,
              controlIds,
              form.getFieldValue('comment'),
              getProjectId()
            )
          )
        } else if (states.title === ProgressState.Rejected && states.isComment) {
          const comment = form.getFieldValue('comment')
          _.forEach(controlIds, (controlId) => {
            createNotification.push(
              sendMailAboutEvaluations(
                NotificationType.Rejected,
                type,
                evaluationName,
                [controlId],
                comment,
                getProjectId()
              )
            )
          })
        } else {
          setStates({ ...states, loading: false, visible: false })
          return
        }

        Promise.all(createNotification)
          .then(() => {
            setStates({ ...states, loading: false, visible: false })
            form.resetFields()
            alertRef.current?.showAlert('success', '공지 성공')
          })
          .catch((e) => {
            // eslint-disable-next-line no-console
            console.log('Failed to send a notification', e)
            alertRef.current?.showAlert('error', '공지 보내기에 실패했습니다.')
          })
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.log('Failed to change state', e)
        alertRef.current?.showAlert('error', '상태 변경에 실패했습니다.')
      })
  }, [type, evaluationName, staffs, states, form, evaluations, activeTab])

  const changeOwnerOrPerformer = React.useCallback(
    (event: CellValueChangedEvent, changeOwner: boolean): void => {
      if (event.newValue === event.oldValue) {
        return
      }

      const newStaff = _.find(staffs, { id: event.newValue })
      if (!newStaff || !newStaff?.id) {
        alertRef.current?.showAlert('error', '변경에 실패했습니다.')
        // TODO(sangmuk): 담당자를 원래값으로 복구
        return
      }

      setLoadingPerformer(true)
      updateOwnerOrPerformer(changeOwner, type, evaluationName, event.data.controlId, newStaff.id)
        .catch((e) => {
          // eslint-disable-next-line no-console
          console.log('Failed to change evaluation staff', e)
          alertRef.current?.showAlert('error', '변경에 실패했습니다.')
        })
        .finally(() => setLoadingPerformer(false))
    },
    [type, evaluationName, staffs, form]
  )

  const changeReport = React.useCallback(
    (controlId: string): void => {
      const api = getGridApi(activeTab)
      if (api) {
        setReport({
          ...report,
          selectedControlId: controlId,
          ...getNeighborControlIdAndChecked(api, controlId)
        })
      }
    },
    [activeTab, report, getGridApi]
  )

  return (
    <>
      {resizeListener}
      <Tabs
        activeKey={activeTab}
        className={classes.root}
        tabBarExtraContent={_.map(stateButtons[activeTab], (button: FState) => {
          return (
            <Button
              disabled={isAlreadyClose(evaluationDetails) && button !== ProgressState.Exported}
              className={classes.button}
              key={button}
              onClick={() => openMessageModal(button, getGridApi(activeTab))}
            >
              {getKoProgress(button)}
            </Button>
          )
        })}
        onChange={(key) => setActiveTab(key as ProgressState)}
      >
        {_.map(
          [
            ProgressState.Unperformed,
            ProgressState.Performing,
            ProgressState.Rejected,
            ProgressState.Performed,
            ProgressState.Approved
          ],
          (tabType: ProgressState) => {
            return (
              <Tabs.TabPane key={tabType} tab={getKoProgress(tabType)}>
                <CommonAgGrid
                  enableRangeSelection
                  columnDefs={columns}
                  frameworkComponents={{
                    CheckboxCellRenderer,
                    FileCellRenderer,
                    StaffCellRenderer,
                    CheckboxHeader
                  }}
                  rowData={evaluations[tabType]}
                  onCellValueChanged={(event) => {
                    if (event.colDef.field === 'state.performer') {
                      changeOwnerOrPerformer(event, false)
                    } else if (event.colDef.field === 'state.owner') {
                      changeOwnerOrPerformer(event, true)
                    }
                  }}
                  onGridReady={(event: AgGridEvent): void => {
                    if (tabType === ProgressState.Unperformed) {
                      setUnperformedGridApi({
                        gridApi: event.api,
                        columnApi: event.columnApi
                      })
                    } else if (tabType === ProgressState.Performing) {
                      setPerformingGridApi({
                        gridApi: event.api,
                        columnApi: event.columnApi
                      })
                    } else if (tabType === ProgressState.Rejected) {
                      setRejectedGridApi({
                        gridApi: event.api,
                        columnApi: event.columnApi
                      })
                    } else if (tabType === ProgressState.Performed) {
                      setPerformedGridApi({
                        gridApi: event.api,
                        columnApi: event.columnApi
                      })
                    } else {
                      setApprovedGridApi({
                        gridApi: event.api,
                        columnApi: event.columnApi
                      })
                    }
                  }}
                />
              </Tabs.TabPane>
            )
          }
        )}
      </Tabs>
      <Modal
        footer={[
          <Button key="back" onClick={() => setStates({ ...states, visible: false })}>
            취소
          </Button>,
          <Button
            disabled={_.some(states.contents, { invalid: true })}
            key="submit"
            loading={states.loading}
            type="primary"
            onClick={handleOk}
          >
            {getKoProgress(states.title)}
          </Button>
        ]}
        title={getKoProgress(states.title)}
        visible={states.visible}
        onCancel={() => setStates({ ...states, visible: false })}
        onOk={handleOk}
      >
        <Typography.Title level={5}>선택된 통제</Typography.Title>
        {_(states.contents)
          .filter({ invalid: false })
          .map(({ id, name }) => {
            return <p style={{ margin: 0 }}>{`[${id}] ${name}`}</p>
          })
          .value()}
        {_.some(states.contents, { invalid: true }) && (
          <>
            <Typography.Text type="danger">
              아래의 통제에 평가 결론이 없습니다. {getKoEvaluation(type)} 보고서 작성에서 평가
              결론을 먼저 추가해주세요.
            </Typography.Text>
            {_(states.contents)
              .filter({ invalid: true })
              .map(({ id, name }) => {
                return <p style={{ margin: 0 }}>{`[${id}] ${name}`}</p>
              })
              .value()}
          </>
        )}
        {states.title === ProgressState.Request && !states.referenceName && (
          <>
            <br />
            <Typography.Text>
              현재 참조로 설정된 {getKoEvaluation(type)}가 없습니다.
            </Typography.Text>
            <br />
          </>
        )}
        {states.title === ProgressState.Request && states.referenceName && (
          <>
            <br />
            <Typography.Text>
              참조로 설정된{' '}
              <Typography.Text strong type="danger">
                {states.referenceName}
              </Typography.Text>
              의 통제에서 저장된 파일을 같이 전달합니다.
            </Typography.Text>
            <br />
          </>
        )}
        <br />
        {_.includes([ProgressState.Request, ProgressState.Rejected], states.title) && (
          <Form
            form={form}
            layout="vertical"
            onValuesChange={(value) => {
              if (_.has(value, 'isComment')) {
                setStates({ ...states, isComment: value.isComment })
              }
            }}
          >
            {states.title === ProgressState.Rejected && (
              <Form.Item className={classes.form} name="isComment" valuePropName="checked">
                <Checkbox>담당자에게 알림을 보내시겠습니까?</Checkbox>
              </Form.Item>
            )}
            <Form.Item className={classes.form} label="코멘트" name="comment">
              <TextArea autoSize={{ minRows: 2, maxRows: 6 }} disabled={!states.isComment} />
            </Form.Item>
          </Form>
        )}
      </Modal>
      <Modal
        bodyStyle={{ padding: 0 }}
        footer={[
          <Button key="back" onClick={() => setReport({ ...report, visible: false })}>
            닫기
          </Button>
        ]}
        title="보고서 상세"
        visible={report.visible}
        width={sizes.width ? sizes.width * 0.8 : 1000}
        onCancel={() => setReport({ ...report, visible: false })}
        onOk={() => setReport({ ...report, visible: false })}
      >
        <div className={classes.recordButtonContainer}>
          <PreviousButton id={report.previousControlId} onChange={changeReport} />
          <div>
            {!isAlreadyClose(evaluationDetails) &&
              _.map(stateButtons[activeTab], (button: FState) => {
                return (
                  <Checkbox
                    checked={(_.get(report, getKoProgress(button)) as boolean) || false}
                    key={`stateCheckbox${button}`}
                    onChange={(event) => {
                      const api = getGridApi(activeTab)
                      if (api && selectedEvaluation) {
                        _.set(
                          selectedEvaluation,
                          `view.${getKoProgress(button)}`,
                          event.target.checked
                        )
                        setReport({
                          ...report,
                          [getKoProgress(button)]: event.target.checked
                        })
                        api.applyTransaction({ update: [selectedEvaluation] })
                      }
                    }}
                  >
                    {getKoProgress(button)}
                  </Checkbox>
                )
              })}
          </div>
          <NextButton id={report.nextControlId} onChange={changeReport} />
        </div>
        {type === EvaluationType.Operation && selectedEvaluation && (
          <OperationEvaluationReportViewer evaluation={selectedEvaluation} staffs={staffs} />
        )}
        {type === EvaluationType.Design && selectedEvaluation && (
          <DesignEvaluationReportView staffs={staffs} evaluation={selectedEvaluation} />
        )}
      </Modal>
      <AlertMessage ref={alertRef} />
      <CircleBackdrop open={loading || loadingPerformer} />
    </>
  )
}

export default EvaluationStateView
