import { ColDef, ColGroupDef, ValueFormatterParams } from '@ag-grid-community/core'
import { blue } from '@ant-design/colors'
import * as EvaluationUtils from '@cck/backend/dist/evaluation/EvaluationUtils'
import * as EvaluationParser from '@cck/backend/dist/evaluation/ExcelParser'
import { BaseFile, FileState, FileType, stateToString } from '@cck/common/dist/data/BaseFile'
import * as Evaluation from '@cck/common/dist/data/Evaluation'
import { Staff } from '@cck/common/dist/data/Staff'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Modal from 'antd/lib/modal'
import Typography from 'antd/lib/typography'
import clsx from 'clsx'
import _ from 'lodash'
import React from 'react'
import { useDropzone } from 'react-dropzone'

import { dateCellRenderer, getFileViewColumns } from '../../../base/utils/FileUtils'
import { StaffCellRenderer } from '../../common/StaffRenderer'
import TableViewer, { TableViewerHandler } from '../../common/TableViewer'
import { deleteCellRenderer, stateCellRenderer } from '../common/DropBox'
import UploadIconAndText from '../common/UploadIconAndText'
import FileNameNoticeView from '../file/FileNameNoticeView'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2)
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  headerText: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
    flex: 1
  },
  dropzone: {
    display: 'flex',
    position: 'relative',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1,
    background: 'WhiteSmoke',
    border: '1px dashed lightgray'
  },
  dragActive: {
    borderColor: blue.primary
  }
}))

export function getFileUploaderColumns(
  type: Evaluation.Type,
  staffs: Staff[],
  deleteFile: (allFiles: BaseFile[], file: BaseFile) => void
): (ColDef | ColGroupDef)[] {
  return [
    ...getFileViewColumns(staffs),
    {
      headerName: '파일 상태',
      field: 'state',
      cellRenderer: stateCellRenderer,
      valueFormatter: (params: ValueFormatterParams): string => stateToString(type, params.value)
    },
    {
      headerName: '삭제',
      field: 'delete',
      width: 120,
      cellRenderer: deleteCellRenderer,
      cellRendererParams: { deleteFile }
    }
  ]
}

interface Props {
  type: Evaluation.Type
  staffs: Staff[]
  evaluation: Evaluation.EvaluationItem
  updateEvaluation: (evaluation: Evaluation.EvaluationData) => void
  files: (BaseFile | Evaluation.EvaluationFile)[]
  setFiles: React.Dispatch<React.SetStateAction<(BaseFile | Evaluation.EvaluationFile)[]>>
}

const EvaluationFileUploader: React.FC<Props> = ({
  type,
  staffs,
  evaluation,
  updateEvaluation,
  files,
  setFiles
}) => {
  const classes = useStyles()
  const viewerRef = React.useRef<TableViewerHandler>(null)

  React.useEffect(() => {
    if (!_.isEmpty(evaluation.content.files)) {
      setFiles(
        _.map(EvaluationUtils.getFileList(evaluation), (file) => {
          file.state = FileState.SAVED_FILE
          return file
        })
      )
    }
  }, [evaluation])

  const onDrop = React.useCallback(
    (originalFiles) => {
      console.log('On drop')
      const acceptedFiles: File[] = []
      _.forEach(originalFiles, (file) => {
        acceptedFiles.push(new File([file], file.name.normalize('NFC'), { type: file.type }))
      })

      EvaluationParser.parseFiles(type, acceptedFiles).then(
        (parsedFiles: (BaseFile | Evaluation.EvaluationFile)[]) => {
          const allFiles = files.slice()
          _.forEach(parsedFiles, (file) => {
            if (file.state === FileState.INVALID_NAME) {
              file.evaluationName = ''
              file.controlId = ''
            }

            const matched = _.findIndex(allFiles, { name: file.name })
            if (
              matched >= 0 &&
              (file.state === FileState.OK || allFiles[matched]?.state !== FileState.OK)
            ) {
              _.pullAt(allFiles, [matched])
              file.state = FileState.ALREADY_EXISTING
            } else if (matched >= 0) {
              return
            }

            if (file.state === FileState.OK) {
              if (file.evaluationName !== evaluation.base.name) {
                file.state = FileState.UNRELATED_OPERATION_EVAL
              } else if (file.controlId !== evaluation.controlId) {
                file.state = FileState.UNRELATED_FILE
              }
            }

            if (file.state === FileState.UNRELATED_FILE) {
              file.state = FileState.DIFFERENT_CONTROL_ID
            }

            if (
              file.type === FileType.Record &&
              Evaluation.isEvaluationFile(file) &&
              Evaluation.isEvaluationData(file.excelData) &&
              _.includes([FileState.OK, FileState.ALREADY_EXISTING], file.state)
            ) {
              Modal.confirm({
                title: Evaluation.getKoEvaluation(type) + '보고서 업데이트',
                content: (
                  <>
                    <Typography.Text strong>{file.name}</Typography.Text>
                    <Typography.Text>현재 보고서 파일을 업데이트 하시겠습니까?</Typography.Text>
                  </>
                ),
                okText: '업데이트',
                cancelText: '취소',
                onOk: () => {
                  if (Evaluation.isEvaluationData(file.excelData)) {
                    updateEvaluation(file.excelData)
                  }
                }
              })
            }

            allFiles.push(file)
          })

          setFiles(allFiles)
        }
      )
    },
    [files]
  )

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    noClick: !_.isEmpty(files)
  })

  const deleteFile = (allFiles: BaseFile[], file: BaseFile): void => {
    const newFiles = allFiles.filter((item) => item.name !== file.name)
    setFiles(newFiles)
  }

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Typography.Text className={classes.headerText}>파일 업로드</Typography.Text>
        <FileNameNoticeView evaluationType={type} evaluationName={evaluation.base.name} />
      </div>
      <div
        className={clsx(classes.dropzone, { [classes.dragActive]: isDragActive })}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        {_.isEmpty(files) && <UploadIconAndText isDragActive={isDragActive} />}
        {!_.isEmpty(files) && (
          <TableViewer
            // autoSizeColumns
            noBorder
            columnDefs={getFileUploaderColumns(type, staffs, deleteFile)}
            domLayout="autoHeight"
            frameworkComponents={{ dateCellRenderer, StaffCellRenderer }}
            ref={viewerRef}
            rowData={files}
          />
        )}
      </div>
    </div>
  )
}

export default EvaluationFileUploader
