import {
  ColDef,
  ColSpanParams,
  EditableCallbackParams,
  GridApi,
  ICellEditorParams,
  ICellRendererParams,
  RowNode,
  ValueFormatterParams
} from '@ag-grid-community/core'
import { ComponentSelectorResult } from '@ag-grid-community/core/dist/cjs/components/framework/userComponentFactory'
import * as Evaluation from '@cck/common/dist/data/Evaluation'
import { DesignDefect } from '@cck/common/dist/data/Evaluation'
import { Control } from '@cck/common/dist/data/PRCUtils'
import { Staff, filterStaffs, StaffFormatValue } from '@cck/common/dist/data/Staff'
import { getKoName } from '@cck/common/dist/data/Translation'
import {
  DefectItem,
  ViewTitle,
  isDefectItem,
  isViewTitle,
  YesNo
} from '@cck/common/dist/data/control/AbstractControl'
import Risk from '@cck/common/dist/data/risk/AbstractRisk'
import { getControlClass } from '@cck/common/dist/utils/Config'
import Checkbox from 'antd/lib/checkbox'
import Switch from 'antd/lib/switch'
import _ from 'lodash'
import React from 'react'

import { getEditableColor } from '../../../base/color'

export interface Row {
  dataId?: string
  key: string
  base?: string
  content: string | boolean
  evidence?: string
  section?:
    | 'readOnly'
    | 'subTitle'
    | 'text'
    | 'selector'
    | 'booleanSelector'
    | 'yesNoSelector'
    | 'staffSelector'
    | 'departmentPrinter'
  editable?: boolean
  options?: string[]
}

function RequiredCellRenderer(props: any): HTMLElement {
  const text = document.createElement('p')
  text.style.marginBottom = '0'
  if (props.data.section === 'requiredText') {
    const span = document.createElement('span')
    span.style.color = 'red'
    span.style.fontWeight = 'normal'
    span.innerHTML = '*'
    text.appendChild(span)
  }
  text.innerHTML += props.value
  return text
}

export const CheckboxCellRenderer = (props: any): React.ReactElement => {
  return (
    <Checkbox
      checked={props.value === 'Yes' || (typeof props.value === 'boolean' && props.value)}
      onClick={(event) => {
        if (!props.data.editable || (props.isFinal && !props.data.hasFinal)) return
        const checked = (event.target as any)?.checked
        const colId = props.column.colId
        let dataValue
        if (_.includes(['Yes', 'No'], props.data[colId])) {
          dataValue = checked ? 'Yes' : 'No'
        } else {
          dataValue = checked
        }
        props.node.setDataValue(colId, dataValue)
      }}
    />
  )
}

export const SwitchCellRenderer = (props: any): React.ReactElement => {
  return (
    <Switch
      checked={props.value === 'Yes'}
      checkedChildren="Yes"
      unCheckedChildren="No"
      onClick={(checked) => {
        if (!props.data.editable || (props.isFinal && !props.data.hasFinal)) return
        const colId = props.column.colId
        props.node.setDataValue(colId, checked ? 'Yes' : 'No')
      }}
    />
  )
}

export function getCellStyle(params: any): any {
  const cellStyle = {
    lineHeight: 'normal',
    display: 'flex',
    alignItems: 'center',
    paddingTop: '4px',
    paddingBottom: '4px',
    wordBreak: 'keep-all'
  }
  if (params.data.section === 'subTitle') {
    _.assign(cellStyle, {
      backgroundColor: '#f8f8f8',
      fontWeight: 700,
      fontSize: '13px',
      textAlign: 'center'
    })
  } else if (params.colDef.field === 'key') {
    _.assign(cellStyle, { backgroundColor: '#f8f8f8', fontWeight: 700, fontSize: '13px' })
  } else if (params.colDef.field === 'content' && params.data.section === 'readOnly') {
    _.assign(cellStyle, { backgroundColor: 'rgb(217, 217, 217, 0.45)' })
  } else if (params.colDef.editable || _.isEqual(params.colDef.field, 'hasFinal')) {
    _.assign(cellStyle, { backgroundColor: getEditableColor() })
  } else if (!params.colDef.editable) {
    _.assign(cellStyle, { backgroundColor: 'rgb(255, 255, 255, 0.45)' })
  }
  return cellStyle
}

export function getSubTitleItem(base: string): Row {
  return {
    key: base,
    content: base + '(수정 대상 존재시 하기 기입)',
    evidence: base + '(변경항목 수정 이유 기입)',
    section: 'subTitle'
  }
}

export function getReadOnlyItem(type: 'control' | 'risk', key: string, base: string): Row {
  return {
    key: getKoName(type, key),
    base,
    content: '수정불가',
    section: 'readOnly'
  }
}

function getBaseItem(
  defect: DesignDefect,
  type: 'control' | 'risk',
  key: string,
  base: string
): Row {
  return {
    dataId: `${type}.${key}`,
    key: getKoName(type, key) || key,
    section: 'text', // 'requiredText' or 'text'
    base,
    content: _.get(defect, `${type}.${key}.content`),
    evidence: _.get(defect, `${type}.${key}.evidence`)
  }
}

export function getDesignItem(
  defect: DesignDefect,
  type: 'control' | 'risk',
  key: string,
  base: string,
  options?: string[]
): Row {
  const baseItem = getBaseItem(defect, type, key, base)
  if (_.isEmpty(options)) {
    return baseItem
  }

  return {
    ...baseItem,
    section: 'selector',
    options
  }
}

export function getDesignBooleanItem(
  defect: DesignDefect,
  type: 'control' | 'risk',
  key: string,
  base: string
): Row {
  return {
    dataId: `${type}.${key}`,
    key: getKoName(type, key),
    base: base === 'Yes' || (typeof base === 'boolean' && base) ? '○' : '',
    content: _.get(defect, `${type}.${key}.content`) || '',
    evidence: _.get(defect, `${type}.${key}.evidence`) || '',
    section: 'booleanSelector'
  }
}

export function getDesignYesNoItem(
  defect: DesignDefect,
  type: 'control' | 'risk',
  key: string,
  base: string
): any {
  const item = getDesignItem(defect, type, key, base)
  return {
    ...item,
    section: 'yesNoSelector'
  }
}

export function getDesignEvaluationColumns(staffs: Staff[]): ColDef[] {
  return [
    {
      headerName: '',
      field: 'key',
      editable: false,
      colSpan: (params: ColSpanParams): number => {
        if (params.data.section === 'subTitle') return 2
        return 1
      },
      cellRenderer: RequiredCellRenderer
    },
    {
      headerName: '기존 통제 정보',
      field: 'base',
      flex: 1,
      editable: false,
      valueFormatter: (params: ValueFormatterParams): string => {
        if (params.data.section === 'staffSelector') {
          return StaffFormatValue(staffs)(params.value)
        }
        return params.value
      }
    },
    {
      headerName: '수정대상',
      field: 'content',
      flex: 1,
      colSpan: (params: ColSpanParams): number => {
        if (params.data.section === 'readOnly') return 2
        return 1
      },
      editable: (params: EditableCallbackParams): boolean => {
        if (
          _.includes(
            ['subTitle', 'readOnly', 'booleanSelector', 'yesNoSelector', 'departmentPrinter'],
            params.data.section
          )
        ) {
          return false
        }
        return params.data.editable
      },
      cellRendererSelector: (params: ICellRendererParams): ComponentSelectorResult => {
        if (params.data.section === 'booleanSelector') {
          return { component: 'CheckboxCellRenderer' }
        } else if (params.data.section === 'yesNoSelector') {
          return { component: 'SwitchCellRenderer' }
        }
        return {}
      },
      valueFormatter: (params: ValueFormatterParams): string => {
        if (params.data.section === 'staffSelector') {
          return StaffFormatValue(staffs)(params.value)
        }
        return params.value
      },
      cellEditorSelector: (params: ICellEditorParams): ComponentSelectorResult => {
        if (params.data.section === 'selector') {
          return {
            component: 'agRichSelectCellEditor',
            params: { values: params.data.options }
          }
        } else if (params.data.section === 'staffSelector') {
          const filteredStaffs = filterStaffs(staffs, params.data.dataId === 'control.owner')
          return {
            component: 'agRichSelectCellEditor',
            params: {
              values: _.map(filteredStaffs, (staff) => staff.id)
            }
          }
        }
        return {
          component: 'agLargeTextCellEditor',
          params: {
            maxLength: 500,
            rows: 20
          }
        }
      }
    },
    {
      headerName: '수정근거',
      field: 'evidence',
      flex: 1,
      editable: (params: EditableCallbackParams): boolean => {
        if (_.includes(['subTitle', 'readOnly'], params.data.section)) {
          return false
        }
        return params.data.editable
      },
      cellEditor: 'agLargeTextCellEditor',
      cellEditorParams: {
        maxLength: 500,
        rows: 20
      }
    }
  ]
}

const getRowData = (api: GridApi): any[] => {
  const rowData: any[] = []
  api.forEachNode((node: RowNode) => {
    rowData.push(node.data)
  })
  return rowData
}

export const isEmptyReport = (rowData: any): boolean => {
  const content: any[] = []
  const evidence: any[] = []
  _.forEach(rowData, (row) => {
    if (_.includes(['subTitle', 'readOnly'], row.section)) return
    // No로 선택되어 있고 evidence칸이 비어있다면 비어있다고 간주한다.
    if (row.section === 'yesNoSelector' && row.content === 'No' && _.isEmpty(row.evidence)) {
      content.push(false)
      evidence.push(row.evidence)
    } else {
      content.push(row.content)
      evidence.push(row.evidence)
    }
  })
  return !(_.some(content, Boolean) || _.some(evidence, Boolean))
}

export function checkEmptyReport(api: GridApi): boolean {
  return isEmptyReport(getRowData(api))
}

export function getEvaluationData(gridApi: GridApi, defect: DesignDefect): any {
  const data: any = {}
  gridApi.forEachNode((node: RowNode) => {
    const row = node.data
    if (
      !_.includes(
        ['text', 'selector', 'booleanSelector', 'yesNoSelector', 'staffSelector'],
        row.section
      )
    ) {
      return
    }

    if (row.section === 'yesNoSelector' && row.content === 'No' && _.isEmpty(row.evidence)) {
      const yesNo = _.get(defect, `${row.dataId}.content`)
      if (yesNo !== 'Yes') {
        _.set(data, `${row.dataId}.content`, yesNo)
      }
    } else if (row.section === 'text') {
      _.set(data, `${row.dataId}.content`, row.content || '')
      _.set(data, `${row.dataId}.evidence`, row.evidence || '')
      return
    } else {
      _.set(data, `${row.dataId}.content`, row.content)
    }
    _.set(data, `${row.dataId}.evidence`, row.evidence)
  })
  return data
}

function convertDesignDefectRow(
  risk: Risk,
  control: Control,
  designDefect: DesignDefect,
  data: (ViewTitle | DefectItem)[]
): Row[] {
  return data.map((item) => {
    if (isViewTitle(item)) {
      return getSubTitleItem(item.value)
    }
    if (!isDefectItem(item)) {
      throw new Error(`Invalid defect item ${item}`)
    }

    const target = item.type === 'control' ? control : risk
    if (item.key === 'id') {
      if (item.type === 'control') return getReadOnlyItem('control', 'id', designDefect.controlId)
      if (item.type === 'risk') return getReadOnlyItem('risk', 'id', risk.id)
    } else if (item.radio) {
      if (item.radio === YesNo) {
        return getDesignYesNoItem(
          designDefect,
          item.type,
          item.key,
          _.get(target, item.key, YesNo.no)
        )
      }
      return getDesignItem(
        designDefect,
        item.type,
        item.key,
        _.get(target, item.key, ''),
        Object.values(item.radio)
      )
    } else if (item.isBoolean) {
      return getDesignBooleanItem(designDefect, item.type, item.key, _.get(target, item.key))
    } else if (item.isStaff) {
      return {
        ...getDesignItem(designDefect, item.type, item.key, _.get(target, item.key)),
        key: getKoName('control', `control${item.key[0].toUpperCase() + item.key.slice(1)}`),
        section: 'staffSelector'
      }
    }
    return getDesignItem(designDefect, item.type, item.key, _.get(target, item.key, ''))
  })
}

export function getTableData(
  risk: Risk,
  control: Control,
  designDefect: DesignDefect,
  editable: boolean
): { infoData: Row[]; methodData: Row[]; infoEmpty: boolean; methodEmpty: boolean } {
  const { infoData: info, methodData: method } = getControlClass().getDesignDefect()
  const infoData = convertDesignDefectRow(risk, control, designDefect, info)
  const methodData = convertDesignDefectRow(risk, control, designDefect, method)

  _.forEach(infoData, (row) => {
    row.editable = editable
  })
  _.forEach(methodData, (row) => {
    row.editable = editable
  })

  return {
    infoData,
    methodData,
    infoEmpty: isEmptyReport(infoData),
    methodEmpty: isEmptyReport(methodData)
  }
}

export function getDefectSummary(
  evaluation: Evaluation.EvaluationItem
): { category: string; key: string; content: string; evidence: string }[] {
  const defect = evaluation.state.weakStateData as DesignDefect
  const data: { category: string; key: string; content: string; evidence: string }[] = []

  const categories: Record<string, { key: 'risk' | 'control'; subKey: string }[]> =
    getControlClass().getDesignDefectSummary()

  _.forEach(categories, (values, category) => {
    _.forEach(values, ({ key, subKey }) => {
      const content = _.get(defect, `${key}.${subKey}.content`)
      const evidence = _.get(defect, `${key}.${subKey}.evidence`)
      if (content && evidence && !_.isEmpty(content) && !_.isEmpty(evidence)) {
        data.push({
          category,
          key: getKoName(key, subKey),
          content,
          evidence
        })
      }
    })
  })

  return data
}
