/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ColDef, ColGroupDef } from '@ag-grid-enterprise/all-modules'
import {
  FileOutlined,
  FileExcelOutlined,
  FilePptOutlined,
  FileZipOutlined
} from '@ant-design/icons'
import { downloadFile } from '@cck/backend/dist/evaluation/EvaluationDownloader'
import { BaseFile } from '@cck/common/dist/data/BaseFile'
import { Staff, StaffValueFormatter } from '@cck/common/dist/data/Staff'
import { getKoName } from '@cck/common/dist/data/Translation'
import { parseTimeStr } from '@cck/common/dist/utils/TimeUtils'
import _ from 'lodash'
import React from 'react'

function getFileIcon(fileName: string): React.ReactElement {
  let icon = <FileOutlined />
  if (_.endsWith(fileName, 'xlsm') || _.endsWith(fileName, 'xlsx')) {
    icon = <FileExcelOutlined />
  } else if (_.endsWith(fileName, 'pptx') || _.endsWith(fileName, 'ppt')) {
    icon = <FilePptOutlined />
  } else if (_.endsWith(fileName, 'zip')) {
    icon = <FileZipOutlined />
  }
  return icon
}

function fileNameCellRenderer(params: any): HTMLElement {
  const basefile = params.data as BaseFile
  const aElement = document.createElement('a')
  aElement.innerHTML = params.value
  aElement.onclick = () =>
    downloadFile(basefile.evaluationType, basefile.evaluationName, basefile.controlId, params.value)
  const div = document.createElement('div')
  div.appendChild(aElement)

  if (!_.isEmpty(params.data.description)) {
    const span = document.createElement('span')
    span.style.color = 'red'
    span.innerHTML = ' ' + params.data.description
    div.appendChild(span)
  }
  return div
}

export function dateCellRenderer(params: any): HTMLElement {
  const span = document.createElement('span')
  span.innerHTML = params.value ? parseTimeStr(params.value) : ''
  return span
}

export function getFileViewColumns(staffs: Staff[]): (ColDef | ColGroupDef)[] {
  return [
    {
      headerName: getKoName('common', 'fileName'),
      field: 'name',
      flex: 3,
      cellRenderer: fileNameCellRenderer,
      sortable: true
    },
    { headerName: getKoName('common', 'type'), field: 'type', sortable: true },
    {
      headerName: getKoName('common', 'updater'),
      field: 'updater',
      cellRenderer: 'StaffCellRenderer',
      flex: 1,
      valueFormatter: StaffValueFormatter(staffs),
      filterParams: {
        valueFormatter: StaffValueFormatter(staffs)
      },
      sortable: true
    },
    {
      headerName: getKoName('common', 'updateTime'),
      field: 'updateTime',
      cellRenderer: dateCellRenderer,
      width: 220,
      sortable: true
    }
  ]
}

export function FileCellRenderer(params: any): React.ReactElement {
  const field = params.colDef.field
  let icon
  if (
    _.isEqual(field, 'template') ||
    (_.includes(['content.files.evidence', 'content.files.extra'], field) &&
      !_.isEmpty(params.value))
  ) {
    icon = getFileIcon('')
  } else if (
    _.includes(['content.files.record', 'content.files.population'], field) &&
    !_.isEmpty(params.value?.name)
  ) {
    icon = getFileIcon(params.value.name)
  }
  return <div style={{ textAlign: 'center', fontSize: 20 }}>{icon}</div>
}

export function FileNameCellRenderer(params: any): React.ReactElement {
  const filename = _.head(_.split(params.value, ', '))
  let icon
  if (filename) {
    icon = getFileIcon(filename)
  }
  return <div style={{ textAlign: 'center', fontSize: 20 }}>{icon}</div>
}
