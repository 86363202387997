import { Staff, filterStaffs } from '@cck/common/dist/data/Staff'
import { getKoName } from '@cck/common/dist/data/Translation'
import {
  EditItem,
  isViewTitle,
  isEditItem,
  ViewTitle
} from '@cck/common/dist/data/control/AbstractControl'
import { getControlClass } from '@cck/common/dist/utils/Config'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Checkbox from 'antd/lib/checkbox'
import Form, { Rule } from 'antd/lib/form'
import Input from 'antd/lib/input'
import Radio from 'antd/lib/radio'
import Select from 'antd/lib/select'
import _ from 'lodash'
import React from 'react'

import { renderStaffDepartment } from '../../../common/StaffRenderer'
import TableHeader from '../../../common/TableHeader'
import TableItem from '../../../common/TableItem'

const useStyle = makeStyles((theme: Theme) => ({
  form: {
    marginBottom: 0,
    width: '100%'
  },
  select: {
    maxWidth: 330
  },
  formSelect: {
    maxWidth: 400,
    flexGrow: 1,
    marginBottom: 0
  }
}))

const RenderRadio = (radioEnum: Record<string, string>): React.ReactElement => {
  return (
    <Radio.Group>
      {_.map(radioEnum, (type) => (
        <Radio key={type} value={type}>
          {type}
        </Radio>
      ))}
    </Radio.Group>
  )
}

const RenderCheck = (checkNames: string[], name: string): React.ReactElement => {
  const options: Record<string, string> = _.reduce(
    checkNames,
    (result: Record<string, string>, key: string) => {
      result[key] = getKoName('control', `${name}_${key}`)
      return result
    },
    {}
  )
  return (
    <Checkbox.Group>
      {_.map(options, (optionStr, option) => {
        return (
          <Checkbox key={option} style={{ marginLeft: 0, marginRight: 8 }} value={option}>
            {optionStr}
          </Checkbox>
        )
      })}
    </Checkbox.Group>
  )
}

interface ItemProps {
  name: string
  radioTypes?: Record<string, string>
  checkTypes?: string[]
  noDivider?: boolean
  required?: boolean
  disabled?: boolean
  prefix?: string
}

const ItemRow: React.FC<ItemProps> = ({
  name,
  radioTypes,
  checkTypes,
  noDivider,
  required,
  disabled,
  prefix
}) => {
  const classes = useStyle()
  const koName = getKoName('control', name)
  const rules: Rule[] = []
  if (required) {
    rules.push({
      required: true,
      message: `${koName}을 입력해주세요.`
    })
  }

  let container
  if (radioTypes) container = RenderRadio(radioTypes)
  else if (checkTypes) container = RenderCheck(checkTypes, prefix || name)
  else container = <Input.TextArea autoSize disabled={disabled} />

  return (
    <TableItem key={name} name={koName} noDivider={noDivider} required={required}>
      <Form.Item className={classes.form} name={name} rules={rules}>
        {container}
      </Form.Item>
    </TableItem>
  )
}

interface Props {
  selectedStaff?: Staff
  staffs?: Staff[]
}

const ControlEditView: React.FC<Props> = ({ selectedStaff, staffs }) => {
  const classes = useStyle()

  const staffSelectValidator = React.useCallback(
    (type: 'incharge' | 'owner'): ((rule: unknown, value: string) => Promise<void>) => {
      return (rule: unknown, value: string) => {
        if (_.isEmpty(value)) {
          return Promise.reject(new Error(`${getKoName('control', type)}를 선택해주세요.`))
        }
        return Promise.resolve()
      }
    },
    []
  )

  const staffSelectOptions = React.useCallback(
    (type: 'incharge' | 'owner') => {
      const filteredStaffs = filterStaffs(staffs || [], type === 'owner')
      return _.map(filteredStaffs, (staff) => (
        <Select.Option key={staff.id} value={staff.id}>
          {`${staff.name} (${staff.email})`}
        </Select.Option>
      ))
    },
    [staffs]
  )

  const renderItems = () => {
    const editItems = getControlClass().getControlEditItems()
    return _.map(editItems, (item: EditItem | ViewTitle, index) => {
      if (isViewTitle(item)) {
        return <TableHeader value={item.value} />
      } else if (isEditItem(item)) {
        if (item.isStaff) {
          const target = item.name
          if (target !== 'owner' && target !== 'incharge') {
            throw new Error(`Invalid staff key ${item.name}`)
          }

          return (
            <TableItem key={target} name={getKoName('control', target)} required>
              <Form.Item
                className={classes.formSelect}
                name={target}
                rules={[() => ({ validator: staffSelectValidator(target) })]}
              >
                <Select className={classes.select} size="small">
                  {staffSelectOptions(target)}
                </Select>
              </Form.Item>
            </TableItem>
          )
        } else if (item.isDepartment) {
          return (
            <TableItem required name={getKoName('control', item.name)}>
              {renderStaffDepartment(selectedStaff)}
            </TableItem>
          )
        } else {
          return (
            <ItemRow
              name={item.name}
              required={item.required}
              prefix={item.prefix}
              checkTypes={item.checkTypes}
              radioTypes={item.radioTypes}
              noDivider={index === editItems.length - 1}
            />
          )
        }
      }

      throw new Error(`Invalid edit item ${item}`)
    })
  }

  return <>{renderItems()}</>
}

export default ControlEditView
