import * as ControlManager from '@cck/backend/dist/rcm/ControlManager'
import * as CycleManager from '@cck/backend/dist/rcm/CycleManager'
import * as ProcessManager from '@cck/backend/dist/rcm/ProcessManager'
import * as RiskManager from '@cck/backend/dist/rcm/RiskManager'
import * as StaffManager from '@cck/backend/dist/rcm/StaffManager'
import { BasePRC, Process } from '@cck/common/dist/data/PRC'
import { Control } from '@cck/common/dist/data/PRCUtils'
import { Staff } from '@cck/common/dist/data/Staff'
import Risk from '@cck/common/dist/data/risk/AbstractRisk'
import Tabs from 'antd/lib/tabs'
import Typography from 'antd/lib/typography'
import _ from 'lodash'
import React from 'react'

import { lightBlue } from '../../../base/color'
import AlertMessage, { AlertMessageHandler } from '../../common/AlertMessage'
import CardTabs from '../../common/CardTabs'
import CycleEditor from './CycleEditor'
import PRCEditor from './PRCEditor'

type LoadType = 'cycle' | 'process' | 'risk' | 'control' | 'staff'

type GetReferenceFunction = (
  cycle: string,
  category: string,
  subCategory: string | undefined
) => Record<string, string>

function GetCycleIds(cycles: CycleManager.SimpleNode[]): {
  cycleNumber: string
  categoryNumber: string
  subCategoryNumber: string
}[] {
  const ids = []
  for (const cycle of cycles) {
    for (const category of cycle?.children) {
      for (const subCategory of category?.children) {
        ids.push({
          cycleNumber: cycle.id,
          categoryNumber: category.id,
          subCategoryNumber: subCategory.id
        })
      }
    }
  }
  return ids
}

async function loadPRC(types: LoadType[]) {
  const controls = await ControlManager.getContainCycle()
  return _.compact(
    await Promise.all(
      _.map(types, async (type) => {
        if (type === 'cycle') {
          return { type, data: await CycleManager.getAllTree() }
        } else if (type === 'process') {
          return { type, data: await ProcessManager.getContainCycle(controls) }
        } else if (type === 'risk') {
          return { type, data: await RiskManager.getContainCycle(controls) }
        } else if (type === 'staff') {
          return { type, data: await StaffManager.getAll() }
        } else if (type === 'control') {
          return { type, data: controls }
        }
      })
    )
  )
}

const RCMEditor: React.FC = () => {
  const [states, setStates] = React.useState({ loading: true })
  const [cycles, setCycles] = React.useState([] as CycleManager.SimpleNode[])
  const [processes, setProcesses] = React.useState([] as Process[])
  const [risks, setRisks] = React.useState([] as Risk[])
  const [controls, setControls] = React.useState([] as Control[])
  const [staffs, setStaffs] = React.useState([] as Staff[])
  const [warning, setWarning] = React.useState({
    process: '',
    risk: '',
    control: '',
    riskMapping: '',
    controlMapping: ''
  })
  const [activeTab, setActiveTab] = React.useState('1')
  const alertRef = React.useRef<AlertMessageHandler>(null)

  const LoadPRCData = React.useCallback(
    (types: LoadType[]): void => {
      setStates({ ...states, loading: true })
      loadPRC(types)
        .then((values) => {
          _.forEach(values, ({ type, data }) => {
            if (type === 'cycle') {
              setCycles(data as CycleManager.SimpleNode[])
            } else if (type === 'process') {
              setProcesses(data as Process[])
            } else if (type === 'risk') {
              setRisks(data as Risk[])
            } else if (type === 'control') {
              setControls(data as Control[])
            } else if (type === 'staff') {
              setStaffs(data as Staff[])
            }
          })
        })
        .finally(() => {
          setStates({
            ...states,
            loading: false
          })
        })
    },
    [states]
  )

  React.useEffect(() => {
    LoadPRCData(['cycle', 'process', 'risk', 'control', 'staff'])
  }, [])

  React.useEffect(() => {
    const newWarning = {
      process: '',
      risk: '',
      control: '',
      riskMapping: '',
      controlMapping: ''
    }

    for (const id of GetCycleIds(cycles)) {
      if (!newWarning.process && !_.find(processes, id)) {
        newWarning.process =
          `분류 ${id.cycleNumber}.${id.categoryNumber}.${id.subCategoryNumber}` +
          `에 프로세스가 존재하지 않습니다.`
      }
      if (!newWarning.risk && !_.find(risks, id)) {
        newWarning.risk =
          `분류 ${id.cycleNumber}.${id.categoryNumber}.${id.subCategoryNumber}` +
          `에 리스크가 존재하지 않습니다.`
      }
      if (!newWarning.control && !_.find(controls, id)) {
        newWarning.control =
          `분류 ${id.cycleNumber}.${id.categoryNumber}.${id.subCategoryNumber}` +
          `에 컨트롤이 존재하지 않습니다.`
      }
    }

    const noProcess = _.find(risks, (item) => _.isEmpty(item.processIds))
    if (noProcess) {
      newWarning.riskMapping = `${noProcess.id}에 연결된 프로세스가 없습니다.`
    } else {
      const noControl = _.find(risks, (item) => _.isEmpty(item.controlIds))
      if (noControl) {
        newWarning.riskMapping = `${noControl.id}에 연결된 컨트롤이 없습니다.`
      }
    }

    const noRisk = _.find(controls, (control) => _.isEmpty(control.riskIds))
    if (noRisk) {
      newWarning.controlMapping = `${noRisk.id}에 연결된 리스크가 없습니다.`
    } else {
      const noStaff = _.find(controls, (control) => _.isEmpty(control.staffIds))
      if (noStaff) {
        newWarning.controlMapping = `${noStaff.id}에 연결된 담당자가 없습니다.`
      }
    }

    setWarning({
      ...warning,
      ...newWarning
    })
  }, [cycles, processes, risks, controls])

  const renderTabName = React.useCallback(
    (name: string, active: boolean, warningMessage: string): React.ReactElement => {
      return (
        <>
          {warningMessage && <Typography.Text type="danger">*</Typography.Text>}
          <Typography.Text style={{ color: active ? lightBlue.color : undefined }}>
            {name}
          </Typography.Text>
        </>
      )
    },
    []
  )

  const getReferenceIds = React.useCallback(
    (target: BasePRC[], key: 'id' | 'name'): GetReferenceFunction => {
      return (
        cycle: string,
        category: string,
        subCategory: string | undefined
      ): Record<string, string> => {
        const filters: Record<string, string> = {
          cycleNumber: cycle,
          categoryNumber: category
        }
        if (subCategory) {
          filters.subCategoryNumber = subCategory
        }
        const results = _(target)
          .filter(filters)
          .reduce((result, value) => {
            result[value.id] = _.get(value, key)
            return result
          }, {} as Record<string, string>)
        return results
      }
    },
    []
  )

  const getSubReferenceIds = React.useCallback((target: BasePRC[], key: 'id') => {
    return (
      cycle: string,
      category: string,
      subCategory: string | undefined,
      processId: string
    ): Record<string, string> => {
      const filters: Record<string, string> = {
        cycleNumber: cycle,
        categoryNumber: category
      }
      if (subCategory) {
        filters.subCategoryNumber = subCategory
      }

      const results = _(target)
        .filter(filters)
        .reduce((result, value) => {
          if (_.get(value, 'processIds[0]') === processId) {
            result[value.id] = _.get(value, key)
          }
          return result
        }, {} as Record<string, string>)
      return results
    }
  }, [])

  return (
    <>
      <CardTabs defaultActiveKey={activeTab} onChange={(activeKey) => setActiveTab(activeKey)}>
        <Tabs.TabPane key="1" tab="분류">
          <CycleEditor
            cycles={cycles}
            loadCycles={() => LoadPRCData(['cycle'])}
            loading={states.loading}
            setCycles={(newData) => setCycles(newData)}
          />
        </Tabs.TabPane>
        <Tabs.TabPane key="2" tab={renderTabName('프로세스', activeTab === '2', warning.process)}>
          <PRCEditor
            cycles={cycles}
            data={processes}
            loadData={() => LoadPRCData(['process'])}
            loading={states.loading}
            setData={(newData) => setProcesses(newData)}
            type="process"
            warning={warning.process}
          />
        </Tabs.TabPane>
        <Tabs.TabPane key="3" tab={renderTabName('리스크', activeTab === '3', warning.risk)}>
          <PRCEditor
            cycles={cycles}
            data={risks}
            loadData={() => LoadPRCData(['risk'])}
            loading={states.loading}
            setData={(newData) => setRisks(newData)}
            type="risk"
            warning={warning.risk}
            getReferenceIds={getReferenceIds(processes, 'id')}
            getReferenceNames={getReferenceIds(processes, 'name')}
          />
        </Tabs.TabPane>
        <Tabs.TabPane key="4" tab={renderTabName('컨트롤', activeTab === '4', warning.control)}>
          <PRCEditor
            cycles={cycles}
            data={controls}
            loadData={() => LoadPRCData(['control'])}
            loading={states.loading}
            setData={(newData) => setControls(newData)}
            staff={staffs}
            type="control"
            warning={warning.control}
            getReferenceIds={getReferenceIds(processes, 'id')}
            getReferenceNames={getReferenceIds(processes, 'name')}
            getSubReferenceIds={getSubReferenceIds(risks, 'id')}
            getSubReferenceNames={getReferenceIds(risks, 'name')}
          />
        </Tabs.TabPane>
      </CardTabs>
      <AlertMessage ref={alertRef} />
    </>
  )
}

export default RCMEditor
