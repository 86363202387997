import {
  updateProgressState,
  saveEvaluationItem,
  updateWeakStateData
} from '@cck/backend/dist/evaluation/EvaluationUpdater'
import { progressStateToString } from '@cck/backend/dist/evaluation/EvaluationUtils'
import { getAll as LoadStaffs } from '@cck/backend/dist/rcm/StaffManager'
import { BaseFile } from '@cck/common/dist/data/BaseFile'
import * as Evaluation from '@cck/common/dist/data/Evaluation'
import { Staff } from '@cck/common/dist/data/Staff'
import { makeStyles, Theme } from '@material-ui/core'
import Button from 'antd/lib/button'
import Tabs from 'antd/lib/tabs'
import Typography from 'antd/lib/typography'
import _ from 'lodash'
import React from 'react'
import { useCookies } from 'react-cookie'

import AlertMessage, { AlertMessageHandler } from '../../common/AlertMessage'
import CardTabs from '../../common/CardTabs'
import CircleBackdrop from '../../common/CircleBackdrop'
import LoadingButton from '../../common/LoadingButton'
import ControlView from '../../rcm/common/ControlView'
import {
  EvaluationSelectAttribute,
  loadEvaluationDetails,
  loadEvaluationNames
} from '../common/EvaluationUtils'
import DesignEvaluationReportEditor from '../view/DesignEvaluationReportEditor'
import EvaluationSelectAccordion from '../view/EvaluationSelectAccordion'
import OperationEvaluationReportEditor from '../view/OperationEvaluationReportEditor'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    overflowY: 'auto',
    minHeight: 0
  },
  content: {
    display: 'flex',
    flexDirection: 'column'
  },
  subcontent: {
    padding: 0
  },
  editableTab: {
    padding: theme.spacing(1),
    flexDirection: 'column',
    '& .ant-tabs-tabpane': {
      padding: 0
    }
  },
  designEvaluationEditor: {
    padding: theme.spacing(2)
  },
  saveButtonContainer: {
    paddingBottom: theme.spacing(2),
    textAlign: 'end'
  },
  performedButton: {
    marginRight: theme.spacing(1)
  }
}))

export interface EvaluationReportEditorHandler {
  getNewEvaluation: () => {
    evaluationData: Evaluation.OperationEvaluationData | Evaluation.DesignEvaluationData
    deletedFiles: BaseFile[]
    newFiles: BaseFile[]
    weakState?: Evaluation.DesignWeakState
    weakStateData?: Evaluation.DesignDefect
  }
}

interface ReportProps {
  type: Evaluation.Type
  staffs: Staff[] | undefined
  evaluation: Evaluation.EvaluationItem | undefined
  unperformEvaluation: () => Promise<void>
  onSaveEvaluation: (
    evaluationData: Evaluation.OperationEvaluationData | Evaluation.DesignEvaluationData,
    deletedFiles: BaseFile[],
    newFiles: BaseFile[],
    progressState: Evaluation.ProgressState,
    weakState?: Evaluation.DesignWeakState,
    weakStateData?: Evaluation.DesignDefect
  ) => void
}

const EvaluationReport: React.FC<ReportProps> = ({
  type,
  staffs,
  evaluation,
  unperformEvaluation,
  onSaveEvaluation
}) => {
  const classes = useStyles()
  const editorRef = React.useRef<EvaluationReportEditorHandler>(null)
  if (!evaluation || !staffs || _.isEmpty(evaluation)) {
    return <div />
  }

  const saveButton = (
    <div>
      {evaluation.base.state === '1' && (
        <>
          <Typography.Text
            style={{
              marginRight: 4,
              visibility: _.includes(
                [Evaluation.ProgressState.Performed, Evaluation.ProgressState.Approved],
                evaluation?.state.state
              )
                ? 'visible'
                : 'hidden'
            }}
            type="danger"
          >
            {progressStateToString(evaluation.state.state)} 된 보고서는 저장할 수 없습니다.
          </Typography.Text>
          <LoadingButton
            className={classes.performedButton}
            disabled={_.includes([Evaluation.ProgressState.Approved], evaluation?.state.state)}
            onClick={() => {
              if (evaluation.state.state === Evaluation.ProgressState.Performed) {
                // 수행 완료 취소
                unperformEvaluation()
                return Promise.resolve()
              }

              if (editorRef?.current) {
                const { evaluationData, deletedFiles, newFiles, weakState, weakStateData } =
                  editorRef.current.getNewEvaluation()
                onSaveEvaluation(
                  evaluationData,
                  deletedFiles,
                  newFiles,
                  Evaluation.ProgressState.Performed,
                  weakState,
                  weakStateData
                )
              }
              return Promise.resolve()
            }}
            danger={evaluation?.state.state === Evaluation.ProgressState.Performed}
          >
            {evaluation?.state.state === Evaluation.ProgressState.Performed
              ? '수행완료 취소'
              : '수행완료'}
          </LoadingButton>
          <Button
            // 수행완료, 승인완료일 경우 저장 불가
            disabled={_.includes(
              [Evaluation.ProgressState.Performed, Evaluation.ProgressState.Approved],
              evaluation?.state.state
            )}
            type="primary"
            onClick={() => {
              if (editorRef?.current) {
                const { evaluationData, deletedFiles, newFiles, weakState, weakStateData } =
                  editorRef.current.getNewEvaluation()
                onSaveEvaluation(
                  evaluationData,
                  deletedFiles,
                  newFiles,
                  Evaluation.ProgressState.Performing,
                  weakState,
                  weakStateData
                )
              }
            }}
          >
            저장
          </Button>
        </>
      )}
      {evaluation.base.state === Evaluation.EvaluationState.Closed && (
        <Typography.Text style={{ marginRight: 4 }} type="danger">
          마감 된 {Evaluation.getKoEvaluation(type)}는 수정 할 수 없습니다.
        </Typography.Text>
      )}
    </div>
  )

  if (type === 'operation') {
    return (
      <CardTabs
        defaultActiveKey="1"
        overflow
        className={classes.editableTab}
        tabBarExtraContent={saveButton}
      >
        <Tabs.TabPane key="1" tab={Evaluation.getKoEvaluation('operation')}>
          <div className={classes.subcontent}>
            <OperationEvaluationReportEditor
              evaluation={evaluation}
              ref={editorRef}
              staffs={staffs || []}
            />
          </div>
        </Tabs.TabPane>
        <Tabs.TabPane key="2" tab="통제활동">
          <div className={classes.subcontent}>
            {evaluation?.content.control && (
              <ControlView control={evaluation.content.control} staffs={staffs || []} />
            )}
          </div>
        </Tabs.TabPane>
      </CardTabs>
    )
  }

  return (
    <div className={classes.designEvaluationEditor}>
      <div className={classes.saveButtonContainer}>{saveButton}</div>
      <DesignEvaluationReportEditor ref={editorRef} evaluation={evaluation} staffs={staffs || []} />
    </div>
  )
}

interface Props {
  type: Evaluation.Type
}

const EvaluationEditor: React.FC<Props> = ({ type }) => {
  const classes = useStyles()
  const [select, setSelect] = React.useState<EvaluationSelectAttribute>({
    loading: false,
    selectedName: Evaluation.BASE_ITEM,
    names: [],
    bases: [],
    items: []
  })
  const [states, setStates] = React.useState({
    selectedDetailId: '',
    saveLoading: false
  })
  const [staffs, setStaffs] = React.useState<Staff[]>([])
  const alertRef = React.useRef<AlertMessageHandler>(null)
  const [cookies, setCookie] = useCookies([`${type}EvaluationName`])

  React.useEffect(() => {
    LoadStaffs().then((newStaffs) => {
      setStaffs(newStaffs)
    })
  }, [])

  React.useEffect(() => {
    loadEvaluationNames(type, cookies[`${type}EvaluationName`], select, setSelect)
    setStates({ ...states, selectedDetailId: '' })
  }, [type])

  const onSaveEvaluationDetail = React.useCallback(
    (
      evaluationData: Evaluation.OperationEvaluationData | Evaluation.DesignEvaluationData,
      deletedFile: BaseFile[],
      newFiles: BaseFile[],
      progressState: Evaluation.ProgressState,
      weakState?: Evaluation.DesignWeakState,
      weakStateData?: Evaluation.DesignDefect
    ): void => {
      setStates({
        ...states,
        saveLoading: true
      })

      const updatePromise = [
        saveEvaluationItem(
          type,
          select.selectedName,
          select.items || [],
          states.selectedDetailId,
          evaluationData,
          deletedFile,
          newFiles,
          progressState
        )
      ]

      if (type === Evaluation.Type.Design && weakState && weakStateData) {
        updatePromise.push(
          updateWeakStateData(
            type,
            select.selectedName,
            states.selectedDetailId,
            weakState,
            weakStateData
          )
        )
      }

      Promise.all(updatePromise)
        .then(() => {
          loadEvaluationDetails(type, select.selectedName, select, setSelect)
          alertRef.current?.showAlert(
            'success',
            progressState === Evaluation.ProgressState.Performed ? '수행 완료' : '저장 성공'
          )
        })
        .catch((e) => {
          // eslint-disable-next-line no-console
          console.log('Failed to update ', e)
          if (e.message === 'not changed') {
            alertRef.current?.showAlert('warning', '변경 사항이 없음')
          } else if (e.message === 'empty header') {
            alertRef.current?.showAlert('warning', '샘플링 결과의 헤더에 빈칸을 채워주세요.')
          } else {
            alertRef.current?.showAlert('error', '저장 실패')
          }
        })
        .finally(() => {
          setStates({
            ...states,
            saveLoading: false
          })
        })
    },
    [states, select, alertRef]
  )

  const onEvaluationSelect = React.useCallback(
    (evaluationName: string): void => {
      loadEvaluationDetails(type, evaluationName, select, setSelect)
      setStates({ ...states, selectedDetailId: '' })
      setCookie(`${type}EvaluationName`, evaluationName)
    },
    [states, select]
  )

  const selectedEvaluation = React.useMemo(
    () => _.find(select.items, { controlId: states.selectedDetailId }),
    [select, states]
  )

  return (
    <div className={classes.root}>
      <EvaluationSelectAccordion
        type={type}
        staffs={staffs}
        select={select}
        selectedDetailId={states.selectedDetailId}
        onEvaluationSelect={onEvaluationSelect}
        onEvaluationDetailClick={(evaluationDetail: Evaluation.EvaluationItem) => {
          setStates({
            ...states,
            selectedDetailId: evaluationDetail.controlId
          })
        }}
      />
      <EvaluationReport
        type={type}
        staffs={staffs}
        evaluation={selectedEvaluation}
        unperformEvaluation={() =>
          updateProgressState(
            type,
            select.selectedName,
            [states.selectedDetailId],
            Evaluation.ProgressState.Performing
          )
            .then(() => {
              alertRef.current?.showAlert('success', '수행 완료가 취소되었습니다.')
              loadEvaluationDetails(type, select.selectedName, select, setSelect)
            })
            .catch(() => alertRef.current?.showAlert('error', '상태 변경이 실패했습니다.'))
        }
        onSaveEvaluation={onSaveEvaluationDetail}
      />
      <AlertMessage ref={alertRef} />
      <CircleBackdrop open={states.saveLoading} />
    </div>
  )
}

export default EvaluationEditor
