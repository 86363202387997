import Typography from '@material-ui/core/Typography'
import { makeStyles, Theme } from '@material-ui/core/styles'
import React from 'react'

const useStyles = makeStyles((theme: Theme) => ({
  meereLogin: {
    display: 'block',
    width: 190,
    height: 20
  },
  tmonCropLogin: {
    overflow: 'hidden',
    maxWidth: 120,
    maxHeight: 50
  },
  tmonLogo: {
    display: 'block',
    maxWidth: 'initial',
    maxHeight: 'initial',
    width: 120,
    height: 120,
    marginTop: '-40%',
    marginLeft: '-10%'
  },
  dayouLogin: {
    display: 'block',
    width: 100
  },
  meereLogo: {
    display: 'block',
    width: 190,
    height: 20,
    margin: theme.spacing(1, 2)
  },
  tmonCrop: {
    overflow: 'hidden',
    maxWidth: 120,
    maxHeight: 28,
    margin: theme.spacing(0.5, 2)
  },
  dayouLogo: {
    display: 'block',
    margin: theme.spacing(1, 2.5)
  },
  tpcLogo: {
    display: 'block'
  },
  helixmithLogo: { display: 'block' }
}))

export function getManualPath(): string {
  const manualDir = process.env.REACT_APP_MANUAL_DIR || 'manual'
  return `${process.env.PUBLIC_URL}/${manualDir}`
}

export function getLoginImage(): React.ReactElement | undefined {
  const classes = useStyles()
  if (process.env.REACT_APP_IMAGE_DIR === 'meere') {
    return (
      <img
        className={classes.meereLogin}
        src={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_IMAGE_DIR}/logo_white.svg`}
        alt="logo"
      />
    )
  } else if (process.env.REACT_APP_IMAGE_DIR === 'tmon') {
    return (
      <div className={classes.tmonCropLogin}>
        <img
          className={classes.tmonLogo}
          src={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_IMAGE_DIR}/logo.png`}
          alt="logo"
        />
      </div>
    )
  } else if (process.env.REACT_APP_IMAGE_DIR === 'dayouplus') {
    return (
      <img
        className={classes.dayouLogo}
        src={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_IMAGE_DIR}/logo_w.png`}
        alt="logo"
      />
    )
  } else if (process.env.REACT_APP_IMAGE_DIR === 'tpc') {
    return (
      <img
        className={classes.tpcLogo}
        src={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_IMAGE_DIR}/logo_2.png`}
        alt="logo"
      />
    )
  } else if (process.env.REACT_APP_IMAGE_DIR === 'helixmith') {
    return (
      <img
        className={classes.helixmithLogo}
        src={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_IMAGE_DIR}/logo.png`}
        alt="logo"
      />
    )
  }

  return (
    <Typography className="mt-32" color="inherit" variant="subtitle1">
      CCK Solution
    </Typography>
  )
}

export function getLogoImage(): React.ReactElement | undefined {
  const classes = useStyles()
  if (process.env.REACT_APP_IMAGE_DIR === 'meere') {
    return (
      <img
        className={classes.meereLogo}
        src={`${process.env.PUBLIC_URL}/meere/logo_orange.svg`}
        alt="logo"
      />
    )
  } else if (process.env.REACT_APP_IMAGE_DIR === 'tmon') {
    return (
      <div className={classes.tmonCrop}>
        <img
          className={classes.tmonLogo}
          src={`${process.env.PUBLIC_URL}/tmon/logo.png`}
          alt="logo"
        />
      </div>
    )
  } else if (process.env.REACT_APP_IMAGE_DIR === 'dayouplus') {
    return (
      <img
        className={classes.dayouLogo}
        src={`${process.env.PUBLIC_URL}/dayouplus/logo.gif`}
        alt="logo"
      />
    )
  } else if (process.env.REACT_APP_IMAGE_DIR === 'tpc') {
    return (
      <div style={{ margin: 8 }}>
        <img
          className={classes.tpcLogo}
          src={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_IMAGE_DIR}/logo_1.png`}
          alt="logo"
        />
      </div>
    )
  } else if (process.env.REACT_APP_IMAGE_DIR === 'helixmith') {
    return (
      <div style={{ margin: 8 }}>
        <img
          className={classes.helixmithLogo}
          src={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_IMAGE_DIR}/logo.png`}
          alt="logo"
        />
      </div>
    )
  }

  return undefined
}
