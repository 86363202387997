import Typography from '@material-ui/core/Typography'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { Row, Checkbox } from 'antd'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import React from 'react'

const useStyles = makeStyles((theme: Theme) => ({
  checkbox: {
    display: 'block',
    height: 30
  }
}))

interface Props {
  type: string
  cycle: boolean
  worker: boolean
  onChange: (name: 'cycle' | 'worker', checked: boolean) => void
}

const CategorySelector: React.FC<Props> = ({ type, cycle, worker, onChange }) => {
  const classes = useStyles()
  return (
    <div>
      <Typography noWrap variant="subtitle1">
        탐색할 항목 선택
      </Typography>
      <Checkbox.Group>
        <Row>
          <Checkbox
            checked={cycle}
            className={classes.checkbox}
            value="cycle"
            onChange={(e: CheckboxChangeEvent) => {
              onChange('cycle', e.target.checked)
            }}
          >
            분류
          </Checkbox>
        </Row>
        <Row>
          <Checkbox
            checked={worker}
            className={classes.checkbox}
            disabled={type !== 'control'}
            value="worker"
            onChange={(e: CheckboxChangeEvent) => {
              onChange('worker', e.target.checked)
            }}
          >
            담당자
          </Checkbox>
        </Row>
      </Checkbox.Group>
    </div>
  )
}

export default CategorySelector
