import {
  FolderOutlined,
  FolderOpenOutlined,
  FilePptOutlined,
  WarningOutlined,
  CheckOutlined
} from '@ant-design/icons'
import { BasePRC, PRCType } from '@cck/common/dist/data/PRC'
import { Staff } from '@cck/common/dist/data/Staff'
import { getDataTree, Node } from '@cck/common/dist/tree/Tree'
import _ from 'lodash'
import React from 'react'

const checkControlOption = (item: Node, controlOption: string): boolean => {
  if (item.type !== 'control') {
    return true
  }
  let keyControl = false
  if (item.keyControl) {
    keyControl = item.keyControl
  }
  return (
    controlOption === 'both' ||
    (controlOption === 'key control' && keyControl) ||
    (controlOption === 'except key control' && !keyControl)
  )
}

export const getTreeIcon = (
  expanded: boolean | undefined,
  type: string | undefined
): React.ReactElement => {
  if (type === 'process') {
    return <FilePptOutlined />
  } else if (type === 'risk') {
    return <WarningOutlined />
  } else if (type === 'control') {
    return <CheckOutlined />
  }
  return expanded ? <FolderOpenOutlined /> : <FolderOutlined />
}

export function applyIconAndControlOption(
  items: Node[],
  leafType: string,
  controlOption: string
): Node[] {
  const convertToDataNode = (item: Node): Node => {
    const newNode = _.cloneDeep(item)
    newNode.children = []

    if (item.children) {
      for (let i = 0; i < item.children?.length; ++i) {
        if (checkControlOption(item.children[i], controlOption)) {
          newNode.children.push(convertToDataNode(item.children[i]))
        }
      }
    }

    if (_.includes(['process', 'risk', 'control'], item.type)) {
      if (item.type === 'control' && item.keyControl) {
        newNode.title = `${item.id} (핵심)`
      } else {
        newNode.title = item.id
      }
      newNode.icon = getTreeIcon(false, item.type)
    } else {
      newNode.icon = ({ expanded }) => getTreeIcon(expanded, '')
    }
    return newNode
  }

  const nodes: Node[] = []
  for (let i = 0; i < items.length; ++i) {
    nodes.push(convertToDataNode(items[i]))
  }
  return [
    {
      key: 'root0',
      id: 'root0',
      title: leafType,
      icon: ({ expanded }) => getTreeIcon(expanded, ''),
      children: nodes,
      type: 'root'
    }
  ]
}

export function loadRCMTree(
  type: PRCType,
  keys: string[],
  controlOption: string,
  data: BasePRC[],
  cycles: { cycleNumber: string; categoryNumber: string }[],
  departments: { id: string }[],
  staffs: Staff[]
): Node[] {
  const items = getDataTree(keys, data, cycles, departments, staffs)
  return applyIconAndControlOption(items, type, controlOption)
}
