/* eslint-disable @typescript-eslint/no-explicit-any */
import { ColDef, ColGroupDef, GridApi, ColumnApi, AgGridEvent } from '@ag-grid-community/core'
import { AgGridReact, AgGridReactProps } from '@ag-grid-community/react'
import { AllModules } from '@ag-grid-enterprise/all-modules'
import { TableContent } from '@cck/common/dist/data/Evaluation'
import { makeStyles, Theme } from '@material-ui/core/styles'
import clsx from 'clsx'
import _ from 'lodash'
import React from 'react'

const useStyles = makeStyles((theme: Theme) => ({
  table: {
    '& .ag-cell': {
      lineHeight: 'normal',
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1)
    },
    '& .ag-cell-wrap-text': {
      wordBreak: 'keep-all'
    }
  },
  noHeader: {
    '& .ag-header': {
      border: 0
    }
  }
}))

function InitializeGrid(
  gridApi: GridApi,
  gridColumnApi: ColumnApi,
  data: any,
  isPopup: boolean | undefined
): void {
  gridApi.setRowData(data)
  const allColumnIds: string[] = []
  _.forEach(gridColumnApi.getAllColumns(), (column) => {
    allColumnIds.push(column.getColId())
  })
  gridColumnApi.autoSizeColumns(allColumnIds)
  if (isPopup) {
    gridApi.sizeColumnsToFit()
  }

  gridApi.resetRowHeights()
}

export interface TableContentProps extends AgGridReactProps {
  noHeader?: boolean
  wrapText?: boolean
  columnOptions?: Record<string, Record<string, any>>
}

export interface TableContentHandler {
  forceRefresh: () => void
}

interface Props {
  value?: TableContent
  onChange?: (value: TableContent) => void
  editable?: boolean
  gridOptions?: TableContentProps
  isPopup?: boolean
}

const TableContentView = React.forwardRef<TableContentHandler, Props>(
  ({ value, onChange, editable, gridOptions, isPopup }, viewerRef) => {
    const classes = useStyles()
    const [columns, setColumns] = React.useState<(ColDef | ColGroupDef)[]>()

    const [gridApi, setGridApi] = React.useState<GridApi>()
    const [gridColumnApi, setGridColumnApi] = React.useState<ColumnApi>()
    const onGridReady = (params: AgGridEvent): void => {
      setGridApi(params.api)
      setGridColumnApi(params.columnApi)
      setTimeout(() => {
        InitializeGrid(
          params.api,
          params.columnApi,
          _.take(value?.rowObjectList, value?.rowCountExceptHeader),
          isPopup
        )
      }, 500)
    }

    const { noHeader, wrapText, columnOptions, defaultColDef, ...restGridOptions } =
      gridOptions || {
        columnOptions: undefined,
        wrapText: false,
        noHeader: false,
        defaultColDef: {}
      }

    React.useImperativeHandle(viewerRef, () => ({
      forceRefresh() {
        if (gridApi) {
          gridApi.refreshCells({ force: true })
        }
      }
    }))

    React.useEffect(() => {
      const newColumns: (ColDef | ColGroupDef)[] = []
      _.forEach(value?.headerRow, (header) => {
        const index = header.indexOf('/')
        if (index > 0) {
          const superHeader = header.slice(0, index)
          const subHeader = header.slice(index + 1)
          const col = _.find(newColumns, { headerName: superHeader }) as ColGroupDef
          if (col) {
            col.children.push({
              headerName: subHeader,
              field: header,
              ...columnOptions?.[header]
            })
          } else {
            newColumns.push({
              headerName: superHeader,
              children: [
                {
                  headerName: subHeader,
                  field: header,
                  ...columnOptions?.[header]
                }
              ]
            })
          }
        } else {
          newColumns.push({
            headerName: header,
            field: header,
            ...columnOptions?.[header]
          })
        }
      })
      setColumns(newColumns)
    }, [value, columnOptions])

    React.useEffect(() => {
      if (gridApi && gridColumnApi) {
        setTimeout(
          () =>
            InitializeGrid(
              gridApi,
              gridColumnApi,
              _.take(value?.rowObjectList, value?.rowCountExceptHeader),
              isPopup
            ),
          100
        )
      }
    }, [value])

    return (
      <div
        className={clsx(wrapText ? 'ag-theme-wrap-text' : 'ag-theme-cck', classes.table, {
          [classes.noHeader]: noHeader
        })}
        style={{ width: '100%' }}
      >
        {!_.isEmpty(value?.headerRow) && (
          <AgGridReact
            {...restGridOptions}
            applyColumnDefOrder
            enableCellChangeFlash
            enableFillHandle
            enableRangeSelection
            undoRedoCellEditing
            columnDefs={columns}
            defaultColDef={{
              ...defaultColDef,
              wrapText: true,
              autoHeight: true,
              resizable: true,
              lockPosition: true,
              editable,
              cellEditor: 'agLargeTextCellEditor',
              cellEditorParams: {
                maxLength: '300',
                cols: '50',
                rows: '3'
              },
              filter: 'agSetColumnFilter',
              filterParams: {
                excelMode: 'windows' // can be 'windows' or 'mac'
              }
            }}
            domLayout="autoHeight"
            headerHeight={noHeader ? 0 : undefined}
            modules={AllModules}
            popupParent={document.querySelector('body') || undefined}
            processDataFromClipboard={(params) => {
              // For windows excel, ignore last empty line.
              const last = _.last(params.data)
              if (params.data.length > 1 && last?.length === 1 && _.isEmpty(last[0])) {
                return _.dropRight(params.data)
              }
              return params.data
            }}
            undoRedoCellEditingLimit={20}
            onCellEditingStopped={(event) => {
              const newValue = _.cloneDeep(value)
              const field = event.colDef.field
              if (newValue && onChange && field && _.isNumber(event?.rowIndex)) {
                newValue.rowObjectList[event.rowIndex][field] = event.newValue
                // If onChange the value, undo not working.
                onChange(newValue)
              }
              if (gridApi) {
                // If reset row heights, undo not working.
                gridApi.resetRowHeights()
              }
            }}
            onColumnResized={(params: any) => params.api.resetRowHeights()}
            onGridReady={onGridReady}
            onPasteEnd={(event) => {
              const rowData: any[] = []
              gridApi?.forEachNode((rowNode, index) => {
                rowData.push(rowNode.data)
              })

              const newValue = _.cloneDeep(value)
              if (newValue && onChange) {
                newValue.rowObjectList = rowData
                // If onChange the value, undo not working.
                onChange(newValue)
              }
              if (gridApi) {
                // If reset row heights, undo not working.
                gridApi.resetRowHeights()
              }
            }}
          />
        )}
      </div>
    )
  }
)

export default TableContentView
