import { getKoName } from '@cck/common/dist/data/Translation'
import { hasThreeCategories, noProcessName } from '@cck/common/dist/utils/Config'
import _ from 'lodash'
import React from 'react'

import TableItem from '../../../common/TableItem'
import SelectItem, { selectValidator } from './SelectItem'
import { FormTypes } from './Types'

export interface Props {
  type: 'process' | 'risk' | 'control'
  isNew: boolean
  formValues: FormTypes
  getReferenceIds?: (
    cycle: string,
    category: string,
    subCategory: string | undefined
  ) => Record<string, string>
}

const ProcessSelectFormItem: React.FC<Props> = ({ type, isNew, formValues, getReferenceIds }) => {
  if (type === 'process') {
    return <></>
  }

  const options = React.useMemo(() => {
    if (!getReferenceIds) {
      return {}
    }
    return getReferenceIds(
      formValues.cycle,
      formValues.category,
      hasThreeCategories() ? formValues.subCategory : undefined
    )
  }, [getReferenceIds, formValues])

  return (
    <>
      <SelectItem
        required
        dataKey="processId"
        disabled={
          _.isEmpty(formValues.cycle) ||
          _.isEmpty(formValues.category) ||
          (hasThreeCategories() && _.isEmpty(formValues.subCategory)) ||
          !isNew
        }
        name={getKoName('process', 'id')}
        options={options}
        rules={[() => ({ validator: selectValidator(`${getKoName('process', 'id')}를`) })]}
      />
      <TableItem name={getKoName('process', noProcessName() ? 'narrative' : 'name')} minHeight={48}>
        {formValues.processName}
      </TableItem>
    </>
  )
}

export default ProcessSelectFormItem
