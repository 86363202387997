/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme
} from '@material-ui/core'
import { Button, Image } from 'antd'
import _ from 'lodash'
import React from 'react'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import rehypeSlug from 'rehype-slug'
import remarkGfm from 'remark-gfm'
import './manual.css'

import { lightBlue } from '../base/color'
import { getManualPath } from '../base/utils/ConfigUtils'

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    display: 'grid',
    gridTemplateColumns: '300px 1fr',
    '@media print': {
      gridTemplateColumns: '1fr'
    }
  },
  navContainer: {
    top: 0,
    position: 'sticky',
    alignSelf: 'start',
    flexDirection: 'column',
    minWidth: '20em',
    background: 'rgba(245,247,249,1.00)',
    borderRight: '1px solid rgba(211,220,228,1.00)',
    '@media print': {
      display: 'none'
    }
  },
  nav: {
    padding: theme.spacing(1),
    height: '100vh',
    maxHeight: '100vh',
    overflow: 'auto'
  },
  markdown: {
    margin: theme.spacing(10),
    maxWidth: 1200
  },
  pageBreak: {
    pageBreakAfter: 'always'
  },
  a: {
    color: '#666',
    '&:hover': {
      color: lightBlue.color
    }
  },
  ul: {
    listStyle: 'none',
    margin: 0,
    padding: 0
  },
  li: {
    listStyle: 'none',
    margin: 0,
    marginLeft: theme.spacing(1),
    padding: 0
  },
  h1: {
    scrollMarginTop: '16px',
    border: '0 solid black',
    borderBottomColor: 'rgba(227, 232, 237, 1.00)',
    borderBottomWidth: 1,
    fontSize: 'xx-large',
    fontWeight: 'bold'
  },
  h2: {
    scrollMarginTop: '16px',
    fontWeight: 'bold'
  },
  p: {
    lineHeight: '24px',
    paddingBottom: 2
  },
  button: {
    display: 'flex',
    marginLeft: theme.spacing(1)
  },
  table: {
    border: '1px solid #E0DFE0',
    marginBottom: theme.spacing(1)
  },
  tablehead_cell: {
    backgroundColor: 'rgba(0, 0, 0, 0.04)'
  }
}))

function updatePath(manualPath: string, src: string | undefined): string {
  if (!src) return ''

  let path = manualPath
  if (!_.endsWith(path, '/')) {
    path += '/'
  }

  if (_.startsWith(src, '/')) {
    path += _.slice(src, 1)
  } else {
    path += src
  }
  return path
}

function extractNavFromMarkdown(markdown: string): { nav: string; other: string } {
  const start = markdown.indexOf('<nav>')
  const end = markdown.indexOf('</nav>') + '</nav>'.length

  if (start === -1 || end === -1) {
    return { nav: '', other: markdown }
  }

  const nav = markdown.slice(start, end)
  const other = markdown.substring(0, start) + markdown.substring(end)
  return { nav, other }
}

// NOTE: useState를 사용하면 IntersectionObserver가 동작을 하지않아 dom 바로 조작
// NOTE: This was modified from below url.
// https://www.emgoto.com/react-table-of-contents/#create-a-hook-to-find-all-headings-on-the-page
function useIntersectionObserver(contents: string) {
  const headingElementsRef = React.useRef<Record<string, any>>({})
  React.useEffect(() => {
    const headingElements = Array.from(document.querySelectorAll('h1, h2'))
    const callback = (headings: any) => {
      headingElementsRef.current = headings.reduce((map: any, headingElement: any) => {
        map[headingElement.target.id] = headingElement
        return map
      }, headingElementsRef.current)

      if (_.some(_.map(headingElementsRef.current, (element) => element.isIntersecting))) {
        _.forEach(headingElementsRef.current, (element) => {
          const encodedId = encodeURIComponent(element.target.id)
          const parent = document.querySelector(`nav li a[href="#${encodedId}"]`)?.parentElement
          if (element.isIntersecting) {
            parent?.classList.add('active')
          } else {
            parent?.classList.remove('active')
          }
        })
      }
    }

    // eslint-disable-next-line no-undef
    const observer = new IntersectionObserver(callback, {
      rootMargin: '0px 0px -20% 0px'
    })
    headingElements.forEach((element) => observer.observe(element))

    return () => observer.disconnect()
  }, [contents])
}

const Manual: React.FC = () => {
  const classes = useStyles()
  const [text, setText] = React.useState('')
  const [toc, setToc] = React.useState('')

  const manualPath = React.useMemo(() => getManualPath(), [])
  useIntersectionObserver(text)

  React.useEffect(() => {
    // eslint-disable-next-line no-undef
    fetch(updatePath(manualPath, 'manual.md'))
      .then((response) => response.text())
      .then((newText) => {
        const { nav, other } = extractNavFromMarkdown(newText)
        setToc(nav)
        setText(other)
      })
  }, [])

  return (
    <div className={classes.main}>
      <ReactMarkdown
        className={classes.navContainer}
        remarkPlugins={[remarkGfm]}
        rehypePlugins={[rehypeRaw, rehypeSlug]}
        components={{
          li: (props) => {
            return <li className={classes.li} {...props} />
          },
          ul: ({ depth, ...props }) => {
            if (depth < 2) {
              return <ul className={classes.ul} {...props} />
            }
            return null
          },
          a: ({ href, ...props }) => {
            if (!href) {
              return <a className={classes.a} {...props} />
            }

            return (
              <a
                className={classes.a}
                href={href}
                onClick={(event) => {
                  event.preventDefault()
                  document
                    .querySelector(decodeURIComponent(href))
                    ?.scrollIntoView({ behavior: 'smooth' })
                }}
                {...props}
              />
            )
          },
          nav: (props) => <nav className={classes.nav} {...props} />
        }}
      >
        {toc}
      </ReactMarkdown>
      <ReactMarkdown
        className={classes.markdown}
        remarkPlugins={[remarkGfm]}
        rehypePlugins={[rehypeRaw, rehypeSlug]}
        components={{
          li: (props) => <li className={classes.p} {...props} />,
          h1: (props) => {
            if (_.has(props, 'style') && _.get(props, 'style.fontSize') === 'xx-large') {
              return <h1 className={classes.h1} {...props} />
            }
            return (
              <>
                <div className={classes.pageBreak}></div>
                <h1 className={classes.h1} {...props} />
              </>
            )
          },
          h2: (props) => <h2 className={classes.h2} {...props} />,
          button: ({ type, ...props }) => (
            <Button type="default" className={classes.button} {...props} />
          ),
          img: ({ src, alt, ...props }) => <Image src={updatePath(manualPath, src)} alt={alt} />,
          table: (props) => <Table className={classes.table}>{_.get(props, 'children')}</Table>,
          thead: (props) => <TableHead>{_.get(props, 'children')}</TableHead>,
          tbody: (props) => <TableBody>{_.get(props, 'children')}</TableBody>,
          th: (props) => (
            <TableCell
              className={classes.tablehead_cell}
              align={_.get(props.node, 'properties.align')}
            >
              {_.get(props, 'children')}
            </TableCell>
          ),
          tr: (props) => <TableRow>{_.get(props, 'children')}</TableRow>,
          td: (props) => (
            <TableCell align={_.get(props.node, 'properties.align')}>
              {_.get(props, 'children')}
            </TableCell>
          )
        }}
      >
        {text}
      </ReactMarkdown>
    </div>
  )
}

export default Manual
