/* eslint-disable @typescript-eslint/no-explicit-any */
import { ColDef } from '@ag-grid-community/core'
import {
  getFileListForSaving,
  getFileNameWithoutPrefix
} from '@cck/backend/dist/evaluation/EvaluationUtils'
import { BaseFile, FileState, FileType } from '@cck/common/dist/data/BaseFile'
import * as Evaluation from '@cck/common/dist/data/Evaluation'
import { getStaffNameAndEmailById, Staff } from '@cck/common/dist/data/Staff'
import { getKoName } from '@cck/common/dist/data/Translation'
import { parseDateStr, parseTimeStr } from '@cck/common/dist/utils/TimeUtils'
import Divider from '@material-ui/core/Divider'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Form from 'antd/lib/form'
import Input from 'antd/lib/input'
import InputNumber from 'antd/lib/input-number'
import Typography from 'antd/lib/typography'
import _ from 'lodash'
import React from 'react'

import { grey } from '../../../base/color'
import TableContentView, { TableContentProps } from '../../common/TableContentView'
import TableHeader from '../../common/TableHeader'
import TableItem, { ComplexTableItem } from '../../common/TableItem'
import {
  adjustAlphabetHeaders,
  changeHeaders,
  changeNumberOfRow,
  convertDynamicHeaderTable,
  initTable
} from '../../common/TableUtils'
import agMultiSelectCellEditor from '../../common/agMultiSelectCellEditor'
import { modifyOperationEvaluationForSaving } from '../common/EvaluationUtils'
import { EvaluationReportEditorHandler } from '../report/EvaluationEditor'
import EvaluationFileUploader from './EvaluationFileUploader'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    border: grey.border,
    margin: theme.spacing(2)
  },
  stringText: {
    whiteSpace: 'break-spaces'
  },
  table: {
    marginBottom: 0,
    padding: theme.spacing(2)
  },
  form: {
    marginBottom: 0,
    width: '100%'
  },
  description: {
    margin: theme.spacing(0, 2, 2, 2)
  }
}))

interface ItemProps {
  name: string
  koName?: string
  noDivider?: boolean
  noHeader?: boolean
  gridOptions?: TableContentProps
  type?: 'string' | 'table' | 'number'
  placeholder?: string
  description?: string
}

const ItemRow: React.FC<ItemProps> = ({
  name,
  koName,
  noDivider,
  gridOptions,
  type = 'string',
  placeholder,
  description
}) => {
  const classes = useStyles()
  if (type === 'table') {
    return (
      <>
        <Form.Item className={classes.table} name={name}>
          <TableContentView editable gridOptions={gridOptions} />
        </Form.Item>
        {!_.isEmpty(description) && (
          <div className={classes.description}>
            <Typography.Text type="secondary">{description}</Typography.Text>
          </div>
        )}
        <Divider />
      </>
    )
  }

  return (
    <TableItem name={koName || getKoName('control', name)} noDivider={noDivider}>
      {type === 'number' && (
        <Form.Item className={classes.form} name={name}>
          <InputNumber min={1} />
        </Form.Item>
      )}
      {type === 'string' && (
        <Form.Item className={classes.form} name={name}>
          <Input placeholder={placeholder} />
        </Form.Item>
      )}
    </TableItem>
  )
}

function initEvaluation(evaluation: Evaluation.OperationEvaluationData): void {
  if (_.isNaN(evaluation.samplingCount)) {
    evaluation.samplingCount = 1
  }
  if (_.isNaN(evaluation.samplingAttributeCount)) {
    evaluation.samplingAttributeCount = 1
  }
  if (_.isNaN(evaluation.populationTableColumnCount)) {
    evaluation.populationTableColumnCount = 1
  }

  initTable(evaluation.samplingResult, ['No', ''])
  initTable(evaluation.attributeContents, ['Attribute', '테스트 상세내용', '예외사항 정의'])
  initTable(evaluation.testContents, [
    'No',
    'Attribute_1/결과',
    'Attribute_1/상세',
    '테스트 결과',
    '증빙자료 Ref'
  ])
  convertDynamicHeaderTable(evaluation.samplingResult)
}

interface Props {
  evaluation: Evaluation.EvaluationItem
  staffs: Staff[]
}

const OperationEvaluationReportEditor = React.forwardRef<EvaluationReportEditorHandler, Props>(
  ({ evaluation, staffs }, editorRef) => {
    const classes = useStyles()
    const [states, setStates] = React.useState({
      controlValidCount: 0,
      controlInvalidCount: 0
    })
    const [form] = Form.useForm()
    const [files, setFiles] = React.useState<BaseFile[]>([])
    const evalData = React.useMemo(
      () => evaluation.content.data as Evaluation.OperationEvaluationData,
      [evaluation]
    )

    const getNumberFromForm = React.useCallback(
      (path) => {
        const value = _.parseInt(form.getFieldValue(path) || '0')
        return value && value > 0 ? value : 1
      },
      [form]
    )

    React.useImperativeHandle(editorRef, () => ({
      getNewEvaluation() {
        const { deletedFiles, newFiles } = getFileListForSaving(evaluation, files)
        const newOperationEvaluationData = _.cloneDeep(
          form.getFieldsValue()
        ) as Evaluation.OperationEvaluationData
        newOperationEvaluationData.type = Evaluation.Type.Operation
        newOperationEvaluationData.controlId = evaluation.controlId
        newOperationEvaluationData.invalidCount = states.controlInvalidCount.toString()
        newOperationEvaluationData.validCount = states.controlValidCount.toString()
        newOperationEvaluationData.samplingCount = getNumberFromForm('samplingCount')
        newOperationEvaluationData.populationTableColumnCount =
          getNumberFromForm('populationTableCount')
        newOperationEvaluationData.samplingAttributeCount =
          getNumberFromForm('samplingAttributeCount')
        modifyOperationEvaluationForSaving(newOperationEvaluationData)
        return {
          evaluationData: newOperationEvaluationData,
          deletedFiles,
          newFiles
        }
      }
    }))

    const updateValidCount = React.useCallback(
      (testContents?: Evaluation.TableContent): void => {
        if (!testContents) return

        let controlValidCount = 0
        let controlInvalidCount = 0
        for (let i = 0; i < testContents.rowCountExceptHeader; ++i) {
          const testResultKey = _.nth(testContents.headerRow, -2)
          if (testResultKey && testContents.rowObjectList[i][testResultKey] === '유효함') {
            controlValidCount += 1
          } else {
            controlInvalidCount += 1
          }
        }
        setStates({ ...states, controlValidCount, controlInvalidCount })
        form.setFieldsValue({ controlValidCount, controlInvalidCount })
      },
      [form, states]
    )

    React.useEffect(() => {
      form.resetFields()
      const newEvaluationData = _.cloneDeep(evalData)
      initEvaluation(newEvaluationData)
      updateValidCount(newEvaluationData.testContents)
      form.setFieldsValue(newEvaluationData)
    }, [evalData])

    const firstColumnOptions = {
      resizable: false,
      editable: false,
      cellStyle: { backgroundColor: '#f8f8f8' }
    }

    const onFormChange = React.useCallback(
      (formName, info) => {
        const values: Record<string, any> = {}
        _.forEach(info.changedFields, ({ name, value }) => {
          const filedPath = Array.isArray(name) ? _.join(name, '/') : name
          values[filedPath] = value
        })
        _.forEach(values, (value, name) => {
          if (name === 'samplingCount') {
            const samplingCount = parseInt(value)
            const { samplingResult, testContents } = form.getFieldsValue([
              'samplingResult',
              'testContents'
            ])
            changeNumberOfRow(samplingResult, samplingCount + 1)
            changeNumberOfRow(testContents, samplingCount)
            form.setFieldsValue({
              samplingResult,
              testContents
            })
            updateValidCount(testContents)
          } else if (name === 'populationTableColumnCount') {
            const populationTableColumnCount = parseInt(value)
            const { samplingResult } = form.getFieldsValue(['samplingResult'])
            adjustAlphabetHeaders(samplingResult, populationTableColumnCount)
            form.setFieldsValue({ samplingResult })
          } else if (name === 'samplingAttributeCount') {
            const samplingAttributeCount = parseInt(value)
            const { attributeContents, testContents } = form.getFieldsValue([
              'attributeContents',
              'testContents'
            ])
            changeNumberOfRow(attributeContents, samplingAttributeCount)
            const headers: string[] = []
            for (let i = 1; i < samplingAttributeCount + 1; ++i) {
              headers.push('Attribute_' + i + '/결과')
              headers.push('Attribute_' + i + '/상세')
            }
            changeHeaders(testContents, [
              _.nth(testContents.headerRow, 0),
              ...headers,
              _.nth(testContents.headerRow, -2),
              _.nth(testContents.headerRow, -1)
            ])
            form.setFieldsValue({
              attributeContents,
              testContents
            })
          } else if (name === 'testContents') {
            updateValidCount(value)
          }
        })
      },
      [form]
    )

    return (
      <div className={classes.root}>
        <Form.Provider onFormChange={onFormChange}>
          <Form form={form} name="ReportEditor">
            <TableHeader value="Test Info" />
            <ComplexTableItem name={getKoName('control', 'number')} value={evaluation.controlId} />
            <ComplexTableItem
              name={getKoName('control', 'period')}
              value={evaluation.base.period}
            />
            <ComplexTableItem
              name={getKoName('control', 'inchargeInRCM')}
              value={getStaffNameAndEmailById(staffs, evaluation.content.control.incharge)}
            />
            <ComplexTableItem
              name={getKoName('control', 'ownerInRCM')}
              value={getStaffNameAndEmailById(staffs, evaluation.content.control.owner)}
            />
            <ComplexTableItem
              name={getKoName('control', 'createDate')}
              value={parseDateStr('YYYY-MM-DD', evalData.createDate)}
            />
            <ComplexTableItem
              name={getKoName('control', 'updateTime')}
              value={parseTimeStr(evalData.updateTime)}
            />
            <EvaluationFileUploader
              staffs={staffs}
              evaluation={evaluation}
              files={files}
              setFiles={setFiles}
              type="operation"
              updateEvaluation={(excelData: any) => {
                form.resetFields()
                const newEvaluationData = _.cloneDeep(evalData)
                modifyOperationEvaluationForSaving(newEvaluationData)
                _.assign(newEvaluationData, _.omit(excelData, ['controlID']))
                initEvaluation(newEvaluationData)
                updateValidCount(newEvaluationData.testContents)
                form.setFieldsValue(newEvaluationData)
              }}
            />
            <div className={classes.description}>
              <Typography.Text type="secondary">
                업로드하려는 파일을 상단의 박스로 끌어 놓기
              </Typography.Text>
            </div>
            <Divider />
            <TableHeader value="모집단 정보" />
            <ItemRow
              name="populationDescription"
              placeholder="모집단 파일을 업로드 할 경우 자동으로 파일명이 기재되며, 별도 파일을 업로드 하지 않을 경우 모집단 관련 설명을 기재하여야 함."
            />
            <ItemRow name="populationCount" type="number" />
            <ItemRow name="populationCompleteness" />
            <ItemRow name="samplingMethod" />
            <ItemRow koName="샘플링 항목수(행)" name="samplingCount" type="number" />
            <ItemRow koName="항목당 속성수(열)" name="populationTableColumnCount" type="number" />
            <TableHeader value="샘플링 결과" />
            <ItemRow
              gridOptions={{
                columnOptions: {
                  A: { ...firstColumnOptions, width: 120 }
                }
              }}
              name="samplingResult"
              type="table"
            />
            <TableHeader value="테스트 수행 기준" />
            <ItemRow name="samplingAttributeCount" type="number" />
            <ItemRow
              gridOptions={{
                columnOptions: {
                  Attribute: { ...firstColumnOptions, width: 120 }
                }
              }}
              name="attributeContents"
              type="table"
            />
            <TableHeader value="테스트 수행 내역" />
            <ItemRow
              description="증빙자료 Ref는 증빙 파일 중 선택하여야 합니다."
              gridOptions={{
                wrapText: true,
                frameworkComponents: { agMultiSelectCellEditor },
                columnOptions: {
                  No: { ...firstColumnOptions, width: 80 },
                  ..._.reduce(
                    _.filter(evalData.testContents?.headerRow, (header) =>
                      _.endsWith(header, '결과')
                    ),
                    (result, header) => {
                      result[header] = { width: 120, resizable: false }
                      return result
                    },
                    {} as Record<string, ColDef>
                  ),
                  '테스트 결과': {
                    suppressPaste: true,
                    width: 120,
                    resizable: false,
                    cellEditor: 'agRichSelectCellEditor',
                    cellEditorParams: { values: ['유효함', '미비사항 발견'] }
                  },
                  '증빙자료 Ref': {
                    suppressPaste: true,
                    cellEditor: 'agMultiSelectCellEditor',
                    cellEditorParams: {
                      values: _.compact(
                        _.map(files, (file) => {
                          if (
                            file.type === FileType.Evidence &&
                            _.includes(
                              [FileState.OK, FileState.ALREADY_EXISTING, FileState.SAVED_FILE],
                              file.state
                            )
                          ) {
                            return getFileNameWithoutPrefix(
                              evaluation.base.name,
                              evaluation.controlId,
                              file.name
                            )
                          }
                          return null
                        })
                      )
                    }
                  }
                }
              }}
              name="testContents"
              type="table"
            />
            {/* <ItemRow noDivider name='controlException' /> */}
            <TableHeader value="결과정리" />
            <ItemRow name="populationCount" type="number" />
            <ItemRow name="samplingCount" type="number" />
            <TableItem name={getKoName('control', 'validCount')}>
              <Typography className={classes.stringText}>{states.controlValidCount}</Typography>
            </TableItem>
            <TableItem name={getKoName('control', 'invalidCount')}>
              <Typography className={classes.stringText}>{states.controlInvalidCount}</Typography>
            </TableItem>
            <ItemRow noDivider name="testResult" />
          </Form>
        </Form.Provider>
      </div>
    )
  }
)

export default OperationEvaluationReportEditor
