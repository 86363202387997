import { AgGridReact } from '@ag-grid-community/react'
import { AgGridEvent, AllModules, ColumnApi, GridApi } from '@ag-grid-enterprise/all-modules'
import {
  DesignDefect,
  DesignDefectItem,
  DesignWeakState,
  EvaluationItem,
  ItemState,
  OperationDefect,
  OperationWeakState
} from '@cck/common/dist/data/Evaluation'
import { getStaffNameAndEmailById, Staff } from '@cck/common/dist/data/Staff'
import { getKoName } from '@cck/common/dist/data/Translation'
import { parseTimeStr } from '@cck/common/dist/utils/TimeUtils'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Button from 'antd/lib/button'
import Typography from 'antd/lib/typography'
import clsx from 'clsx'
import _ from 'lodash'
import React from 'react'

import { grey } from '../../../base/color'
import TableHeader from '../../common/TableHeader'
import TableItem from '../../common/TableItem'
import {
  CheckboxCellRenderer,
  Row,
  SwitchCellRenderer,
  checkEmptyReport,
  getDesignEvaluationColumns,
  getCellStyle,
  getTableData
} from '../common/DesignDefectUtils'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: theme.spacing(2),
    border: grey.border,
    borderBottom: 0
  },
  saveButton: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    margin: theme.spacing(1),
    justifyContent: 'flex-end'
  },
  content: {
    overflowY: 'auto',
    padding: 0,
    flexGrow: 1
  },
  editableContent: {
    border: grey.border
  },
  description: {
    margin: theme.spacing(2),
    border: grey.border
  },
  performedButton: {
    marginRight: theme.spacing(1)
  }
}))

interface Props {
  editable?: boolean
  staffs: Staff[]
  evaluation: EvaluationItem
  itemState: ItemState
  onSaveEvaluationDefect: (
    weakState: OperationWeakState | DesignWeakState,
    weakStateData: OperationDefect | DesignDefect
  ) => Promise<void>
}

const DesignEvaluationDefectView: React.FC<Props> = ({
  editable,
  staffs,
  evaluation,
  itemState,
  onSaveEvaluationDefect
}) => {
  const classes = useStyles()
  const reportContainerRef = React.useRef<HTMLDivElement>(null)
  const [infoGridApi, setInfoGridApi] = React.useState({
    gridApi: {} as GridApi,
    gridColumnApi: {} as ColumnApi
  })
  const [methodGridApi, setMethodGridApi] = React.useState({
    gridApi: {} as GridApi,
    gridColumnApi: {} as ColumnApi
  })

  const [infoData, setInfoData] = React.useState<Row[]>([])
  const [methodData, setMethodData] = React.useState<Row[]>([])
  const [emptyCheck, setEmptyCheck] = React.useState({
    infoEmpty: true,
    methodEmpty: true
  })

  const prepareDataFromDesignDefect = React.useCallback(
    (newDesignDefect: DesignDefect): void => {
      const {
        infoData: newInfoData,
        methodData: newMethodData,
        infoEmpty: newInfoEmpty,
        methodEmpty: newMethodEmpty
      } = getTableData(
        evaluation.content.risk,
        evaluation.content.control,
        newDesignDefect,
        !!editable
      )
      setInfoData(newInfoData)
      setMethodData(newMethodData)
      setEmptyCheck({
        infoEmpty: newInfoEmpty,
        methodEmpty: newMethodEmpty
      })
    },
    [editable, evaluation]
  )

  React.useEffect(() => {
    if (!_.isEmpty(infoGridApi.gridApi)) {
      const params = { force: true, suppressFlash: false }
      infoGridApi.gridApi.refreshCells(params)
    }
  }, [infoData])
  React.useEffect(() => {
    if (!_.isEmpty(methodGridApi.gridApi)) {
      const params = { force: true, suppressFlash: false }
      methodGridApi.gridApi.refreshCells(params)
    }
  }, [methodData])

  const designDefect = React.useMemo(() => itemState.weakStateData as DesignDefect, [itemState])
  React.useEffect(() => {
    prepareDataFromDesignDefect(designDefect)
    if (reportContainerRef.current) {
      reportContainerRef.current.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    }
  }, [designDefect])

  const saveDesignDefect = React.useCallback(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const assignResults = (result: DesignDefect, row: any): void => {
      if (row.dataId) {
        let content = row.content || ''
        // NOTE: Yes/No인 경우에, evidence만 있을 경우 자동으로 No를 추가한다.
        if (
          _.isEmpty(content) &&
          _.includes(['booleanSelector', 'yesNoSelector'], row.section) &&
          !_.isEmpty(row.evidence)
        ) {
          content = 'No'
        }

        _.set(result, row.dataId, {
          content,
          evidence: row.evidence || ''
        })
      }
    }

    const newEvaluationDefect = _.cloneDeep(designDefect)
    infoGridApi.gridApi.forEachNode((node) => {
      assignResults(newEvaluationDefect, node.data)
    })
    methodGridApi.gridApi.forEachNode((node) => {
      assignResults(newEvaluationDefect, node.data)
    })

    const checkNotChanged = (row: string | DesignDefectItem) => {
      return (
        !_.has(row, 'content') || (_.get(row, 'content') === '' && _.get(row, 'evidence') === '')
      )
    }
    const notChanged =
      _.every(newEvaluationDefect.control, (row) => checkNotChanged(row)) &&
      _.every(newEvaluationDefect.risk, (row) => checkNotChanged(row))
    const weakState = notChanged ? DesignWeakState.Done : DesignWeakState.Modification
    onSaveEvaluationDefect(weakState, newEvaluationDefect)
  }, [designDefect, infoGridApi, methodGridApi, onSaveEvaluationDefect])

  return (
    <>
      {editable && (
        <div className={classes.saveButton}>
          {/* TODO(sangmuk): 설계평가가 마무리 된 이후에는 수정 불가능하도록 변경 */}
          <Button type="primary" onClick={() => saveDesignDefect()}>
            저장
          </Button>
        </div>
      )}
      <div
        className={clsx(classes.content, { [classes.editableContent]: editable })}
        ref={reportContainerRef}
      >
        <div className={classes.description}>
          <TableItem name={getKoName('control', 'id')}>
            <Typography.Text>{evaluation.controlId}</Typography.Text>
          </TableItem>
          <TableItem name={getKoName('control', 'period')}>
            <Typography.Text>{evaluation.base.period}</Typography.Text>
          </TableItem>
          <TableItem name={getKoName('control', 'controlIncharge')}>
            <Typography.Text>
              {getStaffNameAndEmailById(staffs, evaluation.content.control.incharge)}
            </Typography.Text>
          </TableItem>
          <TableItem name={getKoName('control', 'controlOwner')}>
            <Typography.Text>
              {getStaffNameAndEmailById(staffs, evaluation.content.control.owner)}
            </Typography.Text>
          </TableItem>
          <TableItem name={getKoName('control', 'createDate')}>
            <Typography.Text>{parseTimeStr(evaluation.content.data.createDate)}</Typography.Text>
          </TableItem>
          <TableItem name={getKoName('control', 'updateTime')} noDivider>
            <Typography.Text>{parseTimeStr(evaluation.content.data.updateTime)}</Typography.Text>
          </TableItem>
        </div>
        <div className={classes.root}>
          <TableHeader value="통제 정보 업데이트" />
          <div className="ag-theme-design-evaluation">
            <AgGridReact
              enableRangeSelection
              undoRedoCellEditing
              columnDefs={getDesignEvaluationColumns(staffs)}
              defaultColDef={{
                menuTabs: [],
                wrapText: true,
                autoHeight: true,
                resizable: true,
                lockPosition: true,
                suppressSizeToFit: true,
                cellStyle: getCellStyle
              }}
              domLayout="autoHeight"
              frameworkComponents={{ CheckboxCellRenderer, SwitchCellRenderer }}
              modules={AllModules}
              rowData={infoData}
              onCellValueChanged={(event) => {
                if (event.type === 'cellValueChanged' && event.data?.dataId === 'control.owner') {
                  const staff = _.find(staffs, { id: event.newValue })
                  if (staff) {
                    event.api.forEachNode((rowNode) => {
                      if (rowNode.data?.dataId === 'control.department') {
                        rowNode.setDataValue('content', staff.departmentName)
                      }
                    })
                  }
                }
                event.api.resetRowHeights()
                setEmptyCheck({
                  ...emptyCheck,
                  infoEmpty: checkEmptyReport(event.api)
                })
              }}
              onGridReady={(params: AgGridEvent): void => {
                setInfoGridApi({ gridApi: params.api, gridColumnApi: params.columnApi })
              }}
            />
          </div>
        </div>
        <div className={classes.root}>
          <TableHeader value="통제 평가 방법 업데이트" />
          <div className="ag-theme-design-evaluation">
            <AgGridReact
              enableRangeSelection
              undoRedoCellEditing
              columnDefs={getDesignEvaluationColumns(staffs)}
              defaultColDef={{
                menuTabs: [],
                wrapText: true,
                autoHeight: true,
                resizable: true,
                lockPosition: true,
                suppressSizeToFit: true,
                cellStyle: getCellStyle
              }}
              domLayout="autoHeight"
              frameworkComponents={{ CheckboxCellRenderer, SwitchCellRenderer }}
              modules={AllModules}
              rowData={methodData}
              onCellValueChanged={(event) => {
                event.api.resetRowHeights()
                setEmptyCheck({
                  ...emptyCheck,
                  methodEmpty: checkEmptyReport(event.api)
                })
              }}
              onGridReady={(params: AgGridEvent): void => {
                setMethodGridApi({ gridApi: params.api, gridColumnApi: params.columnApi })
              }}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default DesignEvaluationDefectView
