import { LicenseManager } from '@ag-grid-enterprise/core'
import firebase from '@cck/backend/dist/firebase/fbase'
import { getOrCreateUser } from '@cck/backend/dist/utils/Admin'
import { Level } from '@cck/common/dist/data/Staff'
import { makeStyles } from '@material-ui/core'
import CssBaseline from '@material-ui/core/CssBaseline'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import { ConfigProvider } from 'antd'
import locale from 'antd/lib/locale/ko_KR'
import _ from 'lodash'
import 'moment/locale/ko'
import React, { ReactElement } from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  RouteComponentProps,
  useLocation,
  RouteProps
} from 'react-router-dom'

import { lightBlue } from '../base/color'
import { AppMenu, convertToMenuStr, getMenu, MenuName } from '../base/data/AppMenu'
import { getUserMenu } from '../base/data/User'
import Manual from '../manual/Manual'
import AdminPage from './admin/AdminPage'
import AlertMessage, { AlertMessageHandler } from './common/AlertMessage'
import LoadingPage from './common/LoadingPage'
import Dashboard from './dashboard/Dashboard'
import Header from './drawer/Header'
import EvaluationCreationView from './evaluation/creation/EvaluationCreationView'
import EvaluationDefectView from './evaluation/defect/EvaluationDefectView'
import EvidenceUploader from './evaluation/evidence/EvidenceUploader'
import EvaluationFileManagement from './evaluation/file/EvaluationFileManagement'
import EvaluationManagementView from './evaluation/management/EvaluationManagementView'
import PopulationSampling from './evaluation/population/PopulationSampling'
import EvaluationEditor from './evaluation/report/EvaluationEditor'
import FileBox from './fileBox/FileBox'
import Login from './login/Login'
import RCMBackup from './rcm/backup/RCMBackup'
import RCMEditor from './rcm/editor/RCMEditor'
import RCMVersionView from './rcm/version/RCMVersionView'
import RCMViewer from './rcm/viewer/RCMViewer'
import ResultReport from './resultReport/ResultReport'

LicenseManager.setLicenseKey(
  // eslint-disable-next-line max-len
  'CompanyName=CCK Solution,LicensedApplication=CCK_Solution,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=0,AssetReference=AG-013189,ExpiryDate=26_January_2022_[v2]_MTY0MzE1NTIwMDAwMA==224b6f081958562d34691d373b0af6d4'
)

const theme = createMuiTheme({
  palette: {
    primary: {
      main: lightBlue.color || '#1890ff'
    },
    secondary: {
      light: '#d0f4fe',
      main: '#61daf',
      dark: '#33c2f8'
    }
  }
})

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    width: '100%',
    height: '100%',
    flexGrow: 1,
    flexDirection: 'column'
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    padding: 0
  }
}))

const hasMenu = (appMenus: AppMenu[], menu: MenuName): boolean => {
  const found = _.find(appMenus, { name: menu })
  if (found) {
    return true
  }

  for (const appMenu of appMenus) {
    if (hasMenu(appMenu.children || [], menu)) {
      return true
    }
  }
  return false
}

const PrivateRoute: React.FC<RouteProps> = (props) => {
  if (!firebase.auth()?.currentUser) {
    const redirectComponent = () => <Redirect to={{ pathname: '/login' }} />
    return <Route {...props} component={redirectComponent} render={undefined} />
  }
  return <Route {...props} />
}

const Main: React.FC<RouteComponentProps> = ({ match }) => {
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)
  const [menu, setMenu] = React.useState(MenuName.Dashboard)
  const [userLevel, setUserLevel] = React.useState<Level>(Level.NORMAL)
  const location = useLocation()

  React.useEffect(() => {
    getOrCreateUser().then((user) => {
      setUserLevel(user.level)
    })
  }, [])

  React.useEffect(() => {
    setOpen(false)
    setMenu(getMenu(location.pathname, match.path))
  }, [location])

  const menus = React.useMemo(() => getUserMenu(userLevel), [userLevel])

  if (!hasMenu(menus, menu)) {
    return null
  }

  return (
    <>
      <Header current={menu} menus={menus} open={open} setOpen={setOpen} />
      <div className={classes.root}>
        <main className={classes.content}>
          <Switch>
            {_.map(
              [
                { menu: MenuName.Dashboard, component: <Dashboard /> },
                { menu: MenuName.RCMViewer, component: <RCMViewer /> },
                { menu: MenuName.RCMEditor, component: <RCMEditor /> },
                { menu: MenuName.RCMVersion, component: <RCMVersionView /> },
                { menu: MenuName.RCMBackup, component: <RCMBackup /> },
                {
                  menu: MenuName.OperationEvaluationCreation,
                  component: <EvaluationCreationView type="operation" />
                },
                {
                  menu: MenuName.OperationEvaluationFileManagement,
                  component: <EvaluationFileManagement type="operation" />
                },
                {
                  menu: MenuName.OperationEvaluationPopulationSampling,
                  component: <PopulationSampling />
                },
                {
                  menu: MenuName.OperationEvaluationEvidence,
                  component: <EvidenceUploader />
                },
                {
                  menu: MenuName.OperationEvaluationWritingReport,
                  component: <EvaluationEditor type="operation" />
                },
                {
                  menu: MenuName.OperationEvaluationManagement,
                  component: <EvaluationManagementView type="operation" />
                },
                {
                  menu: MenuName.OperationEvaluationDefect,
                  component: <EvaluationDefectView type="operation" />
                },
                {
                  menu: MenuName.DesignEvaluationCreation,
                  component: <EvaluationCreationView type="design" />
                },
                {
                  menu: MenuName.DesignEvaluationFileManagement,
                  component: <EvaluationFileManagement type="design" />
                },
                {
                  menu: MenuName.DesignEvaluationWritingReport,
                  component: <EvaluationEditor type="design" />
                },
                {
                  menu: MenuName.DesignEvaluationManagement,
                  component: <EvaluationManagementView type="design" />
                },
                {
                  menu: MenuName.DesignEvaluationModification,
                  component: <EvaluationDefectView type="design" />
                },
                { menu: MenuName.ResultReport, component: <ResultReport /> },
                { menu: MenuName.AdminPage, component: <AdminPage /> },
                { menu: MenuName.FileBox, component: <FileBox /> }
              ],
              ({ menu: menuType, component }: { menu: MenuName; component: ReactElement }) => {
                if (!hasMenu(menus, menu)) {
                  return null
                }
                return (
                  <Route
                    key={`route_${match.path}_${convertToMenuStr(menuType)}`}
                    path={`${match.path}/${convertToMenuStr(menuType)}`}
                    render={() => component}
                  />
                )
              }
            )}
          </Switch>
        </main>
      </div>
    </>
  )
}

const App: React.FC = () => {
  const classes = useStyles()
  const [states, setStates] = React.useState({
    loading: true,
    auth: firebase.auth()
  })
  const alertRef = React.useRef<AlertMessageHandler>(null)

  React.useEffect(() => {
    states.auth.useDeviceLanguage()
    const unregisterAuthObserver = states.auth.onAuthStateChanged(() => {
      // console.log('auth state changed', states.auth.currentUser)
      if (!states.auth.currentUser) {
        setStates({ ...states, loading: false })
        return
      }

      getOrCreateUser()
        .then((user) => {
          if (!_.includes([Level.ADMIN, Level.NORMAL, Level.UPLOADER, Level.WATCHER], user.level)) {
            // eslint-disable-next-line no-console
            console.log('auth state changed guest 관리자 alert', states.auth.currentUser)
            alertRef.current?.showAlert('info', '관리자 승인 중, 완료 후 접속 가능.')
            states.auth.signOut()
          }
        })
        .finally(() => {
          setStates({ ...states, loading: false })
        })
    })
    return () => unregisterAuthObserver()
  }, [])

  return (
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <div className={classes.root}>
          <ConfigProvider locale={locale}>
            <CssBaseline />
            {states.loading && <LoadingPage />}
            {!states.loading && (
              <Router>
                <Switch>
                  <Route component={Login} path="/login" />
                  <Route component={Manual} path="/manual" />
                  <PrivateRoute
                    path="/web"
                    render={(props: RouteComponentProps) => <Main {...props} />}
                  />
                  <Redirect path="*" to="/login" />
                </Switch>
              </Router>
            )}
            <AlertMessage ref={alertRef} />
          </ConfigProvider>
        </div>
      </ThemeProvider>
    </React.StrictMode>
  )
}

export default App
