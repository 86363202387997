import { progressStateToString } from '@cck/backend/dist/evaluation/EvaluationUtils'
import { parsePopulationFromStorage } from '@cck/backend/dist/evaluation/ExcelParser'
import { getOrCreateUser } from '@cck/backend/dist/utils/Admin'
import { FileState } from '@cck/common/dist/data/BaseFile'
import * as Evaluation from '@cck/common/dist/data/Evaluation'
import { Staff, Level } from '@cck/common/dist/data/Staff'
import { getFileSizeByMB, MAX_POPULATION_SIZE } from '@cck/common/dist/utils/FileUtils'
import { parsePopulationFileForWorker } from '@cck/js/src/PopulationFileReader'
import { useWorker } from '@koale/useworker'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Button from 'antd/lib/button'
import Typography from 'antd/lib/typography'
import _ from 'lodash'
import React from 'react'

import { grey } from '../../../base/color'
import AlertMessage, { AlertMessageHandler } from '../../common/AlertMessage'
import CircleBackdrop from '../../common/CircleBackdrop'
import PopulationFileUploader, { PopulationFileUploaderHandler } from './PopulationFileUploader'
import PopulationViewer, { PopulationHandler } from './PopulationViewer'
import SamplingDetailView, { SamplingHandler } from './SamplingDetailView'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: 'white',
    border: grey.border,
    display: 'flex',
    flexDirection: 'column',
    margin: theme.spacing(2),
    padding: theme.spacing(1)
  },
  saveButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginRight: theme.spacing(2)
  }
}))

interface Props {
  evaluation: Evaluation.EvaluationItem
  staffs: Staff[]
  onSavePopulationAndSampling: (
    populationFileName: string,
    population: Evaluation.TableContent | undefined,
    sampling: Evaluation.TableContent | undefined
  ) => void
  onSavePopulationFileAndSampling: (
    populationFileName: string,
    populationFiles: Evaluation.EvaluationFile[] | undefined,
    sampling: Evaluation.TableContent | undefined
  ) => void
  onResetPopulationSampling: () => void
}

const SamplingView: React.FC<Props> = ({
  evaluation,
  staffs,
  onSavePopulationAndSampling,
  onSavePopulationFileAndSampling,
  onResetPopulationSampling
}) => {
  const classes = useStyles()
  const populationFileUploaderRef = React.useRef<PopulationFileUploaderHandler>(null)
  const populationRef = React.useRef<PopulationHandler>(null)
  const samplingRef = React.useRef<SamplingHandler>(null)
  const [population, setPopulation] = React.useState<{
    fileName: string
    tableData: Evaluation.TableContent | undefined
  }>({
    fileName: '',
    tableData: undefined
  })
  const [userLevel, setUserLevel] = React.useState('UPLOADER')
  const [numberOfPopulation, setNumberOfPopulation] = React.useState(0)
  const [populationEditable, setPopulationEditable] = React.useState(true)
  const [canModified, setCanModified] = React.useState(false)
  const [populationFile, setPopulationFile] = React.useState<Evaluation.EvaluationFile | undefined>(
    undefined
  )
  const [states, setStates] = React.useState({ open: false })
  const alertRef = React.useRef<AlertMessageHandler>(null)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [readFileWorker] = useWorker(parsePopulationFileForWorker as any, {
    remoteDependencies: ['https://unpkg.com/xlsx@0.17.3/dist/xlsx.full.min.js']
  })

  React.useEffect(() => {
    getOrCreateUser().then((user) => {
      setUserLevel(_.get(user, 'level'))
    })
  }, [])

  const evalData = React.useMemo(() => {
    if (
      evaluation.content.files?.population &&
      evaluation.content.files.population.state === FileState.OK
    ) {
      evaluation.content.files.population.state = FileState.SAVED_FILE
    }
    return _.cloneDeep(evaluation?.content.data as Evaluation.OperationEvaluationData)
  }, [evaluation])

  React.useEffect(() => {
    if (
      evaluation.content.files?.population &&
      evaluation.content.files.population.state === FileState.OK
    ) {
      setCanModified(userLevel === Level.ADMIN || userLevel === Level.NORMAL)
    } else {
      setCanModified(true)
    }
  }, [evaluation, userLevel])

  React.useEffect(() => {
    setPopulation({
      fileName: '',
      tableData: undefined
    })
    setPopulationEditable(true)

    if (
      evaluation.content.files?.population &&
      _.includes([FileState.OK, FileState.SAVED_FILE], evaluation.content.files.population.state)
    ) {
      setStates({ ...states, open: true })
      parsePopulationFromStorage(
        evaluation.base.name,
        evaluation.controlId,
        evaluation.content.files?.population.name,
        readFileWorker
      )
        .then((parsedFile) => {
          if (parsedFile.state !== FileState.OK) {
            alertRef.current?.showAlert('error', _.join(parsedFile.excelException, ' '))
          }
          parsedFile.state = FileState.SAVED_FILE
          if (evaluation.content.files.population) {
            parsedFile.updateTime = evaluation.content.files.population.updateTime
            parsedFile.updater = evaluation.content.files.population.updater
          }
          setPopulationFile(parsedFile)
        })
        .finally(() => setStates({ ...states, open: false }))
    } else {
      setPopulationFile(undefined)
    }
  }, [evaluation])

  const isPerformedEvaluation = _.includes(
    [Evaluation.ProgressState.Performed, Evaluation.ProgressState.Approved],
    evaluation.state.state
  )

  return (
    <div>
      <div className={classes.saveButtonContainer}>
        <Typography.Text
          style={{
            marginRight: 4,
            display: isPerformedEvaluation ? 'block' : 'none'
          }}
          type="danger"
        >
          {progressStateToString(evaluation.state.state)} 된 보고서는 변경할 수 없습니다.
        </Typography.Text>
        <Typography.Text
          style={{
            marginRight: 4,
            display: !isPerformedEvaluation && !canModified ? 'block' : 'none'
          }}
          type="danger"
        >
          이미 작성된 모집단은 관리자 혹은 통제 평가자만 수정/삭제 할 수 있습니다.
        </Typography.Text>
        <Button
          type="primary"
          disabled={isPerformedEvaluation || !canModified}
          onClick={() => {
            try {
              if (populationEditable) {
                onSavePopulationAndSampling(
                  population.fileName,
                  populationRef.current?.getPopulation(),
                  samplingRef.current?.getSampling()
                )
              } else {
                onSavePopulationFileAndSampling(
                  population.fileName,
                  populationFileUploaderRef.current?.getPopulationFiles(),
                  samplingRef.current?.getSampling()
                )
              }
            } catch (e) {
              if ((e as Error).message === 'empty header') {
                alertRef.current?.showAlert('error', '모집단에서 비어있는 헤더가 있습니다.')
                return
              }
              onSavePopulationAndSampling(
                population.fileName,
                undefined,
                samplingRef.current?.getSampling()
              )
            }
          }}
        >
          저장
        </Button>
        <Button
          danger
          style={{
            marginLeft: 4,
            display: userLevel === Level.NORMAL || userLevel === Level.ADMIN ? 'block' : 'none'
          }}
          onClick={() => onResetPopulationSampling()}
          disabled={isPerformedEvaluation}
        >
          초기화
        </Button>
      </div>
      <div className={classes.root}>
        <PopulationFileUploader
          ref={populationFileUploaderRef}
          staffs={staffs}
          evaluationName={evaluation.base.name}
          controlId={evaluation.controlId}
          populationFile={populationFile}
          setPopulation={(
            populationFileName: string,
            newPopulation: Evaluation.TableContent | undefined,
            files: Evaluation.EvaluationFile[]
          ) => {
            const isEditable =
              !files ||
              _.isEmpty(files) ||
              !files[0].file ||
              getFileSizeByMB(files[0].file) < MAX_POPULATION_SIZE
            setPopulation({
              ...population,
              fileName: populationFileName,
              tableData: newPopulation
            })
            setPopulationEditable(isEditable)
          }}
        />
      </div>
      <div className={classes.root}>
        <PopulationViewer
          editable={populationEditable}
          canModified={canModified}
          setEditable={(editable) => setPopulationEditable(editable)}
          evaluation={evaluation}
          population={population.tableData}
          populationFile={populationFile}
          ref={populationRef}
          setNumberOfPopulation={(value) => setNumberOfPopulation(value)}
        />
      </div>
      {(userLevel === Level.ADMIN || userLevel === Level.NORMAL || userLevel === Level.WATCHER) && (
        <div className={classes.root}>
          <SamplingDetailView
            control={evaluation.content.control}
            ref={samplingRef}
            samplingResult={evalData.samplingResult}
            getPopulation={() => populationRef.current?.getPopulation()}
            getPopulationFiles={() => populationFileUploaderRef.current?.getPopulationFiles()}
            numberOfPopulation={numberOfPopulation}
          />
        </div>
      )}
      <AlertMessage ref={alertRef} />
      <CircleBackdrop open={states.open} />
    </div>
  )
}

export default SamplingView
