import * as EvaluationUtils from '@cck/backend/dist/evaluation/EvaluationUtils'
import { BaseFile } from '@cck/common/dist/data/BaseFile'
import * as Evaluation from '@cck/common/dist/data/Evaluation'
import _ from 'lodash'
import React from 'react'

import { getFileViewColumns } from '../../../base/utils/FileUtils'
import TableViewer, { TableViewerHandler } from '../../common/TableViewer'

interface Props {
  type: Evaluation.Type
  document: Evaluation.EvaluationItem
}

const EvaluationFileView: React.FC<Props> = ({ type, document }) => {
  const [files, setFiles] = React.useState<BaseFile[]>([])
  const viewerRef = React.useRef<TableViewerHandler>(null)

  React.useEffect(() => {
    setFiles(EvaluationUtils.getFileList(document))
  }, [type, document])

  // TODO(sangmuk): Load staffs?
  return (
    <TableViewer
      noBorder
      columnDefs={getFileViewColumns([])}
      domLayout="autoHeight"
      ref={viewerRef}
      rowData={files}
    />
  )
}

export default EvaluationFileView
