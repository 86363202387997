import packageJson from 'frontend/package.json'
import React from 'react'
import { CookiesProvider } from 'react-cookie'
import ReactDOM from 'react-dom'

import '@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css'
import '@ag-grid-enterprise/all-modules/dist/styles/ag-theme-alpine.css'
import './ag-grid-custom.scss'
import './index.css'
import 'firebaseui/dist/firebaseui.css'
import CacheBuster from './cache/CacheBuster'
import App from './component/App'
import reportWebVitals from './reportWebVitals'

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
global.appVersion = packageJson.version

ReactDOM.render(
  <React.StrictMode>
    <CookiesProvider>
      <CacheBuster>
        {({
          isLoading,
          isLatestVersion,
          clearCacheAndReload
        }: {
          isLoading: boolean
          isLatestVersion: boolean
          clearCacheAndReload: () => void
        }) => {
          if (isLoading) return null

          if (!isLoading && !isLatestVersion) {
            // You can decide how and when you want to force reload
            clearCacheAndReload()
          }

          return <App />
        }}
      </CacheBuster>
    </CookiesProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
